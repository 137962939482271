import {
    SET_ACCESS_TOKEN, SIGN_IN_FAILURE, SIGN_OUT, SET_USER_PROFILE,
    SET_USER_PHOTO,
    SET_API_ACCESS_TOKEN,
    SET_POWER_BI_ACCESS_TOKEN,
    SET_CORE_API_ACCESS_TOKEN,
    SET_COMMON_SERVICE_TOKEN
} from '../../constants/actions/Auth/auth';
import { IAction } from '../../constants/types/IAction';
import { IAuthState } from '../../constants/types/Auth';
import _ from 'lodash';

const initialState: IAuthState = {
    user: null,
    error: null,
    userProfile: null,
    blobUrl: null,
    accessToken: null,
    groups: null,
    apiAccessToken: null,
    powerBIAccessToken: null,
    coreApiAccessToken : null
};

const AuthReducers = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SIGN_IN_FAILURE:
            return {
                ...state,
                user: null,
                error: action.payload.error,
            };
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload.accessToken,
                groups: action.payload.groups,
                user: _.cloneDeep(action.payload.user),
            };
        case SIGN_OUT:
            return {
                ...state,
                user: null,
                error: null
            };
        case SET_USER_PHOTO:
            return {
                ...state,
                blobUrl: action.payload.blobUrl
            }
        case SET_USER_PROFILE:
            return {
                ...state,
                userProfile: action.payload
            }
        case SET_API_ACCESS_TOKEN:
            return {
                ...state,
                apiAccessToken: action.payload.accessToken
            }
        case SET_POWER_BI_ACCESS_TOKEN:
            return {
                ...state,
                powerBIAccessToken: action.payload.accessToken
            }
        case SET_COMMON_SERVICE_TOKEN:
            return {
                ...state,
                commonServiceToken: action.payload.accessToken
            }
        case SET_CORE_API_ACCESS_TOKEN:
            return {
                ...state,
                coreApiAccessToken: action.payload.accessToken
            }
        default:
            return state;
    }
}

export default AuthReducers;