import React from "react";
import { ICategory } from "./BoardInterfaces";
import "./Board.css";
import SideNav from "../common/SideNav";
import EngagementsList from "../engagements/EngagementsList";
import { connect } from "react-redux";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import {
  getEngagements,
  getEngagementsCategory,
} from "../../actions/UI/Engagements";
import { Account, AuthResponse } from "msal";
import { toggleSpinner } from "../../actions/UI/globalSpinner";
import AppConstants, { AppRoles } from "../../constants/AppConstants";
import moment from "moment";

import {
  activeSideNavs,
  ISorting,
  searchCalled,
} from "../../constants/types/UI/Generic";
import {
  ENGAGEMENT_STATUS,
  IGetEngagementCategoryPayload,
  IGetEngagementPayload,
  MAPPED_ENGAGEMENT_STATUS,
  ENGAGEMENT_VIEWS,
} from "../../constants/types/UI/Engagements";
import { IDataResponse } from "../../constants/types/API/Apiresponse";
import {
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  IconButton,
  IContextualMenuProps,
  IIconProps,
  MessageBarType,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";
import { ENGAGEMENTS_URL } from "../../constants/routes";
import { logEvent, TeamsMenuClicked } from "../../utils/insightsClient";
import { showModal } from "../../actions/UI/Modal";
import { IModalPayload } from "../../constants/types/UI/Modal";
import { MPJSONDialog } from "../utils/MPJSONDialog";
import { IBannerMessagePayload } from "../../constants/types/UI/BannerMessage";
import { setBanner } from "../../actions/UI/BannerMessage";

interface IBoardProps {
  dndHandler: (a: string, b: string) => void;
  searchTerm: string;
  activeStatus: string;
  getEngagements: Function;
  user: Account | null;
  apiAccessToken: AuthResponse | null;
  graphTokenObj: AuthResponse | null;
  coreApiAccessToken: AuthResponse | null;
  engagementsData: IDataResponse;
  engagementCategories: IDataResponse;
  toggleSpinner: Function;
  history: any;
  changeActiveStatus: Function;
  selectedView: string;
  sortingOrder: ISorting;
  changeSortingOrder: Function;
  buildAndShowBanner: (
    message: string,
    messageType: MessageBarType,
    timeOut: number
  ) => void;
  upsertEngagementState: IDataResponse;
  metaData: IDataResponse;
  getEngagementCategories: Function;
  roles: { name: string }[] | undefined;
  modalProps: IModalPayload;
  showModal: Function;
  setBanner: Function;
  userContext: any;
}

export interface IEngagements {
  IdLink: any;
  EngagementId: number;
  Customer: string;
  Customer_Onboarding: string;
  Description: string;
  OfferTitle: string;
  OfferCode: string;
  SubmittedDateTime: string;
  LiveDateTime: string;
  EndDate: string;
  DeliveryLead: string;
  DeliveryLeadDetails?: any;
  Status: number;
  DeliveryPod: [
    {
      Alias: string;
    }
  ];
  DeliveryTeam: any;
}

interface IBoardState {
  items: any;
  _columns: IColumn[];
  categoryData: ICategory[];
  sideMenuStatus: boolean;
  activeStatusData: {
    displayText: string;
    count: number;
  };
  copilotPinnedState: boolean;
}

interface IDisplayPicture {
  displayName: string;
  displayPicture: string;
}

const teamsIcon: IIconProps = { iconName: "TeamsLogo16" };

const calloutProps = { gapSpace: 0 };
// The TooltipHost root uses display: inline by default.
// If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};

const sortEngagements = (
  a: { Status: number; Count: number },
  b: { Status: number; Count: number }
) => {
  const orderToBeShown = {
    [ENGAGEMENT_STATUS.LIVE]: 0,
    [ENGAGEMENT_STATUS.CREATED]: 1,
    [ENGAGEMENT_STATUS.PUBLISHING]: 2,
    [ENGAGEMENT_STATUS.GOING_LIVE]: 3,
    [ENGAGEMENT_STATUS.CLOSED]: 4,
    [ENGAGEMENT_STATUS.FAILED]: 5,
    [ENGAGEMENT_STATUS.DRAFT]: 6,
    [ENGAGEMENT_STATUS.COMPLETED]: 7,
  };
  var nameA = orderToBeShown[a.Status],
    nameB = orderToBeShown[b.Status];
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};
var renderCount: number = 0;

export class Board extends React.Component<IBoardProps, IBoardState> {
  constructor(props: IBoardProps) {
    super(props);
    this.state = {
      categoryData: [],
      items: [],
      _columns: this.genColumns(
        this.props.sortingOrder,
        this.props.activeStatus === "LIVE" ? 8 : 7
      ),
      sideMenuStatus: false,
      activeStatusData: {
        displayText: "",
        count: 0,
      },
      copilotPinnedState: this.getPinnedState(),
    };
  }

  genColumns = (sortingOrder: ISorting, columns: number): IColumn[] => {
    let renderCountAddOn = renderCount * 20;
    var width = window.innerWidth - window.innerWidth * 0.06 - 70 - 25 - 15;
    var viewPortWidth = width / columns - 20;
    let columnData: IColumn[] = [
      {
        key: (renderCountAddOn + 1).toString(),
        name: "ID",

        fieldName: "IdLink",
        minWidth: viewPortWidth / 2,
        maxWidth: viewPortWidth / 2,
        isSorted: sortingOrder.column == "EngagementId",
        isSortedDescending:
          sortingOrder.column == "EngagementId"
            ? sortingOrder.order === "desc"
            : undefined,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: (renderCountAddOn + 2).toString(),
        name: "Title",
        fieldName: "Customer",
        minWidth: viewPortWidth,
        maxWidth: viewPortWidth,
        isSorted: sortingOrder.column == "Customer",
        isSortedDescending:
          sortingOrder.column == "Customer"
            ? sortingOrder.order === "desc"
            : undefined,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: (renderCountAddOn + 3).toString(),
        name: "Description",
        fieldName: "Description",
        minWidth: viewPortWidth,
        maxWidth: viewPortWidth,
        isSorted: sortingOrder.column == "Description",
        isSortedDescending:
          sortingOrder.column == "Description"
            ? sortingOrder.order === "desc"
            : undefined,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: (renderCountAddOn + 4).toString(),
        name: "Offer Type",
        fieldName: "OfferCode",
        minWidth: viewPortWidth,
        maxWidth: viewPortWidth,
        isSorted: sortingOrder.column == "OfferCode",
        isSortedDescending:
          sortingOrder.column == "OfferCode"
            ? sortingOrder.order === "desc"
            : undefined,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: (renderCountAddOn + 5).toString(),
        name:
          this.props.activeStatus === "IN_DRAFT"
            ? "Created Date"
            : "Submitted Date",
        fieldName: "SubmittedDateTime",
        minWidth: viewPortWidth,
        maxWidth: viewPortWidth,
        isSorted: sortingOrder.column == "SubmittedDateTime",
        isSortedDescending:
          sortingOrder.column == "SubmittedDateTime"
            ? sortingOrder.order === "desc"
            : undefined,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];
    if (this.props.activeStatus === "LIVE") {
      columnData.push({
        key: (renderCountAddOn + 6).toString(),
        name: "Live Date",
        fieldName: "LiveDateTime",
        minWidth: viewPortWidth,
        maxWidth: viewPortWidth,
        isSorted: sortingOrder.column == "LiveDateTime",
        isSortedDescending:
          sortingOrder.column == "LiveDateTime"
            ? sortingOrder.order === "desc"
            : undefined,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      });
    }
    if (this.props.activeStatus === "COMPLETED") {
      columnData.push({
        key: (renderCountAddOn + 6).toString(),
        name: "End date",
        fieldName: "EndDate",
        minWidth: viewPortWidth,
        maxWidth: viewPortWidth,
        isSorted: sortingOrder.column == "EndDate",
        isSortedDescending:
          sortingOrder.column == "EndDate"
            ? sortingOrder.order === "desc"
            : undefined,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      });
    }
    columnData.push(
      {
        key: (renderCountAddOn + 7).toString(),
        name: "Delivery Lead",
        fieldName: "DeliveryLead",
        minWidth: viewPortWidth,
        maxWidth: viewPortWidth,
        isSorted: sortingOrder.column == "DeliveryLead",
        isSortedDescending:
          sortingOrder.column == "DeliveryLead"
            ? sortingOrder.order === "desc"
            : undefined,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: (renderCountAddOn + 8).toString(),
        name: "Delivery Team",
        fieldName: "DeliveryTeam",
        minWidth: viewPortWidth * 1.5,
        maxWidth: viewPortWidth * 1.5,
        isSorted: false,
        isResizable: true,
      },
      {
        key: (renderCountAddOn + 9).toString(),
        name: "Onboarding Type",
        fieldName: "Customer_Onboarding" ,
        minWidth: viewPortWidth,
        maxWidth: viewPortWidth,
        isSorted: sortingOrder.column == "Customer_Onboarding",
        isSortedDescending:
          sortingOrder.column == "Customer_Onboarding"
            ? sortingOrder.order === "desc"
            : undefined,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      }     
    );
    renderCount = renderCount + 1;
    return columnData;
  };

  triggerGetEngagementCategories = () => {
    const engPayload: IGetEngagementCategoryPayload = {
      searchTerm: this.props.searchTerm,
      selectedView: this.props.selectedView,
    };
    this.props.getEngagementCategories(engPayload);
  };

  triggerGetEngagements = () => {
    this.triggerGetEngagementCategories();
    const event = new Event("triggerEngagementUpdate");
    document.dispatchEvent(event);
    this.enableLoader();
    const engPayload: IGetEngagementPayload = {
      alias: this.props.user?.userName.split("@")[0] ?? "",
      searchTerm: this.props.searchTerm,
      activeStatus: this.props.activeStatus,
      sortingOrder: this.props.sortingOrder,
      selectedView: this.props.selectedView,
    };
    this.props.getEngagements(engPayload);
  };

  componentDidMount() {
    if (
      this.props.user &&
      this.props.apiAccessToken &&
      this.props.coreApiAccessToken
    ) {
      this.triggerGetEngagements();
    }
    window.addEventListener("storage", this.handlePinnedState, false);
  }

  componentDidUpdate(prevProps: IBoardProps, prevState: IBoardState) {
    if (prevProps.engagementsData !== this.props.engagementsData) {
      if (
        this.props.engagementsData.data &&
        !this.props.engagementsData.fetching
      ) {
        this.setState({
          items: this.buildEngagementItems(this.props.engagementsData.data),
          activeStatusData: {
            displayText:
              MAPPED_ENGAGEMENT_STATUS[this.props.activeStatus].DISPLAY_NAME,
            count: this.props.engagementsData.data.length,
          },
        });
        this.disableLoader();
      } else if (
        this.props.engagementsData.error &&
        !this.props.engagementsData.fetching
      ) {
        this.props.buildAndShowBanner(
          "Failed to get engagement data properly. Please try again!!",
          MessageBarType.severeWarning,
          5000
        );
        this.disableLoader();
      }
    }

    if (prevProps.engagementCategories !== this.props.engagementCategories) {
      if (
        this.props.engagementCategories.data &&
        !this.props.engagementCategories.fetching
      ) {
        if (!!this.props.searchTerm) {
          const normalIdentifier = this.changeSideNavBasedOnSearch(
            this.props.engagementCategories.data
          );
          if (
            !!normalIdentifier &&
            searchCalled.engagementSearchCalled == true
          ) {
            searchCalled.engagementSearchCalled = false;
            this.sideNavCallback(normalIdentifier);
          } else if (searchCalled.engagementSearchCalled == true) {
            searchCalled.engagementSearchCalled = false;
          }
        }
        this.buildCategories(this.props.engagementCategories.data);
      } else if (
        this.props.engagementCategories.error &&
        !this.props.engagementCategories.fetching
      ) {
        this.props.buildAndShowBanner(
          "Failed to get engagement data properly. Please try again!!",
          MessageBarType.severeWarning,
          5000
        );
        this.disableLoader();
      }
    }

    // get engagements again
    if (
      prevProps.searchTerm !== this.props.searchTerm ||
      prevProps.activeStatus !== this.props.activeStatus ||
      prevProps.sortingOrder !== this.props.sortingOrder ||
      prevProps.selectedView !== this.props.selectedView
    ) {
      this.triggerGetEngagements();
    }

    if (prevProps.activeStatus !== this.props.activeStatus) {
      this.setState({
        _columns: this.genColumns(
          this.props.sortingOrder,
          this.props.activeStatus === "LIVE" ? 8 : 7
        ),
      });
    }
  }

  changeSideNavBasedOnSearch = (engagementCategories: any) => {
    const getSortedCategories = engagementCategories.sort(sortEngagements);
    const foundCategory = getSortedCategories.find((cat: any) => cat.Count > 0);
    if (foundCategory) {
      const mappedEngStatus = Object.values(MAPPED_ENGAGEMENT_STATUS).find(
        (mp_en_status) => {
          return (
            mp_en_status.ORIGINAL_STATUS.indexOf(foundCategory.Status) > -1
          );
        }
      )?.IDENTIFIER;
      var nonNullActive = undefined;
      MAPPED_ENGAGEMENT_STATUS[this.props.activeStatus].ORIGINAL_STATUS.forEach(
        (orgStatus) => {
          const engCategory = getSortedCategories.find(
            (eng: { Status: number; Count: number }) => eng.Status === orgStatus
          );
          if (engCategory !== null && engCategory != undefined) {
            nonNullActive =
              MAPPED_ENGAGEMENT_STATUS[this.props.activeStatus].IDENTIFIER;
          }
        }
      );
      if (!nonNullActive) {
        return mappedEngStatus;
      }
      return undefined;
    }
    return undefined;
  };

  handlePinnedState = () => {
    this.setState({ copilotPinnedState: this.getPinnedState() });
  };

  getPinnedState = (): boolean => {
    const isPinned = sessionStorage.getItem("copilotPinnedState");
    if (isPinned) {
      return JSON.parse(isPinned);
    }
    return false;
  };

  buildEngagementItems = (data: IEngagements[]) => {
    const details: IEngagements[] = this.props.engagementsData.data.map(
      (item: IEngagements) => {
        let container: IEngagements = item;
        let team = "";
        container.IdLink = this.getIdLink(container.EngagementId, container.Status);
        container.Customer_Onboarding = item.Customer_Onboarding;
        container.Description =
          item.Description === "" ? "--" : item.Description;
        container.OfferCode = item.OfferTitle === "" ? "--" : item.OfferTitle;
        container.SubmittedDateTime =
          item.SubmittedDateTime == null
            ? "--"
            : this.formatDateTime(item.SubmittedDateTime);
        container.LiveDateTime =
          item.LiveDateTime == null
            ? "--"
            : this.formatDateTime(item.LiveDateTime);
        container.EndDate =
          item.EndDate == null ? "--" : this.formatDateTime(item.EndDate);
        item.DeliveryPod.forEach(function (element) {
          team =
            team.length > 0
              ? team + ", " + element.Alias
              : team + element.Alias;
        });
        container.DeliveryTeam = this.getDeliveryTeamText(
          team,
          container.EngagementId
        );

        return container;
      }
    );

    return details;
  };

  isUserPartOfDeliveryTeam = (deliveryTeam: string) => {
    let isUserPartOfDeliveryteam = false;

    deliveryTeam.split(", ").forEach((elem: string) => {
      isUserPartOfDeliveryteam =
        isUserPartOfDeliveryteam ||
        (elem == this.props.user?.userName.split("@")[0] ?? "");
    });

    return isUserPartOfDeliveryteam;
  };

  getTeamsMenuProps = (deliveryTeam: string, EngagementID: number) => {
    let menuProps: IContextualMenuProps = {
      items: [
        {
          key: "share",
          text: "Share to Teams",
          title: "Share this Engagement link",
          iconProps: { iconName: "share" },
          onClick: () => {
            logEvent(TeamsMenuClicked, {
              AppName: AppConstants.ApplicationAlias,
              AppLayer: "UI",
              metricName: "TeamsOperation",
              UserScenario: "User Clicks on Teams Menu",
              User: { Alias: this.props.userContext.email },
              applicationRoles: this.props.userContext.roles,
              entityOperation: "Share to Teams",
              entityName: "",
              metaData: {
                EntityId: EngagementID,
                NoOfParticipants: deliveryTeam.split(",").length,
                sourcePage: "Engagements",
              },
              traceSeverity: "Medium",
            });

            let teamsShareLink: string =
              AppConstants.shareToTeamsLink +
              "msgText=Please look into this CAPE Engagement" +
              "&href=" +
              window.location +
              "?id=" +
              EngagementID;
            window.open(
              teamsShareLink,
              "Share to Teams",
              "height=600,width=600,modal=yes,alwaysRaised=yes"
            );
          },
        },
      ],
      directionalHintFixed: false,
    };

    if (!this.isUserPartOfDeliveryTeam(deliveryTeam)) {
      menuProps.items.push({
        key: "scheduleMeeting",
        text: "Schedule meeting with " + deliveryTeam,
        iconProps: { iconName: "CalendarReply" },
        onClick: () => {
          logEvent(TeamsMenuClicked, {
            AppName: AppConstants.ApplicationAlias,
            AppLayer: "UI",
            metricName: "TeamsOperation",
            UserScenario: "User Clicks on Teams Menu",
            User: { Alias: this.props.userContext.email },
            applicationRoles: this.props.userContext.roles,
            entityOperation: "Schedule meeting",
            entityName: "",
            metaData: {
              EntityId: EngagementID,
              NoOfParticipants: deliveryTeam.split(",").length,
              sourcePage: "Engagements",
            },
            traceSeverity: "Medium",
          });

          let scheduleMeetingLink =
            AppConstants.TeamsMeetingDeepLink +
            deliveryTeam +
            "@microsoft.com" +
            "&subject=CAPE Engagement " +
            EngagementID;
          window.open(scheduleMeetingLink, "_blank");
        },
      });
    }
    return menuProps;
  };

  getTeamsGroupMenuProps = (deliveryTeam: string, EngagementID: number) => {
    let teamsAliases = "";
    let groupChat = false;
    let deliveryTeamExcludingUser = "";
    deliveryTeam.split(", ").forEach((elem: string) => {
      if (elem != this.props.user?.userName.split("@")[0] ?? "") {
        if (deliveryTeamExcludingUser.length > 0) {
          deliveryTeamExcludingUser += ", ";
          groupChat = true;
        }
        deliveryTeamExcludingUser += elem;
      }

      teamsAliases += elem + "@microsoft.com,";
    });
    let menuProps: IContextualMenuProps = {
      items: [
        {
          key: "share",
          text: "Share to Teams",
          title: "Share this Engagement link",
          iconProps: { iconName: "share" },
          onClick: () => {
            logEvent(TeamsMenuClicked, {
              AppName: AppConstants.ApplicationAlias,
              AppLayer: "UI",
              metricName: "TeamsOperation",
              UserScenario: "User Clicks on Teams Menu",
              User: { Alias: this.props.userContext.email },
              applicationRoles: this.props.userContext.roles,
              entityOperation: "Share to Teams",
              entityName: "",
              metaData: {
                EntityId: EngagementID,
                NoOfParticipants: deliveryTeam.split(",").length,
                sourcePage: "Engagements",
              },
              traceSeverity: "Medium",
            });

            let teamsShareLink: string =
              AppConstants.shareToTeamsLink +
              "msgText=Please look into this CAPE Engagement" +
              "&href=" +
              window.location +
              "?id=" +
              EngagementID;
            window.open(
              teamsShareLink,
              "Share to Teams",
              "height=600,width=600,modal=yes,alwaysRaised=yes"
            );
          },
        },
        {
          key: "scheduleMeeting",
          text:
            "Schedule meeting with " +
            this.getTeamsMeetingText(deliveryTeamExcludingUser),
          title: deliveryTeamExcludingUser,
          iconProps: { iconName: "CalendarReply" },
          onClick: () => {
            logEvent(TeamsMenuClicked, {
              AppName: AppConstants.ApplicationAlias,
              AppLayer: "UI",
              metricName: "TeamsOperation",
              UserScenario: "User Clicks on Teams Menu",
              User: { Alias: this.props.userContext.email },
              applicationRoles: this.props.userContext.roles,
              entityOperation: "Schedule meeting",
              entityName: "",
              metaData: {
                EntityId: EngagementID,
                NoOfParticipants: deliveryTeam.split(",").length,
                sourcePage: "Engagements",
              },
              traceSeverity: "Medium",
            });

            let scheduleMeetingLink =
              AppConstants.TeamsMeetingDeepLink +
              teamsAliases +
              "&subject=CAPE Engagement " +
              EngagementID;
            window.open(scheduleMeetingLink, "_blank");
          },
        },
      ],
      directionalHintFixed: false,
    };
    return menuProps;
  };

  getTeams = (teamID: number) => {
    let teamText = "";
    this.props.metaData.data.Team.values.forEach((team: any) => {
      if (team.metaData.cdd_Id === teamID) {
        teamText = team.metaData.cdd_Text;
      }
    });
    return teamText;
  };

  buildAndShowBanner(
    message: string,
    messageType: MessageBarType,
    timeOut: number
  ) {
    const bannerProps: IBannerMessagePayload = {
      toShow: true,
      message: message,
      messageType: messageType,
      styleClass: "",
      timeout: timeOut,
    };
    this.props.setBanner(bannerProps);
  }

  onIdClick = (EngagementID: number, status: number) => {
    sessionStorage.setItem("showCopilotWindow", String(false));
    sessionStorage.setItem("engagementPanelOpen", String(true));
    window.dispatchEvent(new Event("storage"));
    // if(status === ENGAGEMENT_STATUS.LIVE) {
    //     this.buildAndShowBanner("You are editing live engagement !", MessageBarType.warning, 6000);
    // }
    this.props.history.push(
      `${ENGAGEMENTS_URL}?id=${EngagementID}${this.props.history["location"][
        "search"
      ].replace("?", "&")}`
    );
  };

  getIdLink = (EngagementId: number, status: number) => {
    return (
      <TooltipHost
        content={"Click to Open"}
        id={EngagementId.toString()}
        calloutProps={calloutProps}
        styles={hostStyles}
      >
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => {
            this.onIdClick(EngagementId, status);
          }}
        >
          {EngagementId}
        </span>
      </TooltipHost>
    );
  };

  getTeamsMeetingText = (deliveryTeam: string) => {
    let leads = deliveryTeam?.split(", ");
    let moreText = " +" + (leads.length - 1) + " more";
    return leads.length > 1 ? leads[0] + moreText : leads[0];
  };

  getDeliveryTeamText = (deliveryTeam: string, EngagementID: number) => {
    let leads = deliveryTeam.split(", ");
    if (leads.length > 1) {
      let firstDeliveryLead = leads[0];
      return (
        <>
          <div style={{ marginTop: "-5px" }}>
            {
              <TooltipHost
                content={"Teams Menu"}
                id={`${deliveryTeam}-${Math.random()}`}
                calloutProps={calloutProps}
                styles={hostStyles}
              >
                <Stack tokens={{ childrenGap: 8 }} horizontal>
                  <IconButton
                    menuProps={this.getTeamsGroupMenuProps(
                      deliveryTeam,
                      EngagementID
                    )}
                    onMenuClick={() =>
                      logEvent(TeamsMenuClicked, {
                        AppName: AppConstants.ApplicationAlias,
                        AppLayer: "UI",
                        metricName: "TeamsOperation",
                        UserScenario: "User Clicks on Teams Menu",
                        User: { Alias: this.props.userContext.email },
                        applicationRoles: this.props.userContext.roles,
                        entityOperation: "Menu",
                        entityName: "",
                        metaData: {
                          EntityId: EngagementID,
                          NoOfParticipants: deliveryTeam.split(",").length,
                          sourcePage: "Engagements",
                        },
                        traceSeverity: "Medium",
                      })
                    }
                    iconProps={teamsIcon}
                    disabled={false}
                    checked={false}
                    style={{
                      paddingLeft: "10px",
                      fontSize: "15px",
                      color: "#464EB8",
                      marginTop: "-3px",
                    }}
                    ariaLabel={`${this.props.user?.userName}-${Math.random()}`}
                  />
                </Stack>
              </TooltipHost>
            }
            {firstDeliveryLead}
            <TooltipHost
              content={deliveryTeam.toString()}
              id={`${deliveryTeam}-${Math.random()}`}
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <span
                aria-describedby={`${deliveryTeam}-${Math.random()}`}
                className="moreData"
              >
                {" "}
                {`+${deliveryTeam.split(", ").length - 1} more`}
              </span>
            </TooltipHost>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            {
              <TooltipHost
                content={"Teams Menu"}
                id={`${deliveryTeam}-${Math.random()}`}
                calloutProps={calloutProps}
                styles={hostStyles}
              >
                <Stack tokens={{ childrenGap: 8 }} horizontal>
                  <IconButton
                    menuProps={this.getTeamsMenuProps(
                      deliveryTeam,
                      EngagementID
                    )}
                    onMenuClick={() =>
                      logEvent(TeamsMenuClicked, {
                        AppName: AppConstants.ApplicationAlias,
                        AppLayer: "UI",
                        metricName: "TeamsOperation",
                        UserScenario: "User Clicks on Teams Menu",
                        User: { Alias: this.props.userContext.email },
                        applicationRoles: this.props.userContext.roles,
                        entityOperation: "Share to Teams",
                        entityName: "",
                        metaData: {
                          EntityId: EngagementID,
                          NoOfParticipants: deliveryTeam.split(",").length,
                          sourcePage: "Engagements",
                        },
                        traceSeverity: "Medium",
                      })
                    }
                    iconProps={teamsIcon}
                    disabled={false}
                    checked={false}
                    style={{
                      paddingLeft: "10px",
                      fontSize: "15px",
                      color: "#464EB8",
                      marginTop: "-3px",
                    }}
                    ariaLabel={`${this.props.user?.userName}-${Math.random()}`}
                  />
                </Stack>
              </TooltipHost>
            }
            {leads[0]}
          </div>
        </>
      );
    }
  };
  // format datetime
  private formatDateTime = (dateTime: string): string => {
    return moment(new Date(dateTime)).isValid()
      ? moment.utc(dateTime).local().format("MM/DD/YYYY hh:mm A")
      : "--";
  };

  // column sorting
  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    this.enableLoader();
    const { _columns, items } = this.state;
    const newColumns: IColumn[] = _columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol.key === currColumn.key) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let fieldName =
          newCol.fieldName === "IdLink" ? "EngagementId" : newCol.fieldName;
        this.props.changeSortingOrder({
          column: fieldName,
          order: currColumn.isSortedDescending ? "desc" : "asc",
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = false;
      }
    });
    this.setState({
      _columns: newColumns,
    });
  };

  getCategoryCount = (engagementCategories: any, identifier: string) => {
    var catCount = 0;
    engagementCategories.forEach(
      (category: { Status: number; Count: number }) => {
        if (
          MAPPED_ENGAGEMENT_STATUS[identifier].ORIGINAL_STATUS.indexOf(
            category.Status
          ) > -1
        ) {
          catCount = catCount + category.Count;
        }
      }
    );
    return catCount;
  };

  buildCategories = (engagementCategories: any) => {
    this.setState({
      categoryData: this.buildCategoryData(engagementCategories),
    });
  };

  getItemCountForProcessing = (engagementCategories: any) => {
    var processingItemCount = this.getCategoryCount(
      engagementCategories,
      MAPPED_ENGAGEMENT_STATUS.PROCESSING.IDENTIFIER
    );

    // Non SiteAdmin role will see processing + failed count under processing category.
    if (
      !this.props.roles?.find(
        (role) => role.name === AppRoles.SiteAdmin.roleName
      )
    ) {
      processingItemCount += this.getCategoryCount(
        engagementCategories,
        MAPPED_ENGAGEMENT_STATUS.ERROR_FOUND.IDENTIFIER
      );
    }

    return processingItemCount;
  };

  buildCategoryData = (engagementCategories: any) => {
    var categoryData: ICategory[] = [
      {
        title: MAPPED_ENGAGEMENT_STATUS.LIVE.IDENTIFIER,
        displayName: MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME,
        itemCount: this.getCategoryCount(
          engagementCategories,
          MAPPED_ENGAGEMENT_STATUS.LIVE.IDENTIFIER
        ),
        icon: "completed",
      },
      {
        title: MAPPED_ENGAGEMENT_STATUS.PROCESSING.IDENTIFIER,
        displayName: MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME,
        itemCount: this.getItemCountForProcessing(engagementCategories),
        icon: "processing",
      },
      {
        title: MAPPED_ENGAGEMENT_STATUS.CLOSED.IDENTIFIER,
        displayName: MAPPED_ENGAGEMENT_STATUS.CLOSED.DISPLAY_NAME,
        itemCount: this.getCategoryCount(
          engagementCategories,
          MAPPED_ENGAGEMENT_STATUS.CLOSED.IDENTIFIER
        ),
        icon: "errorbadge",
      },
      {
        title: MAPPED_ENGAGEMENT_STATUS.COMPLETED.IDENTIFIER,
        displayName: MAPPED_ENGAGEMENT_STATUS.COMPLETED.DISPLAY_NAME,
        itemCount: this.getCategoryCount(
          engagementCategories,
          MAPPED_ENGAGEMENT_STATUS.COMPLETED.IDENTIFIER
        ),
        icon: "accept",
      },
    ];

    //Only SiteAdmin role can see Failed engagements category
    if (
      this.props.roles?.find(
        (role) => role.name === AppRoles.SiteAdmin.roleName
      )
    ) {
      categoryData.push({
        title: MAPPED_ENGAGEMENT_STATUS.ERROR_FOUND.IDENTIFIER,
        displayName: MAPPED_ENGAGEMENT_STATUS.ERROR_FOUND.DISPLAY_NAME,
        itemCount: this.getCategoryCount(
          engagementCategories,
          MAPPED_ENGAGEMENT_STATUS.ERROR_FOUND.IDENTIFIER
        ),
        icon: "error",
      });
    }

    categoryData.push({
      title: MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.IDENTIFIER,
      displayName: MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME,
      itemCount: this.getCategoryCount(
        engagementCategories,
        MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.IDENTIFIER
      ),
      icon: "pageedit",
    });

    return categoryData;
  };

  sideNavCallback = (childData: string) => {
    if (this.props.activeStatus !== childData) {
      activeSideNavs.engagements = childData;
      this.props.changeActiveStatus(childData);
    }
  };

  sideMenuStatus = (childData: boolean) => {
    this.setState({
      sideMenuStatus: childData,
    });
  };

  enableLoader = () => {
    this.props.toggleSpinner({
      spinner: true,
      spinnerMessage: "Fetching Engagements",
    });
  };

  disableLoader = () => {
    this.props.toggleSpinner({
      spinner: false,
      spinnerMessage: "",
    });
  };
  closeModal = () => {
    this.props.showModal({
      toShow: false,
      title: "",
      text: ``,
    });
  };
  render() {
    return (
      <div className="board">
        {this.props.modalProps.toShow && (
          <Dialog
            hidden={false}
            onDismiss={() => this.closeModal()}
            dialogContentProps={{
              type: DialogType.normal,
              title: this.props.modalProps.title,
              subText: this.props.modalProps.text,
            }}
          >
            <DialogFooter>
              <PrimaryButton onClick={() => this.closeModal()} text="Close" />
            </DialogFooter>
          </Dialog>
        )}
        <SideNav
          categoryData={this.state.categoryData}
          parentCallback={this.sideNavCallback}
          activeMenu={this.props.activeStatus}
          userContext={this.props.userContext}
        />
        <EngagementsList
          _columns={this.state._columns}
          items={this.state.items}
          history={this.props.history}
          activeStatusData={this.state.activeStatusData}
          fetching={!!this.props.engagementsData.fetching}
          searchTerm={this.props.searchTerm}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: { ui: any; auth: any; api: any }) => ({
  user: state.auth.user,
  apiAccessToken: state.auth.apiAccessToken,
  coreApiAccessToken: state.auth.coreApiAccessToken,
  graphTokenObj: state.auth.accessToken,
  engagementsData: state.api.engagementsData,
  engagementCategories: state.api.engagementCategories,
  upsertEngagementState: state.api.upsertEngagement,
  metaData: state.api.metaData,
  modalProps: state.ui.modalProps,
  userContext: state.api.userContext.data,
});

const mapDispatchToProps = (dispatch: any) => ({
  getEngagements: (payload: IGetEngagementPayload) =>
    dispatch(getEngagements(payload)),
  getEngagementCategories: (payload: IGetEngagementCategoryPayload) =>
    dispatch(getEngagementsCategory(payload)),
  toggleSpinner: (payload: boolean) => dispatch(toggleSpinner(payload)),
  showModal: (payload: any) => dispatch(showModal(payload)),
  setBanner: (payload: IBannerMessagePayload) => dispatch(setBanner(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Board);
