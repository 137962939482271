import _ from "lodash";
import React, { Component } from "react";
import { signIn } from "../../actions/Auth";
import { IMainProps } from "../../constants/types/UI/Main";
import Header from "./Header";
import Main from "./Main";
import { deleteState, loadState } from "../../utils/localStorage";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import Error404 from "./Error404/Error404";
import * as ROUTES from "../../constants/routes";
import Unauthorized from "../common/Unauthorized/Unauthorized";
import UserRating from "../utils/userrating/UserRating";
import { parseQS } from "../../utils/helperFunctions";
import { MessageBarType } from "@fluentui/react";
import { IBannerMessagePayload } from "../../constants/types/UI/BannerMessage";
import { AppRoles, entityQueryString } from "../../constants/AppConstants";
import { logEvent } from "../../utils/insightsClient";
import Draggable from "react-draggable";
import MenuBar from "./MenuBar";
import { ShowRating } from "../../constants/AppConstants";

export interface ILayoutState {
  unauthorized: boolean;
  error404: boolean;
  handledScopes_Roles: boolean;
  gotMetaData: boolean;
  getParticipantsMeta: boolean;
  showRating: boolean;
}

interface ILayoutProps extends IMainProps {}
const isIframe = window !== window.parent && !window.opener;

class Layout extends Component<ILayoutProps, ILayoutState> {
  constructor(props: ILayoutProps) {
    super(props);
    this.state = {
      unauthorized: false,
      error404: false,
      handledScopes_Roles: false,
      gotMetaData: false,
      getParticipantsMeta: false,
      showRating: false,
    };
  }

  DefaultPageTitle = `CAPE`;

  componentDidMount() {
    //debugger;
    document.title = this.DefaultPageTitle;
    this.onRouteChanged(true);
  }

  buildAndShowBanner(
    message: string,
    messageType: MessageBarType,
    timeOut: number
  ) {
    const bannerProps: IBannerMessagePayload = {
      toShow: true,
      message: message,
      messageType: messageType,
      styleClass: "",
      timeout: timeOut,
    };
    this.props.setBanner(bannerProps);
  }

  componentDidUpdate(prevProps: ILayoutProps, prevState: ILayoutState) {
    const search =
      this.props.location.search && this.props.location.search.length > 1
        ? this.props.location.search.substring(1)
        : "";
    if (
      this.props.location.search !== prevProps.location.search ||
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      if (
        this.state.unauthorized &&
        prevProps.location.pathname === "/" + ROUTES.UNAUTHORIZED_URL
      ) {
        this.setState({ unauthorized: false });
      }
      if (
        this.state.error404 &&
        prevProps.location.pathname === "/" + ROUTES.ERROR404_URL
      ) {
        this.setState({ error404: false });
      }
    }

    if (
      this.props.user &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.onRouteChanged();
    }

    if (
      (prevProps.appScopes !== this.props.appScopes ||
        prevProps.userContext !== this.props.userContext) &&
      this.props.appScopes &&
      this.props.userContext.data
    ) {
      if (
        this.props.userContext.data.roles.length == 0 ||
        this.props.userContext.data.scopes.length == 0
      ) {
        this.props.history.replace(`/${ROUTES.HOME_URL}`);        
        this.setState({ handledScopes_Roles: true });
      } else {
        this.handleScopes_Roles(
          this.props.appScopes,
          this.props.userContext.data.roles
        );
      }
    }
    if (prevProps.metaData !== this.props.metaData) {
      var getAllMetaData = true;
      Object.values(this.props.metaData.data).forEach((metaData: any) => {
        if (!metaData.values) {
          getAllMetaData = false;
        }
      });
      if (getAllMetaData) {
        this.setState({ gotMetaData: true });
      }
    }
    if (prevProps.participantsMeta !== this.props.participantsMeta) {
      if (this.props.participantsMeta.data) {
        this.setState({ getParticipantsMeta: true });
      }
    }
    if (
      (prevState.handledScopes_Roles !== this.state.handledScopes_Roles ||
        prevState.gotMetaData !== this.state.gotMetaData ||
        prevState.getParticipantsMeta !== this.state.getParticipantsMeta) &&
      this.state.handledScopes_Roles &&
      this.state.gotMetaData &&
      this.state.getParticipantsMeta
    ) {
      this.props.toggleSpinner({
        spinner: false,
        spinnerMessage: "",
      });
    }
  }

  handleScopes_Roles = (scopes: any, roles: any) => {
    var path = this.props.location.pathname.substring(1);
    var parsedQS = parseQS(this.props.location.search);
    path = path.charAt(0).toUpperCase() + path.substring(1);

    if (path !== ROUTES.HOME) {
      if (!scopes[path].Show) {
        this.setState({ unauthorized: true }, () => {
          this.props.history.replace(`/${ROUTES.UNAUTHORIZED_URL}`);
        });
      } else if (parsedQS) {
        if ("id" in parsedQS && parsedQS.id === "new" && !scopes[path].Create) {
          this.setState({ unauthorized: true }, () => {
            this.props.history.replace(`/${ROUTES.UNAUTHORIZED_URL}`);
          });
        }
      }
    }

    this.setState({
      handledScopes_Roles: true,
    });
  };

  onRouteChanged = (initial: boolean = false) => {
    try {
      const { history, location, match, user } = this.props;
      const url = match.params.url;
      let search = "";
      let historyPreviouslocation = "";

      let lastRouteURL = loadState("lastRouteURL");
      let lastSearchParams = loadState("lastSearchParams");
      if (lastRouteURL.length > 1) {
        search =
          lastSearchParams.length > 1 ? lastSearchParams.substring(1) : "";
        historyPreviouslocation = lastRouteURL + lastSearchParams;
        deleteState("lastRouteURL");
        deleteState("lastSearchParams");
      } else {
        search =
          location.search && location.search.length > 1
            ? location.search.substring(1)
            : "";
        historyPreviouslocation =
          history.location?.pathname +
          history.location?.search +
          history.location?.hash;
      }
      if (!user) {
        history.replace({
          pathname: `/${url}/auth`,
          state: { previousLocation: historyPreviouslocation, url },
        });
        return;
      }

      if (initial == true) {
        if (this.props.accessToken) {
          this.props.getUserPhoto({
            accessToken: this.props.accessToken.accessToken,
            size: "48x48",
            alias: this.props.user?.userName,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleUserRating = (rating: boolean) => {
    this.setState({ showRating: rating });
  };

  //Show Main only when context is present
  render() {
    const parsedQS = parseQS(this.props.location.search);
    return (
      <>
        <div role="alert" aria-live="assertive">
          {this.props.spinner?.spinner && (
            <Spinner
              size={SpinnerSize.large}
              label={this.props.spinner.spinnerMessage}
              ariaLive="assertive"
              className="loading-panel-full global-spinner"
            />
          )}
        </div>
        <div className="container" >
          {!isIframe && (
            <div className="header-container">
              
              
              <Header
                {...this.props}
                handleUserRating={this.handleUserRating}
                showRating={this.state.showRating}
              />
            </div>
          )}

          {!isIframe &&
            this.props.appScopes &&
            this.state.handledScopes_Roles &&
            this.state.gotMetaData &&
            this.state.getParticipantsMeta && (
              <MenuBar
                history={this.props.history}
                menuBarState={this.props.menuBarState}
                changeMenuBar={this.props.changeMenuBar}
                appScopes={this.props.appScopes}
                userContext={this.props.userContext}
                parsedQS={""}
              />
            )}
          <div
            id="body-container"
            style={{ display: "flex", backgroundColor: "white" }}
          >
            {this.state.unauthorized ? (
              <Unauthorized history={this.props.history} />
            ) : this.state.error404 ? (
              <Error404 />
            ) : (
              this.props.appScopes &&
              this.state.handledScopes_Roles &&
              this.state.gotMetaData &&
              this.state.getParticipantsMeta && (
                <div>
                  <Main {...this.props} />
                  {this.state.showRating === true && (
                    <UserRating
                      toggleSpinner={this.props.toggleSpinner}
                      location={this.props.location}
                      handleUserRating={this.handleUserRating}
                      showRating={this.state.showRating}
                    />
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Layout;
