import { combineReducers } from 'redux';
import AuthReducers from './reducers/Auth';
import {BannerReducer} from './reducers/UI/BannerMessage';
import { ModalReducer } from './reducers/UI/Modal';
import { RatingStyleReducer } from "./reducers/UI/RatingStyle";
import {SpinnerReducer} from './reducers/UI/globalSpinner';
import NavBarReducers from './reducers/UI/navbar';
import ContextReducers from './reducers/API/ContextReducers';
import CommonServicesTokenReducers from './reducers/API/CommonServicesTokenReducers';
import OffersReducers, { OffersDetailsAPIReducers, OffersCategoryReducer, UpsertOfferReducer, UpsertOfferFromDetailReducer, OffersTermsAndConditionReducer, sendEmailToSelfReducer} from "./reducers/API/OffersReducers";
import ParticipantsReducers, { ParticipantsCategoryReducer } from "./reducers/API/ParticipantsReducers";
import EngagementsReducers,{EngagementsCategoryReducer, EngagementsDetailsAPIReducers, UpsertEngagementReducer} from "./reducers/API/EngagementsReducers";
import MenuBarReducers from './reducers/UI/MenuBar';
import {EnagagementsDetailReducer} from './reducers/UI/Engagements';
import {OffersDetailReducer} from './reducers/UI/Offers';
import RatingReducer from './reducers/API/RatingReducer';
import {InitUrlStateReducer} from './reducers/UI/InitialUrl';
import MetaDataReducer,{ParticipantsMetaDataReducer} from './reducers/API/MetaData';
import GraphReducer from './reducers/API/GraphState';
import {ScopesReducer} from './reducers/UI/ScopeReducer';
import {GetNotificationsReducers,DismissNotificationsReducers} from "./reducers/API/NotificationsReducers";

 
const UIReducers = combineReducers({
    navBarOpen: NavBarReducers,
    bannerProps : BannerReducer,
    modalProps: ModalReducer,
    ratingStyle: RatingStyleReducer,
    spinner : SpinnerReducer,
    menuBar : MenuBarReducers,
    engagementDetail : EnagagementsDetailReducer,
    offerDetail : OffersDetailReducer,
    initURLState : InitUrlStateReducer,
    appScopes:ScopesReducer
})

const APIReducers = combineReducers({
  userContext: ContextReducers,
  commonServicesToken: CommonServicesTokenReducers,
  ratingStatus: RatingReducer,
  engagementsData: EngagementsReducers,
  engagementCategories: EngagementsCategoryReducer,
  engagementDetailAPI: EngagementsDetailsAPIReducers,
  offerDetailAPI: OffersDetailsAPIReducers,
  metaData: MetaDataReducer,
  offers: OffersReducers,
  offersTermsAndCondition: OffersTermsAndConditionReducer,
  sendEmailToSelf: sendEmailToSelfReducer,
  offersCategories: OffersCategoryReducer,
  upsertEngagement: UpsertEngagementReducer,
  upsertOffer: UpsertOfferReducer,
  graphState: GraphReducer,
  participants:ParticipantsReducers,
  participantCategories : ParticipantsCategoryReducer,
  participantsMeta : ParticipantsMetaDataReducer,
  upsertOfferFromDetail : UpsertOfferFromDetailReducer,
  getNotifications : GetNotificationsReducers,
  dismissNotifications:DismissNotificationsReducers
});

const RootReducer = combineReducers({
    api: APIReducers,
    auth: AuthReducers,
    ui: UIReducers
});

const initialState = RootReducer({}, {})

export default (state, action) => {
    if (action.type === 'RESET') {
        state = initialState
    }

    return RootReducer(state, action)
}