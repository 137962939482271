
const deleteState = (id?: any) => {
    try {
        localStorage.removeItem(id);
        return true;
    } catch (err) {
        return false;
    }
}

const loadState = (id?: any) => {
    try {
        const state = localStorage.getItem(id);

        if (state == null) return "";

        return state;
    } catch (err) {
        return "";
    }
}

const saveState = (id: any, state: any) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(id, serializedState);
        return true;
    } catch (err) {
        return false;
    }
}

export { deleteState, loadState, saveState };