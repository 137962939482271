import { SAVING_RATING_FAILURE,SAVING_RATING,SAVING_RATING_SUCCESS } from '../../constants/types/API/Rating';
import { IAction } from '../../constants/types/IAction';
import { IRatingApiResponse } from '../../constants/types/API/Apiresponse';

const initialState: IRatingApiResponse = {
    progressing: false,
    success: false,
    failure: false
}

const RatingReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SAVING_RATING:
            return { ...state, progressing: true, success: false, failure: false };
        case SAVING_RATING_SUCCESS:
            return {
                ...state,progressing: false, success: true, failure: false
            };
        case SAVING_RATING_FAILURE:
            return { ...state, progressing: false, success: false, failure: true };
        default:
            return state;
    }
}

export default RatingReducer;