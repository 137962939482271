export interface IMenuItem {
    itemName: string;
    displayName: string;
    isActive: boolean;
}

export const Home: string = "home";
export const Home_DN: string = "Home";
export const Engagements: string = "engagements";
export const Engagements_DN: string = "Engagements";
export const Participants: string = "participants";
export const Participants_DN: string = "Participants";
export const Reports: string = "reports";
export const Reports_DN: string = "Reports";
export const Offers: string = "offers";
export const Offers_DN: string = "Offer Types";

export var init_Menu: { [key: string]: IMenuItem } = {
    [Home]: {
        itemName: Home,
        displayName: Home_DN,
        isActive: false
    },
    [Engagements]: {
        itemName: Engagements,
        displayName: Engagements_DN,
        isActive: false
    },
    [Participants]: {
        itemName: Participants,
        displayName: Participants_DN,
        isActive: false
    },
    [Offers]: {
        itemName: Offers,
        displayName: Offers_DN,
        isActive: false
    },
    [Reports]: {
        itemName: Reports,
        displayName: Reports_DN,
        isActive: false
    },
   
}


