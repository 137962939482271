import { OutputCard, Textarea } from "@fluentai/react-copilot";
import {
  DefaultEffects,
  NeutralColors,
  Stack,
  initializeIcons,
  registerIcons,
} from "@fluentui/react";
import { Text, makeStyles, shorthands } from "@fluentui/react-components";
import { Dismiss12Regular } from "@fluentui/react-icons";
import React, { useCallback, useState } from "react";
import { ReactComponent as CopilotFloatingIcon } from "../../Asset/svgs/Copilot-Floating-Icon.svg";

registerIcons({
  icons: {
    CopilotFloatingIcon: React.createElement(CopilotFloatingIcon),
  },
});

initializeIcons();

const useStylesClasses = makeStyles({
  copilotFloatingIconWrapper: {
    position: "fixed",
    bottom: "5%",
    right: "4%",
    zIndex: 10000,
    display: "flex",
  },
  copilotWelcomeText: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    whiteSpace: "pre-line",
    color: NeutralColors.black,
  },
  copilotWelcomeTextBold: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    whiteSpace: "pre-line",
    color: NeutralColors.black,
  },
  copilotFloatingIconMsg: {
    ...shorthands.padding("12px"),
    backgroundColor: "#fff",
    ...shorthands.borderRadius("4px"),
    outlineStyle: "none",
    boxShadow: DefaultEffects.elevation4,
    marginLeft: "8px",
    justifyContent: "center",
    alignItems: "center",
  },
  copilotFloatingInput: {
    ...shorthands.padding("12px"),
    backgroundColor: "#fff",
    ...shorthands.borderRadius("4px"),
    outlineStyle: "none",
    boxShadow: DefaultEffects.elevation4,
    marginLeft: "8px",
    width: "240px",
    "::-ms-input-placeholder": {
      color: "#707070",
      backgroundColor: "black",
    },
  },
  copilotFloatingIcon: {
    outlineStyle: "none",
    alignSelf: "center",
  },
  copilotFloatingIconContainer: {
    backgroundColor: "#EDEBE9",
    height: "56px",
    width: "56px",
    marginLeft: "8px",
    ...shorthands.padding("4px"),
    ...shorthands.borderRadius("50%"),
    borderBottomRightRadius: "0px",
    outlineStyle: "none",
    boxShadow: DefaultEffects.elevation4,
    display: "flex",
    justifyContent: "center",
  },
  dismissIcon: {
    backgroundColor: NeutralColors.white,
    ...shorthands.padding("4px"),
    ...shorthands.borderRadius("50%"),
    outlineStyle: "none",
    boxShadow: DefaultEffects.elevation4,
  },
});

interface IFloatingActionButtonProps {
  onFloatingIconClick: (isPanelVisible: boolean) => void;
  handleFloatingIcon: Function;
  onSendButtonClicked: (data: string) => void;
}

export const FloatingActionButton: React.FC<IFloatingActionButtonProps> = ({
  onSendButtonClicked,
  onFloatingIconClick,
  handleFloatingIcon,
}) => {
  const [showGreetingMessage, setShowGreetingMessage] = useState(true);
  const [showTextArea, setShowTextArea] = useState(true);
  const [disabledButton, setDisabledButton] = React.useState<boolean>(false);
  const [textFieldValue, setTextFieldValue] = React.useState<string>("");
  const styleClassNames = useStylesClasses();

  React.useEffect(() => {
    let isDisabled =
      textFieldValue.length === 0 ||
      !(textFieldValue.length >= 10 && textFieldValue.length <= 2000);
    setDisabledButton(isDisabled);
  }, [textFieldValue]);

  const onSendMessage = () => {
    onSendButtonClicked(textFieldValue);
    setTextFieldValue("");
  };

  const handleDismiss = useCallback(() => {
    setShowGreetingMessage(false);
  }, []);

  return (
    <Stack horizontal className={styleClassNames.copilotFloatingIconWrapper}>
      {showGreetingMessage && (
        <Stack horizontal>
          <Dismiss12Regular
            className={styleClassNames.dismissIcon}
            onClick={handleDismiss}
          />
          {showTextArea ? (
            <OutputCard
              className={styleClassNames.copilotFloatingIconMsg}
              onClick={() => setShowTextArea(false)}
            >
              <Text className={styleClassNames.copilotWelcomeText}>
                Hi, I`m your CAPE Copilot.{"\n"}
                You can Ask me questions!
              </Text>
            </OutputCard>
          ) : (
            <Textarea
              className={styleClassNames.copilotFloatingInput}
              showCount
              maxLength={2000}
              value={textFieldValue}
              onChange={(e) => setTextFieldValue(e.target.value)}
              characterCount={textFieldValue.length}
              minLength={10}
              aria-label="Copilot Chat"
              placeholder="Ask me support questions"
              onSubmit={onSendMessage}
              disableSend={disabledButton}
            />
          )}
        </Stack>
      )}
      <div
        className={styleClassNames.copilotFloatingIconContainer}
        onClick={() => {
          onFloatingIconClick(true);
          handleFloatingIcon(false);
        }}
      >
        <CopilotFloatingIcon className={styleClassNames.copilotFloatingIcon} />
      </div>
    </Stack>
  );
};
