import { UPDATE_GRAPH_STATE} from '../../constants/actions/API/GraphState';
import _ from 'lodash';
import { IAction } from '../../constants/types/IAction';
import { IGraphState } from '../../constants/types/API/GraphState';

const initialState : IGraphState = {
    photos: {},
    users : {}
}

const GraphReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case UPDATE_GRAPH_STATE:
            var clonedState = _.cloneDeep(state);
            if(action.payload.photos){
                Object.keys(action.payload.photos).forEach(key => {
                    clonedState.photos[key] = action.payload.photos[key];
                });
            }
            if(action.payload.users){
                Object.keys(action.payload.users).forEach(key => {
                    clonedState.users[key] = action.payload.users[key];
                });
            }
            return clonedState;
        default:
            return state;
    }
}

export default GraphReducer;