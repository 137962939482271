import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";

export interface TrackerProps extends RouteComponentProps {
    history: any;
    match: any;
    location: any;
    children: any;
  }

export interface TrackerState {

}

class Tracker extends React.Component<TrackerProps> {

  componentDidMount(){
    window.sessionStorage.setItem("initPath",this.props.location.pathname);
    window.sessionStorage.setItem("initSearch",this.props.location.search);
  }

  render() {
    return this.props.children;
  }
};


export default withRouter(Tracker);