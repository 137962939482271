import * as React from 'react';
import { signIn, getApiAccessToken, getPowerBIApiAccessToken, getCoreApiAccessToken } from '../../actions/Auth';
import { getMetaData, getParticipantsMetaData } from '../../actions/API/MetaData';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { connect } from 'react-redux';
import Unauthorized from '../common/Unauthorized/Unauthorized';
import { AuthResponse } from 'msal';
import { SpinnerSize } from '@fluentui/react';
import { getContext,getCommonServicesToken } from '../../actions/API/Context';
import { IDataResponse } from '../../constants/types/API/Apiresponse';

// Primary rendering method for the component

interface IAuthProps {
    user: any;
    error: any;
    accessToken: AuthResponse | null;
    apiAccessToken: AuthResponse | null;
    coreApiAccessToken: AuthResponse | null;
    powerBIAccessToken: AuthResponse | null;
    commonServiceToken: AuthResponse | null;
    signIn: Function;
    getPowerBIApiAccessToken: Function;
    getCommonServicesToken: Function;
    commonServicesToken:IDataResponse;
    getApiAccessToken: Function;
    getCoreApiAccessToken: Function;
    location: any;
    history: any;
    match: any;
    getMetaData: Function;
    getContext: Function;
    getParticipantsMetaData: Function;
}

class Authentication extends React.Component<IAuthProps, any> {

    constructor(props: IAuthProps) {
        super(props);
    }

    componentDidMount() {
        const { location: { state }, signIn } = this.props;
        if (state) {
            window.localStorage.setItem('previousLocation', state.previousLocation || '/');
        }
        //This is being hit when user enters the route home/auth
        signIn();
        this.props.getPowerBIApiAccessToken();
        this.props.getApiAccessToken();
        this.props.getCoreApiAccessToken();
    }

    componentDidUpdate() {
        const { user, history } = this.props;

        if (user && this.props.accessToken && this.props.apiAccessToken
            && this.props.coreApiAccessToken && this.props.powerBIAccessToken) {
            history.replace(window.localStorage.getItem('previousLocation') || '/');
            this.props.getMetaData();
            this.props.getContext();
            this.props.getParticipantsMetaData();
            this.props.getCommonServicesToken();
        }
    }

    render() {
        const { error = null, match, user, history } = this.props;

        const showContent = (error = null) => (
            error ? (
                <div style={{ width: "90vw", height: "90vh" }}>
                    <Unauthorized history={this.props.history} />
                </div>
            ) : (
                <div>
                    <Spinner
                        size={SpinnerSize.large}
                        label={`We are getting Cape Portal ready for you...`}
                        ariaLive="assertive"
                        className="loading-panel-full global-spinner"
                        role="progressbar"
                    />
                </div>
            )
        )

        return (
            <div className="auth">
                {showContent(error)}
            </div>
        )
    }
}

const mapStateToProps = (state: { auth: any }) => ({
    user: state.auth.user,
    error: state.auth.error,
    accessToken: state.auth.accessToken,
    apiAccessToken: state.auth.apiAccessToken,
    coreApiAccessToken: state.auth.coreApiAccessToken,
    powerBIAccessToken: state.auth.powerBIAccessToken,
    commonServiceToken: state.auth.commonServiceToken
})

const mapDispatchToProps = (dispatch: any) => ({
    signIn: () => dispatch(signIn()),
    getPowerBIApiAccessToken: () => dispatch(getPowerBIApiAccessToken()),
    getApiAccessToken: () => dispatch(getApiAccessToken()),
    getCoreApiAccessToken: () => dispatch(getCoreApiAccessToken()),
    getCommonServicesToken: () => dispatch(getCommonServicesToken()),
    getMetaData: () => dispatch(getMetaData()),
    getContext: () => dispatch(getContext()),
    getParticipantsMetaData: () => dispatch(getParticipantsMetaData())
})

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);