import { MessageBarType } from "@fluentui/react";
import { findLastKey } from "lodash";
import { HOME_URL, ENGAGEMENTS_URL, REPORTS_URL, PARTICIPANTS_URL, OFFERS_URL } from './routes';
import { INotification } from "./types/UI/BannerMessage";
import { buildPersistentMessage, buildShowRatingSettings } from "../utils/helperFunctions";

export default class AppConstants {

    public static InsightsInstrumentationKey: string = process.env.REACT_APP_INSTRUMENTATION_KEY || "02ecdb78-6f4f-4f9b-88b3-76c250e397c9";
    public static ClientAppID: string = process.env.REACT_APP_UI_CLIENTID || "9ca5e3c8-3568-4b22-abee-e1c84b851da4";
    public static WebUiUrl: string = process.env.REACT_APP_REDIRECT_URI_ROOT || 'http://localhost:3000';
    public static CoreAppID: string = process.env.REACT_APP_CORE_SERVICES_CLIENTID || '97f5c6b5-18ce-4121-bddd-d52be5c47557';
    public static CommonServicesAppID: string = process.env.REACT_APP_COMMON_SERVICES_CLIENTID || '298c8d6f-8d66-41c5-96f1-96593873ea10';
    public static CoreAPIUrl: string = process.env.REACT_APP_COREAPP_API_URL || 'https://csucore-test-webapi-westus.azurewebsites.net/';
    public static APP_API_URL: string = process.env.REACT_APP_API_URL || "https://cape-test-v2-api.azurewebsites.net/api/";
    public static ConsumptionReportEmbedUrl: string = process.env.REACT_APP_PBI_EMBEDED_URL || "https://msit.powerbi.com/reportEmbed?reportId=3ef27e66-d29f-46b2-abb2-cbc0303303c2&groupId=37a1da54-619c-4bf3-ad58-6d8093d3cc8a&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9ERi1NU0lULVNDVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJjZXJ0aWZpZWRUZWxlbWV0cnlFbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d";
    public static ConsumptionReportID: string = process.env.REACT_APP_PBI_REPORT_ID || "3ef27e66-d29f-46b2-abb2-cbc0303303c2";
    public static APP_UAT_URL: string = process.env.REACT_APP_UAT_URL || "https://uat-test-webui-westus.azurewebsites.net/";
    public static cookies: string = process.env.REACT_APP_COOKIES_LINK || "https://privacy.microsoft.com/en-US/privacystatement#maincookiessimilartechnologiesmodule";
    public static termsOfUse: string =  process.env.REACT_APP_TERMSOFUSE_LINK ||"https://www.microsoft.com/en-us/legal/intellectualproperty/copyright/default.aspx";
    public static privacy: string =  process.env.REACT_APP_PRIVACY_LINK ||"https://privacy.microsoft.com/en-US/data-privacy-notice";
    public static consumerhealthPrivacy: string = process.env.REACT_APP_CONSUMER_HEALTH_PRIVACY_LINK ||"https://go.microsoft.com/fwlink/?linkid=2259814";
    public static AdminNotificationTeam: string = process.env.REACT_APP_ADMIN_NOTIFICATION_TEAM || "d15bc11f-a2b7-ed11-83fe-000d3a5a7024";
    public static commonServiceUrl: string = process.env.REACT_APP_COMMONSERVICES_URL || "https://commonservices-test-webapi-westus3.azurewebsites.net/";
       
    
    /*App constants, unchanged between environments*/
    
    public static CoreAPIVersion: string = "v2";
    public static CoreUserContextEndpoint: string = "api/v2/Service/GetUserContext";
    public static Authority: string = "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47";
    public static Error404Message: string = "Engagement not found. Please check the Engagement ID";
    
    public static HelpLink = 'https://aka.ms/capesupport';
    public static RequestAccessLink = 'https://aka.ms/cape-accessrequest';
    public static ContactLink = 'mailto:capehelp@microsoft.com';
    public static Copyright = '© Microsoft '.concat(new Date().getFullYear().toString());
    public static ApplicationName: string = "Customer Acceleration Portal For Engagements (CAPE)";
    public static ApplicationAlias: string = "CAPE";
    public static AppliationTitle: string = "Customer Acceleration Portal For Engagements (CAPE)";
    public static FeedbackLink = 'mailto:capehelp@microsoft.com';
    public static AzureRbacUrl = "https://docs.microsoft.com/en-us/azure/role-based-access-control/built-in-roles";
    public static ShowViewJsonLink:boolean = true;
    public static NonPublicCloudFlight:string = "Azure Non Public Cloud";
    public static SACOfferCDDText:string = "Storage Account Contributor";
    public static ManagedServiceRegistrationDeleteRole:string = '91c1777a-f3dc-4fae-b103-61d183457e46';
    public static TeamsChatDeepLink = "https://teams.microsoft.com/l/chat/0/0?users=";
    public static shareToTeamsLink = "https://teams.microsoft.com/share?"
    public static TeamsMeetingDeepLink = "https://teams.microsoft.com/l/meeting/new?attendees=";
    
    public static PersistentNotification: INotification =
        process.env.REACT_APP_PERSISTENT_NOTIFICATION ?
            buildPersistentMessage(process.env.REACT_APP_PERSISTENT_NOTIFICATION) :
            {
                message: "Welcome to the new Cape UI !!",
                messageType: MessageBarType.info,
                timeOut: null
            };

            // public static BellNotifications: INotification[] | null | undefined = [{
            //     Id:6,
            //     Data:[],
            //     message: "Welcome to the new CAPE Website!!",
            //     messageType: MessageBarType.warning,
            //     timeOut: null
            // },
            // {
            //     Id:7,
            //     Data:[],
            //     message: "New Engagement & Offers flows are now up",
            //     messageType: MessageBarType.success,
            //     timeOut: null
            // },
            // {
            //     Id:8,
            //     Data:[],
            //     message: "Save as draft functionality added",
            //     messageType: MessageBarType.info,
            //     timeOut: null
            // }
            // ];
    public static EnableSideNav = false;

    public static ShowRatingByDefault =
        process.env.REACT_APP_USER_RATING_SETTINGS ? buildShowRatingSettings(process.env.REACT_APP_USER_RATING_SETTINGS) :
            {
                [HOME_URL]: true,
                [ENGAGEMENTS_URL]: true,
                [PARTICIPANTS_URL]: true,
                [OFFERS_URL]: true,
                [REPORTS_URL]: true
            }

    public static Keys = {
        BackSpace: 8,
        Enter: 13,
        Delete: 46,
        LeftArrow: 37,
        RightArrow: 39,
        TabKey: 9,
    };
}


export const EndPoints = {
  ContextAPI: "securitycontext/getusercontext",
  CommonTokenAPI: "securitycontext/getcommonservicestoken",
  RatingAPI: "ratings",
  Engagements: "engagements",
  Metadata: "metadata",
  Offers: "offers",
  Participants: "participants",
  EntityRollup: "metadata/getentitiesrollup",
  PublishedOffer: "engagements/getpublishedoffer",
  GetEntityCount: "metadata/getentitycount",
  Search: "search",
  GetOctetStream: "engagements/getarmpackage",
  NotificationsAPI: "notification",
  SendEmailToSelf: "offers/SendEmailToSelf"
};

export const APILabels = {
    getEngagements : "Get Engagements",
    getEngagementCategories : "Get Engagement Categories",
    getOffers : "Get Offers",
    getOfferCategories : "Get Offer Categories",
    getParticipants : "Get Participants",
    getParticipantCategories : "Get Participant Categories",
    getEngagementsMeta : "Get Engagement Meta",
    getOffersDropDown : "Get Offer Dropdown",
    getOffersMeta : "Get Offer Meta",
    getParticipantsMeta : "Get Participant Meta",
    getEngagementById : "Get Engagement By ID",
    getOfferById : "Get Offer By ID",
    getAppContext : "Get App Context",
    getcommonServicesToken : "Get Common Services",
    upsertOffer : "Upsert Offer",
    upsertEngagement : "Upsert Engagement",
    createOfferInPC : "Create Offer in PC",
    saveRatings : "Save Rating",
    getNotifications    :   "Get Notifications",
    dismissNotifications:"Dismiss Notifications",
    offersTermsAndCondition: "Get Offers Terms and Condition",
    sendEmailToSelf: "Send Email to self"
}

export enum SpecialValues {
    Me = '@Me',
    Any = '[Any]'
}

export const AccessLevels = {
    Read: "Read",
    Contribute: "Contribute"
}

export const entityQueryString = {
    EntityName: "entityname",
    EntityValue: "entityvalue",
    ProgramName: "program",
    ProgramId: "programid"
}

export const AppRoles = {
    SiteAdmin: {
        roleName: "Site Admin",
    },

    EngagementManager: {
        roleName: "Engagement Manager",
    },

    DeliveryTeamMember: {
        roleName: "Delivery TeamMember",
    }
};

export const ShowRating = "cape_showRating";
export const ShowHelpText = "cape_showhelptext";
export const base64Append = "data:image/jpeg;charset=utf-8;base64,";
export const SubscriptionIDRegex = /^[0-9A-Fa-f]{8}-([0-9A-Fa-f]{4}-){3}[0-9A-Fa-f]{12}$/m;
export const PlaceHolderOption = 'Select an Option';
export const RequestSuccess = 200;
export const microsfotAliasAppendString = "@microsoft.com";
export const userIDString = "user:";
export const photoIDString = "photo:";
export const updateStatus = "Update Status";
export const updateStatusText = "Do you want to update Offer status?";
export const azureARMTemplate = "Azure ARM Template";