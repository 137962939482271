import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Layout from '../components/common/Layout';
import { setBanner } from '../actions/UI/BannerMessage';
import { IBannerMessagePayload } from '../constants/types/UI/BannerMessage';
import { toggleSpinner } from '../actions/UI/globalSpinner';
import { signIn, getUserPhoto, getApiAccessToken } from '../actions/Auth';
import { toggleNavBar } from '../actions/UI/navigation';
import { changeMenuBar } from '../actions/UI/MenuBar';
import {getContext} from '../actions/API/Context';
import { getEngagementsByID, setEngagementDetails } from '../actions/UI/Engagements';
import { changeInitUrlState } from '../reducers/UI/InitialUrl';
import { getOfferByID, setOfferDetails } from '../actions/UI/Offers';
import { getNotifications,dismissNotifications } from '../actions/API/Notifications';
import { INotifications } from '../constants/types/UI/Notifications';


const mapStateToProps = (state: { ui: any; api: any; auth: any }) => ({
    navBarOpen: state.ui.navBarOpen,
    user: state.auth.user,
    bannerProps: state.ui.bannerProps,
    accessToken: state.auth.accessToken,
    apiAccessToken : state.auth.apiAccessToken,
    coreApiAccessToken : state.auth.coreApiAccessToken,
    powerBIAccessToken : state.auth.powerBIAccessToken,
    spinner: state.ui.spinner,
    profileURL: state.auth.blobUrl,
    menuBarState : state.ui.menuBar,
    engagementDetail: state.ui.engagementDetail,
    offerDetail: state.ui.offerDetail,
    engagementDetailAPI : state.api.engagementDetailAPI,
    offerDetailAPI : state.api.offerDetailAPI,
    initURLState : state.ui.initURLState,
    metaData : state.api.metaData,
    appScopes : state.ui.appScopes,
    userContext : state.api.userContext,
    commonServicesToken : state.api.commonServicesToken,
    participantsMeta : state.api.participantsMeta,
    getNotificationsState : state.api.getNotifications
})

const mapDispatchToProps = (dispatch: any) => ({
    getUserPhoto: (payload: any) => dispatch(getUserPhoto(payload)),
    toggleNavBar: (payload: boolean) => dispatch(toggleNavBar(payload)),
    signIn: () => dispatch(signIn()),
    getApiAccessToken: () => dispatch(getApiAccessToken()),
    setBanner: (payload: IBannerMessagePayload) => dispatch(setBanner(payload)),
    toggleSpinner: (payload: boolean) => dispatch(toggleSpinner(payload)),
    changeMenuBar: (payload: any) => dispatch(changeMenuBar(payload)),
    getEngagementsByID : (id : number) => dispatch(getEngagementsByID(id)),
    getOfferByID : (id : string) => dispatch(getOfferByID(id)),
    setEngagementDetails : (paylaod : any) => dispatch(setEngagementDetails(paylaod)),
    changeInitUrlState : (payload : boolean) => dispatch(changeInitUrlState(payload)),
    setOfferDetails : (paylaod : any) => dispatch(setOfferDetails(paylaod)),
    getNotifications: () => dispatch(getNotifications()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));