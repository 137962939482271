import { IAction } from '../../constants/types/IAction';
import {GET_NOTIFICATIONS,DISMISS_NOTIFICATIONS} from '../../constants/actions/API/Notifications';
import { INotifications } from '../../constants/types/UI/Notifications';


export const getNotifications = () => {
    return {
        type: GET_NOTIFICATIONS
    }
}
export const dismissNotifications = (payload : INotifications[]) => {
    return {
        type: DISMISS_NOTIFICATIONS,
        payload:payload
    }
}