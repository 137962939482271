import React, { Component } from "react";
import { ComboBox, IComboBox, IComboBoxOption,
     IComboBoxProps,IComboBoxStyles } from '@fluentui/react/lib/ComboBox';
import "./CustomComboBox.css"     


interface ICustomComboBoxProps extends Partial<IComboBoxProps> {
    updateCDD : Function;
    isFormValid? : boolean;
}

interface ICustomComboBoxState {
    onFocussed: boolean;
}

const comboBoxStyles: Partial<IComboBoxStyles> = {
    optionsContainer: { maxHeight: 245 }
};


class CustomDropDown extends Component<ICustomComboBoxProps, ICustomComboBoxState>{

    constructor(props: ICustomComboBoxProps) {
        super(props);
        this.state = {
            onFocussed: false
        }
    }

    updateDropDownKey = (_event: any, option?: IComboBoxOption | undefined) => {
        if(!!option){
            this.props.updateCDD(option);
        }
    }

    render() {
        return (
            <ComboBox
                key={this.props.label ?? "placeholder-key"}
                useComboBoxAsMenuWidth
                selectedKey={this.props.selectedKey}
                onChange={this.updateDropDownKey}
                onFocus={(e) => {
                    this.setState({ onFocussed: true });
                }}
                placeholder={this.props.placeholder ?? ""}
                className={`custom-combo-box ${this.props.className} ${!!this.props.disabled ? 'combobox-disabled' : 'combobox-enabled'}`}
                autoComplete='on'
                styles={{...{},...comboBoxStyles,...this.props.styles}}
                label={this.props.label}
                disabled={!!this.props.disabled}
                required={!!this.props.required}
                options={this.props.options ?? []}
                errorMessage={((this.state.onFocussed || !this.props.isFormValid) && this.props.required) ? this.props.errorMessage : undefined}
            />
        );
    }
}

export default CustomDropDown;