
export const SIGN_IN: string = 'SIGN_IN';
export const SIGN_IN_FAILURE: string = 'SIGN_IN_FAILURE';
export const SIGN_OUT: string = 'SIGN_OUT';
export const SET_USER_PROFILE: string = 'GET_USER_PROFILE';

export const SET_ACCESS_TOKEN: string = 'SET_ACCESS_TOKEN';
export const SET_USER_PHOTO: string = 'SET_USER_PHOTO';
export const GET_USER_PHOTO: string = 'GET_USER_PHOTO';
export const GET_API_ACCESS_TOKEN: string = 'GET_API_ACCESS_TOKEN';


export const SET_API_ACCESS_TOKEN: string = 'SET_API_ACCESS_TOKEN';
export const REAQUIRE_API_ACCESS_TOKEN : string = 'REAQUIRE_API_ACCESS_TOKEN';
export const SET_IS_USER_IN_ORG: string = 'SET_IS_USER_IN_ORG';

export const CONTEXT_API_SUCCESS: string = 'CONTEXT_API_SUCCESS';
export const CONTEXT_API_FAILURE: string = 'CONTEXT_API_FAILURE';
export const CONTEXT_API_FETCHING: string = 'CONTEXT_API_FETCHING';

export const COMMONTOKEN_API_SUCCESS: string = 'COMMONTOKEN_API_SUCCESS';
export const COMMONTOKEN_API_FAILURE: string = 'COMMONTOKEN_API_FAILURE';
export const COMMONTOKEN_API_FETCHING: string = 'COMMONTOKEN_API_FETCHINGs';

export const GET_POWER_BI_ACCESS_TOKEN : string = 'GET_POWER_BI_ACCESS_TOKEN';
export const SET_POWER_BI_ACCESS_TOKEN : string = 'SET_POWER_BI_ACCESS_TOKEN';

export const GET_CORE_API_ACCESS_TOKEN : string = 'GET_CORE_API_ACCESS_TOKEN';
export const SET_CORE_API_ACCESS_TOKEN : string = 'SET_CORE_API_ACCESS_TOKEN';

export const GET_COMMON_SERVICE_TOKEN : string = 'GET_COMMON_SERVICE_TOKEN';
export const SET_COMMON_SERVICE_TOKEN : string = 'SET_COMMON_SERVICE_TOKEN';

