import React, { useEffect } from "react";
import ThreadRoot from "./Components/ThreadRoot";
import {
  IStyleFunctionOrObject,
  IPanelProps,
  IPanelStyleProps,
  IPanelStyles,
  TeachingBubble,
  DirectionalHint,
} from "office-ui-fabric-react";
import { getBotSuggestions } from "./Services/gptApi";
import { TelemetryContextProvider } from "./Services/TelemetryContext";
import { generateId } from "./Utility/Utilities";
import { AppContextProvider } from "./Services/CopilotContext";
import { CopilotProvider } from "@fluentai/react-copilot";
import RenderDefaultHeader from "./Header";
import { CAPECopilotConstants } from "./Constants";
import { useId } from "@fluentui/react-hooks";
import { FloatingActionButton } from "./Components/FAB/FloatingActionButton";
import Draggable from "react-draggable";
import {
  makeStyles,
  shorthands,
  InlineDrawer,
} from "@fluentui/react-components";
import { INotifications } from "../../constants/types/UI/Notifications";
import { dismissNotifications } from "../../actions/API/Notifications";
import { logEvent, NotificationClick } from "../../utils/insightsClient";
import { useDispatch, useSelector } from "react-redux";

interface ITeachingBubbleProps {
  isTeachingBubbleVisible: boolean;
  setIsTeachingBubbleVisible: (isTeachingBubbleVisible: boolean) => void;
  onTeachingBubbleDismiss: () => void;
}

type Message = {
  id: string;
  from: string;
  type: string;
  message: string;
  isSuggestion?: boolean;
  isInitialMessage?: boolean;
};

type CopilotProps = {
  appInsights: any;
  appInsightsEventNamePrefix: string;
  appName: string;
  currentUserId: string;
  currentUserName: string;
  gptApiUrl: string;
  gptPlatformName: string;
  gptAuthToken: string;
  isOpen: boolean;
  isSplitModeOn?: boolean;
  disableFloatingIcon?: boolean;
  onFloatingIconClick: (isPanelVisible: boolean) => void;
  onDismiss: () => void;
  panelProps?: IPanelProps | undefined;
  panelStyles?:
    | IStyleFunctionOrObject<IPanelStyleProps, IPanelStyles>
    | undefined;
  teachingBubbleProps?: ITeachingBubbleProps | undefined;
  copilotPinAction: Function;
  isPinned: boolean;
  isEngagementWindowOpen: boolean;
  capeTeachingBubbleDetails?: INotifications[];
};

const useCopilotStyles = makeStyles({
  copilotMainContainer: {
    backgroundColor: "#F0F0F0",
    height: "90%",
    width: "26%",
    position: "fixed",
    zIndex: 10000000000000,
    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.12)",
    ...shorthands.borderRadius("10px"),
  },
  copilotModalContainer: {
    backgroundColor: "#F0F0F0",
    width: "26.5%",
    height: "96.5%",
    position: "fixed",
    top: "50px",
    zIndex: 1000,
    ...shorthands.borderRadius("10px"),
  },
});

const Copilot = ({
  appInsights,
  appInsightsEventNamePrefix,
  appName,
  currentUserId,
  currentUserName,
  gptApiUrl,
  gptPlatformName,
  gptAuthToken,
  isOpen,
  onDismiss,
  panelStyles,
  isSplitModeOn,
  disableFloatingIcon,
  teachingBubbleProps,
  onFloatingIconClick,
  copilotPinAction,
  isPinned,
  isEngagementWindowOpen,
  capeTeachingBubbleDetails,
}: CopilotProps) => {
  const [context, setContext] = React.useState({
    appName,
    appInsightsEventNamePrefix,
    currentUserId,
    currentUserName,
    gptPlatformName,
  });
  const [messages, setMessages] = React.useState<Message[]>([]);

  const [suggestions, setSuggestions] = React.useState([]);
  const [showBanner, setShowBanner] = React.useState(false);
  const [floatingMessage, setFloatingMessage] = React.useState("");
  const [showFloatingIcon, setShowFloatingIcon] = React.useState<boolean>();
  const displayTeachingBubble = process.env.REACT_APP_SHOW_TEACHING_BUBBLE;
  const [showTeachingBubble, setShowTeachingBubble] = React.useState<boolean>(
    capeTeachingBubbleDetails && capeTeachingBubbleDetails.length > 0
      ? true
      : false
  );
  const teachingBubbleHeader =
    capeTeachingBubbleDetails && capeTeachingBubbleDetails.length > 0
      ? capeTeachingBubbleDetails[0].Data.PortalNotification.Header
      : CAPECopilotConstants.teachingBubbleHeader;
  const teachingBubbleContent =
    capeTeachingBubbleDetails && capeTeachingBubbleDetails.length > 0
      ? capeTeachingBubbleDetails[0].Data.PortalNotification.Content
      : CAPECopilotConstants.teachingBubbleContent;

  const classes = useCopilotStyles();
  const buttonId = "moreButton";

  const [showPinned, setShowPinned] = React.useState(isPinned);
  const dispatch = useDispatch();

  useEffect(() => {
    const setInitialSuggestions = async () => {
      const suggestionsResponse = await getBotSuggestions(
        gptApiUrl,
        gptAuthToken,
        gptPlatformName
      );

      let suggestions = suggestionsResponse.map((item: any) => item.question);

      const updatedContext = {
        ...context,
        suggestions,
      };
      setContext(updatedContext);
      setSuggestions(suggestions);

      const randomSuggestions = suggestions
        .sort(() => Math.random() - 0.5) // Shuffle the array randomly
        .slice(0, 3); // Get the first 3 elements of the shuffled array

      let suggestionBulletList = "";
      randomSuggestions.forEach((suggestion: string) => {
        suggestionBulletList += `\n- "*${suggestion}*"`;
      });
      let message = `Hi ${currentUserName},\n\nI am your ${appName} Copilot here to help answer your questions. Select one of the suggestions below to get started...\n\nYou can always generate the new prompts suggestions by selecting this button`;

      let initialMessages: any = [
        {
          id: generateId(),
          from: "bot",
          type: "message",
          message,
          isSuggestion: true,
          isInitialMessage: true,
        },
      ];

      setMessages(initialMessages);
    };

    // set event listener for storage change
    window.addEventListener("storage", (event) => {
      const copilotPinnedState = JSON.parse(
        sessionStorage.getItem("copilotPinnedState") || "false"
      );
      
      setShowPinned(copilotPinnedState);

      const copilotWindowVisibleState = JSON.parse(
        sessionStorage.getItem("showCopilotWindow") || "false"
      );

      if (copilotPinnedState || copilotWindowVisibleState) {
        setShowFloatingIcon(false);
        return;
      }
      if (!copilotPinnedState && !copilotWindowVisibleState) {
        setShowFloatingIcon(true);
        return;
      }
    });

    const copilotPinnedState = JSON.parse(
      sessionStorage.getItem("copilotPinnedState") || "false"
    );

    const copilotWindowVisibleState = JSON.parse(
      sessionStorage.getItem("showCopilotWindow") || "false"
    );

    if (!copilotPinnedState && !copilotWindowVisibleState) {
      setShowFloatingIcon(true);
      return;
    }

    if (messages.length < 2) {
      setInitialSuggestions();
    }
  }, [showFloatingIcon]);

  useEffect(() => {
    if (isSplitModeOn) {
      setShowFloatingIcon(false);
    }
  }, [isSplitModeOn]);

  useEffect(() => {
    if (capeTeachingBubbleDetails && capeTeachingBubbleDetails.length > 0) {
      setShowTeachingBubble(true);
    } else {
      setShowTeachingBubble(false);
    }
  }, [capeTeachingBubbleDetails]);

  const refreshChat = () => {
    const randomSuggestions = suggestions
      .sort(() => Math.random() - 0.5) // Shuffle the array randomly
      .slice(0, 3); // Get the first 3 elements of the shuffled array

    let suggestionBulletList = "\n";
    randomSuggestions.forEach((suggestion: any) => {
      suggestionBulletList += `\n- "*${suggestion}*"`;
    });
    let message = `Hi ${currentUserName},\n\nI am your ${appName} Copilot here to help answer your questions. Select one of the suggestions below to get started...\n\nYou can always generate the new prompts suggestions by selecting this button`;

    let initialMessages: any = [
      {
        id: generateId(),
        from: "bot",
        type: "message",
        message,
        isSuggestion: true,
        isInitialMessage: true,
      },
    ];

    setMessages(initialMessages);

    appInsights.trackEvent({ name : `${copilotContext.appInsightsEventNamePrefix}ClearChat`},     
     {
      UserName: copilotContext.currentUserName,
      UserEmail: copilotContext.currentUserId,
    });
  };

  const handleBanner = (value: boolean) => {
    if (value) {
     
      appInsights.trackEvent({ name : `${copilotContext.appInsightsEventNamePrefix}BannerOpen`},
        {
        UserName: copilotContext.currentUserName,
        UserEmail: copilotContext.currentUserId,
      });
    } else {
      
      appInsights.trackEvent({ name : `${copilotContext.appInsightsEventNamePrefix}BannerClose`}
      ,
      {
        UserName: copilotContext.currentUserName,
        UserEmail: copilotContext.currentUserId,
      });
    }
    setShowBanner(value);
  };

  const copilotContext = {
    appName,
    appInsightsEventNamePrefix,
    currentUserId,
    currentUserName,
    gptPlatformName,
  };

  const onSendFloatingWindowMessage = (data: string) => {
    onFloatingIconClick(true);
    setFloatingMessage(data);
  };

  const onCloseHeaderFromPanel = () => {
    setShowFloatingIcon(true);
    onDismiss();
  };

  const onDismissCopilotPinnedMode = () => {
    setShowPinned(false);
    sessionStorage.setItem("copilotPinnedState", String(false));
    sessionStorage.setItem("showCopilotWindow", String(true));
    window.dispatchEvent(new Event("storage"));
  };

  const handleTeachingBubble = () => {
    if (capeTeachingBubbleDetails) {
      setShowTeachingBubble(false);
      dispatch(dismissNotifications(capeTeachingBubbleDetails));
    } else {
      console.log("No data found for teaching bubble");
    }

    // logEvent(NotificationClick, {
    //   AppName: AppConstants.ApplicationAlias,
    //   AppLayer: "UI",
    //   metricName: "User Notification",
    //   UserScenario: "User Landed on CAPE Notification Window",
    //   User: { Alias: this.props.userName },
    //   applicationRoles: this.props.userRole,
    //   entityOperation: "Dismiss",
    //   entityName: "Notification",
    //   metaData: {
    //     notificationId: id,
    //     notificationText: dismissedItems.map(
    //       (i) => i.Data.PortalNotification.Content
    //     ),
    //   },
    //   traceSeverity: "Low",
    // });
  };
  return (
    <CopilotProvider mode="canvas">
      <AppContextProvider copilotContext={context}>
        <TelemetryContextProvider appInsights={appInsights}>
          {!showFloatingIcon && (
            <>
              {!showPinned ? (
                <Draggable
                  positionOffset={{ x: "275%", y: "0%" }}
                  cancel="strong"
                >
                  <div className={classes.copilotMainContainer}>
                    <RenderDefaultHeader
                      appInsights={appInsights}
                      copilotContext={copilotContext}
                      isOpen={isOpen}
                      refreshChat={refreshChat}
                      handleBanner={handleBanner}
                      teachingBubbleProps={teachingBubbleProps}
                      onDismiss={onDismiss}
                      handleFloatingIcon={setShowFloatingIcon}
                      copilotPinAction={copilotPinAction}
                      onDismissPinnedMode={onDismissCopilotPinnedMode}
                      showBanner={showBanner}
                      handleCloseWindow={onCloseHeaderFromPanel}
                      setShowPinned={setShowPinned}
                      showPinned={showPinned}
                      showBannerNotification
                      isEngagementWindowOpen={isEngagementWindowOpen}
                    />
                    <ThreadRoot
                      appInsights={appInsights}
                      copilotContext={copilotContext}
                      alias=""
                      apiUrl={gptApiUrl}
                      authToken={gptAuthToken}
                      platformName={gptPlatformName}
                      messages={messages}
                      setMessages={setMessages}
                      suggestions={suggestions}
                      refreshChat={refreshChat}
                      handleBanner={handleBanner}
                      showBanner={showBanner}
                      onSendFloatingWindowMessage={floatingMessage}
                      setFloatingMessage={setFloatingMessage}
                      isPinned={isPinned}
                    />
                  </div>
                </Draggable>
              ) : (
                <div>
                  <InlineDrawer
                    open
                    position="end"
                    className={classes.copilotModalContainer}
                    separator
                  >
                    <RenderDefaultHeader
                      appInsights={appInsights}
                      copilotContext={copilotContext}
                      isOpen={isOpen}
                      refreshChat={refreshChat}
                      showBanner={showBanner}
                      handleBanner={handleBanner}
                      teachingBubbleProps={teachingBubbleProps}
                      handleCloseWindow={onCloseHeaderFromPanel}
                      onDismissPinnedMode={onDismissCopilotPinnedMode}
                      onDismiss={onDismiss}
                      handleFloatingIcon={setShowFloatingIcon}
                      copilotPinAction={copilotPinAction}
                      setShowPinned={setShowPinned}
                      showPinned={showPinned}
                      showBannerNotification
                      isEngagementWindowOpen={isEngagementWindowOpen}
                    />
                    <ThreadRoot
                      appInsights={appInsights}
                      copilotContext={copilotContext}
                      alias=""
                      apiUrl={gptApiUrl}
                      authToken={gptAuthToken}
                      platformName={gptPlatformName}
                      messages={messages}
                      setMessages={setMessages}
                      suggestions={suggestions}
                      refreshChat={refreshChat}
                      handleBanner={handleBanner}
                      showBanner={showBanner}
                      onSendFloatingWindowMessage={floatingMessage}
                      setFloatingMessage={setFloatingMessage}
                      isPinned={isPinned}
                    />
                  </InlineDrawer>
                </div>
              )}
              {displayTeachingBubble && showTeachingBubble && (
                <TeachingBubble
                  calloutProps={{
                    directionalHint: DirectionalHint.bottomCenter,
                  }}
                  target={`#${buttonId}`}
                  hasCondensedHeadline={true}
                  onDismiss={handleTeachingBubble}
                  hasCloseButton={true}
                  headline={teachingBubbleHeader}
                  footerContent={teachingBubbleContent}
                  focusTrapZoneProps={{
                    forceFocusInsideTrap: true,
                    isClickableOutsideFocusTrap: true,
                  }}
                />
              )}
            </>
          )}
          {disableFloatingIcon
            ? false
            : showFloatingIcon && (
                <FloatingActionButton
                  onFloatingIconClick={onFloatingIconClick}
                  handleFloatingIcon={setShowFloatingIcon}
                  onSendButtonClicked={onSendFloatingWindowMessage}
                />
              )}
        </TelemetryContextProvider>
      </AppContextProvider>
    </CopilotProvider>
  );
};

export default Copilot;
