import { SET_ENGAGEMENT_DETAIL } from '../../constants/actions/UI/Engagements';
import { IAction } from '../../constants/types/IAction';
import { IEngagementDetail } from '../../constants/types/UI/Engagements';

export const initialState : IEngagementDetail= {
    show : false,
    new : false,
    details : null,
    isDraft : false
}

export const EnagagementsDetailReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SET_ENGAGEMENT_DETAIL:
            return action.payload    
        default:
            return state;
    }
}