import React, { useEffect } from 'react';
import { MPMessageBar } from '../utils/MPMessageBar';
import { MessageBarType } from 'office-ui-fabric-react';
import { IBannerMessagePayload } from '../../constants/types/UI/BannerMessage';

interface IBannerProps {
    setBanner: Function;
    bannerProps: IBannerMessagePayload;
}

export const BannerMessage: React.FunctionComponent<IBannerProps> = (props: IBannerProps) => {

    const closeBannerHelper = () => {
        const closeBanner: IBannerMessagePayload = {
            toShow: false,
            message: "",
            styleClass: "",
            messageType: MessageBarType.info,
            timeout: 0
        }
        props.setBanner(closeBanner);
    }


    useEffect(() => {
        let falsifytimer: any;
        const local_timeout = props.bannerProps.timeout;
        if (props.bannerProps.toShow) {
            falsifytimer = setTimeout(() => {
                closeBannerHelper();
            }, local_timeout);
        }
        return () => {
            clearTimeout(falsifytimer)
        };
    }, [props.bannerProps]);
    return (
       <div className="banner-message-parent">
            <div className="banner-message-container">
                {props.bannerProps.toShow && (
                    <MPMessageBar
                        className={props.bannerProps.styleClass}
                        isMultiLine={true}
                        onDismiss={() => closeBannerHelper()}
                        messageType={props.bannerProps.messageType}
                    >
                        <div className="banner-message-style" role="alert"><b>{props.bannerProps.message}</b></div>
                    </MPMessageBar>
                )}
            </div>
        </div>
    );
}