
export const GET_METADATA = "GET_METADATA";
export const GET_METADATA_FETCHING = "GET_METADATA_FETCHING";
export const GET_METADATA_SUCCESS = "GET_METADATA_SUCCESS";
export const GET_METADATA_FAILURE = "GET_METADATA_FAILURE";

export const GET_ENGAGEMENTS_DROPDOWN = "GET_ENGAGEMENTS_DROPDOWN";
export const GET_OFFERS_DROPDOWN = "GET_OFFERS_DROPDOWN";
export const GET_OFFERS_DETAILS = "GET_OFFERS_DETAILS";

export const GET_ENGAGEMENTS_DROPDOWN_SUCCESS = "GET_ENGAGEMENTS_DROPDOWN_SUCCESS";
export const GET_OFFERS_DROPDOWN_SUCCESS = "GET_OFFERS_DROPDOWN_SUCCESS";
export const GET_OFFERS_DETAILS_SUCCESS = "GET_OFFERS_DETAILS_SUCCESS";

export const GET_PARTICIPANTS_META = "GET_PARTICIPANTS_META";
export const GET_PARTICIPANTS_META_FETCHING = "GET_PARTICIPANTS_META_FETCHING";
export const GET_PARTICIPANTS_META_SUCCESS = "GET_PARTICIPANTS_META_SUCCESS";
export const GET_PARTICIPANTS_META_FAILURE = "GET_PARTICIPANTS_META_FAILURE";