export const FETCHING_ENGAGEMENTS: string = "FETCHING_ENGAGEMENTS";
export const GET_ENGAGEMENTS: string = "GET_ENGAGEMENTS";
export const FETCHING_ENGAGEMENT_SUCCESS: string = "FETCHING_ENGAGEMENT_SUCCESS";
export const FETCHING_ENGAGEMENT_FAILURE: string = "FETCHING_ENGAGEMENT_FAILURE";
export const UPDATE_ENGAGEMENTS : string = "UPDATE_ENGAGEMENTS";
export const GET_ENGAGEMENT_DATA : string = "GET_ENGAGEMENT_DATA";
export const GET_ENGAGEMENT_CATEGORIES : string = "GET_ENGAGEMENT_CATEGORIES";
export const GET_ENGAGEMENT_CATEGORIES_FETCHING : string = "GET_ENGAGEMENT_CATEGORIES_FETCHING";
export const GET_ENGAGEMENT_CATEGORIES_SUCCESS : string = "GET_ENGAGEMENT_CATEGORIES_SUCCESS";
export const GET_ENGAGEMENT_CATEGORIES_FAILURE : string = "GET_ENGAGEMENT_CATEGORIES_FAILURE";
