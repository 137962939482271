import React from "react";
import "./OffersList.css";
import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Selection,
} from "@fluentui/react/lib/DetailsList";
import { IOfferDetailData } from "../../constants/types/UI/Offers";
import { OFFERS_URL } from "../../constants/routes";
import { FontIcon } from "office-ui-fabric-react";

interface IOffersListState {
}

interface IOffersListProps {
  offersData: IOffersData[];
  offersHeaderData: IColumn[];
  activeMenuDisplaytext: string;
  history: any;
  fetching : boolean;
}

interface IOffersData {
  key?: number;
  OfferCode?: string;
  Role: string;
  IsActive?: any;
  Team?: number;
  OfferTitle?: string;
  OfferingSummary?: string;
  OfferingDetails?: string;
  AccessLevel?: number | string;
  OfferLegalTerms?: string;
  CloudType?: number;
  OfferTemplateVersion?: string;
  CreatedBy: string;
  CreatedDate: string;
  LastModifiedBy?: string;
  LastModifiedDate?: string;
  IsDeleted?: boolean;
  Status?: number;
  CELAContact?: string;
  EngagementDeliveryLeads?: any;
  LastOfferPublishedOn?: string;
  TotalEngagements?: number;
}

export class OffersList extends React.Component<IOffersListProps, IOffersListState> {
  intervalId: any;
  private rowSelector: Selection;

  constructor(props: IOffersListProps) {
    super(props);
    this.rowSelector = new Selection({
      onSelectionChanged: this.onActionGridRowSelect,
    });

    this.removeAllGridSelection = this.removeAllGridSelection.bind(this);
  }

  removeAllGridSelection = () => {
    this.rowSelector.setAllSelected(false);
  };

  private onActionGridRowSelect = () => {
    let selectedRows: any = this.rowSelector.getSelection();
    if (selectedRows && selectedRows.length > 0) {
      let row: IOfferDetailData = selectedRows[0];
      this.props.history.push(`${OFFERS_URL}?id=${row.OfferCode}`);
    }
  };

  addScrollListener = () => {
    const element = document.querySelector(
      ".offers-detail-list .ms-DetailsList-contentWrapper"
    );
    if (element) {
      element.addEventListener("scroll", (event: any) => {
        this.runOnScroll(event);
      });
      clearInterval(this.intervalId);
    }
  };

  runOnScroll = (event: any) => {
    const headerElement = document.querySelector(
      ".offers-detail-list .ms-DetailsList-headerWrapper"
    );
    if (headerElement) {
      headerElement.scrollLeft = event.target.scrollLeft;
    }
  };

  // componentDidMount() {
  //   this.intervalId = setInterval(this.addScrollListener, 2000);
  // }

  componentDidMount() {
    this.intervalId = setInterval(this.addScrollListener, 2000);
    document.addEventListener('triggerEngagementUpdate',this.removeAllGridSelection,true);
  }

  componentDidUpdate(prevProps: IOffersListProps) {
    this.intervalId = setInterval(this.addScrollListener, 2000);
  }

  render() {
    if (this.props.offersData.length > 0) {
      return (
        <div className="ListContainer offers-detail-list" role="status" aria-label="Offers List">
          <h4 className="offersTitle">{this.props.activeMenuDisplaytext}</h4>
          <div role="log" aria-labelledby='Offers list' aria-describedby='Offers list' >
            <DetailsList
              className="actionDetailsGrid"
              items={this.props.offersData}
              columns={this.props.offersHeaderData}
              setKey="set"
              selectionMode={SelectionMode.single}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              isHeaderVisible={true}
              // selection={this.rowSelector}
              compact={false}
              // layoutMode={DetailsListLayoutMode.fixedColumns}
              constrainMode={ConstrainMode.unconstrained}
              checkboxVisibility={CheckboxVisibility.hidden}
              ariaLabel={this.props.activeMenuDisplaytext + " table"}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="ListContainer">
          <h4 className="offersTitle">{this.props.activeMenuDisplaytext}</h4>
          <div className="noRecord">
            {!this.props.fetching && <div className="no-item-container">
              <div>
                <FontIcon className="no-items-icon" iconName="SearchAndApps" />
              </div>
              <div className="no-item-text" role="alert">No Offers in this status!</div>
            </div>}
          </div>
        </div>
      );
    }
  }
}

export default OffersList;
