import React, { Component, CSSProperties } from "react";
import {
  addDays,
  ChoiceGroup,
  DatePicker,
  Dialog,
  DialogFooter,
  DialogType,
  FocusTrapZone,
  IChoiceGroupOption,
  IconButton,
  IContextualMenuProps,
  IDatePickerStrings,
  IIconProps,
  IStackStyles,
  mergeStyleSets,
  MessageBar,
  Panel,
  PanelType,
  Stack,
  TooltipDelay,
} from "office-ui-fabric-react";
import {
  DefaultButton,
  FontIcon,
  PrimaryButton,
  Link,
  Checkbox,
  IPersonaProps,
  MessageBarType,
  Toggle,
  DirectionalHint,
} from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import { connect } from "react-redux";
import {
  getEngagementsCategory,
  updateEngagements,
} from "../../actions/UI/Engagements";
import "./EngagementDetail.css";
import {
  IEngagementDetail,
  MAPPED_ENGAGEMENT_STATUS,
  ENGAGEMENT_STATUS,
  IEngagementDetailData,
  IEngagementSubscriptions,
  IGetEngagementCategoryPayload,
  MAPPED_ENGAGEMENT_SUBSTATUS,
  ENGAGEMENT_SUBSTATUS,
} from "../../constants/types/UI/Engagements";
import { IComboBox, IComboBoxOption } from "@fluentui/react/lib/ComboBox";
import PeoplePicker from "../common/PeoplePicker/PeoplePicker";
import { AuthResponse } from "msal";
import { Account } from "msal";
import {
  activeEngagementView,
  activeSideNavs,
  IAppScopes,
  IColumn,
  IDate,
  IPeople,
  IUserContext,
} from "../../constants/types/UI/Generic";
import { IBannerMessagePayload } from "../../constants/types/UI/BannerMessage";
import { setBanner } from "../../actions/UI/BannerMessage";
import { showRatingInCenter } from "../../actions/UI/Rating";
import { updateGraphState } from "../../actions/API/GraphState";
import CustomTextField from "../utils/CustomTextField/CustomTextField";
import CustomComboBox from "../utils/CustomComboBox/CustomComboBox";
import AppConstants, {
  AppRoles,
  azureARMTemplate,
  base64Append,
  EndPoints,
  entityQueryString,
  microsfotAliasAppendString,
  photoIDString,
  PlaceHolderOption,
  RequestSuccess,
  ShowHelpText,
  SubscriptionIDRegex,
  userIDString,
} from "../../constants/AppConstants";
import {
  logEvent,
  ActionTrackingEngagement,
  Navigation,
  Feedback_Rating,
  ProgramReferal,
  TeamsMenuClicked,
  ClickTrackingEngagement,
} from "../../utils/insightsClient";
import { ENGAGEMENTS_URL } from "../../constants/routes";
import { IField } from "../../constants/types/UI/Generic";
import {
  IMetaData,
  IParticipantMeta,
} from "../../constants/types/API/MetaData";
import {
  createOfferInPC,
  upsertEngagement,
} from "../../actions/API/EngagementUpdate";
import { IDataResponse } from "../../constants/types/API/Apiresponse";
import { IGraphState } from "../../constants/types/API/GraphState";
import _ from "lodash";
import { parseQS } from "../../utils/helperFunctions";
import ParticipantsModal from "../participants/ParticipantsModal";
import { EntityOperation } from "roosterjs-editor-types";
import {
  TooltipHost,
  ITooltipHostStyles,
  ITooltipProps,
  TooltipOverflowMode,
} from "@fluentui/react/lib/Tooltip";
import { MPJSONDialog } from "../utils/MPJSONDialog";
import { offerStatus } from "../offers/OfferDetail";
import { IRatingPayload } from "../../constants/types/UI/Rating";
import PersistentNotification from "../common/PersistentNotification";
import { IModalPayload } from "../../constants/types/UI/Modal";
import { showModal } from "../../actions/UI/Modal";
import { INotifications } from "../../constants/types/UI/Notifications";
import moment from "moment";
import { check } from "yargs";

const stackStyles: Partial<IStackStyles> = { root: { padding: 10 } };
const teamsIcon: IIconProps = { iconName: "TeamsLogo16" };
const styles = mergeStyleSets({
  root: { selectors: { "> *": { marginBottom: 15 } } },
  control: { maxWidth: 428, marginBottom: 15 },
});
interface IEngagementDetailProps {
  engagementDetail: IEngagementDetail;
  graphTokenObj: AuthResponse | null;
  apiAccessToken: AuthResponse | null;
  coreApiAccessToken: AuthResponse | null;
  user: Account;
  setBanner: Function;
  showRatingInCenter: Function;
  initURLState: boolean;
  history: any;
  metaData: { [key: string]: IMetaData };
  upsertEngagement: Function;
  createOfferInPC: Function;
  toggleSpinner: Function;
  upsertEngagementState: IDataResponse;
  updateEngagementsTable: Function;
  graphState: IGraphState;
  updateGraphState: Function;
  location: {
    pathname: string;
    search: string;
    hash: string;
  };
  appScopes: IAppScopes;
  userContext: IDataResponse<IUserContext>;
  participantsMeta: { [key: string]: IParticipantMeta };
  modalProps: IModalPayload;
  showModal: Function;
  getNotifications: IDataResponse;
  engagementCategories: IDataResponse;
  getEngagementCategories: Function;
  searchTerm: string;
  selectedView: string;
  getContext: Function;
}

interface IEngagementDetailState {
  viewHelp: boolean;
  deliveryTeam: IField<IPeople[]>;
  deliveryNewTeamMember: IField<IPeople[]>;
  internalDescription: IField<string>;
  editComment: IField<string>;
  deliveryLead: IField<IPeople[]>;
  customerSubscriptionIds: IField<string>;
  customerName: IField<string>;
  rossMilestoneTitle: IField<string>;
  rossMileStoneID: IField<string>;
  rossMileStoneType: IField<number>;
  startDate: IDate<Date>;
  endDate: IDate<Date>;
  contractType: IField<IComboBoxOption>;
  offerType: IField<IComboBoxOption>;
  entityType: IField<IComboBoxOption>;
  deliveryOption: IField<IComboBoxOption>;
  deliveryOptionOther: IField<string>;
  status: IField<IComboBoxOption>;
  subStatus: IField<IComboBoxOption>;
  dropDownsData: {
    DeliveryOption: IComboBoxOption[];
    OfferType: IComboBoxOption[];
    EngagementStatus: IComboBoxOption[];
    EngagementSubStatus: IComboBoxOption[];
    EntityType: IComboBoxOption[];
    GeographyOption: IComboBoxOption[];
  };
  isFormValid: boolean;
  isTest: boolean;
  isSubscriptionRemoved: boolean;
  isSupportOnly: boolean;
  isUserInPod: boolean;
  showValidIDNotification: boolean;
  titletoBeShown: string;
  modifiedOffersDropDown: IColumn[];
  toHideParticipantModal: boolean;
  showValidIDMessage: string;
  appRole: {
    isAdmin: boolean;
    isEngagementMember: boolean;
    isDeliveryTeamMember: boolean;
  };
  scopes: {
    create: boolean;
    edit: boolean;
  };
  engagementLevelScope: {
    deliveryLead: boolean;
    deliveryTeam: boolean;
  };
  currentStatus: any;
  currentSubStatus: any;
  accessibilityScopes: {
    irdDisable: boolean;
    normalDisable: boolean;
    disableStatus: boolean;
    editCommentDisable: boolean;
  };
  hideJsonDialog: boolean;
  jsonObject: any;
  partnerCenterUrl: string | null;
  engProgramName: IField<string>;
  engProgramId: number | null;
  cloudType: IChoiceGroupOption[];
  selectedCloudType: IChoiceGroupOption | undefined;
  buttonText: string;
  geographyOption: IField<IComboBoxOption>;
  regionDropdownOption: IComboBoxOption[];
  regionOption: IField<IComboBoxOption>;
  subscriptionIDs: string[];
  engagementSubscriptions: IEngagementSubscriptions[];
  // deliveryoption, geography, region, ross/Milestone, internal description
  enableNonPartnerCenterFields: boolean;
  enableDeliveryLead: boolean;
  enableDeliveryTeam: boolean;
  enableOfferType: boolean;
  enableRossMilestone: boolean;
  enableSave: boolean;
  enableCustomerName: boolean;
  enableCustomerSubscription: boolean;
  enableCustomerOnboardingType: boolean;
  enableDeliveryOption: boolean;
  enableGeography: boolean;
  enableRegion: boolean;
  enableInternalDescription: boolean;
  enableTestToggle: boolean;
  enableSubscriptionAccessRemovalToggle: boolean;
  enableSupportOnlyToggle: boolean;
  showEditLiveEngagementWarning: boolean;
  liveEngagementFieldChanges: boolean;
  editedFields: string[];
  deliveryTeamChanged: boolean;
  deliveryLeadChanged: boolean;
  deliveryOptionChanged: boolean;
  entityTypeChanged: boolean;
  customerNameChanged: boolean;
  subscriptionIDChanged: boolean;
  geographychanged: boolean;
  endDateChanged: boolean;
  regionChanged: boolean;
  internalDescriptionChanged: boolean;
  isSubsIdPresent: boolean;
  enableStartDate: boolean;
  enableEndDate: boolean;
  showModalOnStatusChange: boolean;
  showSupportOnlyContractModal: boolean;
  categoryCount: number;
  showSuccessComponent: boolean;
  showcancelComponent: boolean;
  isNewDeliveryLeadAdded: boolean;
  splitEngagementWindow: boolean;
  showTooltip:boolean;
}

const ttstyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};
const ttcalloutProps = { gapSpace: 0 };

const drafttooltipProps: ITooltipProps = {
  onRenderContent: () => <div>Save my progress and finish later</div>,
};
const isIframe = window !== window.parent && !window.opener;
const modalPropsStyles = { main: { maxWidth: 450 } };
const dialogContentProps = {
  type: DialogType.normal,
  title: "Editing 'Live' Engagement",
  subText:
    "You are making changes to 'Live' engagement, would you like to continue ?",
};

const dialogContentStatusCompleteProps = {
  type: DialogType.normal,
  title: "Confirm Access Removal",
  subText:
    "Please confirm delegated access to customer's subscription(s) for this Engagement is removed before you update the State to Completed",
};
const dialogContentSupportContractProps = {
  type: DialogType.normal,
  title: "Confirm Support contract is Active",
  subText:
    "Please confirm Customer has an active Support contract by working with account team and CSAM .",
};

const submittooltipProps: ITooltipProps = {
  onRenderContent: () => <div>Submit my Engagement</div>,
};
const savetooltipProps: ITooltipProps = {
  onRenderContent: () => <div>Save my Engagement</div>,
};

const downloadT_C: ITooltipProps = {
  onRenderContent: () => <div>Download T&C</div>,
};

const canceltooltipProps: ITooltipProps = {
  onRenderContent: () => <div>Cancel and exit the Engagement</div>,
};
const testToggletooltipProps: ITooltipProps = {
  onRenderContent: () => <div>Select Yes if Engagement is created for testing purposes</div>,
  delay: TooltipDelay.medium,
};
const tooltipId = 'tooltip';

const engStatus = {
  active: "active",
  inactive: "inactive",
};

const ENTITY_TYPE = {
  MILESTONE: "Milestone",
  ROSS: "ROSS",
  NOT_AVAILABLE: "I don't have a ROSS/Milestone ID for this engagement",
};

let ENTITY_IDS = {
  MILESTONE: "",
  ROSS: "",
  NOT_AVAILABLE: "",
};

const modalProps = {
  styles: { main: { maxWidth: 500 } },
  isModeless: true,
  dragOptions: false,
};

const buildDropDowns = (metaData: IColumn[]): IComboBoxOption[] => {
  const dropDownValues: IComboBoxOption[] = [
    {
      key: PlaceHolderOption,
      text: PlaceHolderOption,
      disabled: true,
      styles: { optionText: { fontStyle: "italic", color: "#424040" } },
    },
  ];
  metaData.forEach((column) => {
    dropDownValues.push({ key: column.key, text: column.key.toString() });
  });
  return dropDownValues;
};

const buildEntityType = (entityType: any): IComboBoxOption[] => {
  const entityTypeData: IComboBoxOption[] = [
    {
      key: PlaceHolderOption,
      text: PlaceHolderOption,
      disabled: true,
      styles: { optionText: { fontStyle: "italic", color: "#424040" } },
    },
  ];
  entityType.values.forEach((column: any) => {
    entityTypeData.push({
      key: column.metaData.cdd_Id,
      text: column.key.toString(),
    });
    column.key === ENTITY_TYPE.MILESTONE
      ? (ENTITY_IDS.MILESTONE = column.metaData.cdd_Id)
      : column.key === ENTITY_TYPE.ROSS
      ? (ENTITY_IDS.ROSS = column.metaData.cdd_Id)
      : (ENTITY_IDS.NOT_AVAILABLE = column.metaData.cdd_Id);
  });
  return entityTypeData;
};

const buildGeographyAndRegion = (data: any): IComboBoxOption[] => {
  const optionData: IComboBoxOption[] = [];
  data.values.forEach((column: any) => {
    optionData.push({
      key: column.metaData.cdd_Id.toString(),
      text: column.key.toString(),
      id: column.metaData.cdd_ParentId,
    });
  });
  return optionData;
};

const buildOffertype = (
  offers: IColumn[],
  accessLevels: IColumn[],
  offerStates: IColumn[],
  teams: IColumn[],
  isNew: boolean,
  flights: string[],
  isSupportOnly: boolean
): [IComboBoxOption[], IColumn[]] => {
  const activeStateNo = offerStates.find(
    (val) => val.id.toString().toLowerCase() == engStatus.active
  )?.metaData.cdd_Id;
  const inreviewStateNo = offerStates.find(
    (val) => val.id.toString().toLowerCase() == offerStatus.inReview
  )?.metaData.cdd_Id;
  const indraftStateNo = offerStates.find(
    (val) => val.id.toString().toLowerCase() == offerStatus.draft
  )?.metaData.cdd_Id;
  offers = isSupportOnly
    ? offers.filter((offer) => offer.metaData.IsSupportOnly === true)
    : offers.filter((offer) => offer.metaData.IsSupportOnly !== true);
  const dropDownValues: IComboBoxOption[] = [
    {
      key: PlaceHolderOption,
      text: PlaceHolderOption,
      styles: { optionText: { fontStyle: "italic", color: "#424040" } },
    },
  ];
  var modifiedOffers: IColumn[] = [];
  offers.forEach((offer) => {
    const newKey = `Microsoft - ${teams
      .find((team) => team.metaData.cdd_Id == offer.metaData.Team)
      ?.id?.toString()} - ${offer.metaData.OfferTitle} (${accessLevels
      .find((acsLvl) => acsLvl.metaData.cdd_Id == offer.metaData.AccessLevel)
      ?.id.toString()})`;
    modifiedOffers.push({
      id: newKey,
      key: newKey,
      metaData: offer.metaData,
    });
  });
  modifiedOffers = modifiedOffers.sort((a: IColumn, b: IColumn) => {
    var nameA = a.id.toString().toLowerCase(),
      nameB = b.id.toString().toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  modifiedOffers.forEach((offer) => {
    if (offer.metaData.Status === activeStateNo && !offer.metaData.IsDeleted) {
      dropDownValues.push({
        key: offer.id.toString(),
        text: offer.id.toString(),
      });
    } else if (
      !isNew &&
      offer.metaData.Status !== inreviewStateNo &&
      offer.metaData.Status !== indraftStateNo
    ) {
      dropDownValues.push({
        key: offer.id.toString(),
        text: offer.id.toString(),
        disabled: true,
      });
    }
  });

  return [dropDownValues, modifiedOffers];
};

const buildEngagementStateOptions = (
  liveState: string = "",
  selectedCloudType: string,
  isAdmin: boolean
) => {
  const dropDownValues: IComboBoxOption[] = [
    {
      key: PlaceHolderOption,
      text: PlaceHolderOption,
      styles: { optionText: { fontStyle: "italic", color: "#424040" } },
    },
  ];
  const disabledStates = [
    MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME,
    MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME,
    MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME,
    MAPPED_ENGAGEMENT_STATUS.ERROR_FOUND.DISPLAY_NAME,
    MAPPED_ENGAGEMENT_STATUS.DOWNLOAD_ARM.DISPLAY_NAME,
    MAPPED_ENGAGEMENT_STATUS.DOWNLOAD_TC.DISPLAY_NAME,
    MAPPED_ENGAGEMENT_STATUS.CLOSED.DISPLAY_NAME,
    MAPPED_ENGAGEMENT_STATUS.COMPLETED.DISPLAY_NAME,
  ];
  if (liveState) {
    if (disabledStates.indexOf(liveState) > -1) {
      const enabledStates =
        selectedCloudType === azureARMTemplate &&
        liveState === MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME
          ? disabledStates.splice(
              disabledStates.indexOf(
                MAPPED_ENGAGEMENT_STATUS.DOWNLOAD_ARM.DISPLAY_NAME
              ),
              1
            )
          : isAdmin &&
            (liveState === MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME ||
              liveState === MAPPED_ENGAGEMENT_STATUS.COMPLETED.DISPLAY_NAME)
          ? disabledStates.splice(
              disabledStates.indexOf(
                MAPPED_ENGAGEMENT_STATUS.COMPLETED.DISPLAY_NAME
              ),
              1
            ) &&
            disabledStates.splice(
              disabledStates.indexOf(
                MAPPED_ENGAGEMENT_STATUS.CLOSED.DISPLAY_NAME
              ),
              1
            )
          : isAdmin &&
            liveState === MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME
          ? disabledStates.splice(
              disabledStates.indexOf(
                MAPPED_ENGAGEMENT_STATUS.CLOSED.DISPLAY_NAME
              ),
              1
            )
          : !isAdmin && liveState === MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME
          ? disabledStates.splice(
              disabledStates.indexOf(
                MAPPED_ENGAGEMENT_STATUS.COMPLETED.DISPLAY_NAME
              ),
              1
            )
          : disabledStates.splice(disabledStates.indexOf(liveState), 1);
    }
  }
  Object.values(MAPPED_ENGAGEMENT_STATUS).forEach((item) => {
    dropDownValues.push({
      key: item.DISPLAY_NAME,
      text: item.DISPLAY_NAME,
      disabled:
        selectedCloudType === azureARMTemplate &&
        item.DISPLAY_NAME === MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME
          ? true
          : disabledStates.indexOf(item.DISPLAY_NAME) > -1,
    });
  });

  return dropDownValues;
};
const buildEngagementSubStateOptions = (
  liveState: string = "",
  selectedCloudType: string
) => {
  const dropDownValues: IComboBoxOption[] = [
    {
      key: PlaceHolderOption,
      text: PlaceHolderOption,
      styles: { optionText: { fontStyle: "italic", color: "#424040" } },
    },
  ];

  Object.values(MAPPED_ENGAGEMENT_SUBSTATUS).forEach((item) => {
    dropDownValues.push({
      key: item.DISPLAY_NAME,
      text: item.DISPLAY_NAME,
    });
  });

  return dropDownValues;
};
const Others = "Others";

const sortParticipantHelper = (a: IParticipantMeta, b: IParticipantMeta) => {
  var nameA = a.displayName.toLowerCase(),
    nameB = b.displayName.toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

class EngagementDetail extends Component<
  IEngagementDetailProps,
  IEngagementDetailState
> {
  constructor(props: any) {
    super(props);
    const [dropDownValues, modifiedOffersDropDown] = buildOffertype(
      this.props.metaData.Offers.values ?? [],
      this.props.metaData.PodAuthorizationRole.values ?? [],
      this.props.metaData.OfferState.values ?? [],
      this.props.metaData.Team.values ?? [],
      !!this.props.engagementDetail.new,
      this.props.userContext?.data?.flights ?? [],
      this.props.engagementDetail.new
        ? true
        : this.props.engagementDetail.details &&
          this.props.engagementDetail.details.IsSupportOnly != null
        ? this.props.engagementDetail.details.IsSupportOnly
        : false
    );
    this.state = {
      showSuccessComponent: false,
      viewHelp: false,
      deliveryTeam: {
        value: [
          {
            name: this.props.engagementDetail.new
              ? this.props.user.userName
              : this.props.engagementDetail.details?.DeliveryLead
              ? `${this.props.engagementDetail.details?.DeliveryLead}${microsfotAliasAppendString}`
              : "",
            alias: this.props.engagementDetail.new
              ? this.props.user.userName
              : this.props.engagementDetail.details?.DeliveryLead
              ? `${this.props.engagementDetail.details?.DeliveryLead}${microsfotAliasAppendString}`
              : "",
          },
        ],
        required: true,
      },
      deliveryNewTeamMember: {
        value: [
          {
            name: "",
            alias: "",
          },
        ],
        required: true,
      },
      internalDescription: { value: "" },
      editComment: { value: "" },
      deliveryLead: {
        value: [
          {
            name: this.props.engagementDetail.new
              ? this.props.user.userName
              : this.props.engagementDetail.details?.DeliveryLead
              ? `${this.props.engagementDetail.details?.DeliveryLead}${microsfotAliasAppendString}`
              : "",
            alias: this.props.engagementDetail.new
              ? this.props.user.userName
              : this.props.engagementDetail.details?.DeliveryLead
              ? `${this.props.engagementDetail.details?.DeliveryLead}${microsfotAliasAppendString}`
              : "",
          },
        ],
        required: true,
      },
      customerSubscriptionIds: { value: undefined, required: true },
      engagementSubscriptions: [],
      customerName: { value: "", required: true },
      rossMilestoneTitle: { value: "" },
      rossMileStoneID: { value: "", required: true },
      rossMileStoneType: {},
      startDate: { value: undefined, required: true, errorMessage: "" },
      endDate: { value: undefined, required: true, errorMessage: "" },
      deliveryOptionOther: { value: "", required: true },
      contractType: { required: true },
      offerType: { required: true },
      entityType: { required: true },
      deliveryOption: { required: true },
      status: { required: true },
      subStatus: { required: true },
      dropDownsData: {
        DeliveryOption: buildDropDowns(
          this.props.metaData.DeliveryOption.values ?? []
        ),
        OfferType: dropDownValues,
        EngagementStatus: [],
        EngagementSubStatus: [],
        EntityType: buildEntityType(this.props.metaData.EntityType),
        GeographyOption: buildGeographyAndRegion(this.props.metaData.Geography),
      },
      isFormValid: true,
      isTest: false,
      isSubscriptionRemoved: this.props.engagementDetail.details?.IsSubscriptionRemoved != null
      ? this.props.engagementDetail.details.IsSubscriptionRemoved
      : (this.props.engagementDetail.details?.Status === ENGAGEMENT_STATUS.COMPLETED) ? true : false,
      isSupportOnly: this.props.engagementDetail.new
        ? true
        : this.props.engagementDetail.details?.IsSupportOnly != null
        ? this.props.engagementDetail.details.IsSupportOnly
        : false,
      isUserInPod: false,
      showValidIDNotification: false,
      titletoBeShown: "",
      modifiedOffersDropDown: modifiedOffersDropDown,
      toHideParticipantModal: true,
      showValidIDMessage: "",
      appRole: {
        isAdmin: this.props.userContext.data?.roles?.find(
          (role) => role.name === AppRoles.SiteAdmin.roleName
        )
          ? true
          : false,
        isEngagementMember: this.props.userContext.data?.roles?.find(
          (role) => role.name === AppRoles.EngagementManager.roleName
        )
          ? true
          : false,
        isDeliveryTeamMember: this.props.userContext.data?.roles?.find(
          (role) => role.name === AppRoles.DeliveryTeamMember.roleName
        )
          ? true
          : false,
      },
      scopes: {
        create: this.props.appScopes.Engagements.Create,
        edit: this.props.appScopes.Engagements.Edit,
      },
      engagementLevelScope: {
        deliveryLead: this.props.engagementDetail.details?.DeliveryLead
          ? this.props.engagementDetail.details?.DeliveryLead.toLowerCase() ===
            this.props.user.userName.split("@")[0].toLowerCase()
          : false,
        deliveryTeam: this.props.engagementDetail.details?.DeliveryPod
          ? this.props.engagementDetail.details.DeliveryPod.find(
              (podMember) =>
                podMember.Alias.toLowerCase() ===
                this.props.user.userName.split("@")[0].toLowerCase()
            )
            ? true
            : false
          : false,
      },
      accessibilityScopes: {
        irdDisable: false,
        normalDisable: false,
        disableStatus: false,
        editCommentDisable: true,
      },
      currentStatus:
        Object.values(MAPPED_ENGAGEMENT_STATUS).find(
          (status) =>
            status.ORIGINAL_STATUS.indexOf(
              this.props.engagementDetail.details?.Status ?? -1
            ) > -1
        ) ?? null,
      currentSubStatus:
        Object.values(MAPPED_ENGAGEMENT_SUBSTATUS).find(
          (status) =>
            status.ORIGINAL_STATUS.indexOf(
              this.props.engagementDetail.details?.SubStatusId ?? -1
            ) > -1
        ) ?? null,
      hideJsonDialog: true,
      jsonObject: (
        <div>
          <pre></pre>
        </div>
      ),
      partnerCenterUrl: null,
      engProgramName: { value: "" },
      engProgramId: null,
      cloudType: this.buildCloudType(
        this.props.metaData.CustomerOnboardingType
      ),
      selectedCloudType: undefined,
      buttonText: this.buildCloudType(
        this.props.metaData.CustomerOnboardingType
      ).find(
        (cloudType) =>
          cloudType.key ===
            this.props.engagementDetail.details?.ENG_OnboardingType_Id?.toString() &&
          cloudType.text === azureARMTemplate
      )
        ? "Download T & C"
        : "Submit",
      geographyOption: { required: false },
      regionDropdownOption: this.props.engagementDetail.details
        ?.ENG_Geography_Id
        ? this.filterRegion({
            key: this.props.engagementDetail.details?.ENG_Geography_Id.toString(),
            text: "",
          })
        : [],
      regionOption: { required: false },
      enableNonPartnerCenterFields: false,
      enableDeliveryLead: false,
      enableDeliveryTeam: false,
      enableOfferType: false,
      enableRossMilestone: false,
      enableCustomerName: false,
      enableCustomerSubscription: false,
      enableCustomerOnboardingType: false,
      enableDeliveryOption: false,
      enableGeography: false,
      enableRegion: false,
      enableInternalDescription: false,
      enableSave: false,
      enableTestToggle: false,
      enableSubscriptionAccessRemovalToggle: false,
      enableSupportOnlyToggle: false,
      subscriptionIDs: [],
      showEditLiveEngagementWarning: false,
      liveEngagementFieldChanges: false,
      editedFields: [],
      deliveryTeamChanged: false,
      deliveryLeadChanged: false,
      deliveryOptionChanged: false,
      entityTypeChanged: false,
      customerNameChanged: false,
      subscriptionIDChanged: false,
      geographychanged: false,
      regionChanged: false,
      internalDescriptionChanged: false,
      endDateChanged: false,
      isSubsIdPresent: false,
      enableStartDate: false,
      enableEndDate: false,
      showModalOnStatusChange: false,
      showSupportOnlyContractModal: false,
      categoryCount: 0,
      showcancelComponent: false,
      isNewDeliveryLeadAdded: false,
      splitEngagementWindow: this.getEngagementState(),
      showTooltip:false
    };

    const parsedQS = parseQS(this.props.location.search);
    var engProgramName = parsedQS[entityQueryString.ProgramName];
    var engProgramId = parsedQS[entityQueryString.ProgramId];
    if (engProgramName) {
      logEvent(ProgramReferal, {
        AppName: AppConstants.ApplicationAlias,
        AppLayer: "UI",
        metricName: "Program Referal",
        UserScenario: "User Landing on CAPE portal from a referal site",
        User: { Alias: this.props.userContext.data?.email },
        applicationRoles: this.props.userContext.data?.roles,
        entityOperation: "Click",
        entityName: "Program",
        metaData: {
          landingPage: "Engagement",
          queryString: parsedQS,
          programName: engProgramName,
        },
        traceSeverity: "Important",
      });
    }
  }
  triggerGetEngagementCategories = async () => {
    const engPayload: IGetEngagementCategoryPayload = {
      searchTerm: "",
      selectedView: "1",
    };
    var enaggementsCountURL =
      AppConstants.APP_API_URL +
      `${EndPoints.GetEntityCount}?Entity=Engagement&SearchTerm=${engPayload.searchTerm}&EntityScope=Self`;
    const response = await fetch(enaggementsCountURL, {
      headers: {
        Authorization: `Bearer ${this.props.apiAccessToken?.accessToken}`,
        coreapiaccesstoken: `Bearer ${this.props.coreApiAccessToken?.accessToken}`,
        "Content-Type": "application/octet-stream",
        Accept: "application/octet-stream",
      },
      method: "GET",
    });
    const categoryCount = await response.json();
    this.buildCategories(categoryCount);
  };
  componentDidMount() {
    this.triggerGetEngagementCategories();
    this.updateStatesForEngagement();
    this.setState({
      hideJsonDialog: true,
    });
    if (this.props.engagementDetail.details) {
      if (
        MAPPED_ENGAGEMENT_STATUS.ERROR_FOUND.IDENTIFIER ==
          this.state.currentStatus?.IDENTIFIER ||
        MAPPED_ENGAGEMENT_STATUS.LIVE.IDENTIFIER ==
          this.state.currentStatus?.IDENTIFIER ||
        MAPPED_ENGAGEMENT_STATUS.PROCESSING.IDENTIFIER ==
          this.state.currentStatus?.IDENTIFIER ||
          MAPPED_ENGAGEMENT_STATUS.COMPLETED.IDENTIFIER ==
          this.state.currentStatus?.IDENTIFIER ||
          MAPPED_ENGAGEMENT_STATUS.CLOSED.IDENTIFIER ==
          this.state.currentStatus?.IDENTIFIER
      ) {
        if (
          this.props.engagementDetail.details.Status !==
            ENGAGEMENT_STATUS.DOWNLOADED_ARM &&
          this.props.engagementDetail.details.Status !==
            ENGAGEMENT_STATUS.DOWNLOADED_TC
        ) {
          this.partnerApiCall(this.props.engagementDetail.details?.OfferId);
        }
      }
    }
    window.addEventListener("storage", this.handleEngagementPanel, false);
  }

  componentDidUpdate(
    prevProps: IEngagementDetailProps,
    prevState: IEngagementDetailState
  ) {
    if (prevProps.upsertEngagementState !== this.props.upsertEngagementState) {
      if (
        !this.props.upsertEngagementState.fetching &&
        !this.props.upsertEngagementState.error
      ) {
        if (
          this.props.engagementDetail.new &&
          this.props.upsertEngagementState.data.item.Status ==
            ENGAGEMENT_STATUS.DRAFT
        ) {
          this.buildAndShowBanner(
            `Created new Draft Engagement '${this.state.customerName.value}' successfully`,
            MessageBarType.success,
            7000
          );
        } else if (
          (this.props.engagementDetail.new ||
            this.state.currentStatus?.DISPLAY_NAME ===
              MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME) &&
          this.props.upsertEngagementState.data.item.Status ==
            ENGAGEMENT_STATUS.CREATED
        ) {
          if (!isIframe) {
            this.buildAndShowBanner(
              `Submission received for '${this.state.customerName.value}'. CAPE is now processing your Engagement`,
              MessageBarType.success,
              7000
            );
            this.createOfferInPCAsync();
            this.showUserRatingInCenter(true);
          } else {
            this.setState({
              showSuccessComponent: true,
            });
            this.createOfferInPCAsync();
            window.parent.postMessage(
              {
                EngagementId:
                  this.props.upsertEngagementState.data.item.EngagementId,
                Title: this.props.upsertEngagementState.data.item.Customer,
              },
              "*"
            );
          }
        } else if (
          this.props.upsertEngagementState.data.item.Status ==
            ENGAGEMENT_STATUS.DRAFT &&
          this.props.upsertEngagementState.data.item.Status ==
            ENGAGEMENT_STATUS.DRAFT
        ) {
          this.buildAndShowBanner(
            `Updated Draft Engagement '${this.state.customerName.value}' successfully`,
            MessageBarType.success,
            7000
          );
        } else if (
          this.props.upsertEngagementState.data.item.Status ==
            ENGAGEMENT_STATUS.CREATED &&
          this.state.currentStatus?.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME
        ) {
          this.createOfferInPCAsync();
          this.props.showModal({
            toShow: true,
            title: "Success",
            text: `Your Engagement '${this.props.engagementDetail.details?.EngagementId}' is successfully resubmitted for publishing. It can take up to 48 hours for the Offer to be published Live. After the Offer is re-published, please notify your customer to reaccept the Offer from their Azure portal for the changes to reflect.`,
          });
          setTimeout(() => {
            this.showUserRatingInCenter(true);
          }, 7000);
        } else {
          if (
            this.state.selectedCloudType?.text === azureARMTemplate &&
            (this.props.engagementDetail.new || this.isDraftState())
          ) {
            this.getOctetStream();
          } else {
            this.buildAndShowBanner(
              `Updated Engagement '${this.state.customerName.value}' successfully`,
              MessageBarType.success,
              7000
            );
            this.showUserRatingInCenter(true);
          }
        }
        const Engagement_Status = Object.values(MAPPED_ENGAGEMENT_STATUS).find(
          (engagement) =>
            engagement.ORIGINAL_STATUS.indexOf(
              this.props.upsertEngagementState.data.origEngagement.Status
            ) > -1
        );
        activeSideNavs.engagements = Engagement_Status?.IDENTIFIER ?? "";
        if (this.props.history["location"]["search"] !== "") {
          var url = this.props.history["location"]["search"];
          var urlParts = url.split("?");
          var params = new URLSearchParams(urlParts[1]);
          params.delete("id");
          var newUrl = urlParts[0] + "?" + params.toString();
          this.props.history.push(ENGAGEMENTS_URL + newUrl);
        } else {
          this.props.history.push(ENGAGEMENTS_URL);
        }
        this.manageSpinner(false, "");
      } else if (
        !this.props.upsertEngagementState.fetching &&
        this.props.upsertEngagementState.error
      ) {
        if (this.props.upsertEngagementState.error.newItem) {
          this.buildAndShowBanner(
            `Failed to created new ${
              this.props.upsertEngagementState.error.origEngagement.Status ==
              ENGAGEMENT_STATUS.DRAFT
                ? "Draft"
                : ""
            } Engagement. Please try again!!`,
            MessageBarType.error,
            7000
          );
        } else {
          this.buildAndShowBanner(
            `Failed to update Engagement '${this.state.customerName.value}'. Please try again!!`,
            MessageBarType.error,
            7000
          );
        }
        const Engagement_Status = Object.values(MAPPED_ENGAGEMENT_STATUS).find(
          (engagement) =>
            engagement.ORIGINAL_STATUS.indexOf(
              this.props.upsertEngagementState.error.origEngagement.Status
            ) > -1
        );
        activeSideNavs.engagements = Engagement_Status?.IDENTIFIER ?? "";
        this.manageSpinner(false, "");
      }
    }
    if (prevState.categoryCount !== this.state.categoryCount) {
      this.setState({
        categoryCount: this.state.categoryCount,
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      toHideParticipantModal: true,
    });

    window.removeEventListener("storage", this.handleEngagementPanel, false);
  }

  handleEngagementPanel = () => {
    this.setState({ splitEngagementWindow: this.getEngagementState() });
  };
  getEngagementState = (): boolean => {
    let isEngagementPanelOpen = sessionStorage.getItem("engagementPanelOpen");
    let isCopilotOpen = sessionStorage.getItem("copilotPinnedState");
    if (isEngagementPanelOpen && isCopilotOpen) {
      isEngagementPanelOpen = JSON.parse(isEngagementPanelOpen);
      isCopilotOpen = JSON.parse(isCopilotOpen);
      if (
        String(isEngagementPanelOpen) === "true" &&
        String(isCopilotOpen) === "true"
      ) {
        return true;
      } else {
        return false;
      }
    }
    sessionStorage.setItem("engagementPanelOpen", String(false));
    return false;
  };

  viewJson = () => {
    this.setState({
      hideJsonDialog: false,
    });
  };

  buildCloudType = (cloudType: any): IChoiceGroupOption[] => {
    const cloudTypeData: IChoiceGroupOption[] = [];
    cloudType.values.forEach((column: any) => {
      cloudTypeData.push({
        key: column.metaData.cdd_Id.toString(),
        text: column.key.toString(),
        disabled: column.key.toString() !== azureARMTemplate
          ? false
          : true,
        onRenderField: (props, render) => {
          return (
            <div>
              {render!(props)}
              {this.props.userContext.data?.flights?.includes(
                AppConstants.NonPublicCloudFlight
              ) &&
                props?.text === azureARMTemplate && (
                  <span className="superNpc">Beta</span>
                )}
            </div>
          );
        },
      });
    });
    return cloudTypeData;
  };

  partnerApiCall = (id: string) => {
    const partnerurl =
      AppConstants.APP_API_URL + `${EndPoints.PublishedOffer}?offerId=${id}`;
    this.makeNativeGetApiCall(partnerurl, this.partnerApiCallback, undefined);
  };

  partnerApiCallback = (data: { pcRedirectUri: string }) => {
    this.setState({
      partnerCenterUrl: data.pcRedirectUri,
      jsonObject: (
        <div>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      ),
    });
  };

  private getOctetStream = async () => {
    var graphURL =
      AppConstants.APP_API_URL +
      `${EndPoints.GetOctetStream}?EngagementID=${
        this.props.upsertEngagementState?.data
          ? this.props.upsertEngagementState.data.id
          : this.props.engagementDetail.details?.EngagementId
      }`;
    try {
      const response = await fetch(graphURL, {
        headers: {
          Authorization: `Bearer ${this.props.apiAccessToken?.accessToken}`,
          coreapiaccesstoken: `Bearer ${this.props.coreApiAccessToken?.accessToken}`,
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        method: "GET",
      });
      const blob = await response.blob();
      const engagementID = this.props.upsertEngagementState?.data
        ? this.props.upsertEngagementState.data.id
        : this.props.engagementDetail.details?.EngagementId;
      const fileName =
        this.props.engagementDetail.details?.Status ===
        ENGAGEMENT_STATUS.DOWNLOADED_ARM
          ? engagementID + `_ARM_Package.zip`
          : engagementID + `_T&C_Package.zip`;
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      logEvent(ActionTrackingEngagement, {
        AppName: AppConstants.ApplicationAlias,
        AppLayer: "UI",
        MetricName: "NPC Package Download",
        UserScenario: "User downloads NPC package",
        User: { Alias: this.props.userContext.data?.email },
        ApplicationRole: this.props.userContext.data?.roles,
        EntityOperation: "Download",
        EntityName: "Engagement",
        MetaData: {
          package: fileName,
          userHasNPCFlight: this.props.userContext.data?.flights?.includes(
            AppConstants.NonPublicCloudFlight
          ),
        },
        traceSeverity: "Medium",
      });
      if (!this.props.engagementDetail.details) {
        this.buildAndShowBanner(
          `Created Engagement '${this.state.customerName.value}' successfully`,
          MessageBarType.success,
          7000
        );
        this.showUserRatingInCenter(true);
      }
    } catch (error) {
      //handle error exception
      this.buildAndShowBanner(
        `There seems to be a problem with loading data, Please refresh`,
        MessageBarType.severeWarning,
        7000
      );
    }
  };

  updateStatesForEngagement = () => {
    const parsedQS = parseQS(this.props.location.search);
    // -> Delivery Team & Delivery Lead
    this.getDeliveryLeadData(
      this.props.engagementDetail.new || parsedQS.id == "new"
        ? this.props.user.userName
        : this.props.engagementDetail.details?.DeliveryLead
        ? `${this.props.engagementDetail.details?.DeliveryLead}${microsfotAliasAppendString}`
        : ""
    );
    this.getDeliveryTeamData(
      this.props.engagementDetail.new || parsedQS.id == "new"
        ? [this.props.user.userName]
        : this.props.engagementDetail.details?.DeliveryPod?.map(
            (member) => `${member.Alias}${microsfotAliasAppendString}`
          ) ?? []
    );

    // -> Status && isTest
    this.getStatus();
    this.getSubStatus();
    const selectedCloudType =
      this.state.cloudType.find(
        (cloudType) =>
          cloudType.key ==
          this.props.engagementDetail.details?.ENG_OnboardingType_Id?.toString()
      )?.text ?? "";
    this.setState({
      dropDownsData: {
        ...this.state.dropDownsData,
        EngagementStatus: buildEngagementStateOptions(
          Object.values(MAPPED_ENGAGEMENT_STATUS).find(
            (status) =>
              status.ORIGINAL_STATUS.indexOf(
                this.props.engagementDetail.details?.Status ?? -1
              ) > -1
          )?.DISPLAY_NAME,
          selectedCloudType,
          this.state.appRole.isAdmin
        ),
        EngagementSubStatus: buildEngagementSubStateOptions(
          Object.values(MAPPED_ENGAGEMENT_SUBSTATUS).find(
            (status) =>
              status.ORIGINAL_STATUS.indexOf(
                this.props.engagementDetail.details?.Status ?? -1
              ) > -1
          )?.DISPLAY_NAME,
          selectedCloudType
        ),
      },
    });
    this.setState({
      isTest:
        parsedQS.id == "new"
          ? false
          : !!this.props.engagementDetail.details?.IsTest,
    });
    // this.setState({
    //   isSubscriptionRemoved: !!this.props.engagementDetail.details?.IsSubscriptionRemoved,
    // });

    // -> Delivery Option & OfferType
    const deliveryOptionValue =
      parsedQS.id == "new"
        ? ""
        : this.props.metaData.DeliveryOption.values?.find(
            (delOption) =>
              delOption.metaData.cdd_Id ==
              this.props.engagementDetail.details?.ENG_DeliveryOption_Id
          );
    this.setState({
      deliveryOption: {
        ...this.state.deliveryOption,
        value: deliveryOptionValue
          ? {
              key: deliveryOptionValue.key,
              text: deliveryOptionValue.key.toString(),
            }
          : undefined,
        errorMessage: undefined,
      },
      deliveryOptionOther: {
        ...this.state.deliveryOptionOther,
        value:
          this.props.engagementDetail.details?.ENG_DeliveryOption_Other ??
          undefined,
        errorMessage: undefined,
      },
    });
    const offerTypeValue =
      parsedQS.id == "new"
        ? ""
        : this.state.modifiedOffersDropDown?.find(
            (offerOption) =>
              offerOption.metaData.OfferCode ==
              this.props.engagementDetail.details?.OfferCode
          );
    this.setState({
      offerType: {
        ...this.state.offerType,
        value: offerTypeValue
          ? { key: offerTypeValue.key, text: offerTypeValue.key.toString() }
          : undefined,
        errorMessage: undefined,
      },
    });

    // -> Customer Name, SubscriptionId & Internal description
    this.setState({
      customerName: {
        ...this.state.customerName,
        value:
          parsedQS.id == "new"
            ? ""
            : this.props.engagementDetail.details?.Customer ?? undefined,
        errorMessage: undefined,
      },
      customerSubscriptionIds: {
        ...this.state.subscriptionIDs,
        value: undefined,
        errorMessage: undefined,
      },
      subscriptionIDs: this.props.engagementDetail.details
        ?.EngagementSubscriptions
        ? this.props.engagementDetail.details?.EngagementSubscriptions.filter(
            (id) => id.SubscriptionId != ""
          ).map((i) => i.SubscriptionId)
        : [],
      engagementSubscriptions:
        parsedQS.id == "new"
          ? []
          : this.props.engagementDetail.details?.EngagementSubscriptions
          ? this.props.engagementDetail.details?.EngagementSubscriptions.filter(
              (id) => id.SubscriptionId != ""
            )
          : [],
      internalDescription: {
        ...this.state.internalDescription,
        value:
          parsedQS.id == "new"
            ? ""
            : this.props.engagementDetail.details?.Description ?? undefined,
        errorMessage: undefined,
      },
      selectedCloudType: this.props.engagementDetail.details
        ?.ENG_OnboardingType_Id
        ? {
            key: this.props.engagementDetail.details?.ENG_OnboardingType_Id.toString(),
            text:
              this.state.cloudType.find(
                (cloudType) =>
                  cloudType.key ==
                  this.props.engagementDetail.details?.ENG_OnboardingType_Id.toString()
              )?.text ?? "",
          }
        : undefined,
      geographyOption: {
        value:
          parsedQS.id == "new"
            ? undefined
            : this.props.engagementDetail.details?.ENG_Geography_Id
            ? {
                key: this.props.engagementDetail.details?.ENG_Geography_Id.toString(),
                text:
                  this.state.dropDownsData.GeographyOption.find(
                    (geography) =>
                      geography.key ==
                      this.props.engagementDetail.details?.ENG_Geography_Id.toString()
                  )?.text ?? "",
              }
            : undefined,
        errorMessage: undefined,
      },
      regionOption: {
        value:
          parsedQS.id == "new"
            ? undefined
            : this.props.engagementDetail.details?.ENG_Region_Id
            ? {
                key: this.props.engagementDetail.details?.ENG_Region_Id.toString(),
                text:
                  this.state.regionDropdownOption.find(
                    (region) =>
                      region.key ==
                      this.props.engagementDetail.details?.ENG_Region_Id?.toString()
                  )?.text ?? "",
              }
            : undefined,
        errorMessage: undefined,
      },
    });

    // -> Ross/Milestone ID & Title

    //- handle custom query parameters for HoK
    const [
      rossMilestoneIDFromQS,
      rossMilestoneTypeFromQS,
      engProgramName,
      engProgramId,
    ] = this.handleQueryParam();
    this.setState(
      {
        rossMileStoneID: {
          ...this.state.rossMileStoneID,
          value:
            this.props.engagementDetail.details?.ENG_EntityValue ??
            rossMilestoneIDFromQS ??
            undefined,
        },
        rossMileStoneType: {
          ...this.state.rossMileStoneType,
          value:
            this.props.engagementDetail.details?.ENG_EntityType ??
            rossMilestoneTypeFromQS ??
            undefined,
        },
        engProgramName: {
          ...this.state.engProgramName,
          value:
            this.props.engagementDetail.details?.ENG_ProgramName ??
            engProgramName ??
            undefined,
        },
        engProgramId:
          this.props.engagementDetail.details?.ENG_ProgramId ??
          engProgramId ??
          undefined,
      },
      () => {
        if (this.state.rossMileStoneID.value) {
          this.onEntityTypeChange(
            this.props.engagementDetail.details?.ENG_EntityType ===
              ENTITY_IDS.MILESTONE ||
              rossMilestoneTypeFromQS === ENTITY_IDS.MILESTONE
              ? this.state.dropDownsData.EntityType[1]
              : this.props.engagementDetail.details?.ENG_EntityType ===
                  ENTITY_IDS.ROSS || rossMilestoneTypeFromQS === ENTITY_IDS.ROSS
              ? this.state.dropDownsData.EntityType[2]
              : this.state.dropDownsData.EntityType[0],
            true
          );
        }
        if (
          this.props.engagementDetail.details?.ENG_EntityType?.toString() ==
          ENTITY_IDS.NOT_AVAILABLE
        ) {
          this.onEntityTypeChange(this.state.dropDownsData.EntityType[3], true);
        }
      }
    );
    const today = new Date();
    const startDate =
      parsedQS.id == "new" ||
      this.props.engagementDetail.details?.StartDate === undefined ||
      this.props.engagementDetail.details?.StartDate === null
        ? today
        : new Date(this.props.engagementDetail.details?.StartDate);
    const endDate =
      parsedQS.id == "new" ||
      this.props.engagementDetail.details?.StartDate === undefined ||
      this.props.engagementDetail.details?.EndDate === null
        ? addDays(today, 84)
        : new Date(this.props.engagementDetail.details?.EndDate);
    this.setState({
      startDate: {
        value: startDate,
      },
      endDate: {
        value: endDate,
      },
    });
  };

  handleQueryParam = () => {
    const parsedQS = parseQS(this.props.location.search);
    var rossMilestoneIDFromQS = undefined;
    var rossMilestoneTypeFromQS = undefined;
    var engProgramName = undefined;
    var engProgramId = undefined;
    if (parsedQS.id == "new") {
      rossMilestoneIDFromQS = parsedQS[entityQueryString.EntityValue];
      rossMilestoneTypeFromQS = parsedQS[entityQueryString.EntityName]
        ? this.props.metaData.EntityType.values?.find(
            (entType) =>
              entType.id.toString().toLowerCase() ===
              parsedQS[entityQueryString.EntityName].toLowerCase()
          )?.metaData.cdd_Id
        : undefined;
      engProgramName = parsedQS[entityQueryString.ProgramName];
      engProgramId = parsedQS[entityQueryString.ProgramId];
    }
    return [
      rossMilestoneIDFromQS,
      rossMilestoneTypeFromQS,
      engProgramName,
      engProgramId,
    ];
  };

  triggerEntityDataCall = (id: string, onMount: boolean = false) => {
    const entityurl =
      AppConstants.APP_API_URL + `${EndPoints.EntityRollup}?EntityNumber=${id}`;
    this.makeNativeGetApiCall(entityurl, this.sourceEntityRollupCallBack, {
      id,
      onMount,
    });
    this.manageSpinner(true, "Getting Milestone details");
  };

  sourceEntityRollupCallBack = (
    data: {
      ERP_EntityNumber: string | null;
      ERP_EntityType: string | null;
      ERP_Title: string | null;
    },
    metaData: { id: string; onMount: boolean }
  ) => {
    this.manageSpinner(false, "");
    if (data.ERP_EntityNumber && data.ERP_Title && data.ERP_EntityType) {
      const rossMileStoneType = this.props.metaData.EntityType.values?.find(
        (entType) => entType.id.toString() === data.ERP_EntityType
      )?.metaData.cdd_Id;
      // if (this.state.rossMileStoneType.value && metaData.onMount) {
      //     if (this.state.rossMileStoneType.value !== rossMileStoneType) {
      //         this.buildAndShowBanner('Entity name modified to a different one compared to the URL',
      //             MessageBarType.warning, 4500);
      //     }
      // }
      this.setState({
        rossMileStoneType: {
          ...this.state.rossMileStoneType,
          value: rossMileStoneType,
        },
        rossMilestoneTitle: {
          ...this.state.rossMilestoneTitle,
          value: data.ERP_Title,
        },
        showValidIDNotification:
          this.state.entityType.value?.text === ENTITY_TYPE.MILESTONE
            ? true
            : false,
        showValidIDMessage:
          this.state.entityType.value?.text === ENTITY_TYPE.MILESTONE
            ? "Milestone ID is valid"
            : "",
      });
    } else {
      if (!metaData.onMount) {
        this.setState({
          rossMileStoneType: {},
          rossMilestoneTitle: {},
          showValidIDNotification: false,
          titletoBeShown: ENTITY_TYPE.MILESTONE,
          showValidIDMessage:
            this.state.rossMileStoneID.value !== ""
              ? "Milestone ID is invalid"
              : "",
        });
      }
    }
  };

  makeNativeGetApiCall = (url: string, callBack: Function, metaData: any) => {
    const worker = new Worker("/getApi.worker.js");

    worker.addEventListener("message", (e: any) => {
      const { data, error } = e.data;
      if (!error) {
        callBack(data, metaData);
      } else {
        this.buildAndShowBanner(
          "There seems to be a problem with loading data, Please refresh",
          MessageBarType.severeWarning,
          5000
        );
      }
      worker.terminate();
    });

    worker.postMessage({
      url: url,
      apiAccessToken: this.props.apiAccessToken?.accessToken,
      coreApiAccessToken: this.props.coreApiAccessToken?.accessToken,
    });
  };

  buildAndShowBanner(
    message: string,
    messageType: MessageBarType,
    timeOut: number
  ) {
    const bannerProps: IBannerMessagePayload = {
      toShow: true,
      message: message,
      messageType: messageType,
      styleClass: "",
      timeout: timeOut,
    };
    this.props.setBanner(bannerProps);
  }

  showUserRatingInCenter(showBannerInCenter: boolean) {
    const ratingStyleProps: IRatingPayload = {
      showInCenter: true,
    };
    logEvent(Feedback_Rating, {
      AppName: AppConstants.ApplicationAlias,
      AppLayer: "UI",
      MetricName: "CSATPopup",
      UserScenario: "CSAT component is visible",
      User: { Alias: this.props.userContext.data?.email },
      ApplicationRole: this.props.userContext.data?.roles,
      EntityOperation: "Click",
      EntityName: "Rating",
      MetaData: {
        page: "Engagements",
        action: "Engagement Creation",
      },
      traceSeverity: "Medium",
    });
    this.props.showRatingInCenter(ratingStyleProps);
  }

  manageSpinner = (showSpinner: boolean, spinnerMessage: string) => {
    this.props.toggleSpinner({
      spinner: showSpinner,
      spinnerMessage: spinnerMessage,
    });
  };

  getStatus = () => {
    const currentStatus = this.props.engagementDetail.details?.Status;
    if (currentStatus !== null && currentStatus !== undefined) {
      const mappedStatus = Object.values(MAPPED_ENGAGEMENT_STATUS).find(
        (status) => status.ORIGINAL_STATUS.indexOf(currentStatus) > -1
      );
      const selectedStatus = this.props.engagementDetail.details
        ?.ENG_OnboardingType_Id
        ? {
            key: this.props.engagementDetail.details?.ENG_OnboardingType_Id.toString(),
            text:
              this.state.cloudType.find(
                (cloudType) =>
                  cloudType.key ==
                  this.props.engagementDetail.details?.ENG_OnboardingType_Id.toString()
              )?.text ?? "",
          }
        : undefined;
      this.setState({
        currentStatus: mappedStatus,
        status: {
          ...this.state.status,
          value: mappedStatus
            ? selectedStatus?.text === azureARMTemplate &&
              this.state.currentStatus?.DISPLAY_NAME ===
                MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME
              ? {
                  key: MAPPED_ENGAGEMENT_STATUS.DOWNLOAD_ARM.DISPLAY_NAME,
                  text: MAPPED_ENGAGEMENT_STATUS.DOWNLOAD_ARM.DISPLAY_NAME,
                }
              : selectedStatus?.text === azureARMTemplate &&
                this.state.currentStatus?.DISPLAY_NAME ===
                  MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME
              ? {
                  key: MAPPED_ENGAGEMENT_STATUS.DOWNLOAD_TC.DISPLAY_NAME,
                  text: MAPPED_ENGAGEMENT_STATUS.DOWNLOAD_TC.DISPLAY_NAME,
                }
              : {
                  key: mappedStatus.DISPLAY_NAME,
                  text: mappedStatus.DISPLAY_NAME,
                }
            : undefined,
          errorMessage: undefined,
        },
      });
    }
  };
  getSubStatus = () => {
    const currentSubStatus = this.props.engagementDetail.details?.SubStatusId;
    if (currentSubStatus !== null && currentSubStatus !== undefined) {
      const mappedSubStatus = Object.values(MAPPED_ENGAGEMENT_SUBSTATUS).find(
        (status) => status.ORIGINAL_STATUS.indexOf(currentSubStatus) > -1
      );
      this.setState({
        currentSubStatus: mappedSubStatus,
        subStatus: {
          ...this.state.subStatus,
          value: mappedSubStatus
            ? {
                key: mappedSubStatus.DISPLAY_NAME,
                text: mappedSubStatus.DISPLAY_NAME,
              }
            : undefined,
          errorMessage: undefined,
        },
      });
    }
  };
  obtainGraphReqBody = (alias: string) => {
    const photoBody = {
      id: `${photoIDString}${alias}`,
      url: `/users/${alias}/photos/48x48/$value`,
      method: "GET",
    };
    const userBody = {
      url: `/users/${alias}?$select=displayName,userPrincipalName`,
      method: "GET",
      id: `${userIDString}${alias}`,
      headers: {
        ConsistencyLevel: "eventual",
      },
    };
    if (
      !(alias in this.props.graphState.users) &&
      !(alias in this.props.graphState.photos)
    ) {
      return [photoBody, userBody];
    }
    if (
      alias in this.props.graphState.users &&
      !(alias in this.props.graphState.photos)
    ) {
      return [photoBody];
    } else if (
      alias in this.props.graphState.photos &&
      !(alias in this.props.graphState.users)
    ) {
      return [userBody];
    } else {
      return [];
    }
  };

  getDeliveryTeamData = (aliases: string[]) => {
    var graphReqBody = [];
    for (var i = 0; i < Math.min(aliases.length, 10); i++) {
      graphReqBody.push(...this.obtainGraphReqBody(aliases[i]));
    }
    if (graphReqBody.length !== 0) {
      this.getGraphData(
        { requests: graphReqBody },
        this.getDeliveryTeamDataCallback,
        { aliases, iteratedCount: Math.min(aliases.length, 10), response: [] }
      );
    } else {
      this.buildDeliveryTeam([], aliases);
    }
  };

  getDeliveryTeamDataCallback = (
    data: { responses: any[] },
    metaData: { aliases: string[]; iteratedCount: number; response: any[] }
  ) => {
    var clonedMeta = _.cloneDeep(metaData);
    clonedMeta.response.push(...data.responses);
    var graphReqBody = [];
    if (clonedMeta.iteratedCount <= clonedMeta.aliases.length) {
      this.buildDeliveryTeam(clonedMeta.response, metaData.aliases);
      return;
    }
    for (
      var i = clonedMeta.iteratedCount - 1;
      i < Math.min(clonedMeta.aliases.length, clonedMeta.iteratedCount + 10);
      i++
    ) {
      graphReqBody.push(...this.obtainGraphReqBody(metaData.aliases[i]));
    }
    this.getGraphData(
      { requests: graphReqBody },
      this.getDeliveryTeamDataCallback,
      {
        aliases: clonedMeta.aliases,
        iteratedCount: Math.min(
          clonedMeta.aliases.length,
          clonedMeta.iteratedCount + 10
        ),
        response: clonedMeta.response,
      }
    );
  };

  buildDeliveryTeam = (response: any[], aliases: string[]) => {
    var transformedResponses = this.transformGraphResposnes(response);
    var deliveryTeam: IPeople[] = [];
    aliases.forEach((alias) => {
      deliveryTeam.push({
        name: transformedResponses[`${userIDString}${alias}`]
          ? transformedResponses[`${userIDString}${alias}`].displayName
          : this.props.graphState.users[alias]
          ? this.props.graphState.users[alias].name
          : "",
        alias: transformedResponses[`${userIDString}${alias}`]
          ? transformedResponses[`${userIDString}${alias}`].userPrincipalName
          : this.props.graphState.users[alias]
          ? this.props.graphState.users[alias].alias
          : "",
        imageUrl: transformedResponses[`${photoIDString}${alias}`]
          ? `${base64Append}${transformedResponses[`${photoIDString}${alias}`]}`
          : this.props.graphState.photos[alias]
          ? this.props.graphState.photos[alias]
          : "",
      });
    });
    this.setState({
      deliveryTeam: {
        ...this.state.deliveryTeam,
        value: deliveryTeam,
        errorMessage: undefined,
      },
    });
  };

  transformGraphResposnes = (responseArray: any[]) => {
    var transformedResponses: any = {};
    responseArray.forEach((res: any) => {
      if (res.id.split(":")[0] === "user") {
        this.props.updateGraphState({
          users: {
            [res.id.split(":")[1]]:
              res.status === RequestSuccess
                ? {
                    name: res.body.displayName,
                    alias: res.body.userPrincipalName,
                  }
                : null,
          },
        });
      } else {
        this.props.updateGraphState({
          photos: {
            [res.id.split(":")[1]]:
              res.status === RequestSuccess
                ? `${base64Append}${res.body}`
                : null,
          },
        });
      }
      transformedResponses[res.id] =
        res.status === RequestSuccess ? res.body : null;
    });

    return transformedResponses;
  };

  getTeamsMeetingText = (deliveryTeam: string) => {
    let leads = deliveryTeam?.split(", ");
    let moreText = " +" + (leads.length - 1) + " more";
    return leads.length > 1 ? leads[0] + moreText : leads[0];
  };

  getDeliveryLeadData = (alias: string) => {
    if (
      alias in this.props.graphState.photos &&
      alias in this.props.graphState.users
    ) {
      this.setState({
        deliveryLead: {
          ...this.state.deliveryLead,
          value: [
            {
              name: this.props.graphState.users[alias].name,
              alias: this.props.graphState.users[alias].alias,
              imageUrl: this.props.graphState.photos[alias],
            },
          ],
        },
      });
    } else {
      const deliverLeadReqBody = {
        requests: this.obtainGraphReqBody(alias),
      };
      this.getGraphData(
        deliverLeadReqBody,
        this.getDeliveryLeadPhotoCallback,
        alias
      );
    }
  };

  getDeliveryLeadPhotoCallback = (
    data: { responses: any[] },
    alias: string
  ) => {
    var transformedResponses: any = this.transformGraphResposnes(
      data.responses
    );
    this.setState({
      deliveryLead: {
        ...this.state.deliveryLead,
        value: [
          {
            name: transformedResponses[`${userIDString}${alias}`]
              ? transformedResponses[`${userIDString}${alias}`].displayName
              : this.props.graphState.users[alias]
              ? this.props.graphState.users[alias].name
              : "",
            alias: transformedResponses[`${userIDString}${alias}`]
              ? transformedResponses[`${userIDString}${alias}`]
                  .userPrincipalName
              : this.props.graphState.users[alias]
              ? this.props.graphState.users[alias].alias
              : "",
            imageUrl: transformedResponses[`${photoIDString}${alias}`]
              ? `${base64Append}${
                  transformedResponses[`${photoIDString}${alias}`]
                }`
              : this.props.graphState.photos[alias]
              ? this.props.graphState.photos[alias]
              : "",
          },
        ],
        errorMessage: undefined,
      },
    });
  };

  getGraphData = (reqBody: any, callBack: Function, metaData?: any) => {
    const worker = new Worker("/graph.worker.js");

    worker.addEventListener("message", (e: any) => {
      const { data, error } = e.data;
      if (!error) {
        callBack(data, metaData);
      } else {
        this.buildAndShowBanner(
          "Some fields may not display properly because of an internet issue. Please reload",
          MessageBarType.severeWarning,
          5000
        );
      }
      worker.terminate();
    });

    const url = `https://graph.microsoft.com/v1.0/$batch`;
    const headerPairs = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    worker.postMessage({
      url: url,
      graphToken: this.props.graphTokenObj?.accessToken,
      requestBody: reqBody,
      method: "POST",
      headerPairs: headerPairs,
    });
  };

  closeEngagement = () => {
    sessionStorage.setItem("engagementPanelOpen", String(false));
    window.dispatchEvent(new Event("storage"));
    const parsedQS = parseQS(this.props.location.search);
    var engProgramName =
      parsedQS != null ? parsedQS[entityQueryString.ProgramName] : "";
    if (isIframe && window.top != window.self) {
      this.setState({
        showcancelComponent: true,
      });
    } else {
      if (this.props.initURLState) {
        this.props.history.push(ENGAGEMENTS_URL);
      } else {
        this.props.history.goBack();
      }
      this.props.showModal({
        toShow: false,
      });
    }
    logEvent(ActionTrackingEngagement, {
      AppName: "CAPE",
      AppLayer: "UI",
      MetricName: "Engagement",
      UserScenario: "User cancels create engagement form",
      User: { Alias: this.props.userContext.data?.email },
      ApplicationRole: this.props.userContext.data?.roles,
      MetaData: { programName: engProgramName ? engProgramName : null },
      EntityOperation: "Cancel",
      EntityName: "Engagement",
      traceSeverity: "Low",
    });
  };

  onBetaEnrolmentClick = () => {
    logEvent(ClickTrackingEngagement, {
      AppName: AppConstants.ApplicationAlias,
      AppLayer: "UI",
      MetricName: "NPCBeta Enrollment",
      UserScenario: "User clicks NPC Beta enrollment link",
      User: { Alias: this.props.userContext.data?.email },
      ApplicationRole: this.props.userContext.data?.roles,
      EntityOperation: "Click",
      EntityName: "Engagement",
      MetaData: {},
      traceSeverity: "Medium",
    });
  };
  closeCompleteStatusModal = () => {
    this.setState({
      showModalOnStatusChange: false,
    });
  };
  validateSave = () => {
    var isFormValid = true;
    if (!this.props.engagementDetail.new) {
      isFormValid =
        this.onStatusChange(this.state.status.value, true) && isFormValid;
      isFormValid =
        this.state.status.value?.key ===
        MAPPED_ENGAGEMENT_STATUS.CLOSED.DISPLAY_NAME
          ? this.onSubStatusChange(this.state.subStatus.value, true) &&
            isFormValid
          : true;
    }
    isFormValid =
      this.onDeliveryOptionChange(this.state.deliveryOption.value, true) &&
      isFormValid;
    if (this.state.deliveryOption.value?.key == Others) {
      isFormValid =
        this.onDeliveryOptionOtherChange(
          this.state.deliveryOptionOther.value,
          true
        ) && isFormValid;
    }
    isFormValid =
      this.onOfferTypeChange(this.state.offerType.value, true) && isFormValid;
    isFormValid =
      this.onEntityTypeChange(this.state.entityType.value, true, true) &&
      isFormValid;
    isFormValid =
      this.onRossMileStoneChange(
        this.state.rossMileStoneID.value,
        true,
        true
      ) && isFormValid;
    //isFormValid = this.state.engagementSubscriptions.length > 0 && isFormValid;
    // isFormValid = this.onCustomerSubscriptionChange(this.state.customerSubscriptionId.value, true) && isFormValid;
    if (this.props.engagementDetail.new || this.isDraftState()) {
      isFormValid =
        this.handleStartDateSelection(this.state.startDate.value, true) &&
        isFormValid;
    }
    isFormValid =
      this.handleEndDateSelection(this.state.endDate.value, true) &&
      isFormValid;
    if (this.state.customerSubscriptionIds.value !== "") {
      let ids = this.state.engagementSubscriptions;
      if (this.state.customerSubscriptionIds.value) {
        // //check susbs id exist or
        // ids.push({
        //     EngagementId:this.props.engagementDetail.details?.EngagementId == undefined ? 0 : this.props.engagementDetail.details?.EngagementId,
        //     SubscriptionId:this.state.customerSubscriptionIds.value
        // });
        this.addSubscriptionIDField();
      }
      this.setState({
        engagementSubscriptions: ids,
        customerSubscriptionIds: {
          value: this.state.isSubsIdPresent
            ? this.state.customerSubscriptionIds.value
            : this.state.customerSubscriptionIds.errorMessage != undefined
            ? this.state.customerSubscriptionIds.value
            : "",
          errorMessage: this.state.isSubsIdPresent
            ? this.state.customerSubscriptionIds.errorMessage
            : this.state.customerSubscriptionIds.errorMessage != undefined
            ? this.state.customerSubscriptionIds.errorMessage
            : "",
        },
      });
      isFormValid =
        (this.state.isSubsIdPresent
          ? false
          : this.state.customerSubscriptionIds.errorMessage != undefined &&
            !this.state.isSubsIdPresent
          ? false
          : true) && isFormValid;
    } else {
      isFormValid =
        (this.state.engagementSubscriptions.length > 0 ? true : false) &&
        isFormValid;
    }
    isFormValid =
      this.onCustomerNameChange(this.state.customerName.value, true) &&
      isFormValid;
    isFormValid =
      this.onDeliveryLeadChange(
        this.state.deliveryLead.value?.map((person) => ({
          text: person.name,
          optionalText: person.alias,
          imageUrl: person.imageUrl,
        })),
        true
      ) && isFormValid;
    isFormValid =
      this.onDeliveryTeamChange(
        this.state.deliveryTeam.value?.map((person) => ({
          text: person.name,
          optionalText: person.alias,
          imageUrl: person.imageUrl,
        })),
        true
      ) && isFormValid;
      if (
        !this.props.engagementDetail.new &&
      this.state.currentStatus.DISPLAY_NAME !==
        MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME &&
      this.props.engagementDetail.details?.Status !==
        ENGAGEMENT_STATUS.DOWNLOADED_ARM &&
        this.state.status.value?.key ===
        MAPPED_ENGAGEMENT_STATUS.COMPLETED.DISPLAY_NAME 
      ) {
        isFormValid = !!this.state.isSubscriptionRemoved && isFormValid;
      }
    if (
      !this.state.accessibilityScopes.editCommentDisable &&
      !this.props.engagementDetail.new &&
      this.state.currentStatus.DISPLAY_NAME !==
        MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME &&
      this.props.engagementDetail.details?.Status !==
        ENGAGEMENT_STATUS.DOWNLOADED_ARM
    ) {
      isFormValid =
        this.updateEditComment(this.state.editComment.value, true) &&
        isFormValid;
    }
    this.setState({ isFormValid: isFormValid });
    return isFormValid;
  };

  validateSaveAsDraft = () => {
    var isFormValid = true;
    if (!this.props.engagementDetail.new) {
      isFormValid =
        this.onStatusChange(this.state.status.value, true) && isFormValid;
      isFormValid =
        this.state.status.value?.key ===
        MAPPED_ENGAGEMENT_STATUS.COMPLETED.DISPLAY_NAME
          ? this.onSubStatusChange(this.state.subStatus.value, true) &&
            isFormValid
          : true;
    }
    isFormValid =
      this.onDeliveryOptionChange(this.state.deliveryOption.value, true) &&
      isFormValid;
    if (this.state.deliveryOption.value?.key == Others) {
      isFormValid =
        this.onDeliveryOptionOtherChange(
          this.state.deliveryOptionOther.value,
          true
        ) && isFormValid;
    }
    isFormValid =
      this.onOfferTypeChange(this.state.offerType.value, true) && isFormValid;
    isFormValid =
      this.onEntityTypeChange(this.state.entityType.value, true, true) &&
      isFormValid;
    isFormValid =
      this.onRossMileStoneChange(
        this.state.rossMileStoneID.value,
        true,
        true
      ) && isFormValid;
    //isFormValid = this.state.engagementSubscriptions.length > 0 && isFormValid;
    // isFormValid = this.onCustomerSubscriptionChange(this.state.customerSubscriptionId.value, true) && isFormValid;
    if (this.state.customerSubscriptionIds.value !== "") {
      let ids = this.state.engagementSubscriptions;
      if (this.state.customerSubscriptionIds.value) {
        this.addSubscriptionIDField();
      }
      this.setState({
        engagementSubscriptions: ids,
        customerSubscriptionIds: {
          value: this.state.isSubsIdPresent
            ? this.state.customerSubscriptionIds.value
            : this.state.customerSubscriptionIds.errorMessage != undefined
            ? this.state.customerSubscriptionIds.value
            : "",
          errorMessage: this.state.isSubsIdPresent
            ? this.state.customerSubscriptionIds.errorMessage
            : this.state.customerSubscriptionIds.errorMessage != undefined
            ? this.state.customerSubscriptionIds.errorMessage
            : "",
        },
      });
      isFormValid =
        (this.state.isSubsIdPresent
          ? false
          : this.state.customerSubscriptionIds.errorMessage != undefined &&
            !this.state.isSubsIdPresent
          ? false
          : true) && isFormValid;
    } else {
      isFormValid =
        (this.state.engagementSubscriptions.length > 0 ? true : false) &&
        isFormValid;
    }
    if (this.props.engagementDetail.new || this.isDraftState()) {
      isFormValid =
        this.handleStartDateSelection(this.state.startDate.value, true) &&
        isFormValid;
    }
    isFormValid =
      this.handleEndDateSelection(this.state.endDate.value, true) &&
      isFormValid;
    isFormValid =
      this.onCustomerNameChange(this.state.customerName.value, true) &&
      isFormValid;
    isFormValid =
      this.onDeliveryTeamChange(
        this.state.deliveryTeam.value?.map((person) => ({
          text: person.name,
          optionalText: person.alias,
          imageUrl: person.imageUrl,
        })),
        true
      ) && isFormValid;
    isFormValid =
      this.onDeliveryLeadChange(
        this.state.deliveryLead.value?.map((person) => ({
          text: person.name,
          optionalText: person.alias,
          imageUrl: person.imageUrl,
        })),
        true
      ) && isFormValid;
    this.setState({ isFormValid: isFormValid });
    return isFormValid;
  };

  isDraftState = () => {
    return (
      this.props.engagementDetail.details?.Status == ENGAGEMENT_STATUS.DRAFT
    );
  };

  upsertEngagement = () => {
    this.props.engagementDetail.new || this.isDraftState()
      ? this.validateSave()
        ? this.saveEngagement(
            this.state.selectedCloudType?.text === azureARMTemplate
              ? ENGAGEMENT_STATUS.DOWNLOADED_TC
              : ENGAGEMENT_STATUS.CREATED,
            `Saving ${this.isDraftState() ? "" : "new"} engagement`
          )
        : this.buildAndShowBanner(
            "Some validation errors were found. Please check them!",
            MessageBarType.error,
            6000
          )
      : this.validateSave()
      ? this.saveEngagement(
          this.state.status.value?.key ==
            MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME
            ? this.props.engagementDetail.details?.Status ??
                ENGAGEMENT_STATUS.CREATED
            : Object.values(MAPPED_ENGAGEMENT_STATUS).find(
                (val) => val.DISPLAY_NAME === this.state.status.value?.key
              )?.ORIGINAL_STATUS[0] ??
                (this.state.selectedCloudType?.text === azureARMTemplate &&
                this.state.status.value?.key ==
                  MAPPED_ENGAGEMENT_STATUS.DOWNLOAD_TC.DISPLAY_NAME
                  ? ENGAGEMENT_STATUS.DOWNLOADED_TC
                  : this.state.selectedCloudType?.text === azureARMTemplate &&
                    this.state.status.value?.key ==
                      MAPPED_ENGAGEMENT_STATUS.DOWNLOAD_ARM.DISPLAY_NAME
                  ? ENGAGEMENT_STATUS.DOWNLOADED_ARM
                  : ENGAGEMENT_STATUS.CREATED),
          "Updating engagement"
        )
      : this.buildAndShowBanner(
          "Some validation errors were found. Please check them!",
          MessageBarType.error,
          6000
        );
  };
  closeModal = () => {
    this.setState({
      showEditLiveEngagementWarning: false,
    });
    logEvent(ActionTrackingEngagement, {
      AppName: "CAPE",
      AppLayer: "UI",
      MetricName: "Engagement",
      UserScenario: "User cancels create engagement form",
      User: { Alias: this.props.userContext.data?.email },
      ApplicationRole: this.props.userContext.data?.roles,
      EntityOperation: "Cancel",
      EntityName: "Engagement",
      traceSeverity: "Low",
    });
  };
  closeSupportContractModal = () => {
    this.setState({
      showSupportOnlyContractModal: false,
    });
    logEvent(ActionTrackingEngagement, {
      AppName: "CAPE",
      AppLayer: "UI",
      MetricName: "Engagement",
      UserScenario: "User cancels Is support only contract confirmation",
      User: { Alias: this.props.userContext.data?.email },
      ApplicationRole: this.props.userContext.data?.roles,
      EntityOperation: "Cancel",
      EntityName: "Engagement",
      traceSeverity: "Low",
    });
  };
  checkDeliveryTeamChange = () => {
    let status = false;
    let existingDeliveryTeam =
      this.props.engagementDetail.details?.DeliveryPod?.map(
        (member) => `${member.Alias}${microsfotAliasAppendString}`
      ) ?? [];
    if (this.state?.deliveryTeam?.value) {
      if (
        existingDeliveryTeam.length === this.state.deliveryTeam.value.length
      ) {
        for (let i = 0; i < existingDeliveryTeam.length; i++) {
          status =
            existingDeliveryTeam.indexOf(
              this.state.deliveryTeam.value[i].alias
            ) !== -1;
          if (status === false) {
            break;
          }
        }
      }
    }
    return status;
  };
  checkDeliveryLeadChange = () => {
    let status = false;
    let existingDeliveryLead =
      this.props.engagementDetail.details?.DeliveryLead;
    let currentDeliveryLead = this.state.deliveryLead?.value
      ? this.state.deliveryLead?.value[0].alias.split("@")[0] ?? ""
      : "";
    let existingDeliveryTeam =
      this.props.engagementDetail.details?.DeliveryPod?.map(
        (member) => `${member.Alias}`
      ) ?? [];
    let isNewDeliveryLead = !existingDeliveryTeam.includes(currentDeliveryLead);

    if (this.state?.deliveryLead?.value) {
      if (isNewDeliveryLead) {
        status = true;
      }
    }
    return status;
  };
  statusChanged = () => {
    this.validateSave()
      ? this.upsertEngagement()
      : this.buildAndShowBanner(
          "Some validation errors were found. Please check them!",
          MessageBarType.error,
          6000
        );
      
  };
  showSupportOnlyContract = () => {
    this.validateSave()
      ? this.setState({
          showSupportOnlyContractModal: true,
        })
      : this.buildAndShowBanner(
          "Some validation errors were found. Please check them!",
          MessageBarType.error,
          6000
        );
  };

  saveEngagement = (engState: number, spinnerMessage: string) => {
    var engagementData: Partial<IEngagementDetailData> = {};
    if (this.props.engagementDetail.new) {
      engagementData = {
        EngagementId:
          this.props.engagementDetail.details?.EngagementId == undefined
            ? 0
            : this.props.engagementDetail.details?.EngagementId,
        Status:
          this.state.currentStatus &&
          this.state.currentStatus == ENGAGEMENT_STATUS.LIVE
            ? ENGAGEMENT_STATUS.CREATED
            : engState,
        SubStatusId: Object.values(MAPPED_ENGAGEMENT_SUBSTATUS).find(
          (val) => val.DISPLAY_NAME === this.state.subStatus.value?.key
        )?.ORIGINAL_STATUS[0],
        Customer: this.state.customerName?.value ?? "",
        //SubscriptionId: this.state.customerSubscriptionIds.value,
        EngagementSubscriptions: this.state.engagementSubscriptions,
        DeliveryLead: this.state.deliveryLead?.value
          ? this.state.deliveryLead?.value[0].alias.split("@")[0] ?? ""
          : "",
        Description: this.state.internalDescription?.value ?? "",
        OfferCode:
          this.state.modifiedOffersDropDown?.find(
            (offer) => offer.id == this.state.offerType.value?.key
          )?.metaData?.OfferCode ?? null,
        IsTest: !!this.state.isTest,
        ENG_DeliveryOption_Id:
          this.props.metaData.DeliveryOption.values?.find(
            (deliveryOption) =>
              deliveryOption.id == this.state.deliveryOption.value?.key
          )?.metaData?.cdd_Id ?? null,
        ENG_DeliveryOption_Other:
          this.state.deliveryOption.value?.key == Others
            ? this.state.deliveryOptionOther?.value ?? ""
            : "",
        Aliases: this.state.deliveryTeam.value
          ?.map((person) => person.alias.split("@")[0])
          .join(";"),
        ENG_EntityType: this.state.entityType.value?.key ?? null,
        ENG_EntityValue: this.state.rossMileStoneID.value ?? null,
        StartDate: this.state.startDate.value,
        EndDate: this.state.endDate.value,
        OfferTemplateVersion:
          this.state.modifiedOffersDropDown?.find(
            (offer) => offer.id == this.state.offerType.value?.key
          )?.metaData?.OfferTemplateVersion ?? null,
        ENG_ProgramName: this.state.engProgramName.value ?? "",
        ENG_ProgramId: this.state.engProgramId ?? null,
        IsUserInPod: this.state.isUserInPod,
        IsSubscriptionRemoved:false,
        ENG_OnboardingType_Id:
          this.state.selectedCloudType == undefined
            ? Number(this.state.cloudType[0].key)
            : Number(this.state.selectedCloudType.key),
        ENG_Geography_Id: Number(this.state.geographyOption.value?.key),
        ENG_Region_Id:
          this.state.regionOption.value &&
          Number(this.state.regionOption.value?.key) > 0
            ? Number(this.state.regionOption.value?.key)
            : null,
        CreatedBy: this.props.user?.userName?.split("@")[0].toLowerCase(),
      };
    } else {
      engagementData = {
        ...this.props.engagementDetail.details,
        Status:
          engState === ENGAGEMENT_STATUS.LIVE
            ? this.state.customerNameChanged ||
              this.state.subscriptionIDChanged ||
              !this.checkDeliveryTeamChange() ||
              this.checkDeliveryLeadChange()
              ? ENGAGEMENT_STATUS.CREATED
              : engState
            : engState,
        SubStatusId: Object.values(MAPPED_ENGAGEMENT_SUBSTATUS).find(
          (val) => val.DISPLAY_NAME === this.state.subStatus.value?.key
        )?.ORIGINAL_STATUS[0],
        Customer: this.state.customerName?.value ?? "",
        //SubscriptionId: this.state.customerSubscriptionIds?.value ?? "",
        EngagementSubscriptions: this.state.engagementSubscriptions,
        DeliveryLead: this.state.deliveryLead?.value
          ? this.state.deliveryLead?.value[0].alias.split("@")[0] ?? ""
          : "",
        Description: this.state.internalDescription?.value ?? "",
        OfferCode:
          this.state.modifiedOffersDropDown?.find(
            (offer) => offer.id == this.state.offerType.value?.key
          )?.metaData?.OfferCode ?? null,
        IsTest: !!this.state.isTest,
        ENG_DeliveryOption_Id:
          this.props.metaData.DeliveryOption.values?.find(
            (deliveryOption) =>
              deliveryOption.id == this.state.deliveryOption.value?.key
          )?.metaData?.cdd_Id ?? null,
        ENG_DeliveryOption_Other:
          this.state.deliveryOption.value?.key == Others
            ? this.state.deliveryOptionOther?.value ?? ""
            : "",
        Aliases: this.state.deliveryTeam.value
          ?.map((person) => person.alias.split("@")[0])
          .join(";"),
        ENG_EntityType: this.state.entityType.value?.key ?? null,
        ENG_EntityValue:
          this.state.entityType.value?.text === ENTITY_TYPE.MILESTONE ||
          this.state.entityType.value?.text == ENTITY_TYPE.ROSS
            ? this.state.rossMileStoneID.value
            : "",
        StartDate: this.state.startDate.value,
        EndDate: this.state.endDate.value,
        ENG_OnboardingType_Id:
          this.state.selectedCloudType == undefined
            ? Number(this.state.cloudType[0].key)
            : Number(this.state.selectedCloudType.key),
        ENG_Geography_Id: Number(this.state.geographyOption.value?.key),
        ENG_Region_Id:
          this.state.regionOption.value &&
          Number(this.state.regionOption.value?.key) > 0
            ? Number(this.state.regionOption.value?.key)
            : null,
        Comment: this.state.editComment?.value ?? "",
        IsSubscriptionRemoved:!!this.state.isSubscriptionRemoved,
        LastModifiedBy: this.props.user?.userName?.split("@")[0].toLowerCase(),
      };
    }
    this.manageSpinner(true, spinnerMessage);
    this.props.upsertEngagement(engagementData);
    logEvent(ActionTrackingEngagement, {
      AppName: "CAPE",
      AppLayer: "UI",
      MetricName: "Engagement",
      UserScenario:
        "User " + this.props.engagementDetail.new || this.isDraftState()
          ? "SaveAsDraft "
          : "Submit " + "an Engagement",
      User: { Alias: this.props.userContext.data?.email },
      ApplicationRole: this.props.userContext.data?.roles,
      EntityOperation:
        this.props.engagementDetail.new || this.isDraftState()
          ? "SaveAsDraft"
          : "Submit",
      EntityName: "Engagement",
      Metadata: {
        IsDraft: this.isDraftState() ? true : false,
        IsTestEngagement: !!this.state.isTest,
        IsSubscriptionRemoved: !!this.state.isSubscriptionRemoved,
        DeliveryOption:
          this.props.metaData.DeliveryOption.values?.find(
            (deliveryOption) =>
              deliveryOption.id == this.state.deliveryOption.value?.key
          )?.key ?? null,
        DeliveryTeam: this.state.deliveryTeam.value,
        OfferType: this.state.offerType.value?.key,
        IsRoss:
          this.state.entityType.value?.text == ENTITY_TYPE.ROSS ? true : false,
        IsMilestone:
          this.state.entityType.value?.text === ENTITY_TYPE.MILESTONE
            ? true
            : false,
        NoRossOrMilestone:
          this.state.entityType.value?.text !== ENTITY_TYPE.MILESTONE ||
          this.state.entityType.value?.text !== ENTITY_TYPE.ROSS
            ? true
            : false,
        MilestoneTitle: this.state.rossMileStoneID?.value ?? "",
        RossORMilestoneId: this.state.entityType.value?.key,
        CustomerName: this.state.customerName?.value ?? "",
        SubscriptionIds: this.state.engagementSubscriptions,
        Geography: this.state.geographyOption.value?.key,
        Region: this.state.regionOption.value?.key,
        CustomerOnboardingType: this.state.selectedCloudType?.text,
        Internaldescription: this.state.internalDescription?.value ?? "",
        UserHasNPCFlight: this.props.userContext.data?.flights?.includes(
          AppConstants.NonPublicCloudFlight
        ),
        IsEdit: this.state.editComment?.value ? true : false,
        EditedEngagementState: engagementData.Status,
        EditedFields: this.state.editedFields,
        Comments: this.state.editComment?.value ?? "",
        status:
          engState === ENGAGEMENT_STATUS.LIVE
            ? this.state.customerNameChanged ||
              this.state.subscriptionIDChanged ||
              !this.checkDeliveryTeamChange() ||
              this.checkDeliveryLeadChange()
              ? "Created"
              : this.state.currentStatus &&
                this.state.currentStatus.DISPLAY_NAME
            : this.state.currentStatus && this.state.currentStatus.DISPLAY_NAME,
        saved: true,
        engagementId:
          this.props.engagementDetail.details?.EngagementId == undefined
            ? 0
            : this.props.engagementDetail.details?.EngagementId,
        editedFieldsCount: this.state.editedFields?.length,
        programName: this.state.engProgramName.value ?? "",
      },
      traceSeverity: "Important",
    });
  };

  createOfferInPCAsync = async () => {
    var engagementData: Partial<IEngagementDetailData> = {};
    engagementData = {
      EngagementId: this.props.upsertEngagementState.data.item.EngagementId,
      Status: this.props.upsertEngagementState.data.item.Status,
      Customer: this.props.upsertEngagementState.data.item.Customer ?? "",
      // SubscriptionId: this.props.upsertEngagementState.data.item.EngagementSubscriptions ?? "",
      EngagementSubscriptions:
        this.props.upsertEngagementState.data.item.EngagementSubscriptions,
      DeliveryLead:
        this.props.upsertEngagementState.data.item.DeliveryLead ?? "",
      DeliveryPod: this.props.upsertEngagementState.data.item.DeliveryPod,
      Description: this.props.upsertEngagementState.data.item.Description ?? "",
      OfferId: this.props.upsertEngagementState.data.item.OfferId ?? null,
      OfferCode: this.props.upsertEngagementState.data.item.OfferCode ?? null,
      IsTest: !!this.props.upsertEngagementState.data.item.IsTest,
      ENG_DeliveryOption_Id:
        this.props.upsertEngagementState.data.item.ENG_DeliveryOption_Id ??
        null,
      ENG_DeliveryOption_Other:
        this.props.upsertEngagementState.data.item.ENG_DeliveryOption_Other ??
        "",
      Aliases: this.props.upsertEngagementState.data.item.Aliases,
      ENG_EntityType:
        this.props.upsertEngagementState.data.item.ENG_EntityType ?? null,
      ENG_EntityValue:
        this.props.upsertEngagementState.data.item.ENG_EntityValue ?? null,
      OfferTemplateVersion:
        this.props.upsertEngagementState.data.item.OfferTemplateVersion ?? null,
      ENG_ProgramName:
        this.props.upsertEngagementState.data.item.ENG_ProgramName ?? "",
      ENG_ProgramId:
        this.props.upsertEngagementState.data.item.ENG_ProgramId ?? null,
      IsUserInPod: this.props.upsertEngagementState.data.item.IsUserInPod,
      ENG_OnboardingType_Id:
        this.props.upsertEngagementState.data.item.ENG_OnboardingType_Id,
      ENG_Geography_Id:
        this.props.upsertEngagementState.data.item.ENG_Geography_Id ?? null,
      ENG_Region_Id:
        this.props.upsertEngagementState.data.item.ENG_Region_Id ?? null,
    };

    this.props.createOfferInPC(engagementData);
  };

  checkSubsIdChange = () => {
    let propsArray = this.props.engagementDetail.details
      ?.EngagementSubscriptions
      ? this.props.engagementDetail.details?.EngagementSubscriptions
      : [];
    let isArraySame = this.state.engagementSubscriptions.every(function (
      element
    ) {
      return propsArray.includes(element);
    });
    return isArraySame;
  };

  changedFieldsForTelemetry = () => {
    let fields: string[] = [];
    let fieldChange = false;
    // On customer name change
    if (
      this.props.engagementDetail.details?.Customer !=
      this.state.customerName.value
    ) {
      fields.push("Customer Name");
      fieldChange =
        this.state.currentStatus.DISPLAY_NAME ===
          MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME ||
        this.state.currentStatus.DISPLAY_NAME ===
          MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME
          ? true
          : false;
    }
    // On delivery team change
    if (this.state.deliveryTeamChanged) {
      fields.push("Delivery Team");
      fieldChange =
        this.state.currentStatus.DISPLAY_NAME ===
          MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME ||
        this.state.currentStatus.DISPLAY_NAME ===
          MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME
          ? true
          : false;
    }
    // On delivery lead change
    if (this.state.deliveryLeadChanged) {
      fields.push("Delivery Lead");
      fieldChange =
        this.state.currentStatus.DISPLAY_NAME ===
          MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME ||
        this.state.currentStatus.DISPLAY_NAME ===
          MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME
          ? true
          : false;
    }
    // on delivery option change
    if (this.state.deliveryOptionChanged) {
      fields.push("Delivery Option");
    }
    // On subscription ID change
    if (this.state.subscriptionIDChanged) {
      this.setState({
        accessibilityScopes: {
          ...this.state.accessibilityScopes,
          editCommentDisable: false,
        },
      });
      fields.push("Subscription ID");
      fieldChange =
        this.state.currentStatus.DISPLAY_NAME ===
          MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME ||
        this.state.currentStatus.DISPLAY_NAME ===
          MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME
          ? true
          : false;
    }

    //on entity type change
    if (this.state.entityTypeChanged) {
      fields.push("Ross/Milestone ID");
    }

    //on geography change
    if (this.state.geographychanged) {
      fields.push("Geography");
    }

    //on region change
    if (this.state.regionChanged) {
      fields.push("Region");
    }

    //on internal description change
    if (this.state.internalDescriptionChanged) {
      fields.push("Internal description");
    }

    //on end date change
    if (this.state.endDateChanged) {
      fields.push("End Date");
    }
    this.setState(
      {
        editedFields: fields,
      },
      () => {
        //on change of fields show warning modal
        if (fieldChange) {
          if (
            this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME
          ) {
            this.validateSave()
              ? this.setState({
                  showEditLiveEngagementWarning: true,
                })
              : this.buildAndShowBanner(
                  "Some validation errors were found. Please check them!",
                  MessageBarType.error,
                  6000
                );
          } else {
            this.upsertEngagement();
          }
        } else {
          this.upsertEngagement();
        }
      }
    );
  };

  onRenderFooterContent = () => (
    <div className="formFooter">
      <div style={{ display: "flex" }}>
        {(MAPPED_ENGAGEMENT_STATUS.ERROR_FOUND.IDENTIFIER ==
          this.state.currentStatus?.IDENTIFIER ||
          MAPPED_ENGAGEMENT_STATUS.LIVE.IDENTIFIER ==
            this.state.currentStatus?.IDENTIFIER ||
          MAPPED_ENGAGEMENT_STATUS.PROCESSING.IDENTIFIER ==
            this.state.currentStatus?.IDENTIFIER) &&
          this.state.appRole.isAdmin &&
          AppConstants.ShowViewJsonLink && (
            <div style={{ display: "inline-flex" }}>
              {this.state.selectedCloudType?.text != azureARMTemplate && (
                <a
                  href="javascript:void(0)"
                  aria-label="View Json"
                  onClick={this.viewJson}
                  className="viewJsonLink"
                >
                  View JSON
                </a>
              )}
              {this.state.selectedCloudType?.text == azureARMTemplate &&
                this.props.engagementDetail.details &&
                this.props.engagementDetail.details.Status ===
                  ENGAGEMENT_STATUS.DOWNLOADED_ARM && (
                  <a
                    href="javascript:void(0)"
                    onClick={this.getOctetStream}
                    className="viewJsonLink"
                  >
                    Download ARM Template
                  </a>
                )}
              {this.state.selectedCloudType?.text == azureARMTemplate &&
                this.props.engagementDetail.details &&
                this.props.engagementDetail.details.Status ===
                  ENGAGEMENT_STATUS.DOWNLOADED_TC && (
                  <a
                    href="javascript:void(0)"
                    onClick={this.getOctetStream}
                    className="viewJsonLink"
                  >
                    Download Terms and Condition
                  </a>
                )}
            </div>
          )}
      </div>
      <div style={{ marginLeft: "auto" }}>
        {!(
          this.state.accessibilityScopes.irdDisable &&
          this.state.accessibilityScopes.normalDisable &&
          this.state.accessibilityScopes.disableStatus
        ) && (
          <>
          

          
            {!this.state.showSuccessComponent && (
              <TooltipHost
                tooltipProps={
                  this.state.selectedCloudType?.text === azureARMTemplate &&
                  (this.props.engagementDetail.new || this.isDraftState())
                    ? downloadT_C
                    : (this.state.currentStatus &&
                        this.state.currentStatus.DISPLAY_NAME ===
                          MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME &&
                        (this.state.deliveryTeamChanged ||
                          this.state.customerNameChanged ||
                          this.state.subscriptionIDChanged ||
                          this.state.deliveryLeadChanged)) ||
                      this.isDraftState() ||
                      this.props.engagementDetail.new
                    ? submittooltipProps
                    : savetooltipProps
                }
                calloutProps={ttcalloutProps}
                closeDelay={200}
                styles={ttstyles}
              >
                <PrimaryButton
                  className="details-footer-buttons"
                  styles={stackStyles}
                  text={
                    this.props.engagementDetail.new || this.isDraftState()
                      ? this.state.buttonText
                      : this.state.currentStatus &&
                        this.state.currentStatus.DISPLAY_NAME ===
                          MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME &&
                        (this.state.deliveryTeamChanged ||
                          this.state.customerNameChanged ||
                          this.state.subscriptionIDChanged ||
                          this.state.deliveryLeadChanged)
                      ? "Submit"
                      : "Save"
                  }
                  ariaLabel={
                    this.props.engagementDetail.new || this.isDraftState()
                      ? "Submit my engagement"
                      : this.state.currentStatus &&
                        this.state.currentStatus.DISPLAY_NAME ===
                          MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME &&
                        (this.state.deliveryTeamChanged ||
                          this.state.customerNameChanged ||
                          this.state.subscriptionIDChanged)
                      ? "Submit my engagement"
                      : "Save my engagement"
                  }
                  role="button"
                  onClick={(e) => {
                    if (
                      this.state.currentStatus &&
                      (this.state.currentStatus.DISPLAY_NAME ===
                        MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME ||
                        this.state.currentStatus.DISPLAY_NAME ==
                          MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME) &&
                      this.state.status.value?.key !==
                        MAPPED_ENGAGEMENT_STATUS.COMPLETED.DISPLAY_NAME
                    ) {
                      this.changedFieldsForTelemetry();
                    }else if (
                      this.state.status.value?.key ===
                        MAPPED_ENGAGEMENT_STATUS.COMPLETED.DISPLAY_NAME
                    ) {
                      this.statusChanged();
                    } 
                      else if (
                      this.props.engagementDetail.new &&
                      this.state.isSupportOnly
                    ) {
                      this.showSupportOnlyContract();
                    } else {
                      this.upsertEngagement();
                    }
                  }}
                  disabled={this.state.enableSave}
                />
              </TooltipHost>
            )}
             {this.props.engagementDetail.new && !isIframe && (
              <>
                <TooltipHost
                  tooltipProps={drafttooltipProps}
                  calloutProps={ttcalloutProps}
                  closeDelay={200}
                  styles={ttstyles}
                >
                  <DefaultButton
                    text="Save as Draft"
                    className="details-footer-buttons"
                    ariaLabel="Save my progress and finish later"
                    role="button"
                    style={{ marginLeft: "15px" }}
                    // styles={{
                    //   label: { color: "white" },
                    //   root: {
                    //     backgroundColor: "black",
                    //     border: "1px solid black",
                    //   },
                    // }}
                    onClick={(e) => {
                      this.props.engagementDetail.new
                        ? this.validateSaveAsDraft()
                          ? this.saveEngagement(
                              ENGAGEMENT_STATUS.DRAFT,
                              "Saving Draft engagement"
                            )
                          : this.buildAndShowBanner(
                              "Some validation errors were found. Please check them!",
                              MessageBarType.error,
                              6000
                            )
                        : this.buildAndShowBanner(
                            "This feature is not integrated yet",
                            MessageBarType.warning,
                            5000
                          );
                    }}
                  />
                </TooltipHost>
              </>
            )}
          </>
        )}
  {this.isDraftState() && (
              <>
              
                <TooltipHost
                  tooltipProps={drafttooltipProps}
                  calloutProps={ttcalloutProps}
                  closeDelay={200}
                  styles={ttstyles}
                >
                  <DefaultButton
                    style={{ marginLeft: "15px" }}
                    ariaLabel=" Save my progress and finish later"
                    role="button"
                  
                    text="Save as Draft"
                    onClick={(e) => {
                      this.validateSaveAsDraft()
                        ? this.saveEngagement(
                            ENGAGEMENT_STATUS.DRAFT,
                            "Saving Draft engagement"
                          )
                        : this.buildAndShowBanner(
                            "Some validation errors were found. Please check them!",
                            MessageBarType.error,
                            6000
                          );
                    }}
                  />
                </TooltipHost>
              </>
            )}
        <TooltipHost
          tooltipProps={canceltooltipProps}
          calloutProps={ttcalloutProps}
          closeDelay={200}
          styles={ttstyles}
        >
          <DefaultButton
            className="details-footer-buttons"
            styles={stackStyles}
            text="Cancel"
            ariaLabel="Cancel and exit the Engagement"
            onClick={() => this.closeEngagement()}
          />
        </TooltipHost>
      </div>
    </div>
  );
  getCategoryCount = (engagementCategories: any, identifier: string) => {
    var catCount = 0;
    if (engagementCategories !== null && engagementCategories.data !== null) {
      engagementCategories.forEach(
        (category: { Status: number; Count: number }) => {
          if (
            MAPPED_ENGAGEMENT_STATUS[identifier].ORIGINAL_STATUS.indexOf(
              category.Status
            ) > -1
          ) {
            catCount = catCount + category.Count;
          }
        }
      );
    }
    return catCount;
  };
  buildCategories = (engagementCategories: any) => {
    this.setState(
      {
        categoryCount: this.buildCategoryData(engagementCategories),
      },
      () => this.setAccessibilityScopes_ViewHelp()
    );
  };
  buildCategoryData = (engagementCategories: any) => {
    var categoryData = this.getCategoryCount(
      engagementCategories,
      MAPPED_ENGAGEMENT_STATUS.LIVE.IDENTIFIER
    );
    return categoryData;
  };
  toggleViewHelp = () => {
    const scrollableDiv = document.querySelector(
      ".engagement-detail-panel .ms-Panel-scrollableContent"
    );
    if (!this.state.viewHelp) {
      scrollableDiv?.classList.add("hideXOverflow");
      setTimeout(() => {
        scrollableDiv?.classList.remove("hideXOverflow");
      }, 900);
      window.sessionStorage.setItem(ShowHelpText, "true");
      logEvent(Navigation, {
        AppName: "CAPE",
        AppLayer: "UI",
        MetricName: "Navigation",
        UserScenario: "User navigation",
        User: { Alias: this.props.userContext.data?.email },
        ApplicationRole: this.props.userContext.data?.roles,
        EntityOperation: "Click",
        EntityName: "Button",
        MetaData: {
          item: "Engagement Help",
        },
        traceSeverity: "Low",
      });
    } else {
      window.sessionStorage.setItem(ShowHelpText, "false");
      logEvent(Navigation, {
        AppName: "CAPE",
        AppLayer: "UI",
        MetricName: "Navigation",
        UserScenario: "User navigation",
        User: { Alias: this.props.userContext.data?.email },
        ApplicationRole: this.props.userContext.data?.roles,
        EntityOperation: "Click",
        EntityName: "Button",
        MetaData: {
          item: "Engagement Help",
        },
        traceSeverity: "Low",
        helpOpened: false,
      });
    }
    this.setState({ viewHelp: !this.state.viewHelp });
  };

  onRenderHeader = () => (
    <div className="panelHeader">
      <div className="divWrapper">
        <div className="leftHeader">
          {this.props.engagementDetail.new ? (
            <span>
              <b>New Engagement Request</b>
            </span>
          ) : (
            <span>{`${this.props.engagementDetail.details?.EngagementId} - ${this.props.engagementDetail.details?.Customer}`}</span>
          )}
        </div>
        <div className="rightHeader">
          {isIframe && window.top != window.self ? (
            <div></div>
          ) : (
            <div>
              <TooltipHost
                directionalHint={DirectionalHint.bottomCenter}
                tooltipProps={{
                  onRenderContent: () => (
                    <div>Cancel and exit the Engagement</div>
                  ),
                }}
                calloutProps={ttcalloutProps}
                closeDelay={200}
                styles={ttstyles}
              >
                <FontIcon
                  iconName="Cancel"
                  title="Close"
                  className="header-right-icons clickable"
                  onClick={() => this.closeEngagement()}
                />
              </TooltipHost>
            </div>
          )}
        </div>
      </div>
    </div>
  );  

  renderHelpContent = () => {
    return (
      <main className="help-content">
         <IconButton
    iconProps={{ iconName: 'Cancel' }}
    title="Close"
    ariaLabel="Close"
    onClick={() => this.setState({ viewHelp: false })}
    style={{ position: 'absolute', top: 0, right: 0 }}
  />
        <div className="help-header">
          Step-by-Step Instructions for filling the form:
        </div>
        <div className="help-body">
          <div className="help-sub-body">
            <div className="help-sub-header">Test Engagement</div>
            <div>
              <ul>
                <li>
                  Mark this engagement as a Test Engagement if you are creating
                  it for testing or training purposes. A Test engagement cannot
                  be used with customer subscriptions.
                </li>
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">Delivery Option</div>
            <div>
              <ul>
                <li>
                  It is a required field that will help you categorize your
                  engagements. This field will be auto populated when you come
                  into the engagement through CAPE Link from the MSXi
                  dashboard. You may select 'Others' to type in free-text in
                  case the Delivery Options are not part of the available list.
                </li>
                <li>
                  Example:{" "}
                  <span className="help-example">
                    "Remediation and removal of blockers"
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">Delivery Lead</div>
            <div>
              <ul>
                <li>Defaults to the current logged in user. This is you!</li>
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">Delivery Team</div>
            <div>
              <ul>
                <li>
                  Aliases of Microsoft resources who will be gaining access to
                  the customer's Azure subscription.
                </li>
                <li>
                  Only individuals who have been onboarded for the CAPE Program
                  will be shown. Don't see someone you are looking for? Check
                  the{" "}
                  <Link
                    href=""
                    underline
                    style={{ fontWeight: 500 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.setState({
                        toHideParticipantModal: false,
                      });
                    }}
                  >
                    CAPE Participant List
                  </Link>
                  .
                </li>
                <li>The Delivery Lead is included in the Delivery Team.</li>
                <li>
                  Example:{" "}
                  <span className="help-example">"Developer@Contoso.com"</span>
                </li>
                <li>
                  Note: Verify that all aliases are entered for all Delivery
                  Team members when submitting the Engagement, as the Delivery
                  Team cannot be edited during publishing process until status
                  is Live. Delivery Lead can edit the Delivery Team members once
                  the Offer is Live and resubmit it for publishing. The
                  resubmitted Offer must be accepted by the client after it is
                  Live to reflect any changes to Delivery Team members.
                </li>
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">
              Customer has active Support Contract for this Engagement
            </div>
            <div>
              <ul>
                <li>
                  Change toggle to ‘No’ if your Customer does not have an active
                  Support contract.
                </li>
                <li>
                  Select ‘Yes’ ONLY if your Customer has an active Support
                  contract.
                </li>
                <ul>
                  You can validate current Support Contract (e.g., Unified)
                  status by going to MSX or by using the appropriate self-help
                  K360 wizard below:
                </ul>
                <ol>
                  <li>
                    For <b>Consulting</b> contracting questions, please use the{" "}
                    <Link
                      href="https://k360plus.azurewebsites.net/SCOIntake/ContractingSupportIntake"
                      target="_blank"
                      underline
                      style={{ fontWeight: 500 }}
                    >
                      Consulting Contracting Wizard
                    </Link>{" "}
                    on K360 to access up to date self-help resources or request
                    assistance.
                  </li>
                  <li>
                    For <b>Unified/Premier</b> contracting questions, please use
                    the{" "}
                    <Link
                      href="https://k360support.azurewebsites.net/SCOIntake/ContractingSupportIntake"
                      target="_blank"
                      underline
                      style={{ fontWeight: 500 }}
                    >
                      Support Contracting Wizard
                    </Link>{" "}
                    on K360 to access up to date self-help resources or request
                    assistance.
                  </li>
                </ol>
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">Offer Type</div>
            <div>
              <ul>
                <li>
                  Select an offer type from the drop down that corresponds to
                  your CELA-approved offer type for your engagement.
                </li>
                <li>
                  Example:{" "}
                  <span className="help-example">
                    "CSU - Azure Hands-On Assistance (Contributor)"
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">ROSS/Milestone ID</div>
            <div>
              <ul>
                <li>
                  Track your CAPE engagements as part of a Support Program
                  delivery or MSX Engagement with ROSS or Milestone ID.
                </li>
                <li>
                  Milestone Title will be automatically populated if a valid
                  ROSS/Milestone ID is entered.
                </li>
                <li>ROSS ID is not validated at this time.</li>
                <li>
                  Example: <span className="help-example">"1-111111111"</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">Start Date & End Date</div>
            <div>
              <ul>
                <li>
                  Track the Start and End date for Cape Engagement with your
                  customer.
                </li>
                <li>
                  Start Date will be auto populated to Engagement creation date.
                </li>
                <li>
                  End Date will be auto populated to 3 weeks from Start Date.
                  You can modify End date.
                </li>
                {(this.props.engagementDetail.new ||
                  this.props.engagementDetail.details?.Status ===
                    ENGAGEMENT_STATUS.DRAFT) && (
                  <li>
                    You will not be able to submit a new Engagement if you are
                    Delivery lead of more than{" "}
                    {this.props.userContext.data?.restrictLiveEngagementLimit}{" "}
                    ‘Live’ Engagements on CAPE. You need close any past Live
                    Engagements on CAPE before you can submit new Engagements.
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">Customer Name</div>
            <div>
              <ul>
                <li>
                  Please enter the Customer name as shown on the existing
                  Support contract.
                </li>
                <li>
                  Used for tracking the engagement request internally only, not
                  visible to the customer.
                </li>
                {/* <li>Example: <span className="help-example">"Contoso Ltd."</span></li> */}
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">Subscription ID's</div>
            <div>
              <ul>
                <li>
                  Defines the customer’s Azure subscriptions where the Offer
                  will be visible and where access will be granted.
                </li>
                <li>
                  The customer team member who will give your team delegated
                  access should be either an Owner or Admin of this
                  subscription.
                </li>
                <li>
                  Example:{" "}
                  <span className="help-example">
                    "11111111-1111-1111-1111-111111111111"
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="help-sub-body" style={{ display: "flex" }}>
            <div className="clubbedField">
              <div className="help-sub-header">Geography</div>
              <div>
                <ul>
                  <li>Used for tracking Azure Geography.</li>
                </ul>
              </div>
            </div>
            <div className="clubbedField">
              <div className="help-sub-header">Region</div>
              <div>
                <ul>
                  <li>Used for tracking Azure region.</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="help-sub-body">
            <div className="help-sub-header">Customer Onboarding Type</div>
            <div>
              <ul>
                <li>It is a required field.</li>
                <li>
                  Please choose <b>Managed Service Offer</b> for regions where
                  Azure Marketplace is available.
                </li>
                <li>
                <b>[Currently unavailable]</b>Please choose <b>Azure ARM Template</b> for regions where
                  Azure Marketplace is not available.
                  <ul>
                    <li>
                      [Beta]You'll be able choose this option after you receive
                      email confirming that you have been added to Beta user
                      group.
                    </li>
                    {this.state.selectedCloudType?.text == azureARMTemplate &&
                      this.props.engagementDetail.details &&
                      (this.props.engagementDetail.details.Status ===
                        ENGAGEMENT_STATUS.DOWNLOADED_ARM ||
                        this.props.engagementDetail.details.Status ===
                          ENGAGEMENT_STATUS.DOWNLOADED_TC) && (
                        <div>
                          <li>
                            Step 1 - After you submit the Engagement, you can
                            download Offer Terms & Conditions file along with
                            Readme file. The Readme instructions will guide you
                            to use Adobe E-Sign portal in sending the Terms for
                            customer. Customer will receive an email from
                            adobesign@adobesign.com to review and complete
                            e-signature.
                          </li>
                          <li>
                            Step 2 - After customer completed e-signature,
                            you'll be able to download and share the ARM
                            template along with Readme file with steps for
                            customer to deploy for providing delegated access.
                          </li>
                        </div>
                      )}
                  </ul>
                </li>
                <li>
                  Check the{" "}
                  <Link
                    href="https://status.azure.com/en-us/status"
                    target="_blank"
                    className="ms-Link root-344"
                  >
                    Azure Status
                  </Link>{" "}
                  for Marketplace availability.
                </li>
              </ul>
            </div>
          </div>

          <div className="help-sub-body">
            <div className="help-sub-header">Internal Description</div>
            <div>
              <ul>
                <li>
                  Used for tracking Offer request, not visible to customer, only
                  visible to Delivery Lead and Admin team.
                </li>
                <li>
                  Example:{" "}
                  <span className="help-example">
                    "This engagement helps Contoso Ltd. remove blockers"
                  </span>
                </li>
              </ul>
            </div>
          </div>
          {!this.state.accessibilityScopes.editCommentDisable &&
            !this.props.engagementDetail.new &&
            !this.isDraftState() &&
            this.props.engagementDetail.details?.Status !=
              ENGAGEMENT_STATUS.DOWNLOADED_ARM &&
            this.props.engagementDetail.details?.Status !=
              ENGAGEMENT_STATUS.DOWNLOADED_TC && (
              <div className="help-sub-body">
                <div className="help-sub-header">Comments</div>
                <div>
                  <ul>
                    <li>Enter reason for modifying engagement.</li>
                    <li>Used for internal tracking only.</li>
                  </ul>
                </div>
              </div>
            )}
            {!this.props.engagementDetail.new &&
            !this.isDraftState() &&
            this.props.engagementDetail.details?.Status !=
              ENGAGEMENT_STATUS.DOWNLOADED_ARM &&
            this.props.engagementDetail.details?.Status !=
              ENGAGEMENT_STATUS.DOWNLOADED_TC && this.state.status.value?.key ===
                    MAPPED_ENGAGEMENT_STATUS.COMPLETED.DISPLAY_NAME && (
              <div className="help-sub-body">
                <div className="help-sub-header">Do you confirm your access to customer's Azure environment is removed?</div>
                <div>
                  <ul>
                    <li>Within 3 days of completing the Engagement the customer, the delivery lead is required to delete the access delegations if not already done by the customer. The checkbox should be selected before updating the State of the Engagement as 'Completed'".</li>
                    <li> Check <Link
                              href="https://ms.portal.azure.com/#view/Microsoft_Azure_CustomerHub/MyCustomersBladeV2/~/scopeManagement"
                              target="_blank"
                              className="ms-Link root-344"
                            >
                              My Customers | Delegations
                            </Link> on your Azure Portal for deleting access.</li>
                  </ul>
                </div>
              </div>
            )}
        </div>
      </main>
    );
  };

  checkFieldChange = (value: any) => {
    let changed =
      this.props.engagementDetail.details?.ENG_DeliveryOption_Id! == value
        ? false
        : true;
    return changed;
  };

  onDeliveryLeadChange = (
    items?: any[] | undefined,
    validateForm: boolean = false
  ) => {
    var deliveryLead: IPeople[] = [];
    var deliveryTeamExisting: any[] = this.state.deliveryTeam.value
      ? this.state.deliveryTeam.value
      : [];
    var deliveryTeam: IPeople[] = [];
    var names: string[] = [];
    var aliases: string[] = [];
    if (!!items) {
      items.forEach((item) => {
        if (
          names.indexOf(item.text) == -1 &&
          aliases.indexOf(item.optionalText) == -1
        ) {
          deliveryLead.push({
            name: item.text,
            alias: item.optionalText,
            imageUrl: item.imageUrl,
          });
          let isPresent = this.state.deliveryNewTeamMember?.value?.filter(
            (i) => i.alias === item.optionalText
          );
          if (isPresent?.length === 0) {
            deliveryTeam.push({
              name: item.text,
              alias: item.optionalText,
              imageUrl: item.imageUrl,
            });
          }
          names.push(item.text);
          aliases.push(item.optionalText);
        }
      });
      deliveryTeamExisting &&
        deliveryTeamExisting.forEach((item) => {
          if (
            names.indexOf(item.name) == -1 &&
            aliases.indexOf(item.alias) == -1
          ) {
            deliveryTeam.push({
              name: item.name,
              alias: item.alias,
              imageUrl: item.imageUrl,
            });
          }
        });
    }
    let status = false;
    let existingDeliveryTeam =
      this.props.engagementDetail.details?.DeliveryPod?.map(
        (member) => `${member.Alias}${microsfotAliasAppendString}`
      ) ?? [];
    if (aliases[0] != undefined && !existingDeliveryTeam.includes(aliases[0])) {
      status = true;
      this.setState({
        isNewDeliveryLeadAdded: true,
      });
    }
    this.setState({
      deliveryLead: {
        ...this.state.deliveryLead,
        value: deliveryLead,
        errorMessage:
          deliveryLead.length == 0 ? "'Delivery Lead' is required" : undefined,
      },
      accessibilityScopes: {
        ...this.state.accessibilityScopes,
        editCommentDisable: !status,
      },
      deliveryLeadChanged: status,
      deliveryNewTeamMember: {
        ...this.state.deliveryNewTeamMember,
        value: deliveryTeam,
        errorMessage:
          deliveryTeam.length == 0 ? "'Delivery Team' is required" : undefined,
      },
      deliveryTeam: {
        ...this.state.deliveryTeam,
        value: deliveryTeam,
        errorMessage:
          deliveryTeam.length == 0 ? "'Delivery Team' is required" : undefined,
      },
    });

    if (deliveryLead.length == 0 && validateForm) {
      return false;
    }

    return true;
  };
  onDeliveryTeamChange = (
    items?: any[] | undefined,
    validateForm: boolean = false
  ) => {
    var deliveryTeam: IPeople[] = [];
    var names: string[] = [];
    var aliases: string[] = [];
    if (!!items) {
      items.forEach((item) => {
        if (
          names.indexOf(item.text) == -1 &&
          aliases.indexOf(item.optionalText) == -1
        ) {
          deliveryTeam.push({
            name: item.text,
            alias: item.optionalText,
            imageUrl: item.imageUrl,
          });
          names.push(item.text);
          aliases.push(item.optionalText);
        } else {
          this.buildAndShowBanner(
            `The user '${item.text}' is already picked`,
            MessageBarType.warning,
            5000
          );
        }
      });
    }
    let status = false;
    let existingDeliveryTeam =
      this.props.engagementDetail.details?.DeliveryPod?.map(
        (member) => `${member.Alias}${microsfotAliasAppendString}`
      ) ?? [];
    if (existingDeliveryTeam.length === deliveryTeam.length) {
      for (let i = 0; i < existingDeliveryTeam.length; i++) {
        status = existingDeliveryTeam.indexOf(deliveryTeam[i].alias) !== -1;
        if (status === false) {
          break;
        }
      }
    }
    this.setState({
      deliveryTeam: {
        ...this.state.deliveryTeam,
        value: deliveryTeam,
        errorMessage:
          deliveryTeam.length == 0 ? "'Delivery Team' is required" : undefined,
      },
      accessibilityScopes: {
        ...this.state.accessibilityScopes,
        editCommentDisable: status,
      },
      deliveryNewTeamMember: {
        value: deliveryTeam,
        errorMessage:
          deliveryTeam.length == 0 ? "'Delivery Team' is required" : undefined,
      },
      deliveryTeamChanged: !status,
    });

    if (deliveryTeam.length == 0 && validateForm) {
      return false;
    }

    return true;
  };

  updateInternalDesc = (cont?: string) => {
    this.setState({
      internalDescription: {
        ...this.state.internalDescription,
        value: cont ?? "",
      },
      accessibilityScopes: {
        ...this.state.accessibilityScopes,
        editCommentDisable:
          this.props.engagementDetail.details?.Description !== cont
            ? false
            : true,
      },
      internalDescriptionChanged:
        this.props.engagementDetail.details?.Description !== cont
          ? true
          : false,
    });
  };

  updateEditComment = (cont?: string, validateForm: boolean = false) => {
    const isInvalid = !!cont
      ? !(cont?.trimStart().length >= 2)
        ? true
        : false
      : true;
    this.setState({
      editComment: {
        ...this.state.editComment,
        value: cont ?? "",
        errorMessage: !isInvalid
          ? undefined
          : cont?.trimStart().length == 1
          ? "'Comments' length should be of min 2 characters"
          : "'Comments' are required",
      },
      accessibilityScopes: {
        ...this.state.accessibilityScopes,
        editCommentDisable: false,
      },
    });

    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };

  onMileStoneChange = (cont?: string) => {
    this.setState({
      rossMilestoneTitle: {
        ...this.state.rossMilestoneTitle,
        value: cont ?? "",
      },
    });
  };

  entityTimer: any;
  onRossMileStoneChange = (
    cont?: string,
    validateForm: boolean = false,
    isOnSubmitOrDraft: boolean = false
  ) => {
    let isInvalid = !!cont
      ? !(cont?.trimStart().length >= 1 && cont.length > 0)
        ? true
        : false
      : true;
    if (this.state.entityType.value?.text === ENTITY_TYPE.NOT_AVAILABLE) {
      isInvalid = false;
    }
    clearTimeout(this.entityTimer);
    this.entityTimer = setTimeout(() => {
      if (
        cont &&
        this.state.entityType.value?.text === ENTITY_TYPE.MILESTONE &&
        !isOnSubmitOrDraft
      ) {
        this.triggerEntityDataCall(cont);
      }
    }, 1200);
    this.setState({
      rossMileStoneID: {
        ...this.state.rossMileStoneID,
        value: cont ?? "",
        errorMessage: !isInvalid
          ? undefined
          : cont?.trimStart().length === 0
          ? "Field is required"
          : "",
      },
      showValidIDMessage: "",
    });
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };
  OnStartDateChange = (e: any) => {
    this.setState({
      startDate: { value: e, errorMessage: "" },
      accessibilityScopes: {
        ...this.state.accessibilityScopes,
        editCommentDisable: false,
      },
    });
  };
  OnEndDateChange = (e: any) => {
    this.setState({
      endDate: { value: e, errorMessage: "" },
      accessibilityScopes: {
        ...this.state.accessibilityScopes,
        editCommentDisable: false,
      },
    });
  };
  onCustomerSubscriptionChange = (
    newValue?: string,
    validateForm: boolean = false
  ) => {
    let subscriptionIDs: IEngagementSubscriptions[] =
      this.state.engagementSubscriptions.filter((a) => a.SubscriptionId);
    let isPresent = this.state.engagementSubscriptions.find(
      (id) => id.SubscriptionId == newValue
    )
      ? true
      : false;
    this.setState({
      customerSubscriptionIds: {
        ...this.state.customerSubscriptionIds,
        value: newValue,
        required: newValue ? true : false,
        errorMessage: !!newValue
          ? !newValue?.trimStart()
            ? "'Subscription ID' is required"
            : SubscriptionIDRegex.test(newValue)
            ? undefined
            : "'Subscription ID' is not valid. Example: '11111111-1111-1111-1111-111111111111'"
          : subscriptionIDs.length > 0
          ? ""
          : "'Subscription ID' is required",
      },
      isSubsIdPresent: isPresent,
    });
    if (isPresent) {
      this.setState({
        customerSubscriptionIds: {
          ...this.state.customerSubscriptionIds,
          value: newValue,
          required: newValue ? true : false,
          errorMessage: "'Subscription ID' already exist",
        },
        accessibilityScopes: {
          ...this.state.accessibilityScopes,
          editCommentDisable: true,
        },
      });
    } else {
      this.setState({
        accessibilityScopes: {
          ...this.state.accessibilityScopes,
          editCommentDisable: true,
        },
      });
    }
    let status = false;
    let existingId =
      this.props.engagementDetail.details?.EngagementSubscriptions?.map(
        (element) => {
          return element.SubscriptionId;
        }
      );
    let newId = subscriptionIDs.map((element) => {
      return element.SubscriptionId;
    });
    if (!!newValue && !isPresent) {
      newId.push(newValue);
    }

    if (existingId?.length === newId.length) {
      for (let i = 0; i < existingId?.length; i++) {
        status = existingId?.indexOf(newId[i]) !== -1;
        if (status === false) {
          break;
        }
      }
    }
    if (
      this.state.currentStatus &&
      this.state.currentStatus.DISPLAY_NAME ===
        MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME &&
      this.state.customerSubscriptionIds.errorMessage == null
    ) {
      this.setState({
        accessibilityScopes: {
          ...this.state.accessibilityScopes,
          editCommentDisable: status,
        },
        subscriptionIDChanged: !status,
      });
    }
    if (validateForm) {
      if (this.state.customerSubscriptionIds.value === "") {
        return false;
      }
    }
    return true;
  };

  onDeliveryOptionOtherChange = (
    cont?: string,
    validateForm: boolean = false
  ) => {
    const isInvalid = !!cont ? (!cont?.trimStart() ? true : false) : true;
    this.setState({
      deliveryOptionOther: {
        ...this.state.deliveryOptionOther,
        value: cont ?? "",
        errorMessage: !isInvalid
          ? undefined
          : "'Delivery Option (Other)' is required",
      },
      accessibilityScopes: {
        ...this.state.accessibilityScopes,
        editCommentDisable:
          this.props.engagementDetail.details?.ENG_DeliveryOption_Other != cont
            ? false
            : true,
      },
    });
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };

  onCloudTypeChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    this.setState({
      selectedCloudType: option,
      buttonText: option?.text === azureARMTemplate ? "Download T&C" : "Submit",
      accessibilityScopes: {
        ...this.state.accessibilityScopes,
        editCommentDisable:
          this.state.selectedCloudType != option ? false : true,
      },
    });
  };

  onCustomerNameChange = (cont?: string, validateForm: boolean = false) => {
    const isInvalid = !!cont
      ? !(cont?.trimStart().length >= 2)
        ? true
        : false
      : true;
    this.setState({
      customerName: {
        ...this.state.customerName,
        value: cont ?? "",
        errorMessage: !isInvalid
          ? undefined
          : cont?.trimStart().length == 1
          ? "'Customer Name' length should be of min 2 characters"
          : "'Customer Name' is required",
      },
      accessibilityScopes: {
        ...this.state.accessibilityScopes,
        editCommentDisable:
          this.props.engagementDetail.details?.Customer != cont ? false : true,
      },
      customerNameChanged:
        this.props.engagementDetail.details?.Customer !== cont ? true : false,
    });
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };

  onContractTypeUpdate = (
    option?: IComboBoxOption,
    validateForm: boolean = false
  ) => {
    const isInvalid = !option || option.key == PlaceHolderOption;
    this.setState({
      contractType: {
        ...this.state.contractType,
        value: option,
        errorMessage: isInvalid ? "'Contract Type' is required" : undefined,
      },
    });
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };

  onOfferTypeChange = (
    option?: IComboBoxOption,
    validateForm: boolean = false
  ) => {
    const isInvalid = !option || option.key == PlaceHolderOption;
    this.setState({
      offerType: {
        ...this.state.offerType,
        value: option,
        errorMessage: isInvalid ? "'Offer Type' is required" : undefined,
      },
    });
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };

  filterRegion = (geography?: IComboBoxOption) => {
    let filteredRegion: IComboBoxOption[] = [];
    let regions = buildGeographyAndRegion(this.props.metaData.Region);
    regions.forEach((region: any) => {
      if (geography && geography.key === region.id.toString()) {
        filteredRegion.push({
          key: region.key.toString(),
          text: region.text,
          id: region.id,
        });
      }
    });
    return filteredRegion;
  };

  onGeographyChange = (
    option?: IComboBoxOption,
    validateForm: boolean = false
  ) => {
    const isInvalid = !option || option.key == PlaceHolderOption;
    this.setState({
      geographyOption: {
        ...this.state.geographyOption,
        value: option,
        errorMessage: isInvalid ? "'Geography' is required" : undefined,
      },
      regionDropdownOption: this.filterRegion(option),
      regionOption: { value: { key: "", text: "" } },
      accessibilityScopes: {
        ...this.state.accessibilityScopes,
        editCommentDisable:
          this.props.engagementDetail.details?.ENG_Geography_Id != option
            ? false
            : true,
      },
      geographychanged:
        this.props.engagementDetail.details?.ENG_Geography_Id != option
          ? true
          : false,
    });
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };

  onRegionChange = (
    option?: IComboBoxOption,
    validateForm: boolean = false
  ) => {
    const isInvalid = !option || option.key == PlaceHolderOption;
    this.setState({
      regionOption: {
        ...this.state.regionOption,
        value: option,
        errorMessage: isInvalid ? "'Region' is required" : undefined,
      },
      accessibilityScopes: {
        ...this.state.accessibilityScopes,
        editCommentDisable:
          this.props.engagementDetail.details?.ENG_Region_Id != option
            ? false
            : true,
      },
      regionChanged:
        this.props.engagementDetail.details?.ENG_Region_Id != option
          ? true
          : false,
    });
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };

  onEntityTypeChange = (
    option?: IComboBoxOption,
    validateForm: boolean = false,
    isOnSubmitOrDraft: boolean = false
  ) => {
    const isInvalid = !option || option.key == PlaceHolderOption;
    const [
      rossMilestoneIDFromQS,
      rossMilestoneTypeFromQS,
      engProgramName,
      engProgramId,
    ] = this.handleQueryParam();
    this.setState(
      {
        accessibilityScopes: {
          ...this.state.accessibilityScopes,
          editCommentDisable:
            this.props.engagementDetail.details?.ENG_EntityType != option?.key
              ? false
              : true,
        },
        entityType: {
          ...this.state.entityType,
          value: option,
          errorMessage: isInvalid
            ? "'ROSS/Milestone ID' is required"
            : undefined,
        },
        entityTypeChanged:
          this.props.engagementDetail.details?.ENG_EntityType != option?.key
            ? true
            : false,
        rossMileStoneID: {
          value:
            this.props.engagementDetail.details?.ENG_EntityType === option?.key
              ? this.props.engagementDetail.details?.ENG_EntityValue
              : rossMilestoneIDFromQS
              ? rossMilestoneIDFromQS
              : "",
          required: true,
        },
        showValidIDNotification: false,
        rossMilestoneTitle: this.state.rossMilestoneTitle ?? "",
        titletoBeShown:
          option?.text !== undefined ? option?.text.toString() : "",
        showValidIDMessage: "",
      },
      () => {
        this.onRossMileStoneChange(
          this.state.rossMileStoneID.value,
          false,
          isOnSubmitOrDraft
        );
      }
    );
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };

  onDeliveryOptionChange = (
    option?: IComboBoxOption,
    validateForm: boolean = false
  ) => {
    const isInvalid = !option || option.key == PlaceHolderOption;
    this.setState({
      deliveryOption: {
        ...this.state.deliveryOption,
        value: option,
        errorMessage: isInvalid ? "'Delivery Option' is required" : undefined,
      },
      accessibilityScopes: {
        ...this.state.accessibilityScopes,
        editCommentDisable:
          this.props.engagementDetail.details?.ENG_DeliveryOption_Id !=
          this.props.metaData.DeliveryOption.values?.find(
            (deliveryOption) => deliveryOption.id == option?.key
          )?.metaData?.cdd_Id
            ? false
            : true,
      },
      deliveryOptionChanged:
        this.props.engagementDetail.details?.ENG_DeliveryOption_Id !=
        this.props.metaData.DeliveryOption.values?.find(
          (deliveryOption) => deliveryOption.id == option?.key
        )?.metaData?.cdd_Id
          ? true
          : false,
    });
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };

  onStatusChange = (
    option?: IComboBoxOption,
    validateForm: boolean = false
  ) => {
    const isInvalid = !option || option.key == PlaceHolderOption;
    this.setState({
      status: {
        ...this.state.status,
        value: option,
        errorMessage: isInvalid ? "'Status' is required" : undefined,
      },
    });
    if (isInvalid && validateForm) {
      return false;
    }
    if (
      !isInvalid &&
      (option?.key === "Closed" || option?.key === "Completed")
    ) {
      this.setState({
        endDate: { value: new Date(), errorMessage: "" },
        // accessibilityScopes: {
        //   ...this.state.accessibilityScopes,
        //   editCommentDisable: false,
        // },
      });
    }
    return true;
  };
  onSubStatusChange = (
    option?: IComboBoxOption,
    validateForm: boolean = false
  ) => {
    const isInvalid = !option || option.key == PlaceHolderOption;
    this.setState({
      subStatus: {
        ...this.state.subStatus,
        value: option,
        errorMessage: isInvalid ? "'Reason' is required" : undefined,
      },
    });
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };
  getTeamsGroupMenuProps = (
    deliveryTeam: IPeople[] | undefined,
    EngagementID: number | undefined
  ) => {
    let teamsAliases = "";
    let groupChat = false;

    let deliveryTeamExcludingUser = "";
    this.state.deliveryTeam.value
      ?.map((person) => person.alias.split("@")[0])
      ?.forEach((elem) => {
        if (elem != this.props.user?.userName.split("@")[0] ?? "") {
          if (deliveryTeamExcludingUser.length > 0) {
            deliveryTeamExcludingUser += ", ";
            groupChat = true;
          }
          deliveryTeamExcludingUser += elem;
        }

        teamsAliases += elem + "@microsoft.com,";
      });
    let menuProps: IContextualMenuProps = {
      items: [
        {
          key: "share",
          text: "Share to Teams",
          title: "Share this Engagement link",
          iconProps: { iconName: "share" },
          onClick: () => {
            logEvent(TeamsMenuClicked, {
              AppName: AppConstants.ApplicationAlias,
              AppLayer: "UI",
              metricName: "TeamsOperation",
              UserScenario: "User Clicks on Teams Menu",
              User: { Alias: this.props.userContext.data?.email },
              applicationRoles: this.props.userContext.data?.roles,
              entityOperation: "Share to Teams",
              entityName: "",
              metaData: {
                EntityId: EngagementID,
                NoOfParticipants: teamsAliases.split(",").length - 1,
                sourcePage: "Engagement Detail",
              },
              traceSeverity: "Medium",
            });

            let teamsShareLink: string =
              AppConstants.shareToTeamsLink +
              "msgText=Please look into this CAPE Engagement" +
              "&href=" +
              window.location;
            window.open(
              teamsShareLink,
              "Share to Teams",
              "height=600,width=600,modal=yes,alwaysRaised=yes"
            );
          },
        },
        {
          key: "scheduleMeeting",
          text:
            "Schedule meeting with " +
            this.getTeamsMeetingText(deliveryTeamExcludingUser),
          title: deliveryTeamExcludingUser,
          iconProps: { iconName: "CalendarReply" },
          onClick: () => {
            logEvent(TeamsMenuClicked, {
              AppName: AppConstants.ApplicationAlias,
              AppLayer: "UI",
              metricName: "TeamsOperation",
              UserScenario: "User Clicks on Teams Menu",
              User: { Alias: this.props.userContext.data?.email },
              applicationRoles: this.props.userContext.data?.roles,
              entityOperation: "Schedule meeting",
              entityName: "",
              metaData: {
                EntityId: EngagementID,
                NoOfParticipants: teamsAliases.split(",").length - 1,
                sourcePage: "Engagement Detail",
              },
              traceSeverity: "Medium",
            });

            let scheduleMeetingLink =
              AppConstants.TeamsMeetingDeepLink +
              teamsAliases +
              "&subject=CAPE Engagement " +
              EngagementID;
            window.open(scheduleMeetingLink, "_blank");
          },
        },
      ],
      directionalHintFixed: false,
    };
    return menuProps;
  };

  getTeamsMenuProps = (
    deliveryTeam: IPeople[] | undefined,
    EngagementID: number | undefined
  ) => {
    let menuProps: IContextualMenuProps = {
      items: [
        {
          key: "share",
          text: "Share to Teams",
          title: "Share this Engagement link",
          iconProps: { iconName: "share" },
          onClick: () => {
            logEvent(TeamsMenuClicked, {
              AppName: AppConstants.ApplicationAlias,
              AppLayer: "UI",
              metricName: "TeamsOperation",
              UserScenario: "User Clicks on Teams Menu",
              User: { Alias: this.props.userContext.data?.email },
              applicationRoles: this.props.userContext.data?.roles,
              entityOperation: "Share to Teams",
              entityName: "",
              metaData: {
                EntityId: EngagementID,
                NoOfParticipants: deliveryTeam?.length,
                sourcePage: "Engagement Detail",
              },
              traceSeverity: "Medium",
            });

            let teamsShareLink: string =
              AppConstants.shareToTeamsLink +
              "msgText=Please look into this CAPE Engagement" +
              "&href=" +
              window.location;
            window.open(
              teamsShareLink,
              "Share to Teams",
              "height=600,width=600,modal=yes,alwaysRaised=yes"
            );
          },
        },
      ],
      directionalHintFixed: false,
    };

    if (!this.isUserPartOfDeliveryTeam(deliveryTeam)) {
      menuProps.items.push({
        key: "scheduleMeeting",
        text:
          "Schedule meeting with " +
          deliveryTeam?.map((person) => person.alias.split("@")[0]),
        iconProps: { iconName: "CalendarReply" },
        onClick: () => {
          logEvent(TeamsMenuClicked, {
            AppName: AppConstants.ApplicationAlias,
            AppLayer: "UI",
            metricName: "TeamsOperation",
            UserScenario: "User Clicks on Teams Menu",
            User: { Alias: this.props.userContext.data?.email },
            applicationRoles: this.props.userContext.data?.roles,
            entityOperation: "Schedule meeting",
            entityName: "",
            metaData: {
              EntityId: EngagementID,
              NoOfParticipants: deliveryTeam?.length,
              sourcePage: "Engagement Detail",
            },
            traceSeverity: "Medium",
          });

          let scheduleMeetingLink =
            AppConstants.TeamsMeetingDeepLink +
            deliveryTeam +
            "@microsoft.com" +
            "&subject=CAPE Engagement " +
            EngagementID;
          window.open(scheduleMeetingLink, "_blank");
        },
      });
    }
    return menuProps;
  };

  isUserPartOfDeliveryTeam = (deliveryTeam: IPeople[] | undefined) => {
    let isUserPartOfDeliveryteam = false;

    deliveryTeam?.forEach((elem: IPeople) => {
      isUserPartOfDeliveryteam =
        isUserPartOfDeliveryteam ||
        (elem.alias == this.props.user?.userName ?? "");
    });

    return isUserPartOfDeliveryteam;
  };

  getEditCommentDisableStatus = () => {
    let status =
      this.state.deliveryTeam.value !==
        this.props.engagementDetail.details?.DeliveryPod ||
      this.state.customerSubscriptionIds !==
        this.props.engagementDetail.details?.SubscriptionId
        ? false
        : true;
    return status;
  };

  // add subscription id
  addSubscriptionIDField = () => {
    let ids: IEngagementSubscriptions[] = this.state.engagementSubscriptions;
    let isPresent = false;
    if (this.state.customerSubscriptionIds.value) {
      isPresent = this.state.engagementSubscriptions.find(
        (id) => id.SubscriptionId == this.state.customerSubscriptionIds.value
      )
        ? true
        : false;
      let isValid = SubscriptionIDRegex.test(
        this.state.customerSubscriptionIds.value
      );
      if (isValid) {
        if (isPresent) {
          this.setState({
            customerSubscriptionIds: {
              value: this.state.customerSubscriptionIds.value,
              errorMessage: "'Subscription ID' already exist",
            },
            isSubsIdPresent: isPresent,
          });
        } else {
          ids.push({
            EngagementId:
              this.props.engagementDetail.details?.EngagementId == undefined
                ? 0
                : this.props.engagementDetail.details?.EngagementId,
            SubscriptionId: this.state.customerSubscriptionIds.value,
          });
          let status = this.checkSubsIdChange();
          // let existingId =  this.props.engagementDetail.details?.EngagementSubscriptions?.map((element)=> {return element.SubscriptionId});
          // let newId=ids.map((element)=> {return element.SubscriptionId});
          // if (existingId?.length === newId.length) {
          //     for (let i = 0; i < existingId?.length; i++) {
          //         status = existingId?.indexOf(newId[i]) !== -1;
          //         if (status === false) {
          //             break;
          //         }
          //     }
          // }
          this.setState({
            customerSubscriptionIds: {
              ...this.state.customerSubscriptionIds,
              value: "",
            },
            accessibilityScopes: {
              ...this.state.accessibilityScopes,
              editCommentDisable: status,
            },
            subscriptionIDChanged: !status,
            isSubsIdPresent: isPresent,
          });
        }
      } else {
        this.setState({
          customerSubscriptionIds: {
            ...this.state.customerSubscriptionIds,

            errorMessage:
              "'Subscription ID' is not valid. Example: '11111111-1111-1111-1111-111111111111'",
          },
        });
      }
    }
  };

  // delete subscription id
  deleteSubscriptionIDField = (element: string) => {
    let sIds = this.state.engagementSubscriptions.filter(
      (val: IEngagementSubscriptions) => val.SubscriptionId != element
    );
    let status = false;
    let existingId =
      this.props.engagementDetail.details?.EngagementSubscriptions?.map(
        (element) => {
          return element.SubscriptionId;
        }
      );
    let newId = sIds.map((element) => {
      return element.SubscriptionId;
    });
    if (existingId?.length === newId.length) {
      for (let i = 0; i < existingId?.length; i++) {
        status = existingId?.indexOf(newId[i]) !== -1;
        if (status === false) {
          break;
        }
      }
    }
    this.setState(
      {
        engagementSubscriptions: sIds,
      },
      () => {
        this.setState(
          {
            customerSubscriptionIds: {
              ...this.state.customerSubscriptionIds,
              value: "",
            },
            accessibilityScopes: {
              ...this.state.accessibilityScopes,
              editCommentDisable: status,
            },
            subscriptionIDChanged: !status,
          },
          () =>
            this.setState({
              customerSubscriptionIds: {
                errorMessage:
                  sIds.length == 0 ? "'Subscription ID' is required" : "",
              },
            })
        );
      }
    );
  };
  setAccessibilityScopes_ViewHelp = () => {
    //NonPartnerCenterFields: deliveryoption, geography, region, ross/Milestone, internal description
    const parsedQS = parseQS(this.props.location.search);
    //(draft or live or processing) and (issiteadmin or deliverylead).
    const enableNonPartnerCenterFields = true;

    // //Enabled for Live/InDraft status
    const enableDeliveryLead = !(
      this.props.engagementDetail.new || parsedQS.id == "new"
    )
      ? (this.state.currentStatus.DISPLAY_NAME ===
          MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME ||
          (this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME &&
            this.props.engagementDetail.details?.Status !=
              ENGAGEMENT_STATUS.DOWNLOADED_ARM)) &&
        (this.state.appRole.isAdmin ||
          this.props.engagementDetail.details?.DeliveryLead +
            "@microsoft.com" ===
            this.props.user.userName)
        ? true
        : false
      : false;

    // (draft or live) and (issiteadmin or deliverylead).
    const enableDeliveryTeam =
      this.props.engagementDetail.new || parsedQS.id == "new"
        ? true
        : (this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME ||
            (this.state.currentStatus.DISPLAY_NAME ===
              MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME &&
              this.props.engagementDetail.details?.Status !=
                ENGAGEMENT_STATUS.DOWNLOADED_ARM)) &&
          (this.state.appRole.isAdmin ||
            this.props.engagementDetail.details?.DeliveryLead +
              "@microsoft.com" ===
              this.props.user.userName)
        ? true
        : false;

    // (draft) and (issiteadmin or deliverylead).
    const enableOfferType =
      this.props.engagementDetail.new || parsedQS.id == "new"
        ? true
        : this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME &&
          (this.state.appRole.isAdmin ||
            this.props.engagementDetail.details?.DeliveryLead +
              "@microsoft.com" ===
              this.props.user.userName)
        ? true
        : false;

    // // (draft) and (issiteadmin or deliverylead)
    const enableCustomerName =
      this.props.engagementDetail.new || parsedQS.id == "new"
        ? true
        : (this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME ||
            (this.state.currentStatus.DISPLAY_NAME ===
              MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME &&
              this.props.engagementDetail.details?.Status !=
                ENGAGEMENT_STATUS.DOWNLOADED_ARM)) &&
          (this.state.appRole.isAdmin ||
            this.props.engagementDetail.details?.DeliveryLead +
              "@microsoft.com" ===
              this.props.user.userName)
        ? true
        : false;

    //(draft or processing or live) and (issiteadmin or deliverylead)
    const enableDeliveryOption =
      this.props.engagementDetail.new || parsedQS.id == "new"
        ? true
        : (this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME ||
            (this.state.currentStatus.DISPLAY_NAME ===
              MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME &&
              this.props.engagementDetail.details?.Status !=
                ENGAGEMENT_STATUS.DOWNLOADED_ARM) ||
            (this.props.engagementDetail.details?.Status !==
              ENGAGEMENT_STATUS.DOWNLOADED_TC &&
              this.state.currentStatus.DISPLAY_NAME ===
                MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME)) &&
          (this.state.appRole.isAdmin ||
            this.props.engagementDetail.details?.DeliveryLead +
              "@microsoft.com" ===
              this.props.user.userName)
        ? true
        : false;

    //(draft or processing or live) and (issiteadmin or deliverylead)
    const enableGeography =
      this.props.engagementDetail.new || parsedQS.id == "new"
        ? true
        : (this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME ||
            (this.state.currentStatus.DISPLAY_NAME ===
              MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME &&
              this.props.engagementDetail.details?.Status !=
                ENGAGEMENT_STATUS.DOWNLOADED_ARM) ||
            (this.props.engagementDetail.details?.Status !==
              ENGAGEMENT_STATUS.DOWNLOADED_TC &&
              this.state.currentStatus.DISPLAY_NAME ===
                MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME)) &&
          (this.state.appRole.isAdmin ||
            this.props.engagementDetail.details?.DeliveryLead +
              "@microsoft.com" ===
              this.props.user.userName)
        ? true
        : false;

    //(draft or processing or live) and (issiteadmin or deliverylead)
    const enableRegion =
      this.props.engagementDetail.new || parsedQS.id == "new"
        ? true
        : (this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME ||
            (this.state.currentStatus.DISPLAY_NAME ===
              MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME &&
              this.props.engagementDetail.details?.Status !=
                ENGAGEMENT_STATUS.DOWNLOADED_ARM) ||
            (this.props.engagementDetail.details?.Status !==
              ENGAGEMENT_STATUS.DOWNLOADED_TC &&
              this.state.currentStatus.DISPLAY_NAME ===
                MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME)) &&
          (this.state.appRole.isAdmin ||
            this.props.engagementDetail.details?.DeliveryLead +
              "@microsoft.com" ===
              this.props.user.userName)
        ? true
        : false;

    // (draft or live) and (issiteadmin or deliverylead).
    const enableCustomerSubscription =
      this.props.engagementDetail.new || parsedQS.id == "new"
        ? true
        : (this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME ||
            (this.state.currentStatus.DISPLAY_NAME ===
              MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME &&
              this.props.engagementDetail.details?.Status !=
                ENGAGEMENT_STATUS.DOWNLOADED_ARM)) &&
          (this.state.appRole.isAdmin ||
            this.props.engagementDetail.details?.DeliveryLead +
              "@microsoft.com" ===
              this.props.user.userName)
        ? true
        : false;

    // draft and (issiteadmin or deliverylead).
    const enableCustomerOnboardingType =
      (this.props.engagementDetail.new || parsedQS.id == "new") &&
      this.props.userContext.data?.flights?.includes(
        AppConstants.NonPublicCloudFlight
      )
        ? true
        : this.state.currentStatus?.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME &&
          (this.state.appRole.isAdmin ||
            this.props.engagementDetail.details?.DeliveryLead +
              "@microsoft.com" ===
              this.props.user.userName)
        ? true
        : false;

    //(draft or processing or live) and (issiteadmin or deliverylead)
    const enableInternalDescription =
      this.props.engagementDetail.new || parsedQS.id == "new"
        ? true
        : (this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME ||
            (this.state.currentStatus.DISPLAY_NAME ===
              MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME &&
              this.props.engagementDetail.details?.Status !==
                ENGAGEMENT_STATUS.DOWNLOADED_ARM) ||
            (this.props.engagementDetail.details?.Status !==
              ENGAGEMENT_STATUS.DOWNLOADED_TC &&
              this.state.currentStatus.DISPLAY_NAME ===
                MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME)) &&
          (this.state.appRole.isAdmin ||
            this.props.engagementDetail.details?.DeliveryLead +
              "@microsoft.com" ===
              this.props.user.userName)
        ? true
        : false;
    //( draft or processing or live)
    const enableRossMilestone =
      this.props.engagementDetail.new || parsedQS.id == "new"
        ? true
        : this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME ||
          (this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME &&
            this.props.engagementDetail.details?.Status !=
              ENGAGEMENT_STATUS.DOWNLOADED_ARM) ||
          (this.props.engagementDetail.details?.Status !==
            ENGAGEMENT_STATUS.DOWNLOADED_TC &&
            this.state.currentStatus.DISPLAY_NAME ===
              MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME)
        ? true
        : false;
    const enableTestToggle =
      this.props.engagementDetail.new || parsedQS.id == "new"
        ? true
        : this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME &&
          (this.state.appRole.isAdmin ||
            this.props.engagementDetail.details?.DeliveryLead +
              "@microsoft.com" ===
              this.props.user.userName)
        ? true
        : false;
    const enableSupportOnlyToggle =
      this.props.engagementDetail.new || parsedQS.id == "new"
        ? true
        : this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME &&
          (this.state.appRole.isAdmin ||
            this.props.engagementDetail.details?.DeliveryLead +
              "@microsoft.com" ===
              this.props.user.userName)
        ? true
        : false;
        const enableSubscriptionAccessRemovalToggle =
        this.props.engagementDetail.details?.Status ===
              ENGAGEMENT_STATUS.COMPLETED ?
           false
        : true;
    const enableSave =
      (this.props.engagementDetail.new ||
        parsedQS.id == "new" ||
        this.props.engagementDetail.details?.Status ===
          ENGAGEMENT_STATUS.DRAFT) &&
      this.props.userContext.data != null &&
      this.props.userContext.data.enableCreateEngagementRestriction === true &&
      this.state.categoryCount >
        this.props.userContext.data.restrictLiveEngagementLimit
        ? false
        : (this.props.engagementDetail.details?.Status ===
            ENGAGEMENT_STATUS.DOWNLOADED_ARM ||
            this.props.engagementDetail.details?.Status ===
              ENGAGEMENT_STATUS.DOWNLOADED_TC) &&
          !this.state.appRole.isAdmin
        ? false
        : true;
    const enableStartDate =
      this.props.engagementDetail.new || parsedQS.id == "new"
        ? true
        : this.props.engagementDetail.details?.Status ===
            ENGAGEMENT_STATUS.CLOSED ||
          this.props.engagementDetail.details?.Status ===
            ENGAGEMENT_STATUS.COMPLETED ||
          this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME ||
          this.state.currentStatus.DISPLAY_NAME ===
            MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME
        ? false
        : true;
    const enableEndDate =
      this.props.engagementDetail.new || parsedQS.id == "new"
        ? true
        : this.props.engagementDetail.details?.Status ===
            ENGAGEMENT_STATUS.DOWNLOADED_ARM ||
          this.props.engagementDetail.details?.Status ===
            ENGAGEMENT_STATUS.DOWNLOADED_TC ||
          this.props.engagementDetail.details?.Status ===
            ENGAGEMENT_STATUS.CLOSED ||
          this.props.engagementDetail.details?.Status ===
            ENGAGEMENT_STATUS.COMPLETED
        ? false
        : true;
    const irdDisable = !this.state.currentStatus
      ? false
      : this.state.scopes.edit
      ? this.state.appRole.isAdmin
        ? [
            MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME,
            MAPPED_ENGAGEMENT_STATUS.ERROR_FOUND.DISPLAY_NAME,
            MAPPED_ENGAGEMENT_STATUS.CLOSED.DISPLAY_NAME,
          ].indexOf(this.state.currentStatus.DISPLAY_NAME) > -1
          ? true
          : this.props.engagementDetail.details?.DeliveryLead +
              "@microsoft.com" ===
              this.props.user.userName || this.state.appRole.isAdmin
          ? false
          : true
        : this.state.appRole.isEngagementMember ||
          this.state.appRole.isDeliveryTeamMember
        ? [
            MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME,
            MAPPED_ENGAGEMENT_STATUS.ERROR_FOUND.DISPLAY_NAME,
            MAPPED_ENGAGEMENT_STATUS.CLOSED.DISPLAY_NAME,
          ].indexOf(this.state.currentStatus.DISPLAY_NAME) > -1
          ? true
          : MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME ===
            this.state.currentStatus.DISPLAY_NAME
          ? this.state.engagementLevelScope.deliveryLead
            ? false
            : this.state.engagementLevelScope.deliveryTeam
            ? true
            : false
          : false
        : true
      : true;

    const normalDisable =
      parsedQS.id == "new" || !this.state.currentStatus
        ? false
        : this.state.scopes.edit
        ? [
            MAPPED_ENGAGEMENT_STATUS.ERROR_FOUND.DISPLAY_NAME,
            MAPPED_ENGAGEMENT_STATUS.CLOSED.DISPLAY_NAME,
            MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME,
          ].indexOf(this.state.currentStatus.DISPLAY_NAME) > -1
          ? true
          : this.props.engagementDetail.details?.DeliveryLead +
              "@microsoft.com" ===
              this.props.user.userName || this.state.appRole.isAdmin
          ? false
          : true
        : true;

    const helpTextSS = window.sessionStorage.getItem(ShowHelpText);
    const appScopesShow = this.props.engagementDetail.new
      ? this.props.appScopes.Engagements.Create
      : this.props.appScopes.Engagements.Edit;
    var helpTextShow = true;
    if (!!helpTextSS) {
      if (helpTextSS == "true") {
        helpTextShow = true && appScopesShow;
      } else helpTextShow = false && appScopesShow;
    }

    const statusDisable =
      !this.state.scopes.edit ||
      this.state.currentStatus?.DISPLAY_NAME ===
        MAPPED_ENGAGEMENT_STATUS.CLOSED.DISPLAY_NAME;

    this.setState({
      accessibilityScopes: {
        irdDisable: irdDisable,
        normalDisable: normalDisable,
        disableStatus: statusDisable,
        editCommentDisable: true,
      },
      viewHelp: helpTextShow,
      enableDeliveryLead: !enableDeliveryLead,
      enableDeliveryTeam: !enableDeliveryTeam,
      enableOfferType: !enableOfferType,
      enableCustomerName: !enableCustomerName,
      enableCustomerSubscription: !enableCustomerSubscription,
      enableCustomerOnboardingType: !enableCustomerOnboardingType,
      enableDeliveryOption: !enableDeliveryOption,
      enableGeography: !enableGeography,
      enableRegion: !enableRegion,
      enableInternalDescription: !enableInternalDescription,
      enableTestToggle: !enableTestToggle,
      enableSubscriptionAccessRemovalToggle: !enableSubscriptionAccessRemovalToggle,
      enableSupportOnlyToggle: !enableSupportOnlyToggle,
      enableRossMilestone: !enableRossMilestone,
      enableSave: !enableSave,
      enableStartDate: !enableStartDate,
      enableEndDate: !enableEndDate,
    });
  };
  handleStartDateSelection = (
    selectedDate: any,
    validateForm: boolean = false
  ) => {
    const selectedValue =
      typeof selectedDate === "object" &&
      selectedDate !== null &&
      "setHours" in selectedDate
        ? selectedDate.setHours(0, 0, 0, 0)
        : undefined;
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const isValidStartDate =
      selectedDate != null && selectedValue >= currentDate
        ? true
        : this.props.engagementDetail.details?.Status ===
          ENGAGEMENT_STATUS.DRAFT
        ? true
        : false;
    const isInvalid = selectedDate === null ? true : false;
    if (isInvalid && validateForm) {
      this.setState({
        startDate: { errorMessage: "Start Date is Required" },
      });
      return false;
    }
    if (isValidStartDate) {
      this.setState({
        startDate: { value: selectedDate, errorMessage: "" },
        accessibilityScopes: {
          ...this.state.accessibilityScopes,
          editCommentDisable: false,
        },
      });
      return true;
    } else {
      this.setState({
        startDate: {
          value: selectedDate,
          errorMessage: "Start Date should be greater than or equal to today",
        },
      });
      return false;
    }
  };
  isValidDate = (dateObject: any) => {
    return new Date(dateObject.value).toString() !== "Invalid Date";
  };
  handleEndDateSelection = (
    selectedDate: any,
    validateForm: boolean = false
  ) => {
    const selectedValue =
      typeof selectedDate === "object" &&
      selectedDate !== null &&
      "setHours" in selectedDate
        ? selectedDate.setHours(0, 0, 0, 0)
        : undefined;
    const startDate = this.state.startDate
      ? this.state.startDate.value
      : undefined;
    const isValidEndDate =
      startDate !== undefined && selectedValue != undefined
        ? selectedValue >= startDate?.setHours(0, 0, 0, 0)
        : false;
    const isInvalid = selectedDate === null ? true : false;
    if (isInvalid && validateForm) {
      this.setState({
        endDate: { errorMessage: "End Date is Required" },
      });
      return false;
    }
    if (isValidEndDate) {
      this.setState({
        endDate: { value: selectedDate, errorMessage: "" },
        accessibilityScopes: {
          ...this.state.accessibilityScopes,
          editCommentDisable: false,
        },
        endDateChanged:
          this.props.engagementDetail.details?.EndDate != selectedDate
            ? true
            : false,
      });
      return true;
    } else {
      this.setState({
        endDate: {
          value: selectedDate,
          errorMessage:
            "End Date should be greater than or equal to Start Date",
        },
      });
      return false;
    }
  };
  OnSupportOnlyToggleChange = (ev: any, checked?: boolean) => {
    let isChecked = checked === true ? true : false;
    const [dropDownValues, modifiedOffersDropDown] = buildOffertype(
      this.props.metaData.Offers.values ?? [],
      this.props.metaData.PodAuthorizationRole.values ?? [],
      this.props.metaData.OfferState.values ?? [],
      this.props.metaData.Team.values ?? [],
      !!this.props.engagementDetail.new,
      this.props.userContext?.data?.flights ?? [],
      isChecked
    );
    this.setState(
      {
        dropDownsData: {
          ...this.state.dropDownsData,
          OfferType: dropDownValues,
        },
        modifiedOffersDropDown: modifiedOffersDropDown,
        isSupportOnly: isChecked,
      },
      () => this.onOfferTypeChange(undefined, false)
    );
  };
  formatDate = (date?: Date): string => {
    if (!date) return "";
    const month = date.toLocaleString("en-US", { month: "short" }); // + 1 because 0 indicates the first Month of the Year.
    const day = date.getDate();
    const year = date.getFullYear();
    return ` ${month} ${day} ${year}`;
  };
  render() {
    console.log(this.state.splitEngagementWindow, "splitEngagementWindow");
    if (this.state.showcancelComponent) {
      return (
        <div className="successBody">
          <div
            style={{
              height: "calc(100vh - 480px)",
              textAlign: "center",
              marginTop: "200px",
            }}
          >
            <div className="successMessageBody">
              <div className="successMessage">You may Close the window.</div>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.showSuccessComponent) {
      return (
        <div className="successBody">
          <div
            style={{
              height: "calc(100vh - 480px)",
              textAlign: "center",
              marginTop: "200px",
            }}
          >
            <div className="successMessageBody">
              <div className="successMessage">
                <Icon iconName="SkypeCircleCheck"></Icon>Thanks!
              </div>
              <div>
                Submission received for {this.state.customerName.value} . CAPE
                is now processing your Engagement.
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <Panel
          type={
            this.state.splitEngagementWindow
              ? PanelType.customNear
              : PanelType.custom
          }
          className={
            isIframe && window.top != window.self
              ? "padTop70px engagement-detail-panel"
              : "padTop50px engagement-detail-panel"
          }
          onRenderFooterContent={this.onRenderFooterContent}
          onRenderNavigationContent={this.onRenderHeader}
          customWidth={this.state.splitEngagementWindow ? "73.5%" : "100%"}
          hasCloseButton={false}
          isOpen={this.props.engagementDetail.show}
          onDismiss={() => this.closeEngagement()}
          closeButtonAriaLabel="Close"
          isBlocking={false}
          onOuterClick={() => {}}
        >
          {this.state.showSupportOnlyContractModal && (
            <Dialog
              hidden={false}
              dialogContentProps={dialogContentSupportContractProps}
            >
              <DialogFooter>
                <PrimaryButton
                  aria-label="Yes"
                  role="Button"
                  onClick={() => {
                    this.setState(
                      {
                        showSupportOnlyContractModal: false,
                      },
                      () => this.upsertEngagement()
                    );
                  }}
                  text="Yes"
                />
                <DefaultButton
                  aria-label="No"
                  role="Button"
                  onClick={() => this.closeSupportContractModal()}
                  text="No"
                />
              </DialogFooter>
            </Dialog>
          )}
          {this.state.showEditLiveEngagementWarning && (
            <Dialog hidden={false} dialogContentProps={dialogContentProps}>
              <DialogFooter>
                <PrimaryButton
                  aria-label="Continue"
                  role="Button"
                  onClick={() => {
                    this.setState(
                      {
                        showEditLiveEngagementWarning: false,
                      },
                      () => this.upsertEngagement()
                    );
                  }}
                  text="Continue"
                />
                <DefaultButton
                  aria-label="Cancel"
                  role="Button"
                  onClick={() => this.closeModal()}
                  text="Cancel"
                />
              </DialogFooter>
            </Dialog>
          )}
          {this.state.showModalOnStatusChange && (
            <Dialog
              hidden={false}
              dialogContentProps={dialogContentStatusCompleteProps}
              // styles={{main: { maxWidth: 450 ,maxHeight: 407,minHeight: 100 }} }
            >
              <DialogFooter>
                <PrimaryButton
                  aria-label="Yes"
                  role="Button"
                  onClick={() => this.upsertEngagement()}
                  text="Yes"
                />
                <DefaultButton
                  aria-label="No"
                  role="Button"
                  onClick={() => this.closeCompleteStatusModal()}
                  text="No"
                />
              </DialogFooter>
            </Dialog>
          )}
          {!this.state.toHideParticipantModal && (
            <ParticipantsModal
              toHide={this.state.toHideParticipantModal}
              sortedParticipants={Object.values(
                this.props.participantsMeta
              ).sort(sortParticipantHelper)}
              aria-label="Participants Dialog"
              toHideDialog={(hide: boolean) => {
                this.setState({
                  toHideParticipantModal: hide,
                });
              }}
            />
          )}
          {!this.state.hideJsonDialog && (
            <MPJSONDialog
              toHide={this.state.hideJsonDialog}
              toHideDialog={(toHide: boolean) => {
                this.setState({ hideJsonDialog: toHide });
              }}
              title="Offer JSON"
              subText={this.state.jsonObject}
            />
          )}
          {/* <div>
                    <PersistentNotification getNotifications={this.props.getNotifications}/>
                </div> */}
          {this.props.userContext.data != null &&
            (this.props.engagementDetail.new ||
              this.props.engagementDetail.details?.Status ===
                ENGAGEMENT_STATUS.DRAFT) &&
            this.props.userContext.data.enableCreateEngagementRestriction ===
              true &&
            this.state.categoryCount >
              this.props.userContext.data.restrictLiveEngagementLimit && (
              <div>
                {
                  <MessageBar
                    messageBarType={MessageBarType.severeWarning}
                    className={"messageBar"}
                    isMultiline={true}
                    messageBarIconProps={{ iconName: "" }}
                  >
                    {`Warning: You will not be able to create a new Engagement as you have more than ${this.props.userContext.data?.restrictLiveEngagementLimit} 'Live' Engagements. Please change the status of previous 'Live' Engagements to 'Completed' before creating new Engagement.
                        Reach out to capehelp@microsoft.com if you need to be part of more than ${this.props.userContext.data?.restrictLiveEngagementLimit} Live engagements.`}
                  </MessageBar>
                }
              </div>
            )}

          <div className="panel-content">
            <div
              className={`engagement-form-content ${this.state.viewHelp}`}
              style={
                this.state.splitEngagementWindow ? { minWidth: "50vw" } : {}
              }
            >
              {!(
                this.state.accessibilityScopes.irdDisable &&
                this.state.accessibilityScopes.normalDisable &&
                this.state.accessibilityScopes.disableStatus
              ) && (
                <div
                  style={{ position: "relative" }}
                  onClick={this.toggleViewHelp}
                >
                  <Icon
                    title="Help"
                    style={{
                      position: "absolute",
                      top: "8px",
                      right: "5px",
                      cursor: "pointer",
                    }}
                    iconName="Help"
                  ></Icon>
                </div>
              )}

              {!this.props.engagementDetail.new && !this.isDraftState() && (
                <div className={`state-content ${this.state.viewHelp}`}>
                  <div className="state-color-label"></div>
                  <div className="state-label-value">
                    <div className="state-label" style={{ marginLeft: "58px" }}>
                      State
                    </div>
                    <div>
                      <CustomComboBox
                        placeholder="Select a state"
                        options={this.state.dropDownsData.EngagementStatus}
                        required={this.state.status.required}
                        errorMessage={this.state.status.errorMessage}
                        selectedKey={this.state.status.value?.key}
                        updateCDD={this.onStatusChange}
                        isFormValid={this.state.isFormValid}
                        disabled={
                          this.state.accessibilityScopes.disableStatus &&
                          !this.state.appRole.isAdmin &&
                          !this.props.engagementDetail.details?.DeliveryLead +
                            "@microsoft.com" ===
                            this.props.user.userName
                        }
                      />
                    </div>
                  </div>
                  {MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.IDENTIFIER !=
                    this.state.currentStatus?.IDENTIFIER &&
                    this.state.appRole.isAdmin && (
                      <>
                        <TooltipHost
                          directionalHint={DirectionalHint.bottomCenter}
                          tooltipProps={{
                            onRenderContent: () => (
                              <div>View in Partner Center</div>
                            ),
                          }}
                          calloutProps={ttcalloutProps}
                          closeDelay={200}
                          styles={ttstyles}
                        >
                          <div>
                            {this.state.selectedCloudType?.text !=
                              azureARMTemplate && (
                              <Link
                                href={this.state.partnerCenterUrl ?? ""}
                                underline
                                target="_blank"
                                style={{ fontWeight: 400, paddingLeft: "10px" }}
                                disabled={
                                  this.state.partnerCenterUrl ? false : true
                                }
                              >
                                View in Partner Center
                              </Link>
                            )}
                          </div>
                        </TooltipHost>
                      </>
                    )}
                </div>
              )}

              {this.state.status.value?.key ===
                MAPPED_ENGAGEMENT_STATUS.CLOSED.DISPLAY_NAME && (
                <div className="state-label-value">
                  <div className="state-label" style={{ marginLeft: "42px" }}>
                    <TooltipHost
                      directionalHint={DirectionalHint.bottomCenter}
                      tooltipProps={{
                        onRenderContent: () => (
                          <div>{"Reason to close Engagement"}</div>
                        ),
                      }}
                      calloutProps={ttcalloutProps}
                      closeDelay={200}
                      styles={ttstyles}
                    >
                      Reason <span className="asterisk">*</span>
                    </TooltipHost>
                  </div>
                  <div>
                    <CustomComboBox
                      placeholder="Select a Reason"
                      options={this.state.dropDownsData.EngagementSubStatus}
                      required={this.state.subStatus.required}
                      errorMessage={this.state.subStatus.errorMessage}
                      selectedKey={this.state.subStatus.value?.key}
                      updateCDD={this.onSubStatusChange}
                      isFormValid={this.state.isFormValid}
                      disabled={this.state.accessibilityScopes.disableStatus}
                    />
                  </div>
                </div>
              )}

              <div
                className="engagement-form-main-content"
                role="main"
                arial-label="Engagement Details"
              >
                <div className="engagement-detail-header">
                  <span
                    role="heading"
                    aria-level={2}
                    className="engagement-content-header"
                  >
                    {this.props.engagementDetail.new
                      ? "New Engagement Request Form"
                      : "Engagement Details"}
                  </span>
                  <div>
                  <TooltipHost
                    directionalHint={DirectionalHint.bottomCenter}
                    tooltipProps={testToggletooltipProps}
                    calloutProps={ttcalloutProps}
                    closeDelay={200}
                    
        styles={{ root: { pointerEvents: 'auto' } }}
        hidden={!this.state.showTooltip}
                                   >
                    <div
                      className="test-engagement"
                      style={{ paddingTop: "15px" }}
                    >
                      <label className="ms-Label">Test Engagement</label>

                      <Toggle
                        ariaLabel="Test Engagement"
                        //aria-describedby={tooltipId}
                        checked={this.state.isTest}
                        onText="Yes"
                        offText="No"
                        style={{ fontWeight: 100 }}
                        onChange={(ev: any, checked?: boolean) => {
                          this.setState({ isTest: !!checked });
                        }}
                        onMouseEnter={() => this.setState({showTooltip:true})}
        onMouseLeave={() => this.setState({showTooltip:false})}
                        disabled={this.state.enableTestToggle}
                      />
                    </div>
                  </TooltipHost>
                  </div>
                </div>
                <div>
                  <div className="engagement-content-sub">
                    <CustomComboBox
                      placeholder="Choose a delivery option"
                      label="Delivery Option"
                      options={this.state.dropDownsData.DeliveryOption}
                      required={this.state.deliveryOption.required}
                      errorMessage={this.state.deliveryOption.errorMessage}
                      selectedKey={this.state.deliveryOption.value?.key}
                      updateCDD={this.onDeliveryOptionChange}
                      isFormValid={this.state.isFormValid}
                      disabled={this.state.enableDeliveryOption}
                    />
                  </div>
                  
                  {this.state.deliveryOption.value?.key == Others && (
                    <div
                      className="engagement-content-sub"
                      style={{ marginBottom: "0" }}
                    >
                      <CustomTextField
                        label="Delivery Option (Other)"
                        required={this.state.deliveryOptionOther.required}
                        value={this.state.deliveryOptionOther.value}
                        errorMessage={
                          this.state.deliveryOptionOther.errorMessage
                        }
                        placeholder="Enter the other Delivery Option"
                        onUpdateCTF={this.onDeliveryOptionOtherChange}
                        letterLimit={100}
                        isFormValid={this.state.isFormValid}
                        disabled={this.state.enableDeliveryOption}
                      />
                    </div>
                  )}
                  <div className="engagement-content-sub">
                    <PeoplePicker
                      accessToken={this.props.graphTokenObj}
                      itemLimit={1}
                      onChange={this.onDeliveryLeadChange}
                      defaultValues={this.state.deliveryLead.value}
                      disabled={this.state.enableDeliveryLead}
                      placeHolder="Choose a delivery lead"
                      allowAtMe={false}
                      title="Delivery Lead"
                      aria-label="Delivery Lead"
                      errorMessage={this.state.deliveryLead.errorMessage}
                      required={this.state.deliveryLead.required}
                    />
                  </div>
                  <div
                    className={`engagement-content-sub delivery-team ${this.state.viewHelp}`}
                  >
                    <div style={{ display: "flex" }}>
                      <PeoplePicker
                        accessToken={this.props.graphTokenObj}
                        onChange={this.onDeliveryTeamChange}
                        defaultValues={
                          this.state.isNewDeliveryLeadAdded
                            ? this.state.deliveryNewTeamMember.value
                            : this.state.deliveryTeam.value
                        }
                        placeHolder="Choose delivery team"
                        allowAtMe={false}
                        title="Delivery Team"
                        aria-label="Delivery Team"
                        required={this.state.deliveryTeam.required}
                        errorMessage={this.state.deliveryTeam.errorMessage}
                        disabled={this.state.enableDeliveryTeam}
                      />
                      {this.state.deliveryTeam.value &&
                        !this.props.engagementDetail.new && (
                          <IconButton
                            onMenuClick={() =>
                              logEvent(TeamsMenuClicked, {
                                AppName: AppConstants.ApplicationAlias,
                                AppLayer: "UI",
                                metricName: "TeamsOperation",
                                UserScenario: "User Clicks on Teams Menu",
                                User: {
                                  Alias: this.props.userContext.data?.email,
                                },
                                applicationRoles:
                                  this.props.userContext.data?.roles,
                                entityOperation: "Menu Click",
                                entityName: "",
                                metaData: {
                                  EntityId:
                                    this.props.engagementDetail.details
                                      ?.EngagementId,
                                  NoOfParticipants:
                                    this.state.deliveryTeam.value?.length,
                                  sourcePage: "Engagement Detail",
                                },
                                traceSeverity: "Medium",
                              })
                            }
                            menuProps={
                              this.state.deliveryTeam.value?.length > 1
                                ? this.getTeamsGroupMenuProps(
                                    this.state.deliveryTeam.value,
                                    this.props.engagementDetail.details
                                      ?.EngagementId
                                  )
                                : this.getTeamsMenuProps(
                                    this.state.deliveryTeam.value,
                                    this.props.engagementDetail.details
                                      ?.EngagementId
                                  )
                            }
                            iconProps={teamsIcon}
                            title="Teams Menu"
                            disabled={false}
                            checked={false}
                            style={{
                              paddingLeft: "10px",
                              fontSize: "20px",
                              color: "#464EB8",
                              alignSelf: "flex-end",
                            }}
                          ></IconButton>
                        )}
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", marginBottom: "7px" }}
                    className="engagement-content-sub"
                  >
                    <div className="clubbedfield">
                      <label className="ms-Label" style={{ fontWeight: "500" }}>
                        Customer has active Support Contract for this Engagement
                      </label>
                    </div>
                    <div
                      className="clubbedfield"
                      style={{ paddingLeft: "74px" }}
                    >
                      <Toggle
                        ariaLabel="Customer has active Support Contract for this Engagement"
                        checked={this.state.isSupportOnly}
                        onText="Yes"
                        offText="No"
                        style={{ fontWeight: 100 }}
                        onChange={this.OnSupportOnlyToggleChange}
                        disabled={this.state.enableOfferType}
                      />
                    </div>
                  </div>
                  <div className="engagement-content-sub">
                    <CustomComboBox
                      placeholder="Choose an offer based on your role"
                      label="Offer Type"
                      styles={{ optionsContainer: { maxHeight: 400 } }}
                      options={this.state.dropDownsData.OfferType}
                      required={this.state.offerType.required}
                      errorMessage={this.state.offerType.errorMessage}
                      selectedKey={this.state.offerType.value?.key}
                      updateCDD={this.onOfferTypeChange}
                      isFormValid={this.state.isFormValid}
                      disabled={this.state.enableOfferType}
                    />
                  </div>
                  <div
                    className="engagement-content-sub"
                    style={{ marginBottom: "20px" }}
                  >
                    <CustomComboBox
                      placeholder="Choose ROSS/Milestone"
                      label="ROSS/Milestone ID"
                      styles={{ optionsContainer: { maxHeight: 400 } }}
                      options={this.state.dropDownsData.EntityType}
                      required={this.state.entityType.required}
                      errorMessage={this.state.entityType.errorMessage}
                      selectedKey={this.state.entityType.value?.key}
                      updateCDD={this.onEntityTypeChange}
                      isFormValid={this.state.isFormValid}
                      disabled={this.state.enableRossMilestone}
                    />
                  </div>
                  {(this.state.entityType.value?.text ===
                    ENTITY_TYPE.MILESTONE ||
                    this.state.entityType.value?.text === ENTITY_TYPE.ROSS) && (
                    <div className="engagement-content-sub">
                      <div className={`ross-milestone ${this.state.viewHelp}`}>
                        <div style={{ marginRight: "20px" }}>
                          <CustomTextField
                            label={`${this.state.titletoBeShown} ID`}
                            placeholder={`Enter ${this.state.titletoBeShown} ID`}
                            value={this.state.rossMileStoneID.value}
                            onUpdateCTF={this.onRossMileStoneChange}
                            required={this.state.rossMileStoneID.required}
                            errorMessage={
                              this.state.rossMileStoneID.errorMessage
                            }
                            donotShowLetters={true}
                            isCopyAllowed={true}
                            disabled={this.state.enableRossMilestone}
                          />
                          <div
                            className={
                              this.state.showValidIDNotification &&
                              this.state.rossMileStoneID.value !== ""
                                ? "valid-ID"
                                : "invalid-ID"
                            }
                          >
                            {this.state.showValidIDMessage}
                          </div>
                        </div>
                        {this.state.entityType.value?.text ===
                          ENTITY_TYPE.MILESTONE && (
                          <div>
                            <CustomTextField
                              label={`${this.state.titletoBeShown} Title`}
                              value={this.state.rossMilestoneTitle.value}
                              onUpdateCTF={this.onMileStoneChange}
                              isCopyAllowed={true}
                              donotShowLetters={true}
                              disabled={true}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{ display: "flex", marginBottom: "22px" }}
                  className="engagement-content-sub"
                >
                  <div className="clubbedField">
                    <DatePicker
                      isRequired
                      label="Start Date"
                      formatDate={this.formatDate}
                      value={this.state.startDate.value}
                      ariaLabel="Select Start date"
                      className={styles.control}
                      onSelectDate={this.handleStartDateSelection}
                      onChange={this.OnStartDateChange}
                      textField={{
                        errorMessage: this.state.startDate.errorMessage,
                      }}
                      disabled={this.state.enableStartDate}
                    />
                  </div>
                  <div className="clubbedField">
                    <DatePicker
                      isRequired
                      label="End Date"
                      formatDate={this.formatDate}
                      value={this.state.endDate.value}
                      ariaLabel="Select End date"
                      className={styles.control}
                      onSelectDate={this.handleEndDateSelection}
                      textField={{
                        errorMessage: this.state.endDate.errorMessage,
                      }}
                      disabled={this.state.enableEndDate}
                    />
                  </div>
                </div>
                <div className="trapZone">
                  <div className="engagement-content-header">
                    Customer Information
                  </div>
                  <div
                    className="engagement-content-sub"
                    style={{ marginBottom: "0" }}
                  >
                    <CustomTextField
                      label="Customer Name"
                      letterLimit={50}
                      required={this.state.customerName.required}
                      value={this.state.customerName.value}
                      errorMessage={this.state.customerName.errorMessage}
                      placeholder="Enter Customer name"
                      onUpdateCTF={this.onCustomerNameChange}
                      isCopyAllowed={true}
                      isFormValid={this.state.isFormValid}
                      disabled={this.state.enableCustomerName}
                    />
                  </div>
                  <div
                    className="engagement-content-sub"
                    style={{ marginBottom: "0" }}
                  >
                    <label style={{ fontWeight: "500" }}>
                      Subscription ID's <span className="asterisk">*</span>
                    </label>
                  </div>

                  {this.state.engagementSubscriptions.map(
                    (element: IEngagementSubscriptions, index: number) => (
                      <div
                        style={{ marginBottom: "0" }}
                        className="ms-grid name-value-row"
                        key={index}
                      >
                        <div
                          className="ms-grid name-value-row"
                          style={{ display: "flex" }}
                        >
                          <div className="ms-Grid-col ms-lg11 subscriptionIdField">
                            <CustomTextField
                              required={false}
                              key={index}
                              value={element.SubscriptionId}
                              errorMessage={
                                this.state.customerSubscriptionIds.errorMessage
                              }
                              onUpdateCTF={this.onCustomerSubscriptionChange}
                              isCopyAllowed={true}
                              donotShowLetters={true}
                              isFormValid={this.state.isFormValid}
                              disabled={true}
                            />
                          </div>
                          <div className="ms-Grid-col ms-lg1 subscriptionIdField">
                            <IconButton
                              iconProps={{ iconName: "Delete" }}
                              title="Delete Subscription ID"
                              ariaLabel="Delete"
                              onClick={() =>
                                this.deleteSubscriptionIDField(
                                  element.SubscriptionId
                                )
                              }
                              style={{
                                marginLeft: "10px",
                                fontSize: "20px",
                                color: "red",
                                alignSelf: "flex-end",
                                background: "#efefef",
                                padding: "10px 18px 10px 18px",
                              }}
                              disabled={
                                this.state.enableCustomerSubscription
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )
                  )}

                  <div
                    style={{
                      marginBottom: "0",
                      display: !this.state.enableCustomerSubscription
                        ? "block"
                        : "none",
                    }}
                    className="ms-grid name-value-row"
                  >
                    <div
                      className="ms-grid name-value-row"
                      style={{ display: "flex" }}
                    >
                      <div className="ms-Grid-col ms-lg11 subscriptionIdField">
                        <CustomTextField
                          required={
                            this.state.customerSubscriptionIds.value !=
                              undefined &&
                            this.state.customerSubscriptionIds.value?.length > 0
                              ? true
                              : this.state.engagementSubscriptions.length == 0
                              ? true
                              : false
                          }
                          value={this.state.customerSubscriptionIds.value}
                          errorMessage={
                            this.state.customerSubscriptionIds.errorMessage
                          }
                          placeholder={
                            this.state.engagementSubscriptions?.length == 0
                              ? "Enter first Subscription ID"
                              : "Enter additional Subscription ID"
                          }
                          onUpdateCTF={this.onCustomerSubscriptionChange}
                          donotShowLetters={true}
                          isCopyAllowed={true}
                          isFormValid={this.state.isFormValid}
                          disabled={
                            this.state.accessibilityScopes.normalDisable
                          }
                        />
                      </div>
                      <div className="ms-Grid-col ms-lg1 subscriptionIdField">
                        <IconButton
                          iconProps={{ iconName: "Add" }}
                          title="Add more Subscription ID"
                          ariaLabel="Add"
                          onClick={() => this.addSubscriptionIDField()}
                          style={{
                            marginLeft: "10px",
                            fontSize: "20px",
                            color: "green",
                            alignSelf: "flex-end",
                            background: "#efefef",
                            padding: "10px 18px 10px 18px",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", marginBottom: "0" }}
                    className="engagement-content-sub"
                  >
                    <div className="clubbedField">
                      <CustomComboBox
                        placeholder="Select a geography option"
                        label="Geography"
                        styles={{ optionsContainer: { maxHeight: 400 } }}
                        options={this.state.dropDownsData.GeographyOption}
                        required={this.state.geographyOption.required}
                        errorMessage={this.state.geographyOption.errorMessage}
                        selectedKey={this.state.geographyOption.value?.key}
                        updateCDD={this.onGeographyChange}
                        isFormValid={this.state.isFormValid}
                        disabled={this.state.enableGeography}
                      />
                    </div>

                    <div className="clubbedField">
                      <CustomComboBox
                        placeholder="Select a region option"
                        label="Region"
                        styles={{ optionsContainer: { maxHeight: 400 } }}
                        options={this.state.regionDropdownOption}
                        required={this.state.regionOption.required}
                        errorMessage={this.state.regionOption.errorMessage}
                        selectedKey={this.state.regionOption.value?.key}
                        updateCDD={this.onRegionChange}
                        isFormValid={this.state.isFormValid}
                        disabled={this.state.enableRegion}
                      />
                    </div>
                  </div>
                  <div
                    className="engagement-content-sub"
                    style={{ marginBottom: "0" }}
                  >
                    <label style={{ fontWeight: "500" }}>
                      Customer Onboarding Type{" "}
                      <span className="asterisk">*</span>
                    </label>
                  </div>
                  <div className="engagement-content-sub">
                    <ChoiceGroup
                      options={this.state.cloudType}
                      required={true}
                      defaultSelectedKey={
                        this.state.selectedCloudType
                          ? this.state.selectedCloudType.key
                          : this.state.cloudType[0].key
                      }
                      onChange={this.onCloudTypeChange}
                      disabled={this.state.enableCustomerOnboardingType}
                    />
                    {/* {!this.props.userContext.data?.flights?.includes(
                      AppConstants.NonPublicCloudFlight
                    ) && (
                      <div style={{ paddingLeft: "202px" }}>
                        {" "}
                        Now available in Beta!{" "}
                        <a
                          onClick={() => this.onBetaEnrolmentClick()}
                          href="https://idwebelements.microsoft.com/GroupManagement.aspx?Group=CAPENPCBeta&Operation=join"
                          target="_blank"
                        >
                          Click here
                        </a>{" "}
                        to signup{" "}
                      </div>
                    )} */}
                    <div style={{ paddingLeft: "202px" }}>
                      {" "}
                      Currently unavailable
                    </div>
                  </div>

                  <div>
                    <div
                      className="engagement-content-sub"
                      style={{ marginBottom: "0" }}
                    >
                      <CustomTextField
                        label="Internal Description"
                        rows={4}
                        placeholder="Enter internal description"
                        letterLimit={255}
                        value={this.state.internalDescription.value}
                        isCopyAllowed={true}
                        onUpdateCTF={this.updateInternalDesc}
                        multiline={true}
                        disabled={this.state.enableInternalDescription}
                      />
                    </div>
                  </div>

                  {(this.state.subscriptionIDChanged ||
                    !this.state.accessibilityScopes.editCommentDisable) &&
                    !this.props.engagementDetail.new &&
                    this.state.currentStatus.DISPLAY_NAME !==
                      MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME &&
                    this.props.engagementDetail.details?.Status !=
                      ENGAGEMENT_STATUS.DOWNLOADED_ARM &&
                    this.props.engagementDetail.details?.Status !=
                      ENGAGEMENT_STATUS.DOWNLOADED_TC &&
                      this.state.currentStatus.DISPLAY_NAME !==
                      MAPPED_ENGAGEMENT_STATUS.COMPLETED.DISPLAY_NAME &&
                      this.state.currentStatus.DISPLAY_NAME !==
                      MAPPED_ENGAGEMENT_STATUS.CLOSED.DISPLAY_NAME && (
                      <div>
                        <div
                          className="engagement-content-sub"
                          style={{ marginBottom: "0" }}
                        >
                          <CustomTextField
                            label="Comments"
                            rows={4}
                            placeholder={
                              "Enter reason for modifying Engagement"
                            }
                            letterLimit={255}
                            isCopyAllowed={true}
                            value={this.state.editComment.value}
                            onUpdateCTF={this.updateEditComment}
                            multiline={true}
                            errorMessage={this.state.editComment.errorMessage}
                            required={true}
                          />
                        </div>
                      </div>
                    )}
                  {!this.props.engagementDetail.new &&
                    this.state.currentStatus.DISPLAY_NAME !==
                      MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME &&
                    this.props.engagementDetail.details?.Status !=
                      ENGAGEMENT_STATUS.DOWNLOADED_ARM &&
                    this.props.engagementDetail.details?.Status !=
                      ENGAGEMENT_STATUS.DOWNLOADED_TC && this.state.status.value?.key ===
                    MAPPED_ENGAGEMENT_STATUS.COMPLETED.DISPLAY_NAME && (
                    <div>
                      <div
                          className="engagement-content-sub"
                          style={{ paddingTop: "15px",marginBottom: "0" }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <label
                           style={{ marginRight: "10px",fontWeight: "500"}} 
                          >
                            Do you confirm your access to customer's
                            subscription(s) on Azure environment is removed?{" "}                            
                            <span className="asterisk">*</span>{" "}
                            <Link
                              href="https://ms.portal.azure.com/#view/Microsoft_Azure_CustomerHub/MyCustomersBladeV2/~/scopeManagement"
                              target="_blank"
                              className="ms-Link root-344"
                            >
                              Check your Azure Portal
                            </Link>{" "}
                          </label>
                          {console.log("subsremo",this.props.engagementDetail.details?.IsSubscriptionRemoved,this.state.isSubscriptionRemoved)}
                          <Toggle
                            ariaLabel="Confirm access on customer's subscription(s)"
                            aria-required={true}
                            checked={this.state.isSubscriptionRemoved}
                            onText="Yes"
                            offText="No"
                            style={{ fontWeight: 100 }}
                            onChange={(ev: any, checked?: boolean) => {
                              this.setState({
                                isSubscriptionRemoved: !!checked,
                              });
                            }}
                            disabled={this.state.enableSubscriptionAccessRemovalToggle}
                          />
                        </div>
                      </div>
                      {!this.state.isSubscriptionRemoved && (
                        <div style={{ color: "rgb(164, 38, 44)",fontSize: "12px",fontWeight: "400" }}>
                          Confirm access is revoked
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {!this.props.engagementDetail.new &&
                  this.state.engProgramName.value === "UAT" && (
                    <div className="programAction">
                      <label>Related Tasks</label>
                      <div>
                        <label>Action</label>{" "}
                        <a
                          href={
                            AppConstants.APP_UAT_URL +
                            "action?id=" +
                            this.props.engagementDetail.details?.ENG_ProgramId
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {this.props.engagementDetail.details?.ENG_ProgramId}
                        </a>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className={`view-help-container ${this.state.viewHelp}`}>
              {this.renderHelpContent()}
            </div>
          </div>
        </Panel>
      );
    }
  }
}

const mapStateToProps = (state: { ui: any; auth: any; api: any }) => ({
  engagementDetail: state.ui.engagementDetail,
  graphTokenObj: state.auth.accessToken,
  user: state.auth.user,
  upsertEngagementState: state.api.upsertEngagement,
  graphState: state.api.graphState,
  apiAccessToken: state.auth.apiAccessToken,
  coreApiAccessToken: state.auth.coreApiAccessToken,
  participantsMeta: state.api.participantsMeta.data,
  engagementCategories: state.api.engagementCategories,
});

const mapDispatchToProps = (dispatch: any) => ({
  setBanner: (payload: IBannerMessagePayload) => dispatch(setBanner(payload)),
  showRatingInCenter: (payload: IRatingPayload) =>
    dispatch(showRatingInCenter(payload)),
  upsertEngagement: (payload: any) => dispatch(upsertEngagement(payload)),
  createOfferInPC: (payload: any) => dispatch(createOfferInPC(payload)),
  updateEngagementsTable: (payload: any) =>
    dispatch(updateEngagements(payload)),
  updateGraphState: (payload: any) => dispatch(updateGraphState(payload)),
  showModal: (payload: any) => dispatch(showModal(payload)),
  getEngagementCategories: (payload: IGetEngagementCategoryPayload) =>
    dispatch(getEngagementsCategory(payload)),
});

export const iconStyle: CSSProperties = {
  padding: "1.5px 6px",
  cursor: "pointer",
  color: "white",
  width: 40,
  height: 30,
  lineHeight: "35px",
  fontSize: 18,
};

export default connect(mapStateToProps, mapDispatchToProps)(EngagementDetail);
