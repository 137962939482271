import React, { RefObject } from "react";
import { getBotResponse } from "../Services/gptApi";
import { generateId, getDateTime } from "../Utility/Utilities";
import styles from "../copilot.module.css";
import Footer from "./Footer/Footer";
import Thread from "./Thread/Thread";

interface IChatRootProps {
  appInsights: any;
  copilotContext: any;
  platformName: string;
  apiUrl: string;
  alias?: string;
  authToken: string;
  messages: any[];
  setMessages: Function;
  suggestions: any[];
  refreshChat: Function;
  handleBanner: Function;
  showBanner: boolean;
  onSendFloatingWindowMessage: string | null;
  setFloatingMessage: Function;
  isPinned: boolean;
}

interface IChatRootState {
  requestValue: string;
  isLoading: boolean;
}

class ChatRoot extends React.Component<IChatRootProps, IChatRootState> {
  private scrollableContainer: RefObject<any>;

  constructor(props: IChatRootProps) {
    super(props);
    this.state = {
      requestValue: "",
      isLoading: false,
    };
    this.scrollableContainer = React.createRef();
  }

  componentDidMount(): void {
    this.scrollToBottom(this.scrollableContainer);
    // set message to send from floating window
    if (this.props.onSendFloatingWindowMessage) {
      this.sendMessage(this.props.onSendFloatingWindowMessage);
      this.props.setFloatingMessage(null);
    }
  }

  componentDidUpdate(prevProps: IChatRootProps): void {
    const prevMessages = prevProps.messages || [];
    const currentMessages = this.props.messages || [];

    const prevShowBanner = prevProps.showBanner;
    const currentShowBanner = this.props.showBanner;

    // Scroll to bottom when the number of messages changes
    if (prevMessages.length !== currentMessages.length) {
      this.scrollToBottom(this.scrollableContainer);
    }

    if (prevShowBanner !== currentShowBanner) {
      this.scrollToTop(this.scrollableContainer);
    }
  }

  scrollToBottom = (componentRef: RefObject<any>) => {
    if (componentRef.current === null) return;
    componentRef.current.scrollTo({
      top: componentRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  scrollToTop = (componentRef: RefObject<any>) => {
    if (componentRef.current === null) return;
    componentRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  setTextFieldValue = (value: string) => {
    this.setState({
      requestValue: value,
    });
  };

  sendMessage = async (question: string) => {
    this.setState({ isLoading: true });
    const conversationArray: any[] = this.props.messages;
    let answer: string | null = null;

    const userMessage = {
      id: generateId(),
      from: "user",
      type: "message",
      message: question,
      dateTime: getDateTime(),
    };

    // Using the callback version of setMessages to overcome batching of state updates.
    //
    // What was happening was that all state updates (currently user prompt, bot response, and bot suggestion)
    // were getting batched into one update, and the scrollToBottom function inside of componentDidUpdate was
    // only running for the first state update.
    //
    // The call back version of setMessages lets scrollToBottom run for each state update.
    this.props.setMessages((messages: any) => [...messages, userMessage]);

    try {
      const httpResponse = await getBotResponse(
        this.props.apiUrl,
        this.props.authToken,
        question,
        this.props.platformName
      );
      const botResponseMessageId = generateId();

      const botResponse = {
        id: botResponseMessageId,
        from: "bot",
        type: "message",
        question,
        message: httpResponse.response,
        isFeedbackEnabled: true,
        isFeedbackUpvoted: null,
        dateTime: getDateTime(),
      };

      this.props.setMessages((messages: any) => [...messages, botResponse]);

      answer = httpResponse.response;

      //Briefly delay the follow up suggestions so that the user can briefly glance at the response
      await this.delay(1000);

      let suggestionsList = "\n";

      const randomSuggestions = this.props.suggestions
        .sort(() => Math.random() - 0.5) // Shuffle the array randomly
        .slice(0, 3); // Get the first 3 elements of the shuffled array

      randomSuggestions.forEach((suggestion: any) => {
        suggestionsList += `\n- "*${suggestion}*"`;
      });
      this.props.setMessages((messages: any) => [...messages]);

      this.trackFeedback(
        `${this.props.copilotContext.appInsightsEventNamePrefix}Inquiry`,
        this.props.copilotContext.currentUserName,
        this.props.copilotContext.currentUserId,
        botResponseMessageId,
        question,
        answer
      );
    } catch (error) {
      console.log(error);

      const botErrorMessage = {
        id: generateId(),
        from: "bot",
        type: "message",
        message:
          "Sorry the CloudGPT service is down right now. Please try again later.",
        dateTime: getDateTime(),
      };
      this.props.setMessages((messages: any) => [...messages, botErrorMessage]);
      this.props.appInsights.trackException({ error });
    }

    this.setState({ isLoading: false });
    this.scrollToBottom(this.scrollableContainer);
  };

  updateMessage = (id: string, messageProperties: any) => {
    let updatedMessage = null;
    this.props.setMessages((messages: any) => {
      return messages.map((message: any) => {
        if (message.id === id) {
          // Return a new object with the updated properties
          updatedMessage = { ...message, ...messageProperties };
          return updatedMessage;
        } else {
          // Return the original message object
          return message;
        }
      });
    });

    return updatedMessage;
  };

  clearMessage = () => {
    this.props.setMessages("");
  };

  delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  trackFeedback = (
    eventName: string | null,
    userName: string | null,
    userEmail: string | null,
    messageId: string | null,
    question: string | null,
    answer: string | null
  ): void => {
    if (!this.props.appInsights) return;

    

    this.props.appInsights.trackEvent({ name :eventName }     
    , {
      MessageId: messageId,
      UserName: userName,
      UserEmail: userEmail,
      Question: question,
      Answer: answer,
    });
  };

  render() {
    return (
      <div
        className={
          this.props.isPinned ? styles.chatPinnedBody : styles.chatBody
        }
      >
        <div style={{ overflowY: "auto" }} ref={this.scrollableContainer}>
          <Thread
            messages={this.props.messages}
            updateMessage={this.updateMessage}
            setTextFieldValue={this.setTextFieldValue}
            isSpinnerActive={this.state.isLoading}
            suggestions={this.props.suggestions}
            showBanner={this.props.showBanner}
            setBanner={this.props.handleBanner}
            apiUrl={this.props.apiUrl}
            authToken={this.props.authToken}
          />
        </div>
        <div className="no-cursor">
          <Footer
            isPinned={this.props.isPinned}
            textFieldValue={this.state.requestValue}
            sendMessage={this.sendMessage}
            setTextFieldValue={this.setTextFieldValue}
            setMessages={this.props.setMessages}
            disabled={this.state.isLoading}
            clearMessage={this.clearMessage}
            refreshChat={this.props.refreshChat}
          />
        </div>
      </div>
    );
  }
}

export default ChatRoot;
