import {IColumn} from '../UI/Generic';

export const MetaDataCategory = {
    EngagementsDropdowns: "EngagementsDropdowns",
    OffersDropDown: "OffersDropDown",
    OfferSDetails: "OfferSDetails"
}

export interface IMetaData {
    metaName : string;
    category : string;
    values : IColumn[] | null
}

export const MetaDataHelper : { [key : string] : IMetaData  }= {
    DeliveryOption: {
        metaName: "DeliveryOption",
        category: MetaDataCategory.EngagementsDropdowns,
        values: null
    },
    EntityType: {
        metaName: "EntityType",
        category: MetaDataCategory.EngagementsDropdowns,
        values: null
    },
    Offers: {
        metaName: "Offers",
        category: MetaDataCategory.OfferSDetails,
        values: null
    },
    CustomerOnboardingType: {
        metaName: "CustomerOnboardingType",
        category: MetaDataCategory.EngagementsDropdowns,
        values: null
    },
    Geography: {
        metaName: "Geography",
        category: MetaDataCategory.EngagementsDropdowns,
        values: null
    },
    Region: {
        metaName: "Region",
        category: MetaDataCategory.EngagementsDropdowns,
        values: null
    },
    PodAuthorizationRole: {
        metaName: "PodAuthorizationRole",
        category: MetaDataCategory.OffersDropDown,
        values: null
    },
    Team: {
        metaName: "Team",
        category: MetaDataCategory.OffersDropDown,
        values: null
    },
    OfferState: {
        metaName: "OfferState",
        category: MetaDataCategory.OffersDropDown,
        values: null
    }
}

export interface IParticipantMeta {
    userAlias: string;
    displayName: string
}