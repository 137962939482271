import { IAction } from '../../constants/types/IAction';
import {SAVE_RATING} from '../../constants/actions/API/Rating';
import { IRating } from '../../constants/types/API/Rating';


export function saveRating(payload : IRating): IAction {
    return {
        type: SAVE_RATING,
        payload : payload
    }
}
