export const SET_OFFER_DETAIL = "SET_OFFER_DETAIL";
export const GET_OFFERS = "GET_OFFERS";
export const GET_OFFER_BY_ID = "GET_OFFER_BY_ID";
export const GET_OFFER_BY_ID_FETCHING = "GET_OFFER_BY_ID_FETCHING";
export const GET_OFFER_BY_ID_FAILURE = "GET_OFFER_BY_ID_FAILURE";
export const GET_OFFER_BY_ID_SUCCESS = "GET_OFFER_BY_ID_SUCCESS";
export const UPDATE_OFFER = "UPDATE_OFFER";

export const GET_TERMS_AND_CONDITION = "GET_TERMS_AND_CONDITION";
export const GET_TERMS_AND_CONDITION_FETCHING = "GET_TERMS_AND_CONDITION_FETCHING";
export const GET_TERMS_AND_CONDITION_FAILURE = "GET_TERMS_AND_CONDITION_FAILURE";
export const GET_TERMS_AND_CONDITION_SUCCESS = "GET_TERMS_AND_CONDITION_SUCCESS";

export const SEND_EMAIL_TO_SELF = "SEND_EMAIL_TO_SELF";
export const SEND_EMAIL_TO_SELF_FETCHING = "SEND_EMAIL_TO_SELF_FETCHING";
export const SEND_EMAIL_TO_SELF_FAILURE = "SEND_EMAIL_TO_SELF_FAILURE";
export const SEND_EMAIL_TO_SELF_SUCCESS = "SEND_EMAIL_TO_SELF_SUCCESS";