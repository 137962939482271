import {
  CopilotChat,
  CopilotMessage,
  FeedbackButtons,
  UserMessage,
} from "@fluentai/react-copilot";
import { Checkbox, Stack, Text,IconButton } from "@fluentui/react";
import { Dialog, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { ArrowClockwise12Filled, Copy20Regular } from "@fluentui/react-icons";
import React, { useState } from "react";
import { CopilotContext } from "../../Services/CopilotContext";
import { TelemetryContext } from "../../Services/TelemetryContext";
import FeedbackCallout from "./FeedbackCallout";
import { useMessageStyles } from "./Message.Styles";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface FeedbackTelemetryProperties {
  MessageId: string;
  UserName: string;
  UserEmail: string;
  Question: string;
  Answer: string;
  IsAnswerIrrelevant?: string;
  IsAnswerInaccurate?: string;
  AdditionalFeedback?: string;
}

interface IMessage {
  id: string;
  from: string;
  message: string;
  question: string;
  isFeedbackEnabled: boolean;
  isFeedbackUpvoted: boolean | null;
  additionalUserFeedback: string;
  isAnswerIrrelevant: string | undefined;
  isAnswerInaccurate: string | undefined;
  isInitialMessage?: boolean;
}

type MessageProps = {
  message: IMessage;
  updateMessage: (messageId: string, message: Partial<IMessage>) => void;
};

const Message = ({ message, updateMessage }: MessageProps) => {
  const [isFeedbackCalloutVisible, setIsFeedbackCalloutVisible] =
    React.useState<boolean>(false);
  const styles = useMessageStyles();
  const appInsights = React.useContext(TelemetryContext);
  const copilotContext = React.useContext(CopilotContext);

  let positiveFeedbackIconName = 'Like';
  let negativeFeedbackIconName = 'Dislike';

  if (message.isFeedbackUpvoted) {
    positiveFeedbackIconName = 'LikeSolid';
  } else if (message.isFeedbackUpvoted === false) {
    negativeFeedbackIconName = 'DislikeSolid';
  }


  const onFeedbackPositiveClick = () => {
    if (message.isFeedbackUpvoted) {
      updateMessage(message.id, { isFeedbackUpvoted: null });
    } else {
      setIsFeedbackCalloutVisible(true);
      updateMessage(message.id, {
        isFeedbackUpvoted: true,
        additionalUserFeedback: "",
      });
    }
  };

  const onFeedbackNegativeClick = () => {
    if (message.isFeedbackUpvoted === false) {
      updateMessage(message.id, { isFeedbackUpvoted: null });
    } else {
      setIsFeedbackCalloutVisible(true);
      updateMessage(message.id, {
        isFeedbackUpvoted: false,
        additionalUserFeedback: "",
      });
    }
  };

  const onFeedbackCalloutDismiss = () => {
    setIsFeedbackCalloutVisible(false);

    if (message.isFeedbackUpvoted) {
      trackFeedback(
        `${copilotContext.appInsightsEventNamePrefix}Like`,
        {
          MessageId: message.id,
          UserName: copilotContext.currentUserName,
          UserEmail: copilotContext.currentUserId,
          Question: message.question,
          Answer: message.message,
        }
      );
    } else {
      trackFeedback(
        `${copilotContext.appInsightsEventNamePrefix}Dislike`,
        {
          MessageId: message.id,
          UserName: copilotContext.currentUserName,
          UserEmail: copilotContext.currentUserId,
          Question: message.question,
          Answer: message.message,
        }
      );
    }
  };

  const onFeedbackCalloutSend = () => {
    setIsFeedbackCalloutVisible(false);


    if (message.isFeedbackUpvoted) {
      trackFeedback(
        `${copilotContext.appInsightsEventNamePrefix}Like`,
        {
          MessageId: message.id,
          UserName: copilotContext.currentUserName,
          UserEmail: copilotContext.currentUserId,
          Question: message.question,
          Answer: message.message,
          AdditionalFeedback: message.additionalUserFeedback,
          IsAnswerIrrelevant: message.isAnswerIrrelevant,
          IsAnswerInaccurate: message.isAnswerInaccurate,
        }
      );
    } else {
      trackFeedback(
        `${copilotContext.appInsightsEventNamePrefix}Dislike`,
        {
          MessageId: message.id,
          UserName: copilotContext.currentUserName,
          UserEmail: copilotContext.currentUserId,
          Question: message.question,
          Answer: message.message,
          AdditionalFeedback: message.additionalUserFeedback,
          IsAnswerIrrelevant: message.isAnswerIrrelevant,
          IsAnswerInaccurate: message.isAnswerInaccurate,
        }
      );
    }
  };

  const trackFeedback = (
    eventName: string,
    properties: FeedbackTelemetryProperties
  ): void => {
    if (!appInsights) return;

    appInsights.trackEvent({
      name: eventName,
      properties,
    });
  };

  const validateNegativeInputFields = (): boolean => {
    // Negative feedback is valid if the text input is filled, or if either of the checkboxes are checked.
    if (
      message.additionalUserFeedback?.length > 0 ||
      message.isAnswerIrrelevant ||
      message.isAnswerInaccurate
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validatePositiveInputFields = (): boolean => {
    if (message.additionalUserFeedback?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleCalloutIrrelevantCheckboxChange = (newValue: string) => {
    updateMessage(message.id, { isAnswerIrrelevant: newValue });
  };

  const handleCalloutInaccurateCheckboxChange = (newValue: string) => {
    updateMessage(message.id, { isAnswerInaccurate: newValue });
  };

  const renderCalloutNegativeFeedbackAdditionalInputs = () => {
    return (
      <Stack tokens={{ childrenGap: 8 }} style={{ marginBottom: "16px" }}>
        <Checkbox
          label="The response is irrelevant."
          onChange={(_, checked) =>
            handleCalloutIrrelevantCheckboxChange(checked ? "true" : "false")
          }
        />
        <Checkbox
          label="The response is inaccurate."
          onChange={(_, checked) =>
            handleCalloutInaccurateCheckboxChange(checked ? "true" : "false")
          }
        />
      </Stack>
    );
  };

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [contentCopied, setContentCopied] = useState(false);

  const handleMouseClick = () => {
    if (isHovered) {
      setIsHovered(false);
      return;
    }
    setIsHovered(true);
  };

  const onClipboardCopy = () => {
    navigator.clipboard.writeText(message.message);
    onClipboardCopySuccess();
  };

  const onClipboardCopySuccess = () => {
    setContentCopied(true);
    setTimeout(() => {
      setIsHovered(false);
      setContentCopied(false);
    }, 2000);
  };

  const renderCopyMessage = () => (
    <>
      <Copy20Regular />
      <Text
        style={{
          fontWeight: 400,
          fontSize: "14px",
        }}
      >
        {contentCopied ? "Copied" : "Copy"}
      </Text>
    </>
  );
  let normalizedMessage = message.message.replace(/\r\n|\n/g, '\n');
  return (

    <CopilotChat className={styles.messageRoot}>
      {!(message.from === "user") ? (
        <>
          {isHovered && (
            <Stack
              horizontal
              className={styles.copyContainer}
              onClick={onClipboardCopy}
            >
              {renderCopyMessage()}
            </Stack>
          )}
          <CopilotMessage
            // className={styles.copilotMessageContainer}
            onClick={handleMouseClick}
          >
            <Text className={styles.message}>
              <ReactMarkdown className={styles.message} remarkPlugins={[remarkGfm]}
                components={{
                  table: ({ node, ...props }) => <table {...props} style={{ borderCollapse: 'collapse', width: '100%' }} />,
                  th: ({ node, ...props }) => <th {...props} style={{ border: '1px solid #ddd', padding: '8px' }} />,
                  td: ({ node, ...props }) => <td {...props} style={{ border: '1px solid #ddd', padding: '8px' }} />,
                }}
              >
                {normalizedMessage}

              </ReactMarkdown>

            </Text>
            {message.isFeedbackEnabled && (
              <div className={styles.feedbackContainer}>
         
                <span className={styles.aiDisclaimer}>
                  AI-generated content may be incorrect
                </span>

                {/* <FeedbackButtons
                  // set the button to positive or negative based on the message's isFeedbackUpvoted is true or false, if null set undefined
                  selected={
                    message.isFeedbackUpvoted
                      ? "positive"
                      : message.isFeedbackUpvoted === false
                        ? "negative"
                        : undefined
                  }
                  positiveFeedbackButton={{ onClick: onFeedbackPositiveClick }}
                  negativeFeedbackButton={{ onClick: onFeedbackNegativeClick }}
                /> */}
                   <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
  <IconButton
    id={`feedback-${message.id}-like`}
    iconProps={{ iconName: positiveFeedbackIconName }}
    title={(message.isFeedbackUpvoted !== null && message.isFeedbackUpvoted !== false) ? "Feedback sent" : "Like"}
    onClick={onFeedbackPositiveClick}
    className={styles.feedbackIcon}
  />
  <IconButton
    id={`feedback-${message.id}-dislike`}
    iconProps={{ iconName: negativeFeedbackIconName }}
    title={message.isFeedbackUpvoted === false ? "Feedback sent" : "Dislike"}
    onClick={onFeedbackNegativeClick}
    className={styles.feedbackIcon}
  />
</div>
                      </div>
            
            )}

          </CopilotMessage>
        </>
      ) : (
        <>
          {isHovered && (
            <Stack
              horizontal
              className={styles.copyContainer}
              onClick={onClipboardCopy}
            >
              {renderCopyMessage()}
            </Stack>
          )}
          <UserMessage
            className={styles.userMessageContainer}
            onClick={handleMouseClick}
          >
            <Text className={styles.message}>
              {message.message.replace(/\n/g, "\n\n")}
            </Text>
          </UserMessage>
        </>
      )}

      <Dialog
        open={isFeedbackCalloutVisible}
        onOpenChange={(_, data) => setIsFeedbackCalloutVisible(data.open)}
        modalType="modal"
      >
        <DialogSurface className={styles.dialogSurface}>
          <DialogTitle className={styles.dialogTitle}>
            Submit Feedback
          </DialogTitle>
          <FeedbackCallout
            isActive={isFeedbackCalloutVisible}
            onFeedbackDismiss={onFeedbackCalloutDismiss}
            onFeedbackSend={onFeedbackCalloutSend}
            label={
              message.isFeedbackUpvoted
                ? "What did you like?"
                : "What was the issue with your response?"
            }
            textFieldTitle={!message.isFeedbackUpvoted}
            calloutTarget={
              message.isFeedbackUpvoted
                ? `#feedback-${message.id}-like`
                : `#feedback-${message.id}-dislike`
            }
            textFieldInputLimit={1000}
            textFieldValue={message.additionalUserFeedback}
            textFieldPlaceholder="Provide details without including personal information"
            renderAdditionalInputs={
              message.isFeedbackUpvoted
                ? null
                : renderCalloutNegativeFeedbackAdditionalInputs
            }
            message={message}
            updateMessage={updateMessage}
            disabled={
              message.isFeedbackUpvoted
                ? !validatePositiveInputFields()
                : !validateNegativeInputFields()
            }
          />
        </DialogSurface>
      </Dialog>
    </CopilotChat>
  );
};

export default Message;
