import React, { Component } from "react";
import SideNav from "../navigation/SideNav";
import { IMainProps } from "../../constants/types/UI/Main";
import "./Main.css";
import Footer from "./Footer";
import LandingComponentHolder from "../landing/LandingComponent";
import MenuBar from "./MenuBar";
import Reports from "./../reports/Reports";
import {
  HOME_URL,
  REPORTS_URL,
  ENGAGEMENTS_URL,
  OFFERS_URL,
  PARTICIPANTS_URL,
} from "../../constants/routes";
import EngagementDetail from "../engagements/EnagagementDetail";
import OfferDetail from "../offers/OfferDetail";
import Engagements from "../engagements/Engagements";
import Offers from "../offers/Offers";
import { logEvent } from "../../utils/insightsClient";
import PersistentNotification from "../common/PersistentNotification";
import {
  IBannerMessagePayload,
  INotification,
} from "../../constants/types/UI/BannerMessage";
import { MessageBarType } from "@fluentui/react";
import _ from "lodash";
import { IEngagementDetail } from "../../constants/types/UI/Engagements";
import { parseQS } from "../../utils/helperFunctions";
import { IOfferDetail } from "../../constants/types/UI/Offers";
import { ParticipantsList } from "../participants/ParticipantsList";
import Participants from "../participants/Participants";
import { INotifications } from "../../constants/types/UI/Notifications";
import { IDataResponse } from "../../constants/types/API/Apiresponse";
import { entityQueryString } from "../../constants/AppConstants";

interface IMainState {
  copilotPinnedState: boolean;
}
const isIframe = window !== window.parent && !window.opener;

function getCopilotPinnedStateFromSessionStorage(): boolean {
  const item = sessionStorage.getItem("copilotPinnedState");
  if (item) {
    return JSON.parse(item);
  }

  sessionStorage.setItem("copilotPinnedState", String(true));
  return true;
}


class Main extends Component<IMainProps, IMainState> {
  constructor(props: IMainProps) {
    super(props);
    this.state = {
      copilotPinnedState: getCopilotPinnedStateFromSessionStorage(),
    };
  }

  componentDidMount() {
    this.routeChangeHandler(this.props.location);
    window.addEventListener("storage", this.handleStorageChange, false);
  }

  manageSpinner = (showSpinner: boolean, spinnerMessage: string) => {
    this.props.toggleSpinner({
      spinner: showSpinner,
      spinnerMessage: spinnerMessage,
    });
  };

  manageEngagement = (
    details: any,
    show: boolean,
    newState: boolean,
    isDraft: boolean
  ) => {
    const engagementDetail: IEngagementDetail = {
      details,
      show: show,
      new: newState,
      isDraft,
    };
    this.props.setEngagementDetails(engagementDetail);
  };

  manageOffer = (details: any, show: boolean, newState: boolean) => {
    const offerDetail: IOfferDetail = {
      details,
      show: show,
      new: newState,
    };
    this.props.setOfferDetails(offerDetail);
  };

  buildAndShowBanner = (
    message: string,
    messageType: MessageBarType,
    timeOut: number,
    showBanner: boolean = true
  ) => {
    const bannerProps: IBannerMessagePayload = {
      toShow: showBanner,
      message: message,
      messageType: messageType,
      styleClass: "",
      timeout: timeOut,
    };
    this.props.setBanner(bannerProps);
  };

  componentDidUpdate(prevProps: IMainProps, prevState: IMainState) {
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.props.location.search !== prevProps.location.search
    ) {
      this.props.changeInitUrlState(false);
      this.routeChangeHandler(this.props.location, prevProps.location);
    }
    if (this.props.engagementDetailAPI !== prevProps.engagementDetailAPI) {
      if (this.props.engagementDetailAPI.data) {
        this.manageSpinner(false, "");
        this.manageEngagement(
          this.props.engagementDetailAPI.data.response,
          true,
          false,
          false
        );
      } else if (this.props.engagementDetailAPI.error) {
        this.manageSpinner(false, "");
        if (this.props.initURLState) {
          this.props.history.replace(ENGAGEMENTS_URL);
        } else {
          this.props.history.goBack();
        }
        this.buildAndShowBanner(
          `Failed to load Engagement-${this.props.engagementDetailAPI.error.id}`,
          MessageBarType.error,
          5000
        );
      }
    }

    if (this.props.offerDetailAPI !== prevProps.offerDetailAPI) {
      if (this.props.offerDetailAPI.data) {
        this.manageSpinner(false, "");
        this.manageOffer(this.props.offerDetailAPI.data.response, true, false);
      } else if (this.props.offerDetailAPI.error) {
        this.manageSpinner(false, "");
        if (this.props.initURLState) {
          this.props.history.replace(OFFERS_URL);
        } else {
          this.props.history.goBack();
        }
        this.buildAndShowBanner(
          `Failed to load Offer-${this.props.offerDetailAPI.error.id}`,
          MessageBarType.error,
          5000
        );
      }
    }
  }

  routeChangeHandler = (
    location: { pathname: any; search: any },
    prevLocation: { pathname: any; search: any } | null = null
  ) => {
    if (location.pathname !== prevLocation?.pathname && location.pathname) {
      this.props.changeMenuBar({
        itemName: location.pathname.substring(1).toLowerCase(),
        isActive: true,
      });
    }
    if (
      location.pathname.substring(1) === ENGAGEMENTS_URL &&
      !!location.search
    ) {
      const parsedQS = parseQS(location.search);
      if (
        ("id" in parsedQS && Number(parsedQS.id)) ||
        ("id" in parsedQS && Number(parsedQS.id) && "program" in parsedQS)
      ) {
        this.manageSpinner(true, `Getting Engagement - '${parsedQS.id}'`);
        this.props.getEngagementsByID(Number(parsedQS.id));
        return;
      } else if ("program" in parsedQS && !("id" in parsedQS)) {
        this.manageEngagement(null, false, false, false);
        this.props.history.push(ENGAGEMENTS_URL + location.search);
        return;
      } else if (
        ("id" in parsedQS && parsedQS.id === "new") ||
        ("id" in parsedQS && parsedQS.id === "new" && "program" in parsedQS)
      ) {
        this.manageEngagement(null, true, true, false);
        return;
      } else if ("id" in parsedQS) {
        if (this.props.initURLState) {
          this.props.history.replace(ENGAGEMENTS_URL);
        } else {
          this.props.history.goBack();
        }
        this.buildAndShowBanner(
          "Engagement Id not valid, please use a valid one",
          MessageBarType.warning,
          5000
        );
        return;
      }
      if (this.props.initURLState) {
        this.props.history.replace(ENGAGEMENTS_URL);
      } else {
        this.props.history.goBack();
      }
      this.buildAndShowBanner(
        "Invalid url, redirecting to engagements",
        MessageBarType.warning,
        5000
      );
    } else if (
      location.pathname.substring(1) === ENGAGEMENTS_URL &&
      !location.search
    ) {
      this.manageEngagement(null, false, false, false);
    }

    if (location.pathname.substring(1) === OFFERS_URL && !!location.search) {
      const parsedQS = parseQS(location.search);
      if (
        ("id" in parsedQS && parsedQS.id === "new") ||
        ("id" in parsedQS && parsedQS.id === "new" && "program" in parsedQS)
      ) {
        this.manageOffer(null, true, true);
        return;
      } else if ("id" in parsedQS) {
        this.manageSpinner(true, `Getting Offer - '${parsedQS.id}'`);
        this.props.getOfferByID(parsedQS.id);
        return;
      } else if (!("id" in parsedQS) && "program" in parsedQS) {
        this.manageOffer(null, false, false);
        this.props.history.replace(OFFERS_URL + location.search);
        return;
      } else {
        if (this.props.initURLState) {
          this.props.history.replace(OFFERS_URL);
        } else {
          this.props.history.goBack();
        }
        this.buildAndShowBanner(
          "Invalid url, redirecting to offers",
          MessageBarType.warning,
          5000
        );
        return;
      }
    } else if (
      location.pathname.substring(1) === OFFERS_URL &&
      !location.search
    ) {
      this.manageOffer(null, false, false);
    }
  };

  handleStorageChange = () => {
    const isCopilotPinned = sessionStorage.getItem("copilotPinnedState");
    if (isCopilotPinned) {
      const parsedData = JSON.parse(isCopilotPinned);
      this.setState({ copilotPinnedState: parsedData });
      return;
    }
  };

  render() {
    const pathname = this.props.location.pathname?.substring(1);
    const parsedQS = parseQS(this.props.location.search);

    return (
      <div
        className={`main`}
        id={
          this.state.copilotPinnedState ? "pinned-layout-main" : "layout--main"
        }
      >
        <div className="main-components">
          <SideNav
            navBarOpen={this.props.navBarOpen}
            toggleNavBar={this.props.toggleNavBar}
          />
          <div className="body-footer">
            <div
              className={
                this.state.copilotPinnedState === true
                  ? "content-body-pinned"
                  : "content-body"
              }
            >
              {!isIframe && (
                <PersistentNotification
                  getNotifications={this.props.getNotificationsState}
                  userContext={this.props.userContext}
                />
              )}
              {pathname === HOME_URL && (
                <LandingComponentHolder
                  location={this.props.location}
                  history={this.props.history}
                  appScopes={this.props.appScopes}
                  newUser={
                    this.props.userContext.data?.roles?.length === 0 ||
                    this.props.userContext.data?.scopes?.length === 0
                  }
                  userContext={this.props.userContext}
                />
              )}
              {pathname === REPORTS_URL && <Reports />}
              {pathname === PARTICIPANTS_URL && (
                <Participants
                  appScopes={this.props.appScopes}
                  history={this.props.history}
                  buildAndShowBanner={this.buildAndShowBanner}
                />
              )}
              {pathname === ENGAGEMENTS_URL &&
                !this.props.engagementDetail.show &&
                !parsedQS?.id && (
                  <Engagements
                    history={this.props.history}
                    buildAndShowBanner={this.buildAndShowBanner}
                    appScopes={this.props.appScopes}
                    roles={this.props.userContext.data?.roles}
                  />
                )}
              {pathname === OFFERS_URL &&
                !this.props.offerDetail.show &&
                !parsedQS?.id && (
                  <Offers
                    appScopes={this.props.appScopes}
                    history={this.props.history}
                    buildAndShowBanner={this.buildAndShowBanner}
                    userContext={this.props.userContext.data}
                  />
                )}
              {this.props.engagementDetail.show && parsedQS?.id && (
                <EngagementDetail
                  initURLState={this.props.initURLState}
                  history={this.props.history}
                  appScopes={this.props.appScopes}
                  location={this.props.location}
                  metaData={this.props.metaData.data}
                  toggleSpinner={this.props.toggleSpinner}
                  userContext={this.props.userContext}
                />
              )}
              {this.props.offerDetail.show && parsedQS?.id && (
                <OfferDetail
                  metaData={this.props.metaData.data}
                  appScopes={this.props.appScopes}
                  initURLState={this.props.initURLState}
                  history={this.props.history}
                  location={this.props.location}
                  toggleSpinner={this.props.toggleSpinner}
                  userContext={this.props.userContext.data}
                />
              )}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
