import { NeutralColors } from "@fluentui/react";
import { makeStyles, shorthands } from "@fluentui/react-components";
import { DefaultEffects } from "office-ui-fabric-react";
import { SharedColors } from "../../Constants";

export const useMessageStyles = makeStyles({
  messageRoot: {
    display: "flex",
    justifyContent: "flex-end",
    ...shorthands.padding("6px"),
  },
  copilotMessageContainer: {
    ...shorthands.padding("12px"),
    ...shorthands.borderRadius("8px"),
    ...shorthands.borderColor(NeutralColors.white),
    outlineStyle: "none",
    backgroundColor: NeutralColors.white,
    color: NeutralColors.black,
    alignSelf: "flex-start",
    marginRight: 0,
    marginLeft: "32",
    boxShadow: DefaultEffects.elevation4,
    justifyContent: "flex-end",
    whiteSpace: "pre-wrap",
  },
  userMessageContainer: {
    ...shorthands.padding("8px"),
    ...shorthands.borderRadius("8px"),
    outlineStyle: "none",
    backgroundColor: SharedColors.lightViolet,
    color: NeutralColors.black,
    alignSelf: "flex-end",
    marginRight: "32",
    marginLeft: 0,
    boxShadow: DefaultEffects.elevation4,
    justifyContent: "flex-start",
    whiteSpace: "pre-wrap",
  },
  aiDisclaimer: {
    color: "rgba(112, 112, 112, 1)",
    fontSize: "10px",
    fontWeight: 400,
    fontFamily: "Segoe UI",
    lineHeight: "14px",
    textAlign: "center",
  },
  messageHeader: {
    fontWeight: 600,
  },
  messageText: {
    color: NeutralColors.black,
  },
  feedbackContainer: {
    paddingTop: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  avatarIcon: {
    backgroundColor: SharedColors.cyanBlue10,
    ...shorthands.borderRadius("50%"),
    ...shorthands.padding("6px"),
    color: NeutralColors.white,
    fontSize: "20px",
    paddingLeft: "7px",
    paddingBottom: "7px",
  },
  suggestionContainer: {
    display: "flex",
    alignSelf: "flex-end",
    alignItems: "center",
  },
  suggestionItemContainer: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-start",
    color: NeutralColors.black,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    maxWidth: "320px",
    ...shorthands.padding("6px"),
    ...shorthands.borderRadius("8px"),
    ...shorthands.borderColor(SharedColors.darkBlue),
    '&:hover': {
      backgroundColor: SharedColors.lightViolet,
      color: "red"
    }
  },
  feedbackButton: {
    ...shorthands.margin("0 4px"),
  },
  refreshButton: {
    ...shorthands.padding("4px"),
    ...shorthands.borderRadius("50%"),
    marginLeft: "8px",
    color: NeutralColors.black,
    outlineStyle: "solid",
    outlineWidth: "1px",
  },
  feedbackIcon: {
    color: SharedColors.cyanBlue10,
    rootDisabled: {
      color: SharedColors.cyanBlue10,
      backgroundColor: 'transparent',
      selectors: { '.ms-Button-icon': { color: SharedColors.cyanBlue10 } },
    },
    root: {
      backgroundColor: 'transparent',
      color: SharedColors.cyanBlue10,
      borderRadius: '50%',
    },
    rootHovered: {
      color: SharedColors.cyanBlue10,
    },
  },
  refreshButtonMessage: {
    ...shorthands.borderRadius("50%"),
    ...shorthands.padding("2px"),
    marginLeft: "6px",
    color: NeutralColors.black,
    outlineStyle: "solid",
    outlineWidth: "1px",
    position: "absolute",
    marginTop: "4px",
  },
  spinner: {
    ...shorthands.margin("16px"),
  },
  threadRoot: {
    ...shorthands.padding("5px"),
  },
  banner: {
    ...shorthands.margin("6px"),
    ...shorthands.padding("12px"),
    ...shorthands.borderRadius("5px"),
    ...shorthands.borderColor(NeutralColors.white),
    outlineStyle: "none",
    backgroundColor: SharedColors.lightBlue,
    color: NeutralColors.black,
    boxShadow: DefaultEffects.elevation4,
    justifyContent: "flex-end",
  },
  bannerLink: {
    marginBottom: 0,
    color: SharedColors.cyanBlue10,
  },
  bannerList: {
    marginBottom: 0,
    color: SharedColors.cyanBlue10,
  },
  bannerHeader: {
    fontSize: "20px",
    fontWeight: 500,
  },
  bannerSpan: {
    color: "#797775",
    fontSize: "12px",
  },
  closeBanner: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  dialogTitle: {
    fontFamily: "Segoe UI",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "26px",
    letterSpacing: "0em",
    marginBottom: "16px",
    outlineStyle: "none",
  },
  dialogSurface: {
    ...shorthands.borderRadius("8px"),
    ...shorthands.padding("15px"),
    backgroundColor: NeutralColors.white,
    outlineStyle: "none",
    outlineWidth: "0px",
  },
  copyContainer: {
    ...shorthands.borderRadius("4px"),
    ...shorthands.padding("8px"),
    zIndex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "flex-end",
    backgroundColor: "#EDEBE9",
    cursor: "pointer",
  },
  copilotLatencyLoader: {
    ...shorthands.padding("8px"),
    ...shorthands.borderRadius("8px"),
    marginTop: "8px",
    marginBottom: "8px",
    boxShadow: DefaultEffects.elevation4,
    outlineStyle: "none",
    fontWeight: 600,
    fontSize: "14px",
  },
  message: {
    color: " #242424",
    fontFamily: "Segoe UI",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  copilotLatencyLoaderProgress: {
    ...shorthands.borderRadius("8px"),
    backgroundColor: SharedColors.cyanBlue10,
  },
});
