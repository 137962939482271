import _, { isEmpty } from "lodash";
import {
  all,
  put,
  delay,
  call,
  fork,
  takeLatest,
  select
} from "redux-saga/effects";
import * as Msal from "msal";
import { GET_CONTEXT,GET_COMMONSERVICESTOKEN } from "../constants/actions/API/Context";
import {
  SIGN_IN,
  SIGN_IN_FAILURE,
  SET_ACCESS_TOKEN,
  GET_USER_PHOTO,
  SET_USER_PHOTO,
  SET_USER_PROFILE,
  GET_API_ACCESS_TOKEN,
  REAQUIRE_API_ACCESS_TOKEN,
  SET_API_ACCESS_TOKEN,
  SET_POWER_BI_ACCESS_TOKEN,
  CONTEXT_API_SUCCESS,
  CONTEXT_API_FAILURE,
  CONTEXT_API_FETCHING,
  GET_POWER_BI_ACCESS_TOKEN,
  GET_CORE_API_ACCESS_TOKEN,
  SET_CORE_API_ACCESS_TOKEN,
  SET_COMMON_SERVICE_TOKEN,
  GET_COMMON_SERVICE_TOKEN,
  COMMONTOKEN_API_FETCHING,
  COMMONTOKEN_API_SUCCESS,
  COMMONTOKEN_API_FAILURE,
} from "../constants/actions/Auth/auth";
import AppConstants, { APILabels, EndPoints } from '../constants/AppConstants';
import { InsightsClient, logApiEvent, logEvent, logApiException, logException, appInsightsConfigs } from "../utils/insightsClient";
import { HOME_URL } from "../constants/routes";
import { SAVE_RATING } from "../constants/actions/API/Rating";
import { IRating, SAVING_RATING, SAVING_RATING_FAILURE, SAVING_RATING_SUCCESS } from '../constants/types/API/Rating'
import {
  FETCHING_ENGAGEMENTS,
  FETCHING_ENGAGEMENT_FAILURE,
  FETCHING_ENGAGEMENT_SUCCESS,
  GET_ENGAGEMENTS,
  GET_ENGAGEMENT_CATEGORIES,
  GET_ENGAGEMENT_CATEGORIES_FAILURE,
  GET_ENGAGEMENT_CATEGORIES_FETCHING,
  GET_ENGAGEMENT_CATEGORIES_SUCCESS,
  GET_ENGAGEMENT_DATA,
} from "../constants/types/API/Engagements";
import {
  FETCHING_OFFERS,
  FETCHING_OFFERS_FAILURE,
  FETCHING_OFFERS_SUCCESS,
  GET_OFFERS,
  GET_OFFERS_DATA,
  GET_OFFERS_CATEGORIES,
  GET_OFFERS_CATEGORIES_FETCHING,
  GET_OFFERS_CATEGORIES_FAILURE,
  GET_OFFERS_CATEGORIES_SUCCESS,
} from "../constants/types/API/Offers";
import {
  FETCHING_PARTICIPANTS,
  FETCHING_PARTICIPANTS_FAILURE,
  FETCHING_PARTICIPANTS_SUCCESS,
  GET_PARTICIPANTS,
  GET_PARTICIPANTS_CATEGORIES,
  GET_PARTICIPANTS_CATEGORIES_FAILURE,
  GET_PARTICIPANTS_CATEGORIES_FETCHING,
  GET_PARTICIPANTS_CATEGORIES_SUCCESS,
  GET_PARTICIPANTS_DATA,
} from "../constants/types/API/Participants";
import {
  GET_ENGAGEMENT_BY_ID_FETCHING,
  GET_ENGAGEMENT_BY_ID_SUCCESS,
  GET_ENGAGEMENT_BY_ID_FAILURE,
  GET_ENGAGEMENT_BY_ID
} from "../constants/actions/UI/Engagements";
import {
  GET_METADATA,
  GET_METADATA_FAILURE,
  GET_ENGAGEMENTS_DROPDOWN,
  GET_OFFERS_DROPDOWN,
  GET_OFFERS_DETAILS,
  GET_ENGAGEMENTS_DROPDOWN_SUCCESS,
  GET_OFFERS_DROPDOWN_SUCCESS,
  GET_OFFERS_DETAILS_SUCCESS,
  GET_PARTICIPANTS_META,
  GET_PARTICIPANTS_META_FETCHING,
  GET_PARTICIPANTS_META_SUCCESS,
  GET_PARTICIPANTS_META_FAILURE
} from "../constants/actions/API/MetaData";
import { Engagements_DN, Offers } from "../constants/types/UI/Menu";
import { MetaDataHelper, MetaDataCategory } from "../constants/types/API/MetaData";
import { IMetaData } from "../constants/types/API/MetaData"
import { ENGAGEMENT_UPSERT, ENGAGEMENT_UPSERT_FAILURE, ENGAGEMENT_UPSERT_STARTING, ENGAGEMENT_UPSERT_SUCCESS, CREATE_OFFER_IN_PC, CREATE_OFFER_IN_PC_STARTING, CREATE_OFFER_IN_PC_SUCCESS, CREATE_OFFER_IN_PC__FAILURE } from "../constants/actions/API/EngagementUpdate";
import { OFFER_UPSERT, OFFER_UPSERT_FAILURE, OFFER_UPSERT_STARTING, OFFER_UPSERT_SUCCESS } from "../constants/actions/API/OfferUpdate";
import { ENGAGEMENT_VIEWS, IEngagementDetailData, IGetEngagementPayload, MAPPED_ENGAGEMENT_STATUS } from "../constants/types/UI/Engagements";
import { UPDATE_GRAPH_STATE } from "../constants/actions/API/GraphState";
import { buildScopes } from "../utils/helperFunctions";
import { SET_SCOPES } from "../constants/actions/UI/Scopes";
import { GET_OFFER_BY_ID, GET_OFFER_BY_ID_FAILURE, GET_OFFER_BY_ID_FETCHING, GET_OFFER_BY_ID_SUCCESS, GET_TERMS_AND_CONDITION, GET_TERMS_AND_CONDITION_FAILURE, GET_TERMS_AND_CONDITION_FETCHING, GET_TERMS_AND_CONDITION_SUCCESS, SEND_EMAIL_TO_SELF, SEND_EMAIL_TO_SELF_FAILURE, SEND_EMAIL_TO_SELF_FETCHING, SEND_EMAIL_TO_SELF_SUCCESS, UPDATE_OFFER } from "../constants/actions/UI/Offers";
import { IGetParticipantPayload } from "../constants/types/UI/Participants";
import { UPSERT_OFFER_FROM_DETAIL, UPSERT_OFFER_FROM_DETAIL_FAILURE, UPSERT_OFFER_FROM_DETAIL_FETCHING, UPSERT_OFFER_FROM_DETAIL_SUCCESS } from "../constants/actions/API/OffersUpsertFromDetail";
import { IOfferDetailData } from "../constants/types/UI/Offers";
import { GET_NOTIFICATIONS,GET_NOTIFICATIONS_SUCCESS,GET_NOTIFICATIONS_FAILURE,GET_NOTIFICATIONS_FETCHING,DISMISS_NOTIFICATIONS,DISMISS_NOTIFICATIONS_FETCHING,DISMISS_NOTIFICATIONS_SUCCESS,DISMISS_NOTIFICATIONS_FAILURE } from "../constants/actions/API/Notifications";


//API methods enum
let apiMethodType = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE"
}

let authConfig = {
  clientId: AppConstants.ClientAppID,
  authority:
    AppConstants.Authority,
  scopes: ["openid", "profile", "user.read", "user.readbasic.all"
  ],
  graphEndpoint: "https://graph.microsoft.com/v1.0/me",
  allowedGroups: []
};

const msalConfig = {
  auth: {
    clientId: AppConstants.ClientAppID,
    authority: "72f988bf-86f1-41af-91ab-2d7cd011db47",
    redirectUri: "https://cape-test-v2.azurewebsites.net/home",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you're having issues on Internet Explorer 11 or Edge
  },
  scopes: ["https://csappseng-sandbox.crm.dynamics.com/.default"]
};

let userAgentApplication = new Msal.UserAgentApplication({
  auth: {
    clientId: AppConstants.ClientAppID,
    authority:
      AppConstants.Authority,
    validateAuthority: true,
    postLogoutRedirectUri: AppConstants.WebUiUrl,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  },
  system: {
    navigateFrameWait: 0
  }
});

const powerBIScopes = {
  scopes: [
    "https://analysis.windows.net/powerbi/api/Dashboard.Read.All",
    "https://analysis.windows.net/powerbi/api/Dataset.ReadWrite.All",
    "https://analysis.windows.net/powerbi/api/Report.Read.All",
    "https://analysis.windows.net/powerbi/api/Workspace.Read.All",
    "https://analysis.windows.net/powerbi/api/Content.Create",
    "https://analysis.windows.net/powerbi/api/App.Read.All"
  ]
};

function readAsURL(blob) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    // reader.onerror = reject
    reader.readAsDataURL(blob);
  });
}

function setDefaultRoute() {
  if (window.location.href.indexOf("auth") == -1) {
    localStorage.setItem("lastRouteURL", window.location.pathname);
    localStorage.setItem("lastSearchParams", window.location.search);
  }
  let stateObj = { id: "100" };
  window.history.pushState(
    stateObj,
    "home",
    window.location.origin + "/" + HOME_URL + "/auth"
  );
}

/*Post api helper*/

function postApiWorker(endpoint, apiAccessToken, coreApiAccessToken, requestBody, webApiIdentifier = "No identifier") {
  let startTime = Date.now();
  var url = AppConstants.APP_API_URL + endpoint;
  const user = userAgentApplication.getAccount();
  return new Promise((resolve, reject) => {
    if (typeof Worker !== "undefined") {
      const worker = new Worker("/postApi.worker.js");
      worker.addEventListener("message", e => {
        const {
          data: { error, data }
        } = e;
        if (!error) {
          logApiEvent(startTime, endpoint, apiMethodType.POST, url, webApiIdentifier, user.userName, user.idToken.roles);
          resolve(data);
        } else {
          logApiException(error, endpoint, apiMethodType.POST, url, webApiIdentifier, user.userName, user.idToken.roles);
          reject(error);
        }

        worker.terminate();
      });

      worker.postMessage({ url, apiAccessToken, coreApiAccessToken, requestBody });
    } else {
      fetch(url, {
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${apiAccessToken}`,
          coreApiAccessToken: `Bearer ${coreApiAccessToken}`
        }),
        method: apiMethodType.POST,
        body: JSON.stringify(requestBody)
      })
        .then(response => {
          if (!response.ok) {
            const error = new Error(response.statusText);
            logApiException(error, endpoint, apiMethodType.POST, url, webApiIdentifier, user.userName, user.idToken.roles);
            throw error;
          }
          logApiEvent(startTime, endpoint, apiMethodType.POST, url, webApiIdentifier, user.userName, user.idToken.roles);
          return response.json();
        })
        .then(resolve)
        .catch(reject);
    }
  });
}

export default function* root() {
  yield all([
    /*For access token*/
    takeLatest(GET_API_ACCESS_TOKEN, getAPIAccessToken),
    takeLatest(GET_POWER_BI_ACCESS_TOKEN, getPowerBIAPIAccessToken),
    takeLatest(GET_CORE_API_ACCESS_TOKEN, getCoreAPIAccessToken),
    // takeLatest(GET_COMMON_SERVICE_TOKEN, getCommonServiceToken),
    takeLatest(SIGN_IN, signIn),
    takeLatest(GET_USER_PHOTO, getUserPhoto),
    takeLatest(REAQUIRE_API_ACCESS_TOKEN, onlyGetAPIAccessToken),
    takeLatest(GET_CONTEXT, getAppContextSaga),
    takeLatest(GET_COMMONSERVICESTOKEN, getCommonServicesToken),
    takeLatest(SAVE_RATING, saveRatingSaga),
    takeLatest(GET_ENGAGEMENTS, getEngagementsSaga),
    takeLatest(GET_ENGAGEMENT_DATA, getEngagementsDataSaga),
    takeLatest(GET_ENGAGEMENT_CATEGORIES, getEngagementsCategoriesSaga),
    takeLatest(GET_ENGAGEMENT_BY_ID, getEngagementByIDSaga),
    takeLatest(GET_METADATA, getMetaDataSaga),
    takeLatest(GET_ENGAGEMENTS_DROPDOWN, getEngagementsDropDownSaga),
    takeLatest(GET_OFFERS_DROPDOWN, getOffersDropDownSaga),
    takeLatest(GET_OFFERS_DETAILS, getOffersDetailsSaga),
    takeLatest(GET_OFFERS, getOffersSaga),
    takeLatest(GET_OFFER_BY_ID, getOfferByIDSaga),
    takeLatest(ENGAGEMENT_UPSERT, upsertEngagementSaga),
    takeLatest(CREATE_OFFER_IN_PC, createOfferInPCSaga),
    takeLatest(GET_PARTICIPANTS, getParticipantsSaga),
    takeLatest(GET_PARTICIPANTS_DATA, getParticipantsDataSaga),
    takeLatest(GET_PARTICIPANTS_CATEGORIES, getParticipantsCategoriesSaga),
    takeLatest(GET_OFFERS_DATA, getOffersDataSaga),
    takeLatest(GET_OFFERS_CATEGORIES, getOffersCategoriesSaga),
    takeLatest(UPDATE_OFFER, upsertOfferSaga),
    takeLatest(GET_PARTICIPANTS_META, getParticipantsMetaDataSaga),
    takeLatest(UPSERT_OFFER_FROM_DETAIL, upsertOfferFromDetailSaga),
    takeLatest(GET_NOTIFICATIONS, getNotificationsSaga),
    takeLatest(DISMISS_NOTIFICATIONS, dismissNotificationsSaga),
    takeLatest(GET_TERMS_AND_CONDITION, getOffersDocumentByID),
    takeLatest(SEND_EMAIL_TO_SELF, sendOffersTCDocToSelf),
  ]);
}


//Helper functions
export const getApiTokenFromStore = state => state.auth.apiAccessToken;
export const getGraphTokenFromStore = state => state.auth.accessToken;
export const getCoreTokenFromStore = state => state.auth.coreApiAccessToken;


function readCompletejson(response) {

  return response.json().then((data) => {
    return data;
  }).catch((error) => {
    //console.log("error", error);
  });
}

/**
* For UpsertingOfferFromDetail
* @param {{type: string,payload : Partial<IOfferDetailData>[]}} upsertOfferFromDetailPayload - For upserting offer from detail screen
*/
function* upsertOfferFromDetailSaga(upsertOfferFromDetailPayload) {
  const offerObjects = upsertOfferFromDetailPayload.payload;
  try {
    yield put({ type: UPSERT_OFFER_FROM_DETAIL_FETCHING });
    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }

    var offerApiCalls = [];
    for (var i = 0; i < offerObjects.length; i++) {
      offerApiCalls.push(call(postApiWorker, `${EndPoints.Offers}/upsert`, apiAccessToken, coreAccessToken, offerObjects[i], APILabels.upsertOffer));
    }

    const responses = yield all(offerApiCalls);

    yield put({
      type: UPSERT_OFFER_FROM_DETAIL_SUCCESS, payload: {
        response: responses,
        originalOffers: offerObjects
      }
    });

  }
  catch (error) {
    yield put({
      type: UPSERT_OFFER_FROM_DETAIL_FAILURE, payload: {
        originalOffers: offerObjects,
        error: "Error occurred while upserting",
      }
    });
  }

}

/**
* For Creating offer in partner center
* @param {{type: string,payload : Partial<IEngagementDetailData}} createOfferInPCPayload - For creating offer in partner center
*/
function* createOfferInPCSaga(createOfferInPCPayload){
  var engagement = createOfferInPCPayload.payload;
  try{
    yield put({ type: CREATE_OFFER_IN_PC_STARTING });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }

    const response = yield call(postApiWorker, `${EndPoints.Engagements}/CreateOfferInPartnerCenter`, apiAccessToken, coreAccessToken, engagement, APILabels.createOfferInPC);
     yield put({
       type: CREATE_OFFER_IN_PC_SUCCESS, payload: {
         item: response,
         origEngagement: engagement
       }
     });

  }
  catch {
     yield put({
       type: CREATE_OFFER_IN_PC__FAILURE, payload: {
         id: engagement.EngagementId,
         error: "Error occurred while creating offer in PC",
         origEngagement: engagement
       }
     });
  }
}

/**
* For UpsertingEngagement
* @param {{type: string,payload : Partial<IEngagementDetailData}} upsertEngagementPayload - For upserting engagement
*/
function* upsertEngagementSaga(upsertEngagementPayload) {
  var engagementObject = upsertEngagementPayload.payload;
  try {

    yield put({ type: ENGAGEMENT_UPSERT_STARTING });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }

    const response = yield call(postApiWorker, `${EndPoints.Engagements}/upsert`, apiAccessToken, coreAccessToken, engagementObject, APILabels.upsertEngagement);

    yield put({
      type: ENGAGEMENT_UPSERT_SUCCESS, payload: {
        id: response.EngagementId, item: response,
        newItem: engagementObject.EngagementId.toString() == "0",
        origEngagement: engagementObject
      }
    });

  }
  catch {

    yield put({
      type: ENGAGEMENT_UPSERT_FAILURE, payload: {
        id: engagementObject.EngagementId,
        error: "Error occurred while upserting",
        newItem: engagementObject.EngagementId.toString() == "0",
        origEngagement: engagementObject
      }
    });

  }

}

/**
* For UpsertingOffer
* @param {{type: string,payload : Partial<IOfferDetailData}} upsertOfferPayload - For upserting offer
*/
function* upsertOfferSaga(upsertOfferPayload) {
  var offerObject = upsertOfferPayload.payload;
  try {
    yield put({ type: OFFER_UPSERT_STARTING });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }

    const response = yield call(
      postApiWorker,
      `${EndPoints.Offers}/upsert`,
      apiAccessToken,
      coreAccessToken,
      offerObject,
      APILabels.upsertOffer
    );

    yield put({
      type: OFFER_UPSERT_SUCCESS,
      payload: {
        id: response.OfferCode,
        item: response,
        newItem: offerObject.OfferCode.toString() === "0",
      },
    });
  } catch {
    yield put({
      type: OFFER_UPSERT_FAILURE,
      payload: {
        id: offerObject.OfferCode,
        error: "Error occurred while upserting",
        newItem: offerObject.OfferCode.toString() === "0",
      },
    });
  }
}

/**
* For SavingRating
* @param {{type: string,payload : IRating}} ratingActionData - rating api action data
*/
function* saveRatingSaga(ratingActionData) {
  var ratingPayload = ratingActionData.payload;
  try {

    yield put({ type: SAVING_RATING });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }

    const response = yield call(postApiWorker, EndPoints.RatingAPI, apiAccessToken, coreAccessToken, ratingPayload, APILabels.saveRatings);
    if (response.ok !== true)
      throw new Error("Unable to save user feedback");

    yield put({ type: SAVING_RATING_SUCCESS });

  }
  catch {

    yield put({ type: SAVING_RATING_FAILURE });

  }

}



/**
* For GetEngagements
* @param {{type: string,payload:IGetEngagementPayload}} engagementAPIPayload - GetEngagement payload
*/
function* getEngagementsSaga(engagementAPIPayload) {
  const apiPayload = engagementAPIPayload.payload;
  try {

    yield put({ type: GET_ENGAGEMENT_DATA, payload: apiPayload });


  } catch (error) {

    yield put({ type: FETCHING_ENGAGEMENT_FAILURE, payload: { error: error } });


  }
}

/**
* For GetEngagementsData
* @param {{type: string,payload:string}} engagementDataPayload - GetEngagementData payload
*/
function* getEngagementsDataSaga(engagementDataPayload) {
  // const odataQS = engagementDataPayload.payload;
  let scope = engagementDataPayload.payload.selectedView === "1" ? "Self" : (engagementDataPayload.payload.selectedView === "2" ? "Team" : "All");
  let activeStatus = engagementDataPayload.payload.activeStatus === "ERROR_FOUND" ? "FAILED" : (engagementDataPayload.payload.activeStatus === "IN_DRAFT" ? "DRAFT" : "");
  let isSiteAdmin = false;
  const user = userAgentApplication.getAccount();
  if(user.idToken.roles?.find(role => role === "SiteAdmin")){
    isSiteAdmin = true;
  }

  const engagementPayload = {
    entity: "Engagement",
    searchTerm: engagementDataPayload.payload.searchTerm,
    status:
      activeStatus !== ""
        ? activeStatus
        : engagementDataPayload.payload.activeStatus,
    entityScope: scope,
    isSiteAdmin: isSiteAdmin,
    sortColumn: engagementDataPayload.payload.sortingOrder.column === "OfferCode" ? "OfferTitle" :  engagementDataPayload.payload.sortingOrder.column,
    sortDirection: engagementDataPayload.payload.sortingOrder.order,
  };

  try {
    yield put({ type: FETCHING_ENGAGEMENTS });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }
    const response = yield call(postApiWorker, `${EndPoints.Search }/getsearchresults`, apiAccessToken, coreAccessToken, engagementPayload, APILabels.getEngagements);
    yield put({ type: FETCHING_ENGAGEMENT_SUCCESS, payload: { response } });
  } catch (error) {
    yield put({ type: FETCHING_ENGAGEMENT_FAILURE, payload: { error: error } });
  }
}

/**
* For GetEngagementsCategories
* @param {{type: string,payload:any}} engagementCategoriesPayload - GetEngagementsCategories payload
*/
function* getEngagementsCategoriesSaga(engagementCategoriesPayload) {
  let scope = "All";
  if (engagementCategoriesPayload.payload.selectedView === "1") {
    scope = "Self";
  } else if (engagementCategoriesPayload.payload.selectedView === "2") {
    scope = "Team";
  }

  let queryString = `Entity=Engagement`;
  if (engagementCategoriesPayload.payload.searchTerm) {
    queryString += `&SearchTerm=${engagementCategoriesPayload.payload.searchTerm}`;
  }
  queryString += `&EntityScope=${scope}`;


  try {
    yield put({ type: GET_ENGAGEMENT_CATEGORIES_FETCHING });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }
    const response = yield call(getApiWorker, `${EndPoints.GetEntityCount}?${queryString}`, apiAccessToken, coreAccessToken, APILabels.getEngagementCategories);
    yield put({ type: GET_ENGAGEMENT_CATEGORIES_SUCCESS, payload: { response } });

  } catch (error) {
    yield put({ type: GET_ENGAGEMENT_CATEGORIES_FAILURE, payload: { error: error } });
  }
}

/**
* For GetOffer
* @param {{type: string,payload:IGetOfferPayload}} offerAPIPayload - GetOffer payload
*/
function* getOffersSaga(offerAPIPayload) {
  const apiPayload = offerAPIPayload.payload;
  try {
    yield put({ type: GET_OFFERS_DATA, payload: apiPayload });

  } catch (error) {
    yield put({ type: FETCHING_OFFERS_FAILURE, payload: { error: error } });
  }
}

/**
* For GetOffersData
* @param {{type: string,payload:string}} offerDataPayload - GetOffersData payload
*/
function* getOffersDataSaga(offerDataPayload) {
  // const odataQS = offerDataPayload.payload;
  const offerPayload = {
    entity: "RoleOffer",
    searchTerm: offerDataPayload.payload.searchTerm,
    status: offerDataPayload.payload.activeStatus,
    entityScope: "",
    sortColumn: offerDataPayload.payload.sortingOrder.column,
    sortDirection: offerDataPayload.payload.sortingOrder.order,
  };

  try {
    yield put({ type: FETCHING_OFFERS });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }
    const response = yield call(postApiWorker, `${EndPoints.Search }/getsearchresults`, apiAccessToken, coreAccessToken, offerPayload, APILabels.getOffers);
    yield put({ type: FETCHING_OFFERS_SUCCESS, payload: { response } });
  } catch (error) {
    yield put({ type: FETCHING_OFFERS_FAILURE, payload: { error: error } });
  }
}

/**
* For GetOffersCategories
* @param {{type: string,payload:string}} offerCategoriesPayload - GetOffersCategories payload
*/
function* getOffersCategoriesSaga(offerCategoriesPayload) {
  const offerPayload = offerCategoriesPayload.payload;
  let queryString = `Entity=RoleOffer`;
  if (offerPayload.searchTerm) {
    queryString += `&SearchTerm=${offerPayload.searchTerm}`;
  }

  try {
    yield put({ type: GET_OFFERS_CATEGORIES_FETCHING });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }
    const response = yield call(
      getApiWorker,
      `${EndPoints.GetEntityCount}?${queryString}`,
      apiAccessToken,
      coreAccessToken,
      APILabels.getOfferCategories
    );
    yield put({
      type: GET_OFFERS_CATEGORIES_SUCCESS,
      payload: { response },
    });
  } catch (error) {
    yield put({
      type: GET_OFFERS_CATEGORIES_FAILURE,
      payload: { error: error },
    });
  }
}

/**
* For getParticipantQS
* @param {IGetParticipantPayload} odataPayload - Odata Payload
*/
// const getParticipantQS = (odataPayload) => {
//   var dataQS = "$filter=";
//   var categoryQS = "$apply=filter("
//   if (odataPayload.activeMenu) {
//     dataQS = dataQS + `RoleName eq '${odataPayload.activeMenu}'`;
//   }
//   if (odataPayload.searchTerm) {
//     dataQS = dataQS + " and " + `(contains(tolower(UserAlias),'${odataPayload.searchTerm.toLowerCase()}') or contains(tolower(DisplayName),'${odataPayload.searchTerm.toLowerCase()}'))`;
//     categoryQS = categoryQS + `contains(tolower(UserAlias),'${odataPayload.searchTerm.toLowerCase()}') or contains(tolower(DisplayName),'${odataPayload.searchTerm.toLowerCase()}')`;
//   } else {
//     categoryQS = categoryQS + 'length(UserAlias) gt 0'
//   }
//   categoryQS = categoryQS + ')';
//   categoryQS = categoryQS + `/groupby((RoleName),aggregate($count as ParticipantCount))`;
//   if (odataPayload.sortingOrder) {
//     dataQS = dataQS + `&$orderby=${odataPayload.sortingOrder.column} ${odataPayload.sortingOrder.order}`;
//   }
//   return [dataQS, categoryQS];
// }

/**
* For GetParticipants
* @param {{type: string,payload:IGetParticipantPayload}} participantAPIPayload - GetEngagement payload
*/
function* getParticipantsSaga(participantAPIPayload) {
  const apiPayload = participantAPIPayload.payload;
  try {

    yield put({ type: GET_PARTICIPANTS_DATA, payload: apiPayload });
    yield put({ type: GET_PARTICIPANTS_CATEGORIES, payload: apiPayload });

  } catch (error) {

    yield put({ type: FETCHING_PARTICIPANTS_FAILURE, payload: { error: error } });
    yield put({ type: GET_PARTICIPANTS_CATEGORIES_FAILURE, payload: { error: error } });

  }
}

/**
* For GetParticipantsData
* @param {{type: string,payload:string}} participantsDataPayload - GetParticipantsData payload
*/
function* getParticipantsDataSaga(participantsDataPayload) {
  const odataQS = participantsDataPayload.payload;
  const participantsCategoryDataPayload = {
    searchTerm: odataQS.searchTerm,
    roleName: odataQS.activeMenu,
    sortColumn:  odataQS.sortingOrder.column,
    sortDirection: odataQS.sortingOrder.order
  };
  try {
    yield put({ type: FETCHING_PARTICIPANTS });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }
    const response = yield call(postApiWorker, `${EndPoints.Participants}/getparticipantsearchresults`, apiAccessToken, coreAccessToken, participantsCategoryDataPayload, APILabels.getParticipants);
    yield put({ type: FETCHING_PARTICIPANTS_SUCCESS, payload: { response } });

  } catch (error) {
    yield put({ type: FETCHING_PARTICIPANTS_FAILURE, payload: { error: error } });
  }
}

/**
* For GetParticipantsCategories
* @param {{type: string,payload:string}} participantsCategoriesPayload - GetParticipantsCategories payload
*/
function* getParticipantsCategoriesSaga(participantsCategoriesPayload) {
  const odataQS = participantsCategoriesPayload.payload;
  const participantsCategoryDataPayload = {
    searchTerm: odataQS.searchTerm    
  };

  try {
    yield put({ type: GET_PARTICIPANTS_CATEGORIES_FETCHING });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }
    const response = yield call(postApiWorker , `${EndPoints.Participants}/getparticipantscount`, apiAccessToken, coreAccessToken, participantsCategoryDataPayload,  APILabels.getParticipantCategories);
    yield put({ type: GET_PARTICIPANTS_CATEGORIES_SUCCESS, payload: { response } });

  } catch (error) {
    yield put({ type: GET_PARTICIPANTS_CATEGORIES_FAILURE, payload: { error: error } });
  }
}

/**
* For GetMetaData
* @param {{type: string}} metaDataPayload - MetaData payload
*/
function* getMetaDataSaga(metaDataPayload) {
  try {
    yield put({ type: GET_ENGAGEMENTS_DROPDOWN });
    yield put({ type: GET_OFFERS_DETAILS });
    yield put({ type: GET_OFFERS_DROPDOWN });

  } catch (error) {
    yield put({ type: GET_METADATA_FAILURE, payload: { error: "Error occurred while calling metaData apis" } });
  }
}

/**
* For GetEngagementsDropDown
* @param {{type: string}} engagementsDropDownPayload - GetEngagementsDropDown payload
*/
function* getEngagementsDropDownSaga(engagementsDropDownPayload) {
  try {

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }

    const response = yield call(getApiWorker, `${EndPoints.Metadata}?formCode=${Engagements_DN}`, apiAccessToken, coreAccessToken, APILabels.getEngagementsMeta);
    var engagementMetas = {}
    Object.values(MetaDataHelper).forEach(mValue => {
      if (mValue.category == MetaDataCategory.EngagementsDropdowns) {
        engagementMetas[mValue.metaName] = _.cloneDeep(mValue);
        engagementMetas[mValue.metaName].values = response.filter(responseSub => responseSub?.cdd_ControlCode == mValue.metaName);
        engagementMetas[mValue.metaName].values = engagementMetas[mValue.metaName].values?.map(origMeta => {
          const tranformedMeta = {
            id: origMeta.cdd_Text,
            key: origMeta.cdd_Text,
            metaData: origMeta
          }
          return tranformedMeta;
        })
      }
    });
    yield put({ type: GET_ENGAGEMENTS_DROPDOWN_SUCCESS, payload: { response: engagementMetas } });

  } catch (error) {
    yield put({ type: GET_METADATA_FAILURE, payload: { error: "Error occurred while calling metaData apis" } });
  }
}

/**
* For GetOffersDetailsSaga
* @param {{type: string}} getOffersDetailsSagaPayload - GetOffersDetailsSaga payload
*/
function* getOffersDetailsSaga(getOffersDetailsSagaPayload) {
  try {

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }

    const response = yield call(getApiWorker, `${EndPoints.Offers}?$select=Role,OfferCode,Team,OfferTitle,AccessLevel,Status,OfferTemplateVersion,IsSupportOnly`, apiAccessToken, coreAccessToken, APILabels.getOffersDropDown);
    var offerDetails = {}
    Object.values(MetaDataHelper).forEach(mValue => {
      if (mValue.category == MetaDataCategory.OfferSDetails) {
        offerDetails[mValue.metaName] = _.cloneDeep(mValue);
        offerDetails[mValue.metaName].values = response;
        offerDetails[mValue.metaName].values = offerDetails[mValue.metaName].values?.map(offer => {
          const tranformedOffer = {
            id: offer.Role,
            key: offer.Role,
            metaData: offer
          }
          return tranformedOffer;
        });
      }
    });
    yield put({ type: GET_OFFERS_DETAILS_SUCCESS, payload: { response: offerDetails } });

  } catch (error) {
    yield put({ type: GET_METADATA_FAILURE, payload: { error: "Error occurred while calling metaData apis" } });
  }
}

/**
* For GetOffersDropDown
* @param {{type: string}} offersDropDownPayload - GetOffersDropDown payload
*/
function* getOffersDropDownSaga(offersDropDownPayload) {
  try {

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }

    const response = yield call(getApiWorker, `${EndPoints.Metadata}?formCode=${Offers}`, apiAccessToken, coreAccessToken, APILabels.getOffersMeta);
    var offerMetas = {}
    Object.values(MetaDataHelper).forEach(mValue => {
      if (mValue.category == MetaDataCategory.OffersDropDown) {
        offerMetas[mValue.metaName] = _.cloneDeep(mValue);
        offerMetas[mValue.metaName].values = response.filter(responseSub => responseSub?.cdd_ControlCode == mValue.metaName);
        offerMetas[mValue.metaName].values = offerMetas[mValue.metaName].values?.map(origMeta => {
          const tranformedMeta = {
            id: origMeta.cdd_Text,
            key: origMeta.cdd_Text,
            metaData: origMeta
          }
          return tranformedMeta;
        })
      }
    });
    yield put({ type: GET_OFFERS_DROPDOWN_SUCCESS, payload: { response: offerMetas } });

  } catch (error) {
    yield put({ type: GET_METADATA_FAILURE, payload: { error: "Error occurred while calling metaData apis" } });
  }
}


/**
* For GetParticipantsMeta
* @param {{type: string}} participantsMetaPayload - participantsMeta payload
*/
function* getParticipantsMetaDataSaga(participantsMetaPayload) {

  const participantsCategoryDataPayload = {    
  };

  try {
    yield put({ type: GET_PARTICIPANTS_META_FETCHING });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }
    const response = yield call(postApiWorker, `${EndPoints.Participants}/getparticipantsearchresults`, apiAccessToken, coreAccessToken, participantsCategoryDataPayload,  APILabels.getParticipantsMeta);
    var usersObject = {};
    response.forEach(user => {
      usersObject[user.UserAlias] = { userAlias: user.UserAlias, displayName: user.DisplayName };
    })
    yield put({ type: GET_PARTICIPANTS_META_SUCCESS, payload: { response: usersObject } });

  } catch (error) {
    yield put({ type: GET_PARTICIPANTS_META_FAILURE, payload: { error: error } });
  }
}

/**
* For GetEngagementByID
* @param {{type: string , payload : number}} engagementByIdPayload - EngagementById payload
*/
function* getEngagementByIDSaga(engagementByIdPayload) {
  try {
    yield put({ type: GET_ENGAGEMENT_BY_ID_FETCHING });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }
    const response = yield call(getApiWorker, `${EndPoints.Engagements}/${engagementByIdPayload.payload}`, apiAccessToken, coreAccessToken, APILabels.getEngagementById);
    yield put({ type: GET_ENGAGEMENT_BY_ID_SUCCESS, payload: { id: engagementByIdPayload.payload, response } });

  } catch (error) {
    yield put({ type: GET_ENGAGEMENT_BY_ID_FAILURE, payload: { id: engagementByIdPayload.payload, error: error } });
  }
}

/**
* For GetOffersDocumentByID
* @param {{type: string , payload : number}} documentByIDPayload - OfferID payload
*/
function* getOffersDocumentByID(documentByIDPayload) {
  try {
    yield put({ type: GET_TERMS_AND_CONDITION_FETCHING });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }
    const response = yield call(getApiWorker, `${EndPoints.Offers}/${documentByIDPayload.payload}`, apiAccessToken, coreAccessToken, APILabels.offersTermsAndCondition);
    yield put({ type: GET_TERMS_AND_CONDITION_SUCCESS, payload: { id: documentByIDPayload.payload, response } });

  } catch (error) {
    yield put({ type: GET_TERMS_AND_CONDITION_FAILURE, payload: { id: documentByIDPayload.payload, error: error } });
  }
}

/**
* For function* sendOffersTCDocToSelf(OfferCode) {
* @param {{type: string , payload : number}} OfferCode - OfferCode payload
*/
function* sendOffersTCDocToSelf(OfferCode) {
  try {
    yield put({ type: SEND_EMAIL_TO_SELF_FETCHING });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }
    const response = yield call(postApiWorker, `${EndPoints.SendEmailToSelf}?offerCode=${OfferCode.payload}`, apiAccessToken, coreAccessToken, APILabels.sendEmailToSelf);
    yield put({ type: SEND_EMAIL_TO_SELF_SUCCESS, payload: { id: OfferCode.payload, response } });

  } catch (error) {
    yield put({ type: SEND_EMAIL_TO_SELF_FAILURE, payload: { id: OfferCode.payload, error: error } });
  }
}

/**
* For GetOfferByID
* @param {{type: string , payload : string}} offerByIdPayload - OfferById payload
*/
function* getOfferByIDSaga(offerByIdPayload) {
  try {
    yield put({ type: GET_OFFER_BY_ID_FETCHING });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }
    var response = yield call(getApiWorker, `${EndPoints.Offers}/${offerByIdPayload.payload}`, apiAccessToken, coreAccessToken, APILabels.getOfferById);
    if (response.length == 0) {
      throw Error("No offer found");
    }
    response = response[0];
    yield put({ type: GET_OFFER_BY_ID_SUCCESS, payload: { id: offerByIdPayload.payload, response } });

  } catch (error) {
    yield put({ type: GET_OFFER_BY_ID_FAILURE, payload: { id: offerByIdPayload.payload, error: error } });
  }
}



function* getAppContextSaga() {
  try {
    yield put({ type: CONTEXT_API_FETCHING, payload: true });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }

    const response = yield call(getApiWorker, EndPoints.ContextAPI, apiAccessToken, coreAccessToken, APILabels.getAppContext);

    yield put({ type: SET_SCOPES, payload: buildScopes(response.scopes) });
    yield put({ type: CONTEXT_API_SUCCESS, payload: { response } });

  } catch (error) {
    yield put({ type: CONTEXT_API_FAILURE, payload: { error: error } });
  }
}
function* getNotificationsSaga() {
  try {

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }

    const response = yield call(getApiWorker, `${EndPoints.NotificationsAPI}/get`, apiAccessToken, coreAccessToken, APILabels.getNotifications);
    yield put({ type: GET_NOTIFICATIONS_SUCCESS, payload: { response } });

  } catch (error) {
    yield put({ type: GET_NOTIFICATIONS_FAILURE, payload: { error: error } });
  }
}
/**
* For DismissNotification
* @param {{type: string,payload : INotifications[]}} dismissNotificationPayload 
*/
function* dismissNotificationsSaga(dismissNotificationPayload) {
  try {
    var dismissNotificationlst = dismissNotificationPayload.payload;
    yield put({ type: DISMISS_NOTIFICATIONS_FETCHING, payload: true });

    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);

    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }

    const response = yield call(postApiWorker, `${EndPoints.NotificationsAPI}/dismiss`, apiAccessToken, coreAccessToken,dismissNotificationlst, APILabels.dismissNotifications);
    yield put({ type: DISMISS_NOTIFICATIONS_SUCCESS, payload: { item: response } });

  } catch (error) {
    yield put({ type: DISMISS_NOTIFICATIONS_FAILURE, payload: { error: error } });
  }
}
function getApiWorker(endpoint, apiAccessToken, coreApiAccessToken, webApiIdentifier = "No identifier") {
  let startTime = Date.now();
  var url = AppConstants.APP_API_URL + endpoint;
  const user = userAgentApplication.getAccount();
  return new Promise((resolve, reject) => {
    if (typeof Worker !== "undefined") {
      const worker = new Worker("/getApi.worker.js");
      worker.addEventListener("message", e => {
        const {
          data: { error, data }
        } = e;
        if (!error) {
          logApiEvent(startTime, endpoint, apiMethodType.GET, url, webApiIdentifier, user.userName, user.idToken.roles);
          resolve(data);
        } else {
          logApiException(error, endpoint, apiMethodType.GET, url, webApiIdentifier, user.userName, user.idToken.roles);
          reject(error);
        }

        worker.terminate();
      });

      worker.postMessage({ url, apiAccessToken, coreApiAccessToken });
    } else {
      fetch(url, {
        headers: new Headers({
          Authorization: `Bearer ${apiAccessToken}`,
          coreApiAccessToken: `Bearer ${coreApiAccessToken}`
        }),
        method: apiMethodType.GET
      })
        .then(response => {
          if (!response.ok) {
            const error = new Error(response.statusText);
            console.log("error",error)
            logApiException(error, endpoint, apiMethodType.GET, url, webApiIdentifier, user.userName, user.idToken.roles);
            throw error;
          }
          logApiEvent(startTime, endpoint, apiMethodType.GET, url, webApiIdentifier, user.userName, user.idToken.roles);
          return response.json();
        })
        .then(resolve)
        .catch(reject);
    }
  });
}


function* postCoreAPIAccessTokenAction(tokenRefreshTimeout) {
  yield delay(tokenRefreshTimeout);
  yield call(onlyGetCoreAPIAccessToken);
}

function* onlyGetCoreAPIAccessToken() {
  const scopes = [
    `api://${AppConstants.CoreAppID}/access_as_user`
  ];
  const user = userAgentApplication.getAccount();
  try {
    if (user) {
      let accessToken = yield userAgentApplication.acquireTokenSilent({
        scopes: scopes
      });
      if (accessToken) {
        yield put({
          type: SET_CORE_API_ACCESS_TOKEN,
          payload: {
            accessToken
          }
        });
      }
      const tokenRefreshTimeout = new Date(accessToken.expiresOn.toISOString()) - new Date() - 3 * 60 * 1000;
      yield fork(postCoreAPIAccessTokenAction, tokenRefreshTimeout);
    } else {
      userAgentApplication.loginRedirect({ scopes: scopes });
    }
  } catch (error) {
    console.error("coreApiAccessToken error", error);
    yield userAgentApplication.acquireTokenRedirect({
      scopes: scopes
    });
  }
}

function* getCoreAPIAccessToken() {
  const scopes = [
    `api://${AppConstants.CoreAppID}/access_as_user`
  ];

  try {
    const user = userAgentApplication.getAccount();

    if (user) {
      let accessToken = yield userAgentApplication.acquireTokenSilent({
        scopes: scopes
      });
      if (accessToken) {
        yield put({
          type: SET_CORE_API_ACCESS_TOKEN,
          payload: {
            accessToken
          }
        });

        const tokenRefreshTimeout = new Date(accessToken.expiresOn.toISOString()) - new Date() - 3 * 60 * 1000;

        yield fork(postCoreAPIAccessTokenAction, tokenRefreshTimeout);
      }
    } else {
      //Note: Commenting here as we are seeing infinite loop in login
      //userAgentApplication.loginRedirect({ scopes: scopes });
    }
  } catch (error) {
    console.error("coreApiAccessToken error", error);
    yield userAgentApplication.acquireTokenRedirect({
      scopes: scopes
    });
  }
}

function* postAPIAccessTokenAction(tokenRefreshTimeout) {
  yield delay(tokenRefreshTimeout);
  yield call(onlyGetAPIAccessToken);
}

function* onlyGetAPIAccessToken() {
  const scopes = [
    `api://${AppConstants.ClientAppID}/access_as_user`
  ];

  const user = userAgentApplication.getAccount();
  try {
    if (user) {
      let accessToken = yield userAgentApplication.acquireTokenSilent({
        scopes: scopes
      });

      if (accessToken) {
        yield put({
          type: SET_API_ACCESS_TOKEN,
          payload: {
            accessToken
          }
        });
      }

      const tokenRefreshTimeout = new Date(accessToken.expiresOn.toISOString()) - new Date() - 3 * 60 * 1000;
      yield fork(postAPIAccessTokenAction, tokenRefreshTimeout);

    } else {
      userAgentApplication.loginRedirect({ scopes: scopes });
    }
  } catch (error) {
    console.error("apiAccessToken error", error);
    yield userAgentApplication.acquireTokenRedirect({
      scopes: scopes
    });
  }
}

function* getAPIAccessToken() {
  const scopes = [
    `api://${AppConstants.ClientAppID}/access_as_user`
  ];

  try {
    const user = userAgentApplication.getAccount();

    if (user) {
      let accessToken = yield userAgentApplication.acquireTokenSilent({
        scopes: scopes
      });
      if (accessToken) {
        yield put({
          type: SET_API_ACCESS_TOKEN,
          payload: {
            accessToken
          }
        });

        const tokenRefreshTimeout = new Date(accessToken.expiresOn.toISOString()) - new Date() - 3 * 60 * 1000;

        yield fork(postAPIAccessTokenAction, tokenRefreshTimeout);
      }
    } else {
      userAgentApplication.loginRedirect({ scopes: scopes });
    }
  } catch (error) {
    console.error("apiAccessToken error", error);
    yield userAgentApplication.acquireTokenRedirect({
      scopes: scopes
    });
  }
}

function* getUserPhoto(payload) {
  let inputdata = payload.payload;
  try {

    // -- Graph API
    const response = yield call(
      fetch,
      `${authConfig.graphEndpoint}/photos/${inputdata.size}/$value`,
      {
        headers: new Headers({
          Authorization: `Bearer ${inputdata.accessToken}`
        }),
        method: apiMethodType.GET
      }
    );

    if (response.ok === false) {
      throw new Error({ error: { ...response } });
    }

    let data = yield call(() => response.blob());


    const content = yield call(readAsURL, data);
    if (content) {
      let blobUrl = content;
      yield put({ type: UPDATE_GRAPH_STATE, payload: { photos: { [inputdata.alias]: blobUrl } } })
      yield put({ type: SET_USER_PHOTO, payload: { blobUrl } });
    }

  } catch (error) {
  }
}

function* userProfileChecker() {
  const state = yield select();
  if (!state?.auth?.userProfile)
    yield call(getUserProfile);
}

/**
* For GetUserProfile
* @param {string} userAlias -  Action
*/

function* getUserProfile() {
  try {

    //Graph API
    const state = yield select();
    const userAlias = state.auth.user.userName;
    let accessTokenObject = yield select(getGraphTokenFromStore);
    let accessToken = "";

    if (accessTokenObject && accessTokenObject.accessToken) {
      accessToken = accessTokenObject.accessToken;
    }

    let graphURL = "";
    graphURL = `https://graph.microsoft.com/v1.0/users?$count=true&$filter=endsWith(mail,'microsoft.com')&$select=id,displayName,mail,userPrincipalName,jobTitle&$search="userPrincipalName:${userAlias}"&$orderBy=displayName`;

    const response = yield call(
      fetch, graphURL, {
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        ConsistencyLevel: 'eventual'
      }),
      method: "GET"
    });

    if (response.ok !== true)
      throw new Error("User not fetched")


    let data = yield call(readCompletejson, response);

    if (data)
      yield put({ type: SET_USER_PROFILE, payload: data.value[0] });
    else {
      throw new Error("User not fetched");
    }

  } catch (error) {
    yield put({ type: SET_USER_PROFILE, payload: null });
  }
}

function* signIn() {
  //-- Initialize App insights
  if (isEmpty(InsightsClient.getAppInsightsKey()) && appInsightsConfigs.InsightsInstrumentationKey) {
    InsightsClient.InitializeInsights(appInsightsConfigs.InsightsInstrumentationKey);
  }

  try {
    const fakeUserPayload = {
      user: { name: "Fake user" },
      groups: [],
      accessToken: "fake-access-token"
    }

    if (userAgentApplication.clientId) {
      let user = userAgentApplication.getAccount();
      const scopes = authConfig.scopes;
      if (!user) {
        userAgentApplication.handleRedirectCallback(error => {
          if (error) {
            const errorMessage = error.errorMessage
              ? error.errorMessage
              : "Unable to acquire access token.";
            // -- setState works as long as navigateToLoginRequestUrl: false
            console.error(errorMessage);
          }
        });
        userAgentApplication.loginRedirect({ scopes: scopes });
      } else {
        yield call(acquireAccessToken, scopes);
      }
    }
    else {
      yield put({ type: SET_ACCESS_TOKEN, payload: fakeUserPayload });
    }
  }
  catch (exception) {

    const fakeError = 'Fake error';
    yield put({ type: SIGN_IN_FAILURE, payload: { error: fakeError } });
    if (userAgentApplication.clientId) {
      yield call(signOut);
    }
  }
}

//-- For signout
function* signOut() {
  if (userAgentApplication.getAccount()) {
    userAgentApplication.logout();
  }
}

function* postAccessTokenAction(tokenRefreshTimeout) {
  yield delay(tokenRefreshTimeout);
  yield call(acquireAccessToken);
}

var calledOnce = false;

function* acquireAccessToken() {
  let scopes = authConfig && authConfig.scopes ? authConfig.scopes : null;

  try {
    // -- Checking if user is authenticated and if their idToken has expired
    const user = userAgentApplication.getAccount();
    // -- If user not present
    if (!user) {
      userAgentApplication.loginRedirect({ scopes: scopes });
    } else {
      let accessToken = yield userAgentApplication.acquireTokenSilent({
        scopes: scopes
      });

      if (!calledOnce) {
        calledOnce = true;
        yield put({ type: UPDATE_GRAPH_STATE, payload: { users: { [user.userName]: { name: user.name, alias: user.userName } } } });
      }
      yield put({
        type: SET_ACCESS_TOKEN,
        payload: {
          accessToken,
          user,
          groups: []
        }
      });

      //set token refresh timeout
      const tokenRefreshTimeout = new Date(accessToken.expiresOn.toISOString()) - new Date() - 3 * 60 * 1000;

      if (accessToken) {
        yield fork(postAccessTokenAction, tokenRefreshTimeout);
      }
    }
  } catch (error) {
    console.error("accesstoken error", error);
    yield userAgentApplication.acquireTokenRedirect({
      scopes: scopes
    });
  }
}

function* postPowerBIAccessTokenAction(tokenRefreshTimeout) {
  yield delay(tokenRefreshTimeout);
  yield call(getPowerBIAPIAccessToken);
}

function* getPowerBIAPIAccessToken() {
  let scopes = authConfig && authConfig.scopes ? authConfig.scopes : null;
  try {
    // -- Checking if user is authenticated and if their idToken has expired
    const user = userAgentApplication.getAccount();
    // -- If user not present
    if (!user) {
      userAgentApplication.loginRedirect({ scopes: scopes });
    } else {
      let accessToken = yield userAgentApplication.acquireTokenSilent(powerBIScopes);
      yield put({
        type: SET_POWER_BI_ACCESS_TOKEN,
        payload: {
          accessToken
        }
      });
      //set token refresh timeout
      const tokenRefreshTimeout = new Date(accessToken.expiresOn.toISOString()) - new Date() - 3 * 60 * 1000;

      if (accessToken) {
        yield fork(postPowerBIAccessTokenAction, tokenRefreshTimeout);
      }
    }
  } catch (error) {
    console.error("powerbitoken error", error);
    yield userAgentApplication.acquireTokenRedirect(powerBIScopes);
  }
}
function* getCommonServicesToken() {
  try {
    yield put({ type: COMMONTOKEN_API_FETCHING, payload: true });
    var apiAccessTokenObj = yield select(getApiTokenFromStore);
    var coreAccessTokenObj = yield select(getCoreTokenFromStore);
    var apiAccessToken = "";
    var coreAccessToken = "";
    if (apiAccessTokenObj && apiAccessTokenObj.accessToken) {
      apiAccessToken = apiAccessTokenObj.accessToken;
    }
    if (coreAccessTokenObj && coreAccessTokenObj.accessToken) {
      coreAccessToken = coreAccessTokenObj.accessToken;
    }
    var url = AppConstants.APP_API_URL + EndPoints.CommonTokenAPI;    
      const response = yield fetch(url, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
           'Content-Type': 'application/json',
           Authorization: `Bearer ${apiAccessToken}`,
        coreApiAccessToken: `Bearer ${coreAccessToken}`
          },
          
        })
      
      const data = yield response.json()
      yield put({type: 'COMMONTOKEN_API_SUCCESS', payload: data});   
   
    //const response = yield call(getApiWorker, EndPoints.CommonTokenAPI, apiAccessToken, coreAccessToken, APILabels.getcommonServicesToken);
     //yield put({ type: COMMONTOKEN_API_SUCCESS, payload: { response} });
  } catch (error) {
    yield put({ type: COMMONTOKEN_API_FAILURE, payload: { error: error } });
  }
}

// function* getCommonServiceToken() {
//   const isIframe = window !== window.parent && !window.opener;
//   if (!(window.self !== window.top && isIframe)) {
//     let scopes = msalConfig && msalConfig.scopes ? msalConfig.scopes : null;
//     try {
//       // -- Checking if user is authenticated and if their idToken has expired
//       const user = userAgentApplication.getAccount();
//       // -- If user not present
//       if (!user) {
//         userAgentApplication.loginRedirect({ scopes: scopes });
//       } else {
//         let accessToken = yield userAgentApplication.acquireTokenSilent(powerBIScopes);
//         yield put({
//           type: SET_COMMON_SERVICE_TOKEN,
//           payload: {
//             accessToken
//           }
//         });
//         //set token refresh timeout
//         const tokenRefreshTimeout = new Date(accessToken.expiresOn.toISOString()) - new Date() - 3 * 60 * 1000;

//         if (accessToken) {
//           yield fork(postPowerBIAccessTokenAction, tokenRefreshTimeout);
//         }
//       }
//     } catch (error) {
//       console.error("Common Service error", error);
//       yield userAgentApplication.acquireTokenRedirect(powerBIScopes);
//     }
//   }
// }