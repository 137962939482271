import { IAction } from "../../constants/types/IAction";
import { IDataResponse } from "../../constants/types/API/Apiresponse";
import { GET_NOTIFICATIONS_FAILURE, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS,GET_NOTIFICATIONS_FETCHING, DISMISS_NOTIFICATIONS_FETCHING, DISMISS_NOTIFICATIONS_SUCCESS, DISMISS_NOTIFICATIONS_FAILURE } 
from "../../constants/actions/API/Notifications";


const initialState: IDataResponse = {
  data: null,
  fetching: false,
  error: null,
};

export const GetNotificationsReducers = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_FETCHING:
      return { ...state, fetching: true, data: null, error: null };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.response,
        fetching: false,
        error: null,
      };
    case GET_NOTIFICATIONS_FAILURE:
      return { ...state, error: action.payload, fetching: false, data: null };
    default:
      return state;
  }
};

export const DismissNotificationsReducers = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case DISMISS_NOTIFICATIONS_FETCHING:
      return { ...state, fetching: true, data: null, error: null };
    case DISMISS_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        error: null,
      };
    case DISMISS_NOTIFICATIONS_FAILURE:
      return { ...state, error: action.payload, fetching: false, data: null };
    default:
      return state;
  }
};


