import React, { Component } from 'react';
import { Image, ImageFit } from 'office-ui-fabric-react/lib/Image';
import menu from '../../assets/navigation/menu.svg';
import './Navigation.css';
import AppConstants from '../../constants/AppConstants';

interface ISideNavProps {
    navBarOpen: boolean;
    toggleNavBar: Function;
}

class SideNav extends Component<ISideNavProps, any> {

    constructor(props: ISideNavProps) {
        super(props);
    }

    render() {
        return (
            <div>
                {AppConstants.EnableSideNav?
                (
                    <div className={`nav-container ${this.props.navBarOpen}`}>
                        <div className="menu-toggle" onClick={() => this.props.toggleNavBar(!this.props.navBarOpen)}>
                            <div className="nav-item"><abbr title="Menu"><Image
                                src={menu}
                                imageFit={ImageFit.contain}
                                width={18}
                                height={18}
                                className={`nav-item--img ${this.props.navBarOpen}`}
                            /></abbr><span className={`nav-item--text ${this.props.navBarOpen}`}>Menu</span></div>
                        </div>
                    </div>
                )
                :
                (<div></div>)}
            </div>);
    }
}

export default SideNav;