import * as React from "react";
import {
  Dialog,
  DialogType,
  DialogFooter,
} from "office-ui-fabric-react/lib/Dialog";
import {
  PrimaryButton,
  DefaultButton,
} from "office-ui-fabric-react/lib/Button";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { useId } from "@uifabric/react-hooks";
import "./ShowOfferImageDialog.css";
const dialogStyles = { main: { maxWidth: 450,  boxShadow: 'none' } };
const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
};

interface Dialogprops {
  toHide: boolean;
  toHideDialog: (toHide: boolean) => void;
  subText: any;
  title: string;
}

export const ShowOfferImageDialog: React.FunctionComponent<Dialogprops> = (props) => {
  const labelId: string = useId("dialogLabel");
  const subTextId: string = useId("subTextLabel");

  const dialogContentProps = {
    type: DialogType.normal,
    title: props.title,
    closeButtonAriaLabel: "Close",
    subText: props.subText,
  };

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true,
      styles: dialogStyles,
      dragOptions: dragOptions,
    }),
    [labelId, subTextId]
  );

  return (
    <>
      <Dialog
        hidden={props.toHide}
        onDismiss={() => {
          props.toHideDialog(true);
        }}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
        className="dialog-md"
      >
        {props.children}
      </Dialog>
    </>
  );
};
