import React, { Component } from "react";
import { Home, IMenuItem, Offers } from "../../constants/types/UI/Menu";
import { connect } from "react-redux";
import { changeMenuBar } from "../../actions/UI/MenuBar";
import { IAppScopes, IUserContext } from "../../constants/types/UI/Generic";
import _ from "lodash";
import AppConstants, {
  AppRoles,
  entityQueryString,
} from "../../constants/AppConstants";
import { IDataResponse } from "../../constants/types/API/Apiresponse";
import * as ROUTES from "../../constants/routes";
import { logEvent, Navigation } from "../../utils/insightsClient";
import {
  Dialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  DialogType,
} from "office-ui-fabric-react";
import { loadState } from "../../utils/localStorage";
import { IComponentStyles } from "@uifabric/foundation";
import { Tag } from '@coherence-design-system/tag';

interface IMenuBarProps {
  menuBarState: { [key: string]: IMenuItem };
  history: any;
  changeMenuBar: Function;
  appScopes: IAppScopes;
  userContext: IDataResponse<IUserContext>;
  parsedQS: any;
}

interface IMenuBarState {
  allowedMenuItems: string[];
  showDialog: boolean;
  currentMenu: string;
}
const dialogContentProps = {
  type: DialogType.normal,
  title: "Discard Changes",
  subText: "Are you sure you want to discard the changes?",
  style: {
    root: {
      backgroundColor: "transparent",
    },
  },
};
class MenuBar extends Component<IMenuBarProps, IMenuBarState> {
  constructor(props: IMenuBarProps) {
    super(props);
    this.state = {
      allowedMenuItems: this.constructMenuItems(),
      showDialog: false,
      currentMenu: "",
    };
  }

  constructMenuItems = () => {
    const clonedScopes: any = _.cloneDeep(this.props.appScopes);
    const allowedMenuItems = Object.keys(this.props.menuBarState).filter(
      (menuItem) => {
        const itemName =
          menuItem.charAt(0).toUpperCase() + menuItem.substring(1);
        if (menuItem == Home) {
          return true;
        } else if (menuItem == Offers) {
          return this.props?.userContext?.data?.roles?.length != undefined &&
            this.props?.userContext?.data?.roles?.length > 0
            ? true
            : false;
        } else {
          return (
            !!clonedScopes[itemName].Show &&
            !(
              !this.props.userContext.data?.roles.find(
                (role: any) => role.name === AppRoles.SiteAdmin.roleName
              ) &&
              [
                ROUTES.OFFERS_URL.toUpperCase(),
                ROUTES.REPORTS_URL.toUpperCase(),
              ].indexOf(menuItem.toUpperCase()) > -1
            )
          );
        }
      }
    );
    return allowedMenuItems;
  };
  closeModal = () => {
    this.setState({
      showDialog: false,
    });
  };
  changeMenu = (menuItem: string) => {
    sessionStorage.setItem("offerPanelOpen", String(false));
    window.dispatchEvent(new Event("storage"));
    this.setState({
      showDialog: false,
    });
    if (this.props.history["location"]["search"] !== "") {
      var url = this.props.history["location"]["search"];
      var urlParts = url.split("?");
      var params = new URLSearchParams(urlParts[1]);
      params.delete("id");
      var newUrl = urlParts[0] + "?" + params.toString();
      this.props.history.push(
        "/" + this.props.menuBarState[menuItem].itemName + newUrl
      );
    } else {
      this.props.history.push("/" + this.props.menuBarState[menuItem].itemName);
    }
    this.props.changeMenuBar({
      itemName: this.props.menuBarState[menuItem].itemName,
      isActive: true,
    });
  };
  render() {
    const parsedQS = this.props.parsedQS;
    var engProgramName =
      parsedQS != null ? parsedQS[entityQueryString.ProgramName] : "";
    return (
      <div className="menu-container">
        {this.state.allowedMenuItems.map((menuItem, index) => (
          <div
            key={`${index}-${menuItem}`}
            className={`menu-item`}
            onClick={() => {
              logEvent(Navigation, {
                AppName: AppConstants.ApplicationAlias,
                AppLayer: "UI",
                MetricName: "Page Navigation",
                UserScenario: "User navigations through CAPE menu",
                User: { Alias: this.props.userContext.data?.email },
                ApplicationRoles: this.props.userContext.data?.roles,
                EntityOperation: "Click",
                EntityName: this.props.menuBarState[menuItem].itemName,
                MetaData: {
                  programName: engProgramName ? engProgramName : null,
                },
                traceSeverity: "Low",
              });
              if (
                (this.props.history["location"]["pathname"].replace("/", "") ===
                  "engagements" ||
                  this.props.history["location"]["pathname"].replace(
                    "/",
                    ""
                  ) === "offers") &&
                this.props.history["location"]["search"].includes("id")
              ) {
                this.setState({
                  showDialog: true,
                  currentMenu: this.props.menuBarState[menuItem].itemName,
                });
              } else {
                this.changeMenu(menuItem);
              }
            }}
          >
            {this.state.showDialog && (
              <Dialog hidden={false} dialogContentProps={dialogContentProps}>
                <DialogFooter>
                  <PrimaryButton
                    aria-label="Discard"
                    role="Button"
                    text="Discard"
                    onClick={() => this.changeMenu(this.state.currentMenu)}
                  />
                  <DefaultButton
                    aria-label="Cancel"
                    role="Button"
                    onClick={() => this.closeModal()}
                    text="Cancel"
                  />
                </DialogFooter>
              </Dialog>
            )}
            <a
              className="menubar-main-name"
              href="javascript:void(0)"
              id={
                index +
                1 +
                "-CAPE-Header-Menubar-" +
                this.props.menuBarState[menuItem].displayName
              }
              role="button"
              aria-label={
                this.props.menuBarState[menuItem].displayName +
                (this.props.menuBarState[menuItem].isActive ? "Selected" : "")
              }
            >
              {this.props.menuBarState[menuItem].displayName}
              {this.props.menuBarState[menuItem].displayName === "Reports" ? (
                <span className="super">
                   <Tag text="Admin" />
                   </span>
              ) : (
                ""
              )}
            </a>
            <div
              className={`${
                this.props.menuBarState[menuItem].isActive
                  ? "menubar-active"
                  : "menubar-inactive"
              }`}
            ></div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state: { ui: any; auth: any; api: any }) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
