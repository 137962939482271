//SharedColors and NeutralColors exist in fluent-ui but not in office-ui-fabric-react
//Using this file in the meantime
export const NeutralColors = {
  black: "#000000",
  gray20: "#edebe9",
  darkGrey: "#797775",
  white: "#ffffff",
};
export const SharedColors = {
    
  cyanBlue10: "#0078d4",
  cyanBlue15: "#106ebe",
  cyanBlue17: "#1664A7",
  cyanBlue20: "#004e8c",
  cyanGreen: "#E3F7EF",
  mintGreen: "#C0ECDD",
  darkSilver: "#707070",
  darkBlue: "#0078d4",
  lightBlue: "#CCD6FF",
  lightViolet: "#ebefff",
};

export const CAPECopilotConstants = {
  teachingBubbleHeader: "Copilot is now available on CAPE!",
  teachingBubbleContent: "Experience the power of AI with the new CAPE Copilot! Use natural language to ask questions about CAPE, get answers to FAQs on CAPE process, and receive customer guidance on how they can delegate access through Azure Lighthouse Offers",
  disclaimerLine1: "Responses on CAPE Engagement(s) data will be available in future.",
  disclaimerLine2: "AI-generated content may be incorrect",
  footerLabelText: "Copilot Chat",
  footerPlaceHolderText: "Ask me support questions",
}
