import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { useId } from '@uifabric/react-hooks';

const dialogStyles = { main: { maxWidth: 450}, overlay: { boxShadow: 'none' } };
const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
};

interface Dialogprops {
    functionality: () => void;
    toHide: boolean;
    toHideDialog: (toHide: boolean) => void;
    subText: string;
    title: string;
}

export const MPDialog: React.FunctionComponent<Dialogprops> = (props) => {
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const dialogContentProps = {
        type: DialogType.normal,
        title: props.title,
        closeButtonAriaLabel: 'Close',
        subText: props.subText,
    };

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,
            styles: dialogStyles,
            dragOptions: dragOptions
        }),
        [labelId, subTextId],
    );

    return (
        <>
            <Dialog
                hidden={props.toHide}
                onDismiss={() => { props.toHideDialog(true) }}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                {props.children}
                <DialogFooter>
                    <PrimaryButton onClick={() => { props.functionality() }} text="Proceed" />
                    <DefaultButton onClick={() => { props.toHideDialog(true) }} text="Cancel" />
                </DialogFooter>
            </Dialog>
        </>
    );
};
