import {
  FETCHING_PARTICIPANTS,
  FETCHING_PARTICIPANTS_FAILURE,
  FETCHING_PARTICIPANTS_SUCCESS,
  GET_PARTICIPANTS_CATEGORIES_FAILURE,
  GET_PARTICIPANTS_CATEGORIES_FETCHING,
  GET_PARTICIPANTS_CATEGORIES_SUCCESS,
} from "../../constants/types/API/Participants";

import { IAction } from "../../constants/types/IAction";
import { IDataResponse } from "../../constants/types/API/Apiresponse";


const initialState: IDataResponse = {
  data: null,
  fetching: false,
  error: null,
};

const ParticipantsReducers = (state = initialState, action: IAction) => {
  switch (action.type) {
    case FETCHING_PARTICIPANTS:
      return { ...state, fetching: true, data: null, error: null };
    case FETCHING_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        data: action.payload.response,
        fetching: false,
        error: null,
      };
    case FETCHING_PARTICIPANTS_FAILURE:
      return { ...state, error: action.payload, fetching: false, data: null };
    default:
      return state;
  }
};

export default ParticipantsReducers;

export const ParticipantsCategoryReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_PARTICIPANTS_CATEGORIES_FETCHING:
      return { ...state, fetching: true, data: null, error: null };
    case GET_PARTICIPANTS_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: action.payload.response,
        fetching: false,
        error: null,
      };
    case GET_PARTICIPANTS_CATEGORIES_FAILURE:
      return { ...state, error: action.payload, fetching: false, data: null };
    default:
      return state;
  }
};

