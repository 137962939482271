import { IAction } from "../../constants/types/IAction";
import {
  SET_ENGAGEMENT_DETAIL,
  GET_ENGAGEMENTS,
  GET_ENGAGEMENT_BY_ID,
} from "../../constants/actions/UI/Engagements";
import { IEngagementDetail, IGetEngagementCategoryPayload, IGetEngagementPayload } from "../../constants/types/UI/Engagements";
import {
  UPDATE_ENGAGEMENTS,
  GET_ENGAGEMENT_CATEGORIES,
} from "../../constants/types/API/Engagements";

export function setEngagementDetails(payload: IEngagementDetail): IAction {
  return {
    type: SET_ENGAGEMENT_DETAIL,
    payload: payload,
  };
}

export function getEngagements(payload : IGetEngagementPayload): IAction {
  return {
    type: GET_ENGAGEMENTS,
    payload
  };
}

export function getEngagementsCategory(payload : IGetEngagementCategoryPayload): IAction {
  return {
    type: GET_ENGAGEMENT_CATEGORIES,
    payload: payload,
  };
}

export function getEngagementsByID(id : number): IAction {
  return {
    type: GET_ENGAGEMENT_BY_ID,
    payload: id
  };
}

export function updateEngagements(engagementsData : any) {
  return {
    type : UPDATE_ENGAGEMENTS,
    payload : engagementsData
  }
}
