import {
    ApplicationInsights, IEventTelemetry, IPageViewTelemetry, IDependencyTelemetry, IExceptionTelemetry,
    IMetricTelemetry, ITraceTelemetry, IPageViewPerformanceTelemetry
} from '@microsoft/applicationinsights-web';
import platform from 'platform';
import AppConstants from '../constants/AppConstants';
import { getWindowLocationHref } from "./browserGlobals";

export class InsightsClient {
    private static instrumentationKey: string;
    private static isInstrumentationKeyLoaded: boolean = false;
    private static AppInsights: ApplicationInsights;

    /**
     * getAppInsightsKey is to get Application Insights Instrumentation Key.
     */
    public static getAppInsightsKey(): string {
        return InsightsClient.instrumentationKey;
    }

    /**
     * Initialize for Application Insights.
     * This will download and install with an Application Insights Instrumentation Key.
     * @param {string} appInsightsKey - Application Insights Instrumentation Key
     */
    public static InitializeInsights(appInsightsKey: string) {
        if (!InsightsClient.isInstrumentationKeyLoaded) {
            this.instrumentationKey = appInsightsKey;
            if (InsightsClient.instrumentationKey) {
                this.AppInsights = new ApplicationInsights({
                    config: {
                        instrumentationKey: appInsightsKey
                    }
                });
                this.AppInsights.loadAppInsights();
                InsightsClient.isInstrumentationKeyLoaded = true;
            }
        }
    }


    /**
     * Starts timing how long the user views a page or other item. Call this when the page opens.
     * This method doesn't send any telemetry. Call {@link stopTrackTelemetry} to log the page when it closes.
     * @param   name  A string that idenfities this item, unique within this HTML document. Defaults to the document title.
     */
    public static startTrackPage(name?: string): any {
        if (InsightsClient.isInstrumentationKeyLoaded && this.AppInsights) {
            return this.AppInsights.startTrackPage(name);
        }
    }

    /**
     * Logs how long a page or other item was visible, after {@link startTrackPage}. Call this when the page closes.
     * @param   name  The string you used as the name in startTrackPage. Defaults to the document title.
     * @param   url   String - a relative or absolute URL that identifies the page or other item. Defaults to the window location.
     * @param   properties  map[string, string] - additional data used to filter pages and metrics in the portal. Defaults to empty.
     * @param   measurements    map[string, number] - metrics associated with this page, displayed in Metrics Explorer on the portal. Defaults to empty.
     */
    public static stopTrackPage(
        name?: string,
        url?: string,
        properties?: { [name: string]: string },
        measurements?: { [name: string]: number }): any {
        if (InsightsClient.isInstrumentationKeyLoaded && this.AppInsights) {
            return this.AppInsights.stopTrackPage(
                name,
                url,
                properties,
                measurements
            );
        }
    }

    /**
     * Logs that a page or other item was viewed.
     * @param   telemetry   object that identifies the page or other item. Defaults to the window location.
     */
    public static trackPageView(telemetry?: IPageViewTelemetry) {
        if (InsightsClient.isInstrumentationKeyLoaded && this.AppInsights) {
            return this.AppInsights.trackPageView(telemetry);
        }
    }

    /**
    * Logs that a page or other item was viewed.
    * @param   telemetry   object that identifies the page along with its performance aspects. Defaults to the window location.
    */
    public static trackPageViewPerformance(telemetry: IPageViewPerformanceTelemetry) {
        if (InsightsClient.isInstrumentationKeyLoaded && this.AppInsights) {
            return this.AppInsights.trackPageViewPerformance(telemetry);
        }
    }

    /**
     * Start timing an extended event. Call {@link stopTrackEvent} to log the event when it ends.
     * @param   name    An optional string that identifies this event uniquely within the document.
     */
    public static startTrackEvent(name?: string): any {
        if (InsightsClient.isInstrumentationKeyLoaded && ApplicationInsights) {
            return this.AppInsights.startTrackEvent(name);
        }
    }

    /**
     * Log an extended event that you started timing with {@link startTrackEvent}.
     * @param   name    The string you used to identify this event in startTrackEvent.
     * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
     * @param   measurements    map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     */
    public static stopTrackEvent(
        name: string,
        properties?: { [name: string]: string },
        measurements?: { [name: string]: number }): any {
        if (InsightsClient.isInstrumentationKeyLoaded && ApplicationInsights) {
            return this.AppInsights.stopTrackEvent(
                name,
                properties,
                measurements
            );
        }
    }

    /**
     * Log a user action or other occurrence.
     * @param   telemetry string describes this event in the page
     * @param   props  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
     */
    public static trackEventInternal(telemetry: string, props?: any) {
        let telemetryEvent: IEventTelemetry = {
            name: telemetry,
        };

        if (this.AppInsights) {
            return this.AppInsights.trackEvent(telemetryEvent, props);
        }
    }

    /**
     * Log a user action or other occurrence.
     * @param   telemetry string describes this event in the page
     * @param   props  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
     */
    public static trackEvent(telemetryEvent: IEventTelemetry, props?: any) {

        if (this.AppInsights) {
            return this.AppInsights.trackEvent(telemetryEvent, props);
        }
    }

    /**
     * Log a dependency call
     * @param  telemetry of type IDependancyTelemetry used to track dependent data of any log event
     */
    public static trackDependencyData(telemetry: IDependencyTelemetry): any {
        if (InsightsClient.isInstrumentationKeyLoaded && this.AppInsights) {
            return this.AppInsights.trackDependencyData(telemetry);
        }
    }

    /**
     * Log an exception you have caught.
     * @param   error any object which logs the rrror from a catch clause, or the string error message.
     * @param   prop map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
     */
    public static trackException(error: any, prop?: any) {
        let telemetry: IExceptionTelemetry = {
            exception: error,
            properties: prop
        };

        //to log message explicitly as insight client not picking from error object.
        if (telemetry.exception) {
            telemetry.exception.message = error?.error !== undefined ? error?.error : error;
        }

        if (InsightsClient.isInstrumentationKeyLoaded && ApplicationInsights) {
            return this.AppInsights.trackException(telemetry);
        }
    }

    /**
     * Log a numeric value that is not associated with a specific event. Typically used to send regular reports of performance indicators.
     * To send a single measurement, use just the first two parameters. If you take measurements very frequently, you can reduce the
     * telemetry bandwidth by aggregating multiple measurements and sending the resulting average at intervals.
     * @param   telemetry of type IMetricTelemtry to tracking the Metric
     */
    public static trackMetric(telemetry: IMetricTelemetry): any {
        if (InsightsClient.isInstrumentationKeyLoaded && ApplicationInsights) {
            return this.AppInsights.trackMetric(telemetry);
        }
    }

    /**
     * Log a diagnostic message.
      * @param   telemetry of type ITraceTelemetry to tracking the Trace in the application
     */
    public static trackTrace(telemetry: ITraceTelemetry): any {
        if (InsightsClient.isInstrumentationKeyLoaded && ApplicationInsights) {
            return this.AppInsights.trackTrace(telemetry);
        }
    }

    /**
     * Immediately send all queued telemetry.
     */
    public static flush(): any {
        if (InsightsClient.isInstrumentationKeyLoaded && this.AppInsights) {
            return this.AppInsights.flush();
        }
    }

    /**
     * Sets the autheticated user id and the account id in this session.
     * User auth id and account id should be of type string. They should not contain commas, semi-colons, equal signs, spaces, or vertical-bars.     *
     * @param authenticatedUserId {string} - The authenticated user id. A unique and persistent string that represents each authenticated user in the service.
     * @param accountId {string} - An optional string to represent the account associated with the authenticated user.
     * @param storeInCookie {boolean} - A boolean flag used to denote whether the authorization can be stored in Cookie or not.
     */
    public static setAuthenticatedUserContext(
        authenticatedUserId: string,
        accountId?: string,
        storeInCookie?: boolean
    ): any {
        if (InsightsClient.isInstrumentationKeyLoaded && ApplicationInsights) {
            return this.AppInsights.setAuthenticatedUserContext(
                authenticatedUserId,
                accountId,
                storeInCookie
            );
        }
    }

    /**
     * Clears the authenticated user id and the account id from the user context.
     */
    public static clearAuthenticatedUserContext(): any {
        if (InsightsClient.isInstrumentationKeyLoaded && ApplicationInsights) {
            return this.AppInsights.clearAuthenticatedUserContext();
        }
    }
}

export const appInsightsConfigs = {
    InsightsInstrumentationKey: AppConstants.InsightsInstrumentationKey,
    LognamePlaceholder: `${AppConstants.ApplicationAlias} - `
};

export const logEvent = (name: any, props: any) => {
   
    let telemetry = appInsightsConfigs.LognamePlaceholder + name;
    InsightsClient.trackEventInternal(telemetry, props);
}

export const logException = (name: any, error: any) => {
    let telemetry = appInsightsConfigs.LognamePlaceholder + name;
    let properties = {
        name: telemetry,
    };

    InsightsClient.trackException(error, properties);
}

export const logApiException = (error: any, endpoint: string, methodType: string,
     url: string, WebApiIdentifier : string = "No identifier", userAlias: string, roles: object) => {
    let telemetry = appInsightsConfigs.LognamePlaceholder + ApiCall + "Failure";

    let properties = {
        AppName:AppConstants.ApplicationAlias,
        AppLayer:"UI",
        MetricName:"APICallFailure",
        UserScenario:WebApiIdentifier,
        EntityName:endpoint,
        EntityOperation:methodType,
        user:{
            Alias:userAlias
        },
        applicationRoles:roles,
        metadata:{ 
            WebAPIUrl:url,
            WebUIUrl: getWindowLocationHref()            
        },
        traceSeverity:"High"
    };

    InsightsClient.trackException(error, properties);
}

export const logApiEvent = (startTime: number, endpoint: string, methodType: string,
     url: string, WebApiIdentifier : string = "No identifier", userAlias: string, roles: object) => {
    let endTime = Date.now();
    let telemetry = appInsightsConfigs.LognamePlaceholder + ApiCall;
    let properties = {
        AppName:AppConstants.ApplicationAlias,
        AppLayer:"UI",
        MetricName:"APICall",
        UserScenario:WebApiIdentifier,
        EntityName:endpoint,
        EntityOperation:methodType,
        user:{
            Alias:userAlias
        },
        applicationRoles:roles,
        metadata: {
            WebAPIUrl: url,
            WebUIUrl: getWindowLocationHref(),
            totalMilliseconds: endTime - startTime
        },
        traceSeverity:"Medium"
    };

    InsightsClient.trackEventInternal(telemetry, properties);
}

export const Navigation="ClickTracking-Navigation";
export const ActionTrackingEngagement="ActionTracking-Engagement";
export const ClickTrackingEngagement="ClickTracking-Engagement";
export const ActionTrackingSearch="ActionTracking-search";
export const SupportMenuItemSelected = "ActionTracking-support";
export const TeamsMenuClicked="ActionTracking-Teams";
export const NotificationClick="ActionTracking-Notification";
export const ApiCall="ActionTracking-APICall";
export const ProgramReferal="ClickTracking-ProgramReferal";
export const Feedback_Rating = "ClickTracking-CSAT";
export const NotificationPreferences = "ActionTracking-NotificationPreference";
export const ProfileSettingsClick="ClickTracking-ProfileSettings";

//to be changed
export const OfferViewHelp = "Offer_View_Help";
export const CreateOffer = "CreateOffer";
export const ActionTrackingOffer = "ActionTracking-Offer";

export const DownloadOffersTCPDF = "Download Legal terms and condition";
export const EmailOffersTCPDFToSelf = "ClickTracking-Email Legal Terms and condition to self";
