import React, { Component } from "react";
import "./Reports.css";
import { models, Report, Embed, service, Page } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import AppConstants from "../../constants/AppConstants";
import { connect } from 'react-redux';


interface IReportsProps {
    powerBIAccessToken: any | null;
}

interface IReportsState {
    report: Report | null;

}

class Reports extends Component<IReportsProps, IReportsState> {

    constructor(props: IReportsProps) {
        super(props);
        this.state = {
            report: null
        }
    }

    eventHandlersMap = new Map([
        ['loaded', function () {
            console.log('Report has loaded');
        }],
        ['rendered', function () {
            console.log('Report has rendered');
        }],
        ['error', function (event?: service.ICustomEvent<any>) {
            if (event) {
                console.error(event.detail);
            }
        }]
    ]);

    render() {
        return (
            <div role="main" aria-label="Reports">
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                        id: AppConstants.ConsumptionReportID,
                        embedUrl: AppConstants.ConsumptionReportEmbedUrl,
                        accessToken: this.props.powerBIAccessToken?.accessToken ?? "",
                        tokenType: models.TokenType.Aad,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: true,
                                    visible: true
                                }
                            },
                            background: models.BackgroundType.Transparent,
                        }
                    }}
                    eventHandlers={this.eventHandlersMap}
                    cssClassName={"bi-report"}
                    getEmbeddedComponent={(embedObject: Embed) => {
                        console.log(`Embedded object of type "${embedObject.embedtype}" received`);
                        this.setState({ report: embedObject as Report });
                    }}
                />
            </div>
        );
    }

}

const mapStateToProps = (state: { auth: any }) => ({
    powerBIAccessToken: state.auth.powerBIAccessToken,
})

const mapDispatchToProps = (dispatch: any) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Reports);