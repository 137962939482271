import {
    GET_METADATA_FETCHING, GET_OFFERS_DROPDOWN_SUCCESS, GET_OFFERS_DETAILS_SUCCESS,
    GET_ENGAGEMENTS_DROPDOWN_SUCCESS,
    GET_METADATA_FAILURE,
    GET_PARTICIPANTS_META_FETCHING,
    GET_PARTICIPANTS_META_FAILURE,
    GET_PARTICIPANTS_META_SUCCESS,
} from '../../constants/actions/API/MetaData';

import { IAction } from '../../constants/types/IAction';
import { IDataResponse } from '../../constants/types/API/Apiresponse';
import { MetaDataHelper } from '../../constants/types/API/MetaData';
import _ from 'lodash';
import { GET_PARTICIPANTS_CATEGORIES_SUCCESS } from '../../constants/types/API/Participants';

const initialState: IDataResponse = {
    data: _.cloneDeep(MetaDataHelper),
    fetching: true,
    error: null
}

//Helper variable
var ApisCalled = 0;

//Helper function
const changeStateOnSuccess = (state : any,payload : any) => {
    ApisCalled++;
    if (ApisCalled == 3) {
        return {
            ...state,
            data: {...state.data,...payload},
            fetching : false, error : null
        };
    }
    else {
        return {
            ...state,
            data: {...state.data,...payload}
        }
    }
}


const MetaDataReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case GET_METADATA_FETCHING:
            return { ...state, fetching: true, error: null };
        case GET_OFFERS_DROPDOWN_SUCCESS:
            return changeStateOnSuccess(state,action.payload.response);
        case GET_OFFERS_DETAILS_SUCCESS:
            return changeStateOnSuccess(state,action.payload.response);
        case GET_ENGAGEMENTS_DROPDOWN_SUCCESS:
            return changeStateOnSuccess(state,action.payload.response);
        case GET_METADATA_FAILURE:
            return { ...state, error: action.payload, fetching: false };
        default:
            return state;
    }
}

export default MetaDataReducer;



const participantsinitialState: IDataResponse = {
    data: null,
    fetching: true,
    error: null
}
export const ParticipantsMetaDataReducer = (state = participantsinitialState, action: IAction) => {
    switch (action.type) {
        case GET_PARTICIPANTS_META_FETCHING:
            return { ...state, fetching: true, error: null,data : null };
        case GET_PARTICIPANTS_META_SUCCESS:
            return { ...state,fetching : false, error : null, data : action.payload.response};
        case GET_PARTICIPANTS_META_FAILURE:
            return { ...state,fetching : false, error : action.payload.error, data : null};
        default:
            return state;
    }
}