import { ISorting } from "./Generic";

export interface IOfferDetail {
    show: boolean;
    new: boolean;
    details: IOfferDetailData | null;
}

export interface IOfferDetailData {
  AccessLevel: number | null;
  AccessLevelName : string | null;
  CELAContact: string | null;
  CloudType: number | null;
  CreatedBy: string;
  CreatedDate: string;
  IsActive: boolean;
  IsDeleted: boolean | null;
  LastModifiedBy: string | null;
  LastModifiedDate: string | null;
  OfferCode: string;
  OfferLegalTerms: string | null;
  OfferTemplate: string | null;
  OfferTemplateVersion: string | null;
  OfferTitle: string | null;
  OfferingDetails: string | null;
  OfferingSummary: string | null;
  Role: string;
  Status: number | null;
  Team: number;
  EngagementDeliveryLeads: string;
  LastOfferPublishedOn: string;
  TeamName : string;
  IsSupportOnly:boolean;
}

export const OFFERS_STATE = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  APPROVED: "Approved",
  IN_REVIEW: "In Review",
  DRAFT: "Draft",
  REJECTED: "Rejected",
  NEW: "New"
};

export const ACCESS_LEVELS = {
  READ_ONLY: "Reader",
  CONTRIBUTER: "Contributor",
  POC: "POC",
  SAC: "Storage Account Contributor"
};


export const OFFERS_COLUMNS_TEXT = {
  ACTIONS: "Actions",
  NAME: "Name",
  CREATED_BY: "Created By",
  CREATED_ON: "Created On",
  NO_OF_ENGAGEMENTS: "No of Engagements",
  LEAD_USERS: "Lead Users",
  LAST_OFFER_PUBLISHED: "Last Offer Published On",
  DEACTIVATED: "Last Modified On",
  LAST_UPDATED_ON:"Last Updated On",
  STATUS: "Status",
  ACCESS_LEVELS: "Access Levels"
};
export const OFFERS_COLUMNS_FIELDS = {
  NAME: "Role",
  CREATED_BY: "CreatedBy",
  CREATED_ON: "CreatedDate",
  NO_OF_ENGAGEMENTS: "TotalEngagements",
  LEAD_USERS: "EngagementDeliveryLeads",
  LAST_OFFER_PUBLISHED: "LastOfferPublishedOn",
  DEACTIVATED: "LastModifiedDate",
  LAST_UPDATED_ON:"LastModifiedDate",
  STATUS: "ToggleStatus",
  ACCESS_LEVELS: "AccessLevel",
};

export interface IGetOffersPayload {
  searchTerm?: string;
  activeStatus?: string;
  selectedView?: string;
  sortingOrder?: ISorting;
  alias: string;
}

export interface IGetOffersCategoryPayload {
  searchTerm?: string;
}
