import React, { Component, CSSProperties } from "react";
import {
  Callout,
  Icon,
  IconButton,
  Link,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import moment from "moment";
import {
  INotifications,
  NOTIFICATION_CODE,
} from "../../constants/types/UI/Notifications";
import { IDataResponse } from "../../constants/types/API/Apiresponse";
import { connect } from "react-redux";
import { dismissNotifications } from "../../actions/API/Notifications";
import { logEvent, NotificationClick } from "../../utils/insightsClient";
import "./Notification.css";
import AppConstants, { AppRoles } from "../../constants/AppConstants";
import { Tag } from '@coherence-design-system/tag';

interface INotificationBellProps {
  userName: string | undefined;
  target: string;
  toggleCallout: Function;
  showCallout: boolean;
  content: INotifications[];
  history: any;
  dismissNotifications: Function;
  dismissNotificationsState: IDataResponse;
  getNotifications: any;
  userRole: { name: string }[] | undefined;
}

interface INotificationBellState {
  showNotificationSubscription: boolean;
}

class NotificationBell extends Component<
  INotificationBellProps,
  INotificationBellState
> {
  constructor(props: INotificationBellProps) {
    super(props);

    this.state = {
      showNotificationSubscription: false,
    };
  }
  dismissNotification(id: string | undefined) {
    let dismissedItems = this.props.content.filter((i) => i.Id === id);
    let currentItems = this.props.content.filter((i) => i.Id !== id);
    this.props.dismissNotifications(dismissedItems);
    this.props.getNotifications(currentItems);
    logEvent(NotificationClick, {
      AppName: AppConstants.ApplicationAlias,
      AppLayer: "UI",
      metricName: "User Notification",
      UserScenario: "User Landed on CAPE Notification Window",
      User: { Alias: this.props.userName },
      applicationRoles: this.props.userRole,
      entityOperation: "Dismiss",
      entityName: "Notification",
      metaData: {
        notificationId: id,
        notificationText: dismissedItems.map(
          (i) => i.Data.PortalNotification.Content
        ),
      },
      traceSeverity: "Low",
    });
  }
  dismissAllNotification() {
    let currentItems: any = [];
    this.props.dismissNotifications(this.props.content);
    this.props.getNotifications(currentItems);
    logEvent(NotificationClick, {
      AppName: AppConstants.ApplicationAlias,
      AppLayer: "UI",
      metricName: "User Notification",
      UserScenario: "User Landed on CAPE Notification Window",
      User: { Alias: this.props.userName },
      applicationRoles: this.props.userRole,
      entityOperation: "DismissAll",
      entityName: "Notification",
      metaData: {
        notificationCount: this.props.content.length,
      },
      traceSeverity: "Low",
    });
  }

  render() {
    return (
      <>
        {this.props.showCallout && (
          <Callout
            className="callout-class"
            role="alertdialog"
            gapSpace={0}
            target={`#${this.props.target}`}
            onDismiss={() => {
              this.props.toggleCallout(false);
            }}
            setInitialFocus
          >
            <div className="notification-title">
              <span>Notifications</span>
              <div>
                <IconButton
                  iconProps={{ iconName: "Clear" }}
                  style={{
                    fontSize: "12px",
                    color: "black",                 
                   
                  }}
                  title="Close"
                  ariaLabel="Close"
                  onClick={() => this.props.toggleCallout(false)}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <div className="notification-title-bar">
                <span>
                  <button
                    hidden={this.props.content.length === 0 ? true : false}
                    onClick={() => this.dismissAllNotification()}
                    className="dismissNotification"
                  >
                    <span className="ms-Button-flexContainer flexContainer-161">
                      <Icon
                        iconName="RingerRemove"
                        title="DismissAll"
                        ariaLabel="Dismiss all"
                        // style={{ color: "white" }}
                      />
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",                          
                          paddingLeft: "8px",
                        }}
                      >
                        Dismiss all
                      </span>
                    </span>
                  </button>
                </span>
              </div>
            </div>

                       
                        {!this.state.showNotificationSubscription && (this.props.content.length > 0 ?
                                this.props.content?.map((notification) => {
                                    return (
                                        <div className="callout-child-container" key={notification.Id}>
                                            <div style={{ display: "flex", justifyContent: "space-between"  }}>
                                                <div style={{ width: "80%"  }}>
                                                    <div dangerouslySetInnerHTML={{ __html: notification.Data.PortalNotification?.Content.trimStart() }}  />
                                                   
                                                    { notification.Data.Scope === 'SiteAdmin' && <span title='Admin Notification' style={{ height: "15px", marginLeft: "0px", fontSize:"12px", marginTop: "10px", verticalAlign:"unset" }} className="super"><Tag text="Admin" /></span>}
                                                   
                                                </div>
                                                <div >
                                                    <div style={{ textAlign:"right"}}>
                                                    <IconButton iconProps={{ iconName: "cancel" }} style={{ fontSize:"12px" , color: "black"     }} title="Dismiss" ariaLabel="Dismiss"
                                                        onClick={() => this.dismissNotification(notification.Id)} /></div>
                                                         <span style={{ fontSize: "12px" }}>{moment.utc(notification.CreatedDate).local().fromNow()}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div></div>
                                                
                                            </div>
                                        </div>
                               
                                    )

                                })
                                :(
                                <div className="callout-child-container notification" style={{ textAlign: "center" }}>
                                   
                                    <div style={{padding:"10px"}} >
                                    <Icon iconName="Brunch" title="DismissAll" ariaLabel="No Notifications"
                                                style={{ fontSize:"50px" }} />
                                                </div>
                                                You don't have any notifications

                                </div>
                         ))}
                    </Callout>
               ) }
            </>
        );
    }

}
const mapStateToProps = (state: { ui: any; auth: any; api: any }) => ({
  dismissNotificationsState: state.api.dismissNotificationsState,
});

const mapDispatchToProps = (dispatch: any) => ({
  dismissNotifications: (payload: any) =>
    dispatch(dismissNotifications(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBell);
export const iconStyle: CSSProperties = {
  color: "white",
  width: 39,
  height: 19,
  marginLeft: "253px",
};
