import React from "react";
import "./SideNav.css";
import { ICategory } from "../board/BoardInterfaces";
import { Icon } from '@fluentui/react/lib/Icon';
import AppConstants, { AppRoles } from "../../constants/AppConstants";
import { OFFERS_STATE } from "../../constants/types/UI/Offers";
import { MAPPED_ENGAGEMENT_STATUS } from "../../constants/types/UI/Engagements";
import { Partcipant_Roles } from "../../constants/types/UI/Participants";
import { ActionTrackingSearch, logEvent } from "../../utils/insightsClient";
import { FontIcon } from "office-ui-fabric-react";

interface ISideNavState {
}

interface ISideNavProps {
  categoryData: ICategory[];
  parentCallback: Function;
  activeMenu: string;
  userContext: any
}

export class SideNav extends React.Component<ISideNavProps, ISideNavState> {
  onSideMenuItemClick = (categoryElement: ICategory) => {
    this.props.parentCallback(categoryElement.title);
    logEvent(ActionTrackingSearch, {
      AppName: AppConstants.ApplicationAlias,
      AppLayer: "UI",
      MetricName: "Search",
      UserScenario: "User performs search",
      User: { Alias: this.props.userContext.email },
      ApplicationRoles: this.props.userContext.roles,
      EntityOperation: "Search",
      EntityName: "Participants",
      MetaData: {
        searchstring:"",
        ResultsCount:this.props.categoryData.filter(i=>i.title === categoryElement.title).map(i=>i.itemCount),
        selectedTab: categoryElement.title
      },
      traceSeverity: "Medium"      
    });
  };

  getSideMenuBarContent = (categoryElement: any, index: number) => {
    return (<li
        onClick={() => this.onSideMenuItemClick(categoryElement)}        
        className={
          (this.props.activeMenu === categoryElement.title
            && (matchMedia('(forced-colors: active)').matches)) ? `sideMenuListItem activeItemContrast` : (this.props.activeMenu === categoryElement.title) ? `sideMenuListItem activeItem` 
            : `sideMenuListItem`
        }

        key={index}
        title={categoryElement.displayName}
        //aria-label={`${categoryElement.displayName} button`}
        //aria-labelledby={`${categoryElement.displayName} button`}
        tabIndex={0}
        //role="button"
      >


        <div className="notification-bell-container" role="button">
         
         <FontIcon iconName={categoryElement.icon} style={{ marginRight: '5px' }}  ></FontIcon>
         <span aria-label={`${categoryElement.displayName} button`} > {categoryElement.displayName} ({categoryElement.itemCount})</span>
          {/* <Icon iconName={categoryElement.icon} className="ms-Icon"
          /> */}
          {/* <div className="categoryName" aria-label={`${categoryElement.displayName} button`}>{categoryElement.displayName} </div> */}
          </div>

      </li>);
  }

  render() {
    let sidenavData = this.props.categoryData;
    let categoryData:any[]=[];
    if (this.props.userContext) {
     categoryData = sidenavData.map((categoryElement, index) => {      
        if (this.props.userContext.roles?.find((role: any) => role.name == AppRoles.SiteAdmin.roleName)) {
          return this.getSideMenuBarContent(categoryElement, index);
        } else if (categoryElement.displayName == OFFERS_STATE.ACTIVE) {
          return this.getSideMenuBarContent(categoryElement, index);
        } else if (categoryElement.displayName == MAPPED_ENGAGEMENT_STATUS.LIVE.DISPLAY_NAME ||
          categoryElement.displayName == MAPPED_ENGAGEMENT_STATUS.PROCESSING.DISPLAY_NAME ||
          categoryElement.displayName == MAPPED_ENGAGEMENT_STATUS.COMPLETED.DISPLAY_NAME ||
          categoryElement.displayName == MAPPED_ENGAGEMENT_STATUS.ERROR_FOUND.DISPLAY_NAME ||
          categoryElement.displayName == MAPPED_ENGAGEMENT_STATUS.IN_DRAFT.DISPLAY_NAME ||
          categoryElement.title == Partcipant_Roles.EngagementManager ||
          categoryElement.title == Partcipant_Roles.SiteAdmin ||
          categoryElement.title == Partcipant_Roles.DeliveryTeamMember) {
          return this.getSideMenuBarContent(categoryElement, index);
        }     
    });
  }
    return (
      <div className="collapsableSidemenu" tabIndex={0}>
        <ul className="sideMenuList">
          {/* <li className="menuListItem">
            <img
              src={menu}
              alt="Menu"
              className="menuIcon"
              onClick={this.onMenuClick}
            />
          </li> */}
          {categoryData}
        </ul>
      </div>
    );
  }
}

export default SideNav;
