import "./UserRating.css";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import {
  Rating,
  RatingSize,
  mergeStyleSets,
  FontWeights,
  TextField,
  getTheme,
  PrimaryButton,
  DefaultButton,
  IconButton,
  IIconProps,
} from "office-ui-fabric-react";
import { ReactComponent as RatingImage } from "../../../assets/RatingComment.svg";
import {
  CSAT_1to5,
  IRating,
  rating_V1,
} from "../../../constants/types/API/Rating";
import { useRef } from "react";
import { FontIcon, MessageBarType } from "@fluentui/react";
import AppConstants, {
  AppRoles,
  ShowRating,
} from "../../../constants/AppConstants";
import CustomTextField from "../CustomTextField/CustomTextField";
import { connect } from "react-redux";
import { Account } from "msal";
import { saveRating } from "../../../actions/API/Rating";
import { IRatingApiResponse } from "../../../constants/types/API/Apiresponse";
import { IBannerMessagePayload } from "../../../constants/types/UI/BannerMessage";
import { setBanner } from "../../../actions/UI/BannerMessage";
import { logEvent, Feedback_Rating } from "../../../utils/insightsClient";
import { buildPersistentMessage } from "../../../utils/helperFunctions";
import { showRatingInCenter } from "../../../actions/UI/Rating";
import { IRatingPayload } from "../../../constants/types/UI/Rating";
import { IUserContext } from "../../../constants/types/UI/Generic";
import { getContext } from "../../../actions/API/Context";
import Draggable from "react-draggable";

interface Props {
  location: any;
  user: Account;
  saveRating: Function;
  showRatingInCenter: Function;
  ratingStatus: IRatingApiResponse;
  toggleSpinner: Function;
  setBanner: Function;
  ratingPosition: any;
  userContext: IUserContext;
  getContext: Function;
  handleUserRating: Function;
  showRating: boolean;
}

const ratingLogEventConstants = {
  SubmittedRatingFeedback: "SubmittedRatingFeedback",
  CanceledRatingFeeback: "CanceledRatingFeeback",
  OpenedRatingFeedback: "OpenedRatingFeedback",
};

var ratingSaveCalled = false;

let ratingCenterStyle = {};

const UserRating: React.FC<Props> = (props) => {
  const [userRatingItem, setuserRatingItem] = useState<IRating>({
    comments: "",
    createdDate: new Date().toISOString(),
    createdBy: props.user ? props.user.userName : "",
    ratingValue: 0,
    ratingType: CSAT_1to5,
    version: rating_V1,
  });
  const cancelIcon: IIconProps = { iconName: "Cancel" };
  const [showModal, setshowModal] = useState<boolean>(
    AppConstants.ShowRatingByDefault[props.location.pathname?.substring(1)]
  );
  const [rating, setrating] = useState<number>(userRatingItem.ratingValue);
  const [comments, setcomments] = useState<string>(userRatingItem.comments);
  const [ratingText, setRatingText] = useState<string>("Not Rated");
  const firstRender = useRef(true);
  const [disabled, setDisabled] = useState(true);

  const saveuserRating = () => {
    let saveRating: IRating = {
      comments: comments,
      createdDate: new Date().toISOString(),
      createdBy: props.user.userName,
      ratingValue: rating,
      ratingType: CSAT_1to5,
      version: rating_V1,
    };

    ratingSaveCalled = true;
    save(saveRating).then(() => {
      props.getContext();
    });
    showDialog(false, ratingLogEventConstants.SubmittedRatingFeedback);
  };
  function save(saveRating: IRating) {
    return new Promise((resolve, reject) => {
      resolve(props.saveRating(saveRating));
    });
  }

  const showDialog = (showDialogState: boolean, eventToRegister: string) => {
    if (showDialogState) {
      setrating(0);
      setcomments("");
      setRatingText("Not Rated");
    } else {
      ratingCenterStyle = {};
    }

    // -> Feedback telemetry
    const logeventData = {
      AppName: AppConstants.ApplicationAlias,
      AppLayer: "UI",
      MetricName: "CSATVisible",
      UserScenario: "CSAT component is visible",
      User: { Alias: props.user.userName },
      ApplicationRole: props.userContext.roles,
      EntityOperation: "Click",
      EntityName: "Rating",
      MetaData: {
        page: props.location.pathname?.substring(1),
        action: "User Click",
      },
      traceSeverity: "Medium",
    };

    logEvent(Feedback_Rating, logeventData);

    window.localStorage.setItem(ShowRating, showDialogState.toString());
  };

  const formValidation = () => {
    if (rating == 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (ratingSaveCalled) {
      if (props.ratingStatus.progressing) {
        props.toggleSpinner({
          spinner: true,
          spinnerMessage: "Saving Feedback",
        });
      } else {
        ratingSaveCalled = false;
        if (props.ratingStatus.failure) {
          const ratingSaveFail: IBannerMessagePayload = {
            toShow: true,
            message: `Failed to save feedback! Please try again later`,
            messageType: MessageBarType.error,
            styleClass: "",
            timeout: 5000,
          };
          props.setBanner(ratingSaveFail);
        }
        if (props.ratingStatus.success) {
          const ratingSaveSuccess: IBannerMessagePayload = {
            toShow: true,
            message: `Your feedback is saved. Thanks for rating us!`,
            messageType: MessageBarType.success,
            styleClass: "",
            timeout: 5000,
          };
          props.setBanner(ratingSaveSuccess);
        }
        props.toggleSpinner({
          spinner: false,
          spinnerMessage: "",
        });
      }
    }
  }, [props.ratingStatus]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setDisabled(formValidation());
  }, [rating, comments]);

  useEffect(() => {
    ratingCenterStyle =
      props.ratingPosition.showInCenter && props.userContext.showCSATModule
        ? { bottom: "26%", right: "40%", height: "400px", width: "500px" }
        : {};
    if (props.ratingPosition.showInCenter && props.userContext.showCSATModule) {
      showDialog(true, ratingLogEventConstants.OpenedRatingFeedback);
    }
  }, [props.ratingPosition]);

  const evaluateShowModal = (location: any) => {
    var showRating: any = window.localStorage.getItem(ShowRating);
    if (showRating) {
      showRating = showRating === "true" ? true : false;
    }
    if (location.pathname) {
      if (showRating == undefined || showRating == null) {
        setshowModal(
          AppConstants.ShowRatingByDefault[location.pathname?.substring(1)]
        );
      } else {
        if (showRating) {
          setshowModal(
            AppConstants.ShowRatingByDefault[location.pathname?.substring(1)]
          );
        } else {
          setshowModal(false);
        }
      }
    }
  };

  useEffect(() => {
    evaluateShowModal(props.location);
  }, []);

  useEffect(() => {
    evaluateShowModal(props.location);
  }, [props.location]);

  function setRatingLabel(rating: number) {
    if (rating == 1) {
      setRatingText("Poor");
    } else if (rating == 2) {
      setRatingText("Fair");
    } else if (rating == 3) {
      setRatingText("Good");
    } else if (rating == 4) {
      setRatingText("Very Good");
    } else if (rating == 5) {
      setRatingText("Excellent");
    }
  }

  const onChangeCommentsValue = (cont?: string) => {
    setcomments(cont ?? "");
  };
  return (
    <>   
      {/* <a
        href="javascript:void(0);"
        style={{ textDecoration: "none" }}
        role="button"
        id="openPopupButton"
        className="float clickable rating-minimized"
        onClick={() =>
          showDialog(true, ratingLogEventConstants.OpenedRatingFeedback)
        }
      >
        <div style={{ marginRight: "10px", marginLeft: "10px" }}>
          Rate Your Experience
        </div>
        <div style={{ marginRight: "10px" }}>
          <FontIcon style={{ paddingTop: "4px" }} iconName="Add" />
        </div>
      </a> */}
      {showModal ||
        (props.showRating && (
          <Draggable>
            <div className="floatDiv" style={ratingCenterStyle}>
              <div>
                <div className={contentStyles.header}>
                  <h2 role="heading" aria-level={2} className="modalHeader">
                    {" "}
                    Rate your experience
                  </h2>
                  <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={() => {
                      showDialog(
                        false,
                        ratingLogEventConstants.CanceledRatingFeeback
                      );
                      props.handleUserRating(false);
                    }}
                    id="closePopupButton"
                  />
                </div>
                <div className={contentStyles.body} id="popup">
                  <Rating
                    min={0}
                    max={5}
                    size={RatingSize.Large}
                    rating={rating}
                    aria-required={true}
                    id="input1"
                    onChange={(ev: any, rating?: number): void => {
                      if (rating) {
                        setrating(rating);
                        setRatingLabel(rating);
                      }
                    }}
                    ariaLabelFormat={"Select {0} of {1} stars"}
                  />
                  <span className="smallText"> {ratingText}</span>
                  <CustomTextField
                    label="Share your comments"
                    rows={props.ratingPosition.showInCenter ? 7 : 4}
                    placeholder="Share your comments"
                    letterLimit={400}
                    value={comments}
                    onUpdateCTF={onChangeCommentsValue}
                    multiline={true}
                    className="ratingCommentBox"
                    required={false}
                  />
                </div>
              </div>
              <div className="rating-footer">
                <PrimaryButton
                  disabled={disabled}
                  text="Submit"
                  onClick={() => {
                    saveuserRating();
                    setshowModal(false);
                  }}
                />{" "}
                &nbsp;
                <DefaultButton
                  onClick={() => {
                    showDialog(
                      false,
                      ratingLogEventConstants.CanceledRatingFeeback
                    );
                    props.handleUserRating(false);
                  }}
                  text="Cancel"
                />
              </div>
            </div>
          </Draggable>
        ))}
    </>
  );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      // color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      // backgroundColor: "rgb(26, 27, 32)",
      fontWeight: FontWeights.semibold,
      height: "60px",
    },
  ],
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
const iconButtonStyles = {
  root: {
    // color: theme.palette.white,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const mapStateToProps = (state: { ui: any; auth: any; api: any }) => ({
  user: state.auth.user,
  ratingStatus: state.api.ratingStatus,
  ratingPosition: state.ui.ratingStyle,
  userContext: state.api.userContext.data,
});

const mapDispatchToProps = (dispatch: any) => ({
  saveRating: (payload: IRating) => dispatch(saveRating(payload)),
  setBanner: (payload: IBannerMessagePayload) => dispatch(setBanner(payload)),
  showRatingInCenter: (payload: IRatingPayload) =>
    dispatch(showRatingInCenter(payload)),
  getContext: () => dispatch(getContext()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRating);
