import React, { useState } from 'react'
import {
  SearchBox,
  IIconProps,
  MessageBarType,
} from "@fluentui/react";
import ParticipantsList from './ParticipantsList';
import { IColumn } from "@fluentui/react/lib/DetailsList";
import { ICategory } from "../board/BoardInterfaces";

import SideNav from "../common/SideNav";
import { getParticipants } from '../../actions/UI/Participants';
import { connect } from 'react-redux';
import { toggleSpinner } from "../../actions/UI/globalSpinner";
import { Account, AuthResponse } from 'msal';
import { IAppScopes, ISorting, searchCalled } from '../../constants/types/UI/Generic';
import { IGetParticipantPayload, IParticipantsData, Partcipant_Roles } from '../../constants/types/UI/Participants';
import { IDataResponse } from '../../constants/types/API/Apiresponse';
import { ActionTrackingSearch, logEvent } from '../../utils/insightsClient';
import AppConstants, { AppRoles } from '../../constants/AppConstants';
import { Text } from '@fluentui/react';

interface IParticipantsState {
  searchTerm: string;
  actualSearchTerm: string;
  items: IParticipantsData[];
  _columns: IColumn[];
  categoryData: ICategory[];
  activeMenu: string;
  sideMenuStatus: boolean;
  sortingOrder: ISorting;
  activeStatusData: {
    displayText: string,
    count: number
  }
}

interface IColumnItems {
  name: string,
  fieldName: string
}

interface IParticipantsProps {
  getParticipants: Function;
  user: Account | null;
  apiAccessToken: AuthResponse | null;
  coreApiAccessToken: AuthResponse | null;
  participants: IDataResponse<IParticipantsData[]>;
  toggleSpinner: Function;
  appScopes: IAppScopes;
  history: any;
  buildAndShowBanner: Function;
  participantCategories : IDataResponse;
  userContext: any;
}

const sortParticipants = (a: { RoleName: string, ParticipantCount: number }, b: { RoleName: string, ParticipantCount: number }) => {
  const orderToBeShown = {
    [Partcipant_Roles.SiteAdmin] : 0,
    [Partcipant_Roles.EngagementManager] : 1,
    [Partcipant_Roles.DeliveryTeamMember] : 2
  }
  var nameA = orderToBeShown[a.RoleName], nameB = orderToBeShown[b.RoleName];
  if (nameA < nameB)
    return -1;
  if (nameA > nameB)
    return 1;
  return 0;
}






var renderCount: number = 0;
class Participants extends React.Component<IParticipantsProps, IParticipantsState> {

  searchTimer: any;
  constructor(props: IParticipantsProps) {
    super(props);
    this.enableLoader();
    this.state = {
      searchTerm: "",
      actualSearchTerm: "",
      categoryData: [],
      activeMenu: Partcipant_Roles.SiteAdmin,
      items: [],
      _columns: this.genColumnData(),
      sortingOrder: { column: "DisplayName", order: "asc" },
      activeStatusData: {
        displayText: "",
        count: 0
      },
      sideMenuStatus: false
    };
  }

  componentDidMount() {
    if (
      this.props.user &&
      this.props.apiAccessToken &&
      this.props.coreApiAccessToken
    ) {
      this.triggerGetParticipants();
    }
  }

  triggerGetParticipants = () => {
    this.enableLoader();
    const engPayload: IGetParticipantPayload = {
      searchTerm: this.state.actualSearchTerm,
      activeMenu: this.state.activeMenu,
      sortingOrder: this.state.sortingOrder
    }
    this.props.getParticipants(engPayload);
  }

  componentDidUpdate(prevProps: IParticipantsProps, prevState: IParticipantsState) {

    if (prevProps.participants !== this.props.participants) {
      if (this.props.participants.data && !this.props.participants.fetching) {
        this.setState({
          items: this.props.participants.data,
          activeStatusData: {
            displayText: this.state.activeMenu,
            count: this.props.participants.data.length,
          },
        });
        logEvent(ActionTrackingSearch, {
          AppName: AppConstants.ApplicationAlias,
          AppLayer: "UI",
          MetricName: "Search",
          UserScenario: "User performs search",
          User: { Alias: this.props.userContext.email},
          ApplicationRoles: this.props.userContext.roles,
          EntityOperation: "Search",
          EntityName: "Participants",
          MetaData: {
            searchstring:this.state.actualSearchTerm,
            ResultsCount:this.props.participants.data.length,
            selectedTab: this.state.activeMenu
          },
          traceSeverity: "Medium"
        });
        this.disableLoader();
      }
      else if (this.props.participants.error && !this.props.participants.fetching) {
        this.props.buildAndShowBanner('Failed to get participants data properly. Please try again!!', MessageBarType.severeWarning, 5000);
        this.disableLoader();
      }
    }

    if (prevProps.participantCategories !== this.props.participantCategories) {
      if (this.props.participantCategories.data && !this.props.participantCategories.fetching) {
        if (!!this.state.actualSearchTerm) {
          const normalIdentifier = this.changeSideNavBasedOnSearch(this.props.participantCategories.data);
          if (!!normalIdentifier && searchCalled.participantSearchCalled == true) {
            searchCalled.participantSearchCalled = false;
            this.sideNavCallback(normalIdentifier);
          } else if(searchCalled.participantSearchCalled == true){
            searchCalled.participantSearchCalled = false;
          }
        }
        this.buildCategories(this.props.participantCategories.data);
      }
      else if (this.props.participantCategories.error && !this.props.participantCategories.fetching) {
        this.props.buildAndShowBanner('Failed to get participants data properly. Please try again!!', MessageBarType.severeWarning, 5000);
        this.disableLoader();
      }
    }

    // get participants again
    if (prevState.actualSearchTerm !== this.state.actualSearchTerm || prevState.activeMenu !== this.state.activeMenu ||
      prevState.sortingOrder !== this.state.sortingOrder) {
      this.triggerGetParticipants();
    }
  }

  changeSideNavBasedOnSearch = (participantCategories: [{ RoleName: string, ParticipantCount: number }]) => {
    const getSortedCategories = participantCategories.sort(sortParticipants);
    const foundCategory = getSortedCategories.find(cat => cat.ParticipantCount > 0);
    if (foundCategory) {
      var currentCategory : any = getSortedCategories.find(sortCat => sortCat.RoleName == this.state.activeMenu);
      currentCategory = currentCategory?.ParticipantCount > 0 ? currentCategory?.RoleName : undefined;
      if (!currentCategory) {
        return foundCategory.RoleName;
      }
      return undefined;
    }
    return undefined;
  }

  buildCategories = (participantCategories: any) => {
    this.setState({
      categoryData: [
        {
          title: Partcipant_Roles.SiteAdmin,
          displayName: Partcipant_Roles.SiteAdmin,
          itemCount: this.getCategoryCount(participantCategories, Partcipant_Roles.SiteAdmin),
          icon: "admin",
        },
        {
          title: Partcipant_Roles.EngagementManager,
          displayName: "Eng. Manager",
          itemCount: this.getCategoryCount(participantCategories, Partcipant_Roles.EngagementManager),
          icon: "pageedit",
        },
        {
          title: Partcipant_Roles.DeliveryTeamMember,
          displayName: "Delivery Team Member",
          itemCount: this.getCategoryCount(participantCategories, Partcipant_Roles.DeliveryTeamMember),
          icon: "people",
        }
      ],
    });
  };

  getCategoryCount = (participantCategories: any, identifier: string) => {
    var catCount = 0;
    participantCategories.forEach((category:
      {
        RoleName: string,
        ParticipantCount: number
      }) => {
        if(category.RoleName === identifier)
        catCount = catCount + category.ParticipantCount;
      }
    );
    return catCount;
  }

  genColumnData = () => {
    let renderCountAddOn = renderCount * 20;
    var width = window.innerWidth - window.innerWidth * 0.06 - 70 - 25 - 15;
    var viewPortWidth = width / 2 - 20;
    var columnItems: IColumn[] = [
      {
        key: `${renderCount}columnName`,
        name: "Name",
        fieldName: "DisplayName",
        minWidth: viewPortWidth,
        maxWidth: viewPortWidth,
        isSorted: true,
        isSortedDescending: false,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: `${renderCount}columnAlias`,
        name: "User Alias",
        fieldName: "UserAlias",
        minWidth: viewPortWidth,
        maxWidth: viewPortWidth,
        isSorted: false,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];
    renderCount = renderCount + 1;
    return columnItems;
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    this.enableLoader();
    const { _columns, items } = this.state;
    const newColumns: IColumn[] = _columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol.key === currColumn.key) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          sortingOrder : {column: newCol.fieldName ?? "", order: currColumn.isSortedDescending ? "desc" : "asc" }
        })
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = false;
      }
    });
    this.setState({
      _columns: newColumns
    });
  };

  sideNavCallback = (childData: string) => {
    if (this.state.activeMenu !== childData) {
      this.setState({ activeMenu: childData });
    }
  };

  changesideMenuStatus = (childData: boolean) => {
    this.setState({
      sideMenuStatus: childData,
    });
  };

  enableLoader = () => {
    this.props.toggleSpinner({
      spinner: true,
      spinnerMessage: "Fetching Participants",
    });
  };

  disableLoader = () => {
    this.props.toggleSpinner({
      spinner: false,
      spinnerMessage: "",
    });
  };

  render() {
    return (
      <div className="participants-page">
        <Text variant="xLarge" block styles={{ root: { fontSize:"22px", margin: "10px" } }}>
          Participants
        </Text>
        <div className="eg-search-view-add">
          <div className="eg-search-view">
            <div className="eg-search">
              <SearchBox
                placeholder="Search within Participants"
                value={this.state.searchTerm}
                id="search-box-board"
                onClear={(ev) => () => { }}
                autoComplete="off"
                onChange={(_, newValue) => {
                  this.setState({
                    searchTerm: newValue ? newValue : ""
                  });
                  clearTimeout(this.searchTimer);
                  this.searchTimer = setTimeout(() => {
                    searchCalled.participantSearchCalled = true;
                    this.setState({
                      actualSearchTerm: newValue ? newValue : "",
                    })
                  }, 800);                  
                }}
              />
            </div>
          </div>

        </div>
        <div className="board">
          <SideNav
            categoryData={this.state.categoryData}
            parentCallback={this.sideNavCallback}
            activeMenu={this.state.activeMenu}
            userContext={this.props.userContext}
          />
          <ParticipantsList
            participantsHeaderData={this.state._columns}
            history={this.props.history}
            participantsData={this.state.items}
            activeMenu={this.state.activeMenu}
            activeStatusData={this.state.activeStatusData}
            fetching={this.props.participants.fetching}
          />
           <div>
      
    
    </div>
  
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: { ui: any; auth: any; api: any }) => ({
  user: state.auth.user,
  apiAccessToken: state.auth.apiAccessToken,
  coreApiAccessToken: state.auth.coreApiAccessToken,
  participants: state.api.participants,
  participantCategories : state.api.participantCategories,
  userContext : state.api.userContext.data,
});

const mapDispatchToProps = (dispatch: any) => ({
  getParticipants: (payload: IGetParticipantPayload) => dispatch(getParticipants(payload)),
  toggleSpinner: (payload: boolean) => dispatch(toggleSpinner(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Participants)
