import { TelemetryContext } from "./TelemetryContext";

export const getBotResponse = async (
  apiUrl: string,
  authToken: string,
  question: string,
  requestedPlatform: string
) => {
  const queryParams = {
    question,
    requestedPlatform,
  };

  const queryString = new URLSearchParams(queryParams);

  const response = await fetch(`${apiUrl}/response?${queryString}`, {
    method: "GET",
    headers: {
      Authorization: authToken,
      "Content-type": "application/json;",
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
};

export const getBotSuggestions = async (apiUrl: string, authToken: string, requestedPlatform: string) => {
    try {
        const queryParams = {
            requestedPlatform
        }

        const queryString = new URLSearchParams(queryParams)

        const response = await fetch(`${apiUrl}/SeedingQuestions?${queryString}`, {
            method: "GET",
            headers: {
                "Authorization": authToken,
                "Content-type": "application/json;"
            }
        })

        if (!response.ok) {
            throw new Error(`HTTP error. status: ${response.status}`);
        }

        let data = await response.json();

        //Until we can query the API, we will filter the FB360 suggestions here:
        const filteredSuggestions = data.questions.filter((item: any) => item.appPlatform === requestedPlatform);
        //From stack: https://stackoverflow.com/questions/19269545/how-to-get-a-number-of-random-elements-from-an-array
        //const randomSuggestions = filteredSuggestions.sort(() => 0.5 - Math.random()).slice(0, 3);

        //return randomSuggestions
        return filteredSuggestions;
    } catch (error) {
        return []
    }
}


export const getLatestAnnouncement = async (apiUrl: string, authToken: string,) => {
    try {
        const response = await fetch(`${apiUrl}/Announcement/Latest`, {
            method: "GET",
            headers: {
                "Authorization": authToken,
                "Content-type": "application/json;"
            }
        })

        if (!response.ok) {
            throw new Error(`HTTP error. status: ${response.status}`);
        }

        let data = await response.json();

        return data;

    } catch (error) {
        return null;
    }
}