
//Quickest way I could find to generate a unique ID w/o adding an extra npm dependency
export const generateId = (): string => {
    const timestamp = Date.now().toString();
    const random = Math.random().toString().substring(2);
    const id = `${timestamp}${random}`;
    return id;
}

export const getDateTime = () => {
    let current = new Date();
    let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
    let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
    let dateTime = cDate + ' ' + cTime;
    return dateTime.toString();
}

