import { IAction } from '../../constants/types/IAction';
import {SHOW_MODAL} from '../../constants/actions/UI/Modal';
import {IModalPayload} from '../../constants/types/UI/Modal';
import _ from 'lodash';

export const initialState : IModalPayload = {
    toShow : false,
    title: "",
    text: ""
};

export const ModalReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SHOW_MODAL:
            return _.cloneDeep(action.payload)    
        default:
            return state;
    }
}