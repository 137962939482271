import React from 'react';
import './Error404.css';
import error404img from '../../../assets/404.svg';
import AppConstants from '../../../constants/AppConstants';

const Error404: React.FunctionComponent<any> = (props:any)=>{
return(
    <div className="errorpage-div">
        {[<div><img className="itemNotFoundImage" alt={AppConstants.Error404Message} src={error404img}></img></div>,<div><h4 className="errorpage-header">{AppConstants.Error404Message}</h4></div>]}
      </div>
);
}

export default Error404;