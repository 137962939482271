import { IAction } from '../../constants/types/IAction';
import {SET_BANNER} from '../../constants/actions/UI/BannerMessage';
import {IBannerMessagePayload} from '../../constants/types/UI/BannerMessage';
import { MessageBarType } from 'office-ui-fabric-react';
import _ from 'lodash';

export const initialState : IBannerMessagePayload = {
    toShow : false,
    message : "",
    messageType : MessageBarType.success,
    styleClass : "",
    timeout : 0
};

export const BannerReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SET_BANNER:
            return _.cloneDeep(action.payload)    
        default:
            return state;
    }
}