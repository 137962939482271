import { IAction } from '../../constants/types/IAction';
import {UPDATE_GRAPH_STATE} from '../../constants/actions/API/GraphState';


export function updateGraphState(payload : any): IAction {
    return {
        type: UPDATE_GRAPH_STATE,
        payload
    }
}
