import React, { ReactNode } from 'react';

const CopilotContext = React.createContext<any>(null);

type AppContext = {
    appInsightsEventNamePrefix: string;
    appName: string;
    gptPlatformName: string;
    currentUserName: string;
    suggestions?: string[];
}

interface AppInsightsProviderProps {
    copilotContext: AppContext;
    children: ReactNode;
}

const AppContextProvider = ({ copilotContext, children }: AppInsightsProviderProps) => {
    return (
        <CopilotContext.Provider value={copilotContext}>
            {children}
        </CopilotContext.Provider>
    );
}

export {CopilotContext, AppContextProvider};