import { IAppScopes, IScope, AppScopes } from "../constants/types/UI/Generic";
import _ from 'lodash';
import { INotification } from "../constants/types/UI/BannerMessage";
import { MessageBarType } from "@fluentui/react";
import { ENGAGEMENTS_URL, HOME_URL, OFFERS_URL, PARTICIPANTS_URL, REPORTS_URL } from "../constants/routes";

export const parseQS = (QS: string) => {
    if (QS !== "?" && !!QS) {
        var parsedSQS: any = {};
        const queryGroups = QS.substring(1).split('&');
        for (var i = 0; i < queryGroups.length; i++) {
            var pair = queryGroups[i].split('=');
            parsedSQS[decodeURIComponent(pair[0]).toLowerCase()] = pair[1] ? decodeURIComponent(pair[1]) : null;
        }
        return parsedSQS;
    }
    return null;
}

export const buildScopes = (scopes: string[]) => {

    var localAppScopes: any = _.cloneDeep(AppScopes);
    scopes.forEach(scope => {
        const splitScope: string[] = scope.split('/');
        localAppScopes[splitScope[0]].Show = true;
        if (splitScope.length > 1) {
            localAppScopes[splitScope[0]][splitScope[1]] = true;
            if (splitScope[1] == "Edit") {
                localAppScopes[splitScope[0]].Create = true;
            }
        }
    });

    return localAppScopes;

}

export const buildPersistentMessage = (pmString: string) => {

    const parsedPMJSON = {
        message : "",
        messageType :"",
        timeOut : "",
    }

    const splitPM =  pmString.split(";");
    splitPM.forEach(indVStr => {
        const kpPair = indVStr.split("=");
        if(kpPair[0].toLowerCase() == "message"){
            parsedPMJSON.message = kpPair[1];
        }
        else if(kpPair[0].toLowerCase() == "messagetype"){
            parsedPMJSON.messageType = kpPair[1];
        }
        else if(kpPair[0].toLowerCase() == "timeout"){
            parsedPMJSON.timeOut = kpPair[1];
        }
    })

    var messageType: MessageBarType = MessageBarType.info;
    switch (parsedPMJSON.messageType?.toLowerCase()) {
        case "info":
            messageType = MessageBarType.info
            break;
        case "error":
            messageType = MessageBarType.error
            break;
        case "blocked":
            messageType = MessageBarType.blocked
            break;
        case "severewarning":
            messageType = MessageBarType.severeWarning
            break;
        case "success":
            messageType = MessageBarType.success
            break;
        case "warning":
            messageType = MessageBarType.warning
            break;
    }
    const persistentNotification: INotification =
    {
        message: parsedPMJSON.message,
        messageType: messageType,
        timeOut: parsedPMJSON.timeOut !== "null" ?
            (new Date(parsedPMJSON.timeOut).valueOf() - new Date().valueOf() >= 0 ? new Date(parsedPMJSON.timeOut).valueOf() - new Date().valueOf() : 0) : null
    }

    return persistentNotification;
}

export const buildShowRatingSettings = (showRating: string) => {
    const userRatingSetting : any = {
        [HOME_URL]: true,
        [ENGAGEMENTS_URL]: false,
        [PARTICIPANTS_URL]: true,
        [OFFERS_URL]: false,
        [REPORTS_URL]: true
    }

    const splitPM =  showRating.split(";");
    splitPM.forEach(indVStr => {
        const kpPair = indVStr.split("=");
        if(kpPair[0].toLowerCase() == HOME_URL.toLowerCase()){
            userRatingSetting[HOME_URL] = kpPair[1] == "true";
        }
        else if(kpPair[0].toLowerCase() == ENGAGEMENTS_URL.toLowerCase()){
            userRatingSetting[ENGAGEMENTS_URL] = kpPair[1] == "true";
        }
        else if(kpPair[0].toLowerCase() == PARTICIPANTS_URL.toLowerCase()){
            userRatingSetting[PARTICIPANTS_URL] = kpPair[1] == "true";
        }
        else if(kpPair[0].toLowerCase() == OFFERS_URL.toLowerCase()){
            userRatingSetting[OFFERS_URL] = kpPair[1] == "true";
        }
        else if(kpPair[0].toLowerCase() == REPORTS_URL.toLowerCase()){
            userRatingSetting[REPORTS_URL] = kpPair[1] == "true";   
        }
    });

    return userRatingSetting;


}