import { IAction } from "../../constants/types/IAction";
import { SHOW_RATING_IN_CENTER } from "../../constants/actions/UI/Rating";
import { IRatingPayload } from "../../constants/types/UI/Rating";

export function showRatingInCenter(payload: IRatingPayload): IAction {
  return {
    type: SHOW_RATING_IN_CENTER,
    payload: payload,
  };
}
