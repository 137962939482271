import React, { Component } from "react";
import { ITextFieldProps, TextField } from '@fluentui/react/lib/TextField';
import { IBannerMessagePayload } from "../../../constants/types/UI/BannerMessage";
import { setBanner } from "../../../actions/UI/BannerMessage";
import { connect } from "react-redux";
import { MessageBarType } from "@fluentui/react";
import './CustomTextField.css';


interface ICustomTextFieldProps extends Partial<ITextFieldProps> {
    letterLimit?: number
    helpText?: string;
    onUpdateCTF: Function;
    setBanner : Function;
    donotShowLetters? : boolean;
    isFormValid? : boolean;
    sanitizeText? : boolean;
    isCopyAllowed? : boolean;
}

interface ICustomTextFieldState {
    charCount: number;
    onFocussed : boolean;
}

class CustomTextField extends Component<ICustomTextFieldProps, ICustomTextFieldState>{
    constructor(props: ICustomTextFieldProps) {
        super(props);
        this.state = {
            charCount: 0,
            onFocussed : false
        }
    }

    componentDidMount() {
        this.trimAndUpdateValue_Count(this.props.value);
    }

    componentDidUpdate(prevProps: ICustomTextFieldProps, prevState: ICustomTextFieldState) {
        if (prevProps.value !== this.props.value) {
            this.trimAndUpdateValue_Count(this.props.value);
        }
    }

    sanitizeText = (val? : string) => {
        var resultString = val;
        if(!!resultString){
            resultString = resultString.replaceAll('”',"\"");
            resultString = resultString.replaceAll('“',"\"");
            resultString = resultString.replaceAll('’',"'");
            resultString = resultString.replaceAll('‘',"'");
            if(val !== resultString){
                const textSanitized: IBannerMessagePayload = {
                    toShow: true,
                    message: `'${this.props.label}' modified to ensure sanity`,
                    messageType: MessageBarType.warning,
                    styleClass: '',
                    timeout: 5000
                }
                this.props.setBanner(textSanitized);
            }
            return resultString;
        }
        return resultString
    }

    trimAndUpdateValue_Count = (str?: string) => {
        var val : any = str;
        if (!!val) {
            if(!!this.props.sanitizeText){
                val = this.sanitizeText(val);
            }
            if (!!this.props.letterLimit && val.length > this.props.letterLimit) {
                this.props.onUpdateCTF(val.substring(0, this.props.letterLimit));
                this.setState({ charCount: this.props.letterLimit });
                const letterLimitReach: IBannerMessagePayload = {
                    toShow: true,
                    message: `Letter limit reached for field '${this.props.label}'`,
                    messageType: MessageBarType.warning,
                    styleClass: '',
                    timeout: 5000
                }
                this.props.setBanner(letterLimitReach);
            }
            else {
                this.props.onUpdateCTF(val);
                this.setState({ charCount: val.length });
            }
        }
        else {
            this.props.onUpdateCTF("");
            this.setState({ charCount: 0 });
        }
    }

    updateText = (_event: any, newValue?: string) => {
        this.props.onUpdateCTF(newValue);
    }

    render() {
        return (
            <div>
                <div>
                    <TextField
                        onFocus={() => {
                            this.setState({ onFocussed : true})
                        }}
                        label={this.props.label}
                        multiline={this.props.multiline}
                        className={`multi-line-tf ${this.props.className}`}
                        rows={this.props.rows}
                        onChange={this.updateText}
                        onCopy={(e) => {
                            if (!this.props.isCopyAllowed) {
                                e.preventDefault();
                                return false;
                            }
                        }
                        }
                        value={this.props.value}
                        placeholder={this.props.placeholder}
                        aria-label={this.props.ariaLabel}
                        styles={{ field: { resize: "vertical" }, ...this.props.styles }}
                        disabled={!!this.props.disabled}
                        required={!!this.props.required}
                        errorMessage={((this.state.onFocussed || !this.props.isFormValid ) && this.props.required) ? this.props.errorMessage : undefined}
                    /></div>
                <div className="cmtf-footer">
                    <div>{` `}{this.props.helpText ?? ""}</div>
                    <div>{` `}{!!this.props.donotShowLetters ? '' : (!!this.props.letterLimit ? `${this.state.charCount}/${this.props.letterLimit} letters` : `${this.state.charCount} letters`)}</div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state: { ui: any, auth: any }) => ({
})

const mapDispatchToProps = (dispatch: any) => ({
    setBanner: (payload: IBannerMessagePayload) => dispatch(setBanner(payload))
})


export default connect(mapStateToProps,mapDispatchToProps)(CustomTextField);