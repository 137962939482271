import {
  FETCHING_ENGAGEMENTS,
  FETCHING_ENGAGEMENT_FAILURE,
  FETCHING_ENGAGEMENT_SUCCESS,
  GET_ENGAGEMENT_CATEGORIES_FAILURE,
  GET_ENGAGEMENT_CATEGORIES_FETCHING,
  GET_ENGAGEMENT_CATEGORIES_SUCCESS,
  UPDATE_ENGAGEMENTS
} from "../../constants/types/API/Engagements";

import { IAction } from "../../constants/types/IAction";
import { IDataResponse } from "../../constants/types/API/Apiresponse";
import { GET_ENGAGEMENT_BY_ID_FAILURE, GET_ENGAGEMENT_BY_ID_FETCHING, GET_ENGAGEMENT_BY_ID_SUCCESS } from "../../constants/actions/UI/Engagements";
import { ENGAGEMENT_UPSERT_FAILURE, ENGAGEMENT_UPSERT_STARTING, ENGAGEMENT_UPSERT_SUCCESS } from "../../constants/actions/API/EngagementUpdate";

const initialState: IDataResponse = {
  data: null,
  fetching: false,
  error: null,
};

const EngagementsReducers = (state = initialState, action: IAction) => {
  switch (action.type) {
    case FETCHING_ENGAGEMENTS:
      return { ...state, fetching: true, data: null, error: null };
    case FETCHING_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        data: action.payload.response,
        fetching: false,
        error: null,
      };
    case FETCHING_ENGAGEMENT_FAILURE:
      return { ...state, error: action.payload, fetching: false, data: null };
    case UPDATE_ENGAGEMENTS : 
        return {...state, data : action.payload, fetching: false, error: null}  
    default:
      return state;
  }
};

export default EngagementsReducers;

export const EngagementsCategoryReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_ENGAGEMENT_CATEGORIES_FETCHING:
      return { ...state, fetching: true, data: null, error: null };
    case GET_ENGAGEMENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: action.payload.response,
        fetching: false,
        error: null,
      };
    case GET_ENGAGEMENT_CATEGORIES_FAILURE:
      return { ...state, error: action.payload, fetching: false, data: null };
    default:
      return state;
  }
};



export const EngagementsDetailsAPIReducers = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_ENGAGEMENT_BY_ID_FETCHING:
      return { ...state, fetching: true, data: null, error: null };
    case GET_ENGAGEMENT_BY_ID_SUCCESS:
      return {
        ...state,
        data: {id : action.payload.id,response : action.payload.response},
        fetching: false,
        error: null,
      };
    case GET_ENGAGEMENT_BY_ID_FAILURE:
      return { ...state, error: action.payload, fetching: false, data: null };
    default:
      return state;
  }
};


export const UpsertEngagementReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case ENGAGEMENT_UPSERT_STARTING:
      return { ...state, fetching: true, data: null, error: null };
    case ENGAGEMENT_UPSERT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        error: null,
      };
    case ENGAGEMENT_UPSERT_FAILURE:
      return { ...state, error: action.payload, fetching: false, data: null };
    default:
      return state;
  }
};