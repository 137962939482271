import { CONTEXT_API_SUCCESS, CONTEXT_API_FAILURE, CONTEXT_API_FETCHING } from '../../constants/actions/Auth/auth';

import { IAction } from '../../constants/types/IAction';
import { IDataResponse } from '../../constants/types/API/Apiresponse';

const initialState: IDataResponse = {
    data: null,
    fetching: false,
    error: null
}

const ContextReducers = (state = initialState, action: IAction) => {
    switch (action.type) {
        case CONTEXT_API_FETCHING:
            return { ...state, fetching: true, data: null, error: null };
        case CONTEXT_API_SUCCESS:
            return {
                ...state,
                data: action.payload.response,
                fetching: false, error: null
            };
        case CONTEXT_API_FAILURE:
            return { ...state, error: action.payload, fetching: false,data:null };
        default:
            return state;
    }
}

export default ContextReducers;