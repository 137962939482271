import { IAction } from '../../constants/types/IAction';
import { SET_OFFER_DETAIL, GET_OFFERS, GET_OFFER_BY_ID, UPDATE_OFFER, GET_TERMS_AND_CONDITION, SEND_EMAIL_TO_SELF} from '../../constants/actions/UI/Offers';
import { IGetOffersCategoryPayload, IGetOffersPayload, IOfferDetail, IOfferDetailData } from '../../constants/types/UI/Offers';
import { GET_OFFERS_CATEGORIES } from '../../constants/types/API/Offers';

export function setOfferDetails(payload: IOfferDetail ): IAction {
    return {
        type: SET_OFFER_DETAIL,
        payload: payload
    }
}

export function getOffers(payload: IGetOffersPayload): IAction {
  return {
    type: GET_OFFERS,
    payload: payload,
  };
}

export function getOffersCategories(payload: IGetOffersCategoryPayload): IAction {
  return {
    type: GET_OFFERS_CATEGORIES,
    payload: payload,
  };
}

export function getOfferByID(id : string): IAction {
  return {
    type: GET_OFFER_BY_ID,
    payload: id
  };
}
export function updateOffer(payload: IOfferDetailData): IAction {
  return {
    type: UPDATE_OFFER,
    payload: payload,
  };
}

export function getOffersTermsAndConditionLink(offerCode: string): IAction {
  return {
    type: GET_TERMS_AND_CONDITION,
    payload: offerCode,
  };
}

export function sendEmailToSelf(offerCode: string): IAction {
  return {
    type: SEND_EMAIL_TO_SELF,
    payload: offerCode,
  };
}

