import React, { Component } from 'react';
import {
    NormalPeoplePicker,
    IPersonaProps,
    IPeoplePickerProps
} from "office-ui-fabric-react";
import { SpecialValues } from '../../../constants/AppConstants';
import { IPeople } from '../../../constants/types/UI/Generic';
import { base64Append } from '../../../constants/AppConstants'
import "./PeoplePicker.css";
import { connect } from 'react-redux';
import { IParticipantMeta } from '../../../constants/types/API/MetaData';

interface IPeoplePickerState {
}

interface ICustomPeoplePickerProps extends Partial<IPeoplePickerProps> {
    //defaultSelectedItems: IPersonaProps[] | undefined;
    allowAtMe?: boolean;
    defaultValues: IPeople[] | null | undefined;
    accessToken: any;
    placeHolder?: string;
    title: string;
    required?: boolean;
    errorMessage?: string;
    isFormValid?: boolean;
    participantsMeta: { [key: string]: IParticipantMeta }
}
const _suggestionProps = {
    suggestionsHeaderText: "Suggested People",
    noResultsFoundText: "No results found",
    loadingText: "Loading",
};

interface IGrapUser {
    id: string;
    displayName: string;
    mail: string;
    userPrincipalName: string;
    jobTitle: string;
    imageurl?: string;
}



class PeoplePicker extends Component<ICustomPeoplePickerProps, IPeoplePickerState>{

    constructor(props: ICustomPeoplePickerProps) {
        super(props);
    }

    private getDefaultPersonas = (persons: IPeople[]) => {
        const personaProps: IPersonaProps[] = [];
        if (persons.length === 0) {
            return personaProps;
        }
        if (persons.length > 0) {
            persons.forEach((person: IPeople) => {
                personaProps.push({
                    text: person.name,
                    imageUrl: person.imageUrl,
                    optionalText: person.alias
                });
            });
        }

        return personaProps;
    };


    /**
     * @function
     * Returns people results(fullname and email) after a REST API call
     * Search for users base on Full name or email starting with characters and the number of those characters should be greater than three
     */
    private _onPeopleFilterChanged = async (
        filterText: string | any,
        currentSelected: any,
        limitResults?: number
    ) => {
        let returnedUsers: any[] = [];

        //Code for rendering @Me
        if (this.props.allowAtMe && filterText.startsWith("@") && filterText.length <= 3) {
            return [
                {
                    key: SpecialValues.Me, primaryText: SpecialValues.Me, text: SpecialValues.Me,
                    showSecondaryText: false,
                    initialsColor: "grey",
                    imageInitials: "Me",
                    displayName: SpecialValues.Me
                }
            ];
        }

        if (filterText.length < 1) {
            return [];
        } else {
            var data = await this.getGraphUsers(filterText, this.props.accessToken.accessToken); 
            var filtered=data.value.filter((i:any)=>Object.keys(this.props.participantsMeta).map(key => key.toLowerCase()).includes(i.userPrincipalName.toLowerCase()));
            if (filtered.length > 0) {               
                var userPics = await this.getGraphPhotos(filtered, this.props.accessToken.accessToken);
                filtered.forEach((element: any) => {
                    var userPic = userPics ? userPics.responses.find((picData: any) => picData.id === element.userPrincipalName) : undefined;
                    returnedUsers.push(
                        {
                            displayName: element.displayName,
                            id: element.id,
                            email: element.mail,
                            alias: element.userPrincipalName,
                            jobTitle: element.jobTitle,
                            text: element.displayName,
                            key: element.displayName,
                            secondaryText: element.userPrincipalName,
                            optionalText: element.userPrincipalName,
                            imageUrl: userPic ? (userPic.status == 200 ? `${base64Append}${userPic.body}` : undefined) : undefined,
                        }
                    );
                });
            }

        }
        return returnedUsers;
    };

    filterAliases = () => {
        var appendString = "userPrincipalName in (";
        if (this.props.participantsMeta) {
            Object.keys(this.props.participantsMeta).forEach(user => {
                appendString = appendString + `'${user}',`
            });
        }
        else {
            appendString = appendString + "'apiNotCalled')";
            return appendString;
        }
        appendString = appendString.substring(0, appendString.length - 1) + ")";
        return appendString;
    }

    /**
    * Gets list of users from Graph API. Returns id,displayName,mail,userPrincipalName (alias),jobTitle from GraphAPI.
    * @param {string} filterText - User text to be filtered
    * @param {string} accessToken - Access Token
    */
    private getGraphUsers = async (filterText: string, accessToken: string) => {
        var graphURL = `https://graph.microsoft.com/v1.0/users?$search="displayName:${filterText}" OR "userPrincipalName:${filterText}" OR "mail:${filterText}"&$select=id,displayName,mail,userPrincipalName,jobTitle&$orderby=displayName&$filter=endsWith(mail,'microsoft.com') &$count=true&$top=999`;
        try {
            const response = await fetch(graphURL, {
                headers: new Headers({
                    Authorization: `Bearer ${accessToken}`,
                    ConsistencyLevel: 'eventual'
                }),
                method: "GET"
            }).then(response => {
                if (!response.ok) {
                    throw { error: response.statusText }
                }
                else {
                    return response.json();
                }
            });

            return response;
        } catch (error) {
        }
    }

    /**
    * Gets list of photos from Graph API. Returns id,displayName,mail,userPrincipalName (alias),jobTitle from GraphAPI.
    * @param {IGrapUser[]} users - User data array
    * @param {string} accessToken - Access Token
    */
    private getGraphPhotos = async (users: IGrapUser[], accessToken: string) => {
        var graphURL = `https://graph.microsoft.com/v1.0/$batch`;
        if (users.length > 0) {
            var photoApiBody: any = users.map(user => ({
                id: user.userPrincipalName,
                url: `/users/${user.userPrincipalName}/photos/48x48/$value`,
                method: "GET"
            }));
            photoApiBody = { requests: photoApiBody }
            try {
                const response = await fetch(graphURL, {
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    }),
                    method: "POST",
                    body: JSON.stringify(photoApiBody)
                }).then(response => {
                    if (!response.ok) {
                        throw { error: response.statusText }
                    }
                    else {
                        return response.json();
                    }
                });
                return response;
            } catch (error) {
                console.log(error);
            }
        }
        return null;
    }

    render() {
        return (
            <div style={{ width: "100%", paddingTop: "5px" }}
                className={`${this.props.itemLimit === 1 ? "single-select" : ""} ${this.props.disabled ? 'people-picker-disabled' : ''}`}>
                <div className={`peoplepickerLabel`}><label className="ms-Label">{this.props.title}
                    {this.props.required ? <span className='asterisk'> *</span> : ''}</label></div>

                <NormalPeoplePicker
                    selectedItems={!!this.props.defaultValues ? this.getDefaultPersonas(this.props.defaultValues) : undefined}
                    itemLimit={this.props.itemLimit}
                    pickerCalloutProps={{
                        preventDismissOnScroll: false,
                    }}
                    defaultSelectedItems={!!this.props.defaultValues ? this.getDefaultPersonas(this.props.defaultValues) : undefined}
                    onChange={this.props.onChange}
                    onResolveSuggestions={this._onPeopleFilterChanged.bind(
                        this
                    )}
                    resolveDelay={800}
                    className={`custom-people-picker ${!!this.props.errorMessage} ${this.props.className}`}
                    pickerSuggestionsProps={_suggestionProps}
                    disabled={this.props.disabled}
                    inputProps={{
                        placeholder: this.props.placeHolder ? this.props.placeHolder : "Search users"
                    }}
                />
                {this.props.errorMessage && this.props.required && <div className="people-picker-error people-error">{this.props.errorMessage}</div>}
            </div>
        );
    }
}

/**
* Returns true if the string has the character
* @param {string} text - User text to be filtered
* @param {string} character - Character
*/
const _hasChar = (text: string, character: any): boolean => {
    return text.trim().indexOf(character) > 0;
}

const mapStateToProps = (state: { ui: any, auth: any, api: any }) => ({
    participantsMeta: state.api.participantsMeta.data
})

const mapDispatchToProps = (dispatch: any) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PeoplePicker);
