import React, { ReactNode } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const TelemetryContext = React.createContext<ApplicationInsights | null>(null);

interface AppInsightsProviderProps {
    appInsights: ApplicationInsights;
    children: ReactNode;
}

const TelemetryContextProvider = ({ appInsights, children }: AppInsightsProviderProps) => {
    return (
        <TelemetryContext.Provider value={appInsights}>
            {children}
        </TelemetryContext.Provider>
    );
}

export {TelemetryContext, TelemetryContextProvider};
