import { SET_OFFER_DETAIL } from '../../constants/actions/UI/Offers';
import { IAction } from '../../constants/types/IAction';
import { IOfferDetail } from '../../constants/types/UI/Offers';

export const initialState : IOfferDetail= {
    show : false,
    new : false,
    details : null
}

export const OffersDetailReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SET_OFFER_DETAIL:
            return action.payload    
        default:
            return state;
    }
}