import * as React from 'react';
import {
    Modal, IDragOptions, ContextualMenu,
    IconButton, IIconProps, SearchBox
} from '@fluentui/react';
import { IParticipantMeta } from '../../constants/types/API/MetaData'
import './ParticipantsModal.css'

const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
};

interface IParticipantsModalProps {
    toHide: boolean;
    toHideDialog: (hide: boolean) => void;
    sortedParticipants: IParticipantMeta[];
}

interface IParticipantsModalState {
    isDraggable: boolean;
    searchTerm: string;
    sortedParticipants: IParticipantMeta[];
}

const cancelIcon: IIconProps = { iconName: 'Cancel' };

class ParticipantsModal extends React.Component<IParticipantsModalProps, IParticipantsModalState>  {

    constructor(props: IParticipantsModalProps) {
        super(props);
        this.state = {
            isDraggable: true,
            sortedParticipants: this.props.sortedParticipants,
            searchTerm: ""
        }
    }

    componentDidUpdate(prevProps: IParticipantsModalProps, prevState: IParticipantsModalState) {
        if (prevState.searchTerm !== this.state.searchTerm) {
            this.setState({
                sortedParticipants: this.state.searchTerm ? this.props.sortedParticipants.filter(participant => {
                    return participant.displayName.toLowerCase().indexOf(this.state.searchTerm?.toLowerCase()) > -1 ||
                        participant.userAlias.toLowerCase().indexOf(this.state.searchTerm?.toLowerCase()) > -1
                }) : this.props.sortedParticipants
            })
        }
    }

    render() {

        return (
            <Modal
                isOpen={!this.props.toHide}
                dragOptions={this.state.isDraggable ? dragOptions : undefined}
                onDismiss={() => { this.props.toHideDialog(true) }}
                className="participants-modal"
                isBlocking={false}
                aria-labelledby="Participants Modal"
                aria-label="Participants Modal"
                aria-modal="true"
            >
                <div className="participants-modal-parent">
                    <div className="participants-modal-header">
                        <a href="javascript:void(0)" className="participants-modal-header-title" aria-label="Participants Dialog"><h4 className="sr-only" role="heading">Participants</h4></a>
                        <div><IconButton
                            aria-label='Close'
                            className="participants-modal-icon"
                            iconProps={cancelIcon}
                            onClick={() => { this.props.toHideDialog(true) }}
                        /></div>
                    </div>
                    <div className="participant-modal-search"><SearchBox
                        placeholder="Search users"
                        value={this.state.searchTerm}
                        autoComplete="off"
                        onChange={(_, newValue) => {
                            this.setState({
                                searchTerm: newValue ? newValue : ""
                            });
                        }} />
                    </div>

                    <table aria-label='Participants' className="tableParticipant">
                        <tr>
                            <th style={{ height: "20px" }}>Name</th>
                            <th style={{ height: "20px" }}>Alias</th>
                        </tr>
                        {this.state.sortedParticipants.map(participant => {
                            return (<tr>
                                <td>{participant.displayName}</td>
                                <td>{participant.userAlias}</td>
                            </tr>
                            );
                        })}
                    </table>
                    {/* <div className="participant-modal-body">
                        <div className="participant-modal-row body-header">
                            <div className="participant-modal-cell left-cell participant-modal-table-header-left">Name</div>
                            <div className="participant-modal-cell right-cell participant-modal-table-header-right">Alias</div>
                        </div>
                        {this.state.sortedParticipants.map(participant => {
                            return (
                                <div className="participant-modal-row">
                                    <div className="participant-modal-cell left-cell" title={participant.displayName}>{participant.displayName}</div>
                                    <div className="participant-modal-cell right-cell" title={participant.userAlias}>{participant.userAlias}</div>
                                </div>
                            );
                        })}
                    </div> */}
                </div>
            </Modal>
        );
    }
};

export default ParticipantsModal;
