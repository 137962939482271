import React from "react";

import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { Provider } from "react-redux";
import Tracker from "./utils/tracker";
import store from "./store";
import {
  HOME_URL,
  REPORTS_URL,
  UNAUTHORIZED_URL,
  ERROR404_URL,
  ENGAGEMENTS_URL,
  OFFERS_URL,
  PARTICIPANTS_URL,
} from "./constants/routes";
import Authentication from "./components/common/Authentication";
import AppContainer from "./containers/AppContainer";
import "office-ui-fabric-core/dist/css/fabric.min.css";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";

function App() {
    return (
    <FluentProvider theme={webLightTheme}>
      <Provider store={store}>
        <Router>
          <Tracker>
            <Switch>
              <Redirect exact from="/" to={`${HOME_URL}`} />
              <Route
                exact
                path={`/:url(${HOME_URL}|${REPORTS_URL}|${ENGAGEMENTS_URL}|${OFFERS_URL}|${PARTICIPANTS_URL})/auth`}
                component={Authentication}
              />
              <Route
                exact
                path={`/:url(${HOME_URL}|${REPORTS_URL}|${ENGAGEMENTS_URL}|${OFFERS_URL}|${PARTICIPANTS_URL}|${UNAUTHORIZED_URL}|${ERROR404_URL})`}
                key={0}
                render={() => <AppContainer />}
              />
              <Route path="*" render={() => <Redirect to="/" />} />
            </Switch>
          </Tracker>
        </Router>
      </Provider>
    </FluentProvider>
  );
}

export default App;
