
import { applyMiddleware, createStore, compose } from 'redux';
import { loadState } from './utils/localStorage';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga'
import sagas from './services/sagas';
import rootReducers from './rootReducer';

const isProduction = process.env.REACT_APP_IS_PRODUCTION;
const loggerMiddleware = createLogger();

const persistedState = loadState();
const composer = isProduction === "true" ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

let middleWares = composer(applyMiddleware(loggerMiddleware, sagaMiddleware));

if (`${process.env.REACT_APP_PRODUCTION}` === 'true') {
  middleWares = composer(applyMiddleware(sagaMiddleware));
}

const store = createStore(rootReducers, persistedState, middleWares);

sagaMiddleware.run(sagas);

export default store;