import * as React from "react";
import { MessageBar, MessageBarType } from "office-ui-fabric-react";

interface ErrorMessageProps {
  children: any;
  onDismiss?: () => void;
  focusOnMount?: boolean;
  containerClassName?: string;
  className?: string;
  closeMessageAriaLabel?: string;
  isMultiLine?: boolean;
  messageType: MessageBarType;
}

export class MPMessageBar extends React.Component<ErrorMessageProps> {
  private MessageRef!: HTMLDivElement;

  setTextInputRef = (element: HTMLDivElement) => {
    this.MessageRef = element;
  };

  componentDidMount() {
    if (this.MessageRef && this.props.focusOnMount) {
      this.MessageRef.focus();
    }
  }

  render() {
    return (
      <div
        tabIndex={-1}
        ref={this.setTextInputRef}
        className={this.props.containerClassName}
      >
        <MessageBar
          className={`${this.props.className}`}
          dismissButtonAriaLabel={"Close"}
          messageBarType={this.props.messageType}
          onDismiss={this.props.onDismiss}
          isMultiline={this.props.isMultiLine}
        >
          {this.props.children}
        </MessageBar>
      </div>
    );
  }
}
