import { SET_SCOPES } from '../../constants/actions/UI/Scopes';
import { IAction } from '../../constants/types/IAction';

export const ScopesReducer = (state = null, action: IAction) => {
    switch (action.type) {
        case SET_SCOPES:
            return action.payload    
        default:
            return state;
    }
}