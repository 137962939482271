import React, { Component } from "react";
import './CustomCheckBoxes.css';
import _ from 'lodash';

interface ICustomCheckBoxesProps {
    items: string[];
    groupName: string;
    selectedCheckBoxes?: string[];
    required?: boolean;
    disabled?: boolean;
    isFormValid?: boolean;
    updateSelectedRB: Function;
    label: string;
    errorMessage? : string;
}

interface ICustomCheckBoxesState {

}

class CustomCheckBoxes extends Component<ICustomCheckBoxesProps, ICustomCheckBoxesState>{

    constructor(props: ICustomCheckBoxesProps) {
        super(props);
    }

    checkBoxClick = (e: any, item: any) => {
        if (e.currentTarget.checked) {
            if (this.props.selectedCheckBoxes?.indexOf(item) === -1) {
                this.props.updateSelectedRB([...this.props.selectedCheckBoxes, item]);
            }
        }
        else {
            var cloneSC = _.cloneDeep(this.props.selectedCheckBoxes ?? []);
            const index = cloneSC.indexOf(e.currentTarget.id);
            if (index > -1) {
                cloneSC.splice(index, 1);
            }
            this.props.updateSelectedRB(cloneSC);
        }
    }

    render() {
        return <div>
            <div className="check-boxes-header">
                {this.props.label}{this.props.required && <span className="asterisk"> *</span>}
            </div>
            <div className="check-boxes-buttons">
                {this.props.items.map(item =>
                    <div className="check-boxes-button">
                        <div><input
                            disabled={this.props.disabled ?? false}
                            type="checkbox"
                            checked={(this.props.selectedCheckBoxes?.indexOf(item) ?? -1) > -1}
                            id={item}
                            value={item}
                            name={this.props.groupName}
                            onChange={(e) => this.checkBoxClick(e, item)}
                        /></div>
                        <div><label htmlFor={item}>{item}</label></div>
                    </div>)
                }
            </div>
            {this.props.errorMessage && this.props.required && <span className="custom-check-boxes-error">{this.props.errorMessage}</span>}
        </div>;
    }
}

export default CustomCheckBoxes;