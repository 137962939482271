import React from "react";
import {
  SearchBox,
  PrimaryButton,
  IIconProps,
  DefaultButton,
  MessageBarType,
  DirectionalHint,
  Icon,
} from "@fluentui/react";
import OffersList from "./OffersList";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import { ICategory } from "../board/BoardInterfaces";

import SideNav from "../common/SideNav";
import { Toggle } from "@fluentui/react/lib/Toggle";
import {
  ACCESS_LEVELS,
  IGetOffersCategoryPayload,
  IOfferDetail,
  IOfferDetailData,
} from "../../constants/types/UI/Offers";
import {
  getOffersCategories,
  getOffersTermsAndConditionLink,
  sendEmailToSelf,
  setOfferDetails,
} from "../../actions/UI/Offers";
import { getOffers, updateOffer } from "../../actions/UI/Offers";
import { connect } from "react-redux";
import { toggleSpinner } from "../../actions/UI/globalSpinner";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { Account, AuthResponse } from "msal";
import AppConstants, {
  AppRoles,
  updateStatus,
  updateStatusText,
} from "../../constants/AppConstants";
import {
  activeSideNavs,
  IAppScopes,
  searchCalled,
} from "../../constants/types/UI/Generic";
import { OFFERS_URL } from "../../constants/routes";
import {
  OFFERS_STATE,
  OFFERS_COLUMNS_FIELDS,
  OFFERS_COLUMNS_TEXT,
  IGetOffersPayload,
} from "../../constants/types/UI/Offers";
import { ISorting } from "../../constants/types/UI/Generic";
import moment from "moment";
import { IDataResponse } from "../../constants/types/API/Apiresponse";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";
import { has, remove } from "lodash";
import {
  ActionTrackingOffer,
  ActionTrackingSearch,
  CreateOffer,
  DownloadOffersTCPDF,
  EmailOffersTCPDFToSelf,
  logEvent,
} from "../../utils/insightsClient";
import { Text } from '@fluentui/react';

interface IOffersState {
  searchTerm: string;
  items: IOffersData[];
  _columns: IColumn[];
  categoryData: ICategory[];
  activeMenu: string;
  activeMenuDisplaytext: string;
  hideDialog: boolean;
  sortingOrder: ISorting;
  sideMenuStatus: boolean;
  totalOffersCount: number;
  toggledElement: any;
  actualSearchTerm: string;
  offerCodeTogetLink: string;
  offerTemplateVersion: string;
  copilotPinnedState: boolean;
  copilotUnpinnedState: boolean;
}

interface IOffersData {
  key?: number;
  OfferCode?: string;
  Role: any;
  IsActive?: any;
  Team?: number;
  OfferTitle?: string;
  OfferingSummary?: string;
  OfferingDetails?: string;
  AccessLevel?: number | string;
  OfferLegalTerms?: string;
  CloudType?: number;
  OfferTemplate?: string;
  OfferTemplateVersion?: string;
  CreatedBy: string;
  CreatedDate: string;
  LastModifiedBy?: string;
  LastModifiedDate?: string;
  IsDeleted?: boolean;
  Status?: number;
  CELAContact?: string;
  EngagementDeliveryLeads?: any;
  LastOfferPublishedOn?: string;
  TotalEngagements?: number;
}

interface IColumnItems {
  name: string;
  fieldName: string;
}

interface IOfferProps {
  setOfferDetails: Function;
  getOffers: Function;
  user: Account | null;
  apiAccessToken: AuthResponse | null;
  coreApiAccessToken: AuthResponse | null;
  offers: any;
  offersCategories: any;
  toggleSpinner: Function;
  appScopes: IAppScopes;
  history: any;
  buildAndShowBanner: (
    message: string,
    messageType: MessageBarType,
    timeOut: number
  ) => void;
  offerStates: any;
  accessLevels: any;
  getOffersCategories: Function;
  getOffersTermsAndConditionLink: Function;
  pdfUrl: any;
  userContext: any;
  getTCLinkState: IDataResponse;
  sendEmailToSelf: Function;
  sendEmailToSelfState: IDataResponse;
}

const calloutProps = { gapSpace: 0 };
// The TooltipHost root uses display: inline by default.
// If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};

const ttstyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};
const ttcalloutProps = { gapSpace: 0 };

const getOfferStatus = (offerStates: any, activeOfferStatus: string) => {
  var activeOfferStatusId = "";
  offerStates.forEach((offerState: any) => {
    if (activeOfferStatus === offerState.metaData.cdd_Id.toString()) {
      activeOfferStatusId = offerState.metaData.cdd_Id.toString();
    }
  });
  return activeOfferStatusId;
};
var renderCount: number = 0;

class Offers extends React.Component<IOfferProps, IOffersState> {
  searchTimer: any;
  private _allItems: IOffersData[] = [];
  private stateIds = {
    ACTIVE: "",
    INACTIVE: "",
    APPROVED: "",
    IN_REVIEW: "",
    DRAFT: "",
    REJECTED: "",
  };

  sortOffers = (
    a: { Status: number; Count: number },
    b: { Status: number; Count: number }
  ) => {
    const orderToBeShown = {
      [this.props.offerStates.values.find(
        (offState: any) => offState.id == OFFERS_STATE.IN_REVIEW
      ).metaData.cdd_Id]: 0,
      [this.props.offerStates.values.find(
        (offState: any) => offState.id == OFFERS_STATE.ACTIVE
      ).metaData.cdd_Id]: 1,
      [this.props.offerStates.values.find(
        (offState: any) => offState.id == OFFERS_STATE.INACTIVE
      ).metaData.cdd_Id]: 2,
      [this.props.offerStates.values.find(
        (offState: any) => offState.id == OFFERS_STATE.DRAFT
      ).metaData.cdd_Id]: 3,
    };
    var nameA = orderToBeShown[a.Status],
      nameB = orderToBeShown[b.Status];
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  private accessLevels = {
    READ_ONLY: "",
    CONTRIBUTER: "",
    POC: "",
    SAC: "",
  };

  constructor(props: IOfferProps) {
    super(props);
    this.enableLoader();
    this.state = {
      searchTerm: "",
      actualSearchTerm: "",
      categoryData: [],
      activeMenu:
        getOfferStatus(this.props.offerStates.values, activeSideNavs.offers) ??
        "",
      activeMenuDisplaytext: "",
      items: [],
      _columns: [],
      hideDialog: true,
      sortingOrder: { column: "LastOfferPublishedOn", order: "desc" },
      sideMenuStatus: false,
      totalOffersCount: 0,
      toggledElement: {},
      offerCodeTogetLink: "",
      offerTemplateVersion: "",
      copilotPinnedState: this.getPinnedState(),
      copilotUnpinnedState: this.getUnpinnedState(),
    };
  }

  createOfferStatusObject = () => {
    this.props.offerStates.values.forEach((offerState: any) => {
      switch (offerState.id) {
        case OFFERS_STATE.ACTIVE:
          this.stateIds.ACTIVE = offerState.metaData.cdd_Id.toString();
          break;
        case OFFERS_STATE.INACTIVE:
          this.stateIds.INACTIVE = offerState.metaData.cdd_Id.toString();
          break;
        case OFFERS_STATE.APPROVED:
          this.stateIds.APPROVED = offerState.metaData.cdd_Id.toString();
          break;
        case OFFERS_STATE.IN_REVIEW:
          this.stateIds.IN_REVIEW = offerState.metaData.cdd_Id.toString();
          break;
        case OFFERS_STATE.DRAFT:
          this.stateIds.DRAFT = offerState.metaData.cdd_Id.toString();
          break;
        case OFFERS_STATE.REJECTED:
          this.stateIds.REJECTED = offerState.metaData.cdd_Id.toString();
          break;
      }
    });
  };

  createAccessLevelObject = () => {
    this.props.accessLevels.values.forEach((accessLevels: any) => {
      switch (accessLevels.id) {
        case ACCESS_LEVELS.READ_ONLY:
          this.accessLevels.READ_ONLY = accessLevels.metaData.cdd_Id.toString();
          break;
        case ACCESS_LEVELS.CONTRIBUTER:
          this.accessLevels.CONTRIBUTER =
            accessLevels.metaData.cdd_Id.toString();
          break;
        case ACCESS_LEVELS.SAC:
          this.accessLevels.SAC = accessLevels.metaData.cdd_Id.toString();
          break;
        case ACCESS_LEVELS.POC:
          this.accessLevels.POC = accessLevels.metaData.cdd_Id.toString();
          break;
      }
    });
  };

  getAccessLevelsText = (accessLevelIds: string) => {
    let accessLevelDisplayText = "";
    switch (accessLevelIds.toString()) {
      case this.accessLevels.READ_ONLY:
        accessLevelDisplayText = ACCESS_LEVELS.READ_ONLY;
        break;
      case this.accessLevels.CONTRIBUTER:
        accessLevelDisplayText = ACCESS_LEVELS.CONTRIBUTER;
        break;
      case this.accessLevels.SAC:
        accessLevelDisplayText = ACCESS_LEVELS.SAC;
        break;
      case this.accessLevels.POC:
        accessLevelDisplayText = ACCESS_LEVELS.POC;
        break;
    }
    return accessLevelDisplayText;
  };

  getActions = (offerCode: string, offerTemplateVersion: string) => {
    return (
      <>
        <a
          href="javascript:void(0);"
          onClick={() =>
            this.getOffersTermsAndConditionLink(offerCode, offerTemplateVersion)
          }
        >
          <Icon
            iconName="PDF"
            title="Download Offer Legal Terms"
            ariaLabel="Download Offer Legal Terms"
            className="emailDownloadIcon"
            style={{ color: "#000000" }}
          />
        </a>
        <a
          href="javascript:void(0);"
          onClick={() => this.sendEmailToSelf(offerCode)}
        >
          <Icon
            iconName="MailReply"
            title="Email Offer Legal Terms to self"
            ariaLabel="Email Offer Legal Terms to self"
            className="emailDownloadIcon"
            style={{ color: "#000000", marginLeft: "9px" }}
          />
        </a>
      </>
    );
  };

  getOffersTermsAndConditionLink = (
    offerCode: string,
    offerTemplateVersion: string
  ) => {
    this.setState(
      {
        offerCodeTogetLink: offerCode,
        offerTemplateVersion: offerTemplateVersion,
      },
      () => {
        this.props.getOffersTermsAndConditionLink(offerCode);
      }
    );
  };

  sendEmailToSelf = (offerCode: string) => {
    this.enableLoader("Sending Email...");
    this.props.sendEmailToSelf(offerCode);
  };

  getDeliveryLeadsText = (deliveryLeads: string, index: number) => {
    let leads = deliveryLeads.split("; ");
    if (leads.length > 1) {
      let firstDeliveryLead = leads[0];
      return (
        <div>
          {firstDeliveryLead}
          <TooltipHost
            content={deliveryLeads.toString()}
            id={index.toString()}
            calloutProps={calloutProps}
            styles={hostStyles}
          >
            <span aria-describedby={index.toString()} className="moreData">
              {" "}
              {`+${deliveryLeads.split("; ").length - 1} more`}
            </span>
          </TooltipHost>
        </div>
      );
    } else {
      return <div>{leads[0]}</div>;
    }
  };

  triggerGetOffers = () => {
    this.props.getOffersCategories({ searchTerm: this.state.actualSearchTerm });
    const event = new Event("triggerEngagementUpdate");
    document.dispatchEvent(event);
    this.enableLoader();
    if (!this.state.activeMenu) {
      this.setState(
        {
          activeMenu: this.stateIds.ACTIVE,
        },
        () => {
          const engPayload: IGetOffersPayload = {
            alias: this.props.user?.userName.split("@")[0] ?? "",
            searchTerm: this.state.actualSearchTerm,
            activeStatus: this.state.activeMenu,
            sortingOrder: this.state.sortingOrder,
          };
          this.props.getOffers(engPayload);
        }
      );
      return;
    }
    const engPayload: IGetOffersPayload = {
      alias: this.props.user?.userName.split("@")[0] ?? "",
      searchTerm: this.state.actualSearchTerm,
      activeStatus: this.state.activeMenu,
      sortingOrder: this.state.sortingOrder,
    };
    this.props.getOffers(engPayload);
  };

  componentDidMount() {
    this.createOfferStatusObject();
    this.createAccessLevelObject();
    if (
      this.props.user &&
      this.props.apiAccessToken &&
      this.props.coreApiAccessToken
    ) {
      this.triggerGetOffers();
    }
    window.addEventListener("storage", this.handlePinnedState, false);
  }

  componentDidUpdate(prevProps: IOfferProps, prevState: IOffersState) {
    if (prevProps.offers !== this.props.offers) {
      if (this.props.offers.data && !this.props.offers.fetching) {
        this.setState({
          _columns: this.genColumnData(this.state.sortingOrder),
          items: this.buildOfferItems(this.props.offers.data),
        });
        this.disableLoader();
      } else if (this.props.offers.error && !this.props.offers.fetching) {
        this.props.buildAndShowBanner(
          "Failed to get offers data properly. Please reload!!",
          MessageBarType.severeWarning,
          5000
        );
        this.disableLoader();
      }
    }

    // if(prevProps.getTCLinkState !== this.props.getTCLinkState) {
    //   if(!this.props.getTCLinkState.fetching && !this.props.getTCLinkState.error){
    //     let PDFUrl = this.props.pdfUrl?.data.response[0].PDFUrl;
    //     var link = document.createElement('a');
    //     link.href = PDFUrl;
    //     link.download = this.state.offerCodeTogetLink + '.PDF';
    //     link.dispatchEvent(new MouseEvent('click'));
    //     logEvent(DownloadOffersTCPDF,{
    //       LinkClicked:"Download Offer Legal terms and condition",
    //       OfferCode: this.state.offerCodeTogetLink,
    //       User: this.props.user?.userName,
    //       Role: this.props.userContext.data?.roles,
    //       Scopes: this.props.userContext.data?.scopes,
    //       OfferTemplateVersion: this.state.offerTemplateVersion
    //     });
    //   }
    // }

    // if(prevProps.sendEmailToSelfState !== this.props.sendEmailToSelfState) {
    //   if (
    //     this.props.sendEmailToSelfState.data &&
    //     !this.props.sendEmailToSelfState.fetching
    //   ) {
    //     this.disableLoader();
    //     this.props.buildAndShowBanner(
    //       "Offer Legal Terms sent to your email successfully.",
    //       MessageBarType.success,
    //       5000
    //     );
    //     logEvent(EmailOffersTCPDFToSelf,{
    //       LinkClicked:"Email Offer Legal terms and condition to Self",
    //       OfferCode: this.state.offerCodeTogetLink,
    //       User: this.props.user?.userName,
    //       Role: this.props.userContext.data?.roles,
    //       Scopes: this.props.userContext.data?.scopes,
    //       OfferTemplateVersion: this.state.offerTemplateVersion});
    //   } else if (
    //     this.props.sendEmailToSelfState.error &&
    //     !this.props.sendEmailToSelfState.fetching
    //   ) {
    //     this.props.buildAndShowBanner(
    //       "Failed to get offers data properly. Please reload!!",
    //       MessageBarType.severeWarning,
    //       5000
    //     );
    //     this.disableLoader();
    //   }
    // }

    if (prevProps.offersCategories !== this.props.offersCategories) {
      if (
        this.props.offersCategories.data &&
        !this.props.offersCategories.fetching
      ) {
        if (!!this.state.actualSearchTerm) {
          const normalIdentifier = this.changeSideNavBasedOnSearch(
            this.props.offersCategories.data
          );
          if (!!normalIdentifier && searchCalled.offerSearchCalled == true) {
            searchCalled.offerSearchCalled = false;
            this.sideNavCallback(normalIdentifier);
          } else if (searchCalled.offerSearchCalled == true) {
            searchCalled.offerSearchCalled = false;
          }
        }
        this.buildCategories(this.props.offersCategories.data);
      } else if (
        this.props.offersCategories.error &&
        !this.props.offersCategories.fetching
      ) {
        this.props.buildAndShowBanner(
          "Failed to get offers data properly. Please reload!!",
          MessageBarType.severeWarning,
          5000
        );
        this.disableLoader();
      }
    }

    // get offers again
    if (
      prevState.actualSearchTerm !== this.state.actualSearchTerm ||
      prevState.activeMenu !== this.state.activeMenu ||
      prevState.sortingOrder !== this.state.sortingOrder
    ) {
      this.triggerGetOffers();
    }

    if (prevState.activeMenu !== this.state.activeMenu) {
      this.setState({ _columns: this.genColumnData(this.state.sortingOrder) });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.handlePinnedState, false);
  }

  handlePinnedState = () => {
    this.setState({ copilotPinnedState: this.getPinnedState() });
  };

  handleUnpinnedState = () => {
    this.setState({ copilotUnpinnedState: this.getUnpinnedState() });
  };

  getPinnedState = (): boolean => {
    const isPinned = sessionStorage.getItem("copilotPinnedState");
    if (isPinned) {
      return JSON.parse(isPinned);
    }
    return false;
  };

  getUnpinnedState = (): boolean => {
    const isPinned = sessionStorage.getItem("showCopilotWindow");
    if (isPinned) {
      return JSON.parse(isPinned);
    }
    return false;
  };

  changeSideNavBasedOnSearch = (
    offerCategories: [{ Status: number; Count: number }]
  ) => {
    const getSortedCategories = offerCategories.sort(this.sortOffers);
    const foundCategory = getSortedCategories.find((cat) => cat.Count > 0);
    if (foundCategory) {
      var currentCategory: any = getSortedCategories.find(
        (sortCat) => sortCat.Status.toString() == this.state.activeMenu
      );
      currentCategory =
        currentCategory?.Count > 0
          ? currentCategory?.Status.toString()
          : undefined;
      if (!currentCategory) {
        return foundCategory.Status.toString();
      }
      return undefined;
    }
    return undefined;
  };

  buildOfferItems = (data: IOffersData[]) => {
    if (this.state) {
      this._allItems = [];
      switch (this.state.activeMenu) {
        case this.stateIds.IN_REVIEW:
          data.forEach((element: any) => {
            let offers = {
              Role: this.getOfferRolename(element.Role, element.OfferCode),
              AccessLevel: this.getAccessLevelsText(element.AccessLevel),
              CreatedBy: element.CreatedBy,
              CreatedDate:
                element.CreatedDate === null
                  ? "--"
                  : this.formatDateTime(element.CreatedDate),
              OfferCode: element.OfferCode,
            };
            this._allItems.push(offers);
          });
          this.setState({
            activeMenuDisplaytext: OFFERS_STATE.IN_REVIEW,
          });
          break;
        case this.stateIds.ACTIVE:
          data.forEach((element: any, index: number) => {
            let offers = {
              Role: this.getOfferRolename(element.Role, element.OfferCode),
              AccessLevel: this.getAccessLevelsText(element.AccessLevel),
              TotalEngagements: element.TotalEngagements,
              EngagementDeliveryLeads:
                element.EngagementDeliveryLeads === null
                  ? "--"
                  : this.getDeliveryLeadsText(
                      element.EngagementDeliveryLeads,
                      index
                    ),
              LastOfferPublishedOn:
                element.LastOfferPublishedOn === null
                  ? "--"
                  : this.formatDateTime(element.LastOfferPublishedOn),
              LastModifiedDate:
                element.LastModifiedDate === null
                  ? "--"
                  : this.formatDateTime(element.LastModifiedDate),
              CreatedBy: element.CreatedBy,
              CreatedDate:
                element.CreatedDate === null
                  ? "--"
                  : this.formatDateTime(element.CreatedDate),
              Actions: this.getActions(
                element.OfferCode,
                element.OfferTemplateVersion
              ),
              OfferCode: element.OfferCode,
            };
            this._allItems.push(offers);
          });
          this.setState({
            activeMenuDisplaytext: OFFERS_STATE.ACTIVE,
          });
          break;
        case this.stateIds.INACTIVE:
          data.forEach((element: any, index: number) => {
            let offers = {
              OfferCode: element.OfferCode,
              Role: this.getOfferRolename(element.Role, element.OfferCode),
              AccessLevel: this.getAccessLevelsText(element.AccessLevel),
              TotalEngagements: element.TotalEngagements,
              EngagementDeliveryLeads:
                element.EngagementDeliveryLeads === null
                  ? "--"
                  : this.getDeliveryLeadsText(
                      element.EngagementDeliveryLeads,
                      index
                    ),
              LastOfferPublishedOn:
                element.LastOfferPublishedOn === null
                  ? "--"
                  : this.formatDateTime(element.LastOfferPublishedOn),
              CreatedBy: element.CreatedBy,
              CreatedDate:
                element.CreatedDate === null
                  ? "--"
                  : this.formatDateTime(element.CreatedDate),
              LastModifiedDate:
                element.LastModifiedDate === null
                  ? "--"
                  : this.formatDateTime(element.LastModifiedDate),
            };
            this._allItems.push(offers);
          });
          this.setState({
            activeMenuDisplaytext: OFFERS_STATE.INACTIVE,
          });
          break;
        default:
          data.forEach((element: any) => {
            let offers = {
              Role: this.getOfferRolename(element.Role, element.OfferCode),
              AccessLevel: this.getAccessLevelsText(element.AccessLevel),
              CreatedBy: element.CreatedBy,
              CreatedDate: this.formatDateTime(element.CreatedDate),
              OfferCode: element.OfferCode,
            };
            this._allItems.push(offers);
          });
          this.setState({
            activeMenuDisplaytext: OFFERS_STATE.DRAFT,
          });
          break;
      }
    }
    return this._allItems;
  };

  getOfferRolename = (roleName: string, offerCode: string) => {
    return (
      <>
        <a
          onClick={() => {
            sessionStorage.setItem("showCopilotWindow", String(false));
            sessionStorage.setItem("offerPanelOpen", String(true));
            window.dispatchEvent(new Event("storage"));
            this.props.history.push(
              "/offers?id=" +
                offerCode +
                this.props.history["location"]["search"].replace("?", "&")
            );
          }}
          title="Click to Open"
          style={{
            textDecoration: "none",
            color: "#1073c8",
            cursor: "pointer",
          }}
        >
          {roleName}
        </a>
      </>
    );
  };

  buildCategories = (offersCategories: any) => {
    this.setState({
      categoryData: [
        {
          title: this.stateIds.IN_REVIEW,
          displayName: OFFERS_STATE.IN_REVIEW,
          itemCount: this.getCategoryCount(
            offersCategories,
            this.stateIds.IN_REVIEW
          ),
          icon: "reviewsolid",
        },
        {
          title: this.stateIds.ACTIVE,
          displayName: OFFERS_STATE.ACTIVE,
          itemCount: this.getCategoryCount(
            offersCategories,
            this.stateIds.ACTIVE
          ),
          icon: "activateorders",
        },
        {
          title: this.stateIds.INACTIVE,
          displayName: OFFERS_STATE.INACTIVE,
          itemCount: this.getCategoryCount(
            offersCategories,
            this.stateIds.INACTIVE
          ),
          icon: "deactivateorders",
        },
        {
          title: this.stateIds.DRAFT,
          displayName: OFFERS_STATE.DRAFT,
          itemCount: this.getCategoryCount(
            offersCategories,
            this.stateIds.DRAFT
          ),
          icon: "pageedit",
        },
      ],
      totalOffersCount: this.props.userContext.roles?.find(
        (role: any) => role.name == AppRoles.SiteAdmin.roleName
      )
        ? this.getCategoryCount(offersCategories, this.stateIds.IN_REVIEW) +
          this.getCategoryCount(offersCategories, this.stateIds.ACTIVE) +
          this.getCategoryCount(offersCategories, this.stateIds.INACTIVE) +
          this.getCategoryCount(offersCategories, this.stateIds.DRAFT)
        : this.getCategoryCount(offersCategories, this.stateIds.ACTIVE),
    });
  };

  getCategoryCount = (offerCategories: any, identifier: string) => {
    var catCount = 0;
    offerCategories.forEach((category: { Status: number; Count: number }) => {
      if (identifier === category.Status.toString()) {
        catCount = catCount + category.Count;
      }
    });
    return catCount;
  };

  getOfferToUpdate = (element: IOfferDetailData, mode: string) => {
    return {
      AccessLevel: element.AccessLevel ? element.AccessLevel : null,
      CELAContact: element.CELAContact ? element.CELAContact : null,
      CloudType: element.CloudType,
      CreatedBy: element.CreatedBy,
      CreatedDate: element.CreatedDate,
      EngagementDeliveryLeads: element.EngagementDeliveryLeads,
      IsActive: element.IsActive,
      IsDeleted: element.IsDeleted,
      LastModifiedBy: element.LastModifiedBy,
      LastModifiedDate: element.LastModifiedDate,
      LastOfferPublishedOn: element.LastOfferPublishedOn,
      OfferCode: element.OfferCode,
      OfferLegalTerms: element.OfferLegalTerms,
      OfferTemplate: element.OfferTemplate,
      OfferTemplateVersion: element.OfferTemplateVersion,
      OfferTitle: element.OfferTitle,
      OfferingDetails: element.OfferTitle,
      OfferingSummary: element.OfferingSummary,
      Role: element.Role,
      Status:
        mode === "activate" ? this.stateIds.ACTIVE : this.stateIds.INACTIVE,
      Team: element.Team,
    };
  };

  // format datetime
  private formatDateTime = (dateTime: string): string => {
    return moment(new Date(dateTime)).isValid()
      ? moment.utc(dateTime).local().format("MM/DD/YYYY hh:mm A")
      : "--";
  };

  genColumnData = (sortingOrder: ISorting) => {
    let renderCountAddOn = renderCount * 20;
    var width = window.innerWidth - window.innerWidth * 0.06 - 70 - 25 - 15;
    var viewPortWidth = width / 4 - 20;
    var columnData: IColumn[] = [];
    var columnItems: IColumn[] = [
      {
        key: (renderCountAddOn + 1).toString(),
        name: OFFERS_COLUMNS_TEXT.NAME,
        fieldName: OFFERS_COLUMNS_FIELDS.NAME,
        minWidth: viewPortWidth,
        maxWidth: viewPortWidth,
        isSorted: sortingOrder.column === "Role",
        isSortedDescending:
          sortingOrder.column === "Role"
            ? sortingOrder.order === "desc"
            : undefined,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: (renderCountAddOn + 2).toString(),
        name: OFFERS_COLUMNS_TEXT.ACCESS_LEVELS,
        fieldName: OFFERS_COLUMNS_FIELDS.ACCESS_LEVELS,
        minWidth: viewPortWidth,
        maxWidth: viewPortWidth,
        isSorted: sortingOrder.column === "AccessLevel",
        isSortedDescending:
          sortingOrder.column == "AccessLevel"
            ? sortingOrder.order === "desc"
            : undefined,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: (renderCountAddOn + 3).toString(),
        name: OFFERS_COLUMNS_TEXT.CREATED_BY,
        fieldName: OFFERS_COLUMNS_FIELDS.CREATED_BY,
        minWidth: viewPortWidth,
        maxWidth: viewPortWidth,
        isSorted: sortingOrder.column === "CreatedBy",
        isSortedDescending:
          sortingOrder.column === "CreatedBy"
            ? sortingOrder.order === "desc"
            : undefined,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: (renderCountAddOn + 4).toString(),
        name: OFFERS_COLUMNS_TEXT.CREATED_ON,
        fieldName: OFFERS_COLUMNS_FIELDS.CREATED_ON,
        minWidth: viewPortWidth,
        maxWidth: viewPortWidth,
        isSorted: sortingOrder.column === "CreatedDate",
        isSortedDescending:
          sortingOrder.column === "CreatedDate"
            ? sortingOrder.order === "desc"
            : undefined,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];
    if (this.state) {
      viewPortWidth =
        width / (this.state.activeMenu == this.stateIds.ACTIVE ? 8 : 7) - 21;
      switch (this.state.activeMenu) {
        case this.stateIds.ACTIVE:
          columnItems = [
            {
              key: (renderCountAddOn + 6).toString(),
              name: OFFERS_COLUMNS_TEXT.NAME,
              fieldName: OFFERS_COLUMNS_FIELDS.NAME,
              minWidth: viewPortWidth,
              maxWidth: 300,
              isSorted: sortingOrder.column === "Role",
              isSortedDescending:
                sortingOrder.column === "Role"
                  ? sortingOrder.order === "desc"
                  : undefined,
              isResizable: true,
              onColumnClick: this._onColumnClick,
            },
            {
              key: (renderCountAddOn + 7).toString(),
              name: OFFERS_COLUMNS_TEXT.ACCESS_LEVELS,
              fieldName: OFFERS_COLUMNS_FIELDS.ACCESS_LEVELS,
              minWidth: viewPortWidth,
              maxWidth: 85,
              isSorted: sortingOrder.column === "AccessLevel",
              isSortedDescending:
                sortingOrder.column === "AccessLevel"
                  ? sortingOrder.order === "desc"
                  : undefined,
              isResizable: true,
              onColumnClick: this._onColumnClick,
            },
            {
              key: (renderCountAddOn + 8).toString(),
              name: OFFERS_COLUMNS_TEXT.NO_OF_ENGAGEMENTS,
              fieldName: OFFERS_COLUMNS_FIELDS.NO_OF_ENGAGEMENTS,
              minWidth: viewPortWidth,
              maxWidth: viewPortWidth,
              isSorted: sortingOrder.column === "TotalEngagements",
              isSortedDescending:
                sortingOrder.column === "TotalEngagements"
                  ? sortingOrder.order === "desc"
                  : undefined,
              isResizable: true,
              onColumnClick: this._onColumnClick,
            },
            {
              key: (renderCountAddOn + 9).toString(),
              name: OFFERS_COLUMNS_TEXT.LEAD_USERS,
              fieldName: OFFERS_COLUMNS_FIELDS.LEAD_USERS,
              minWidth: viewPortWidth,
              maxWidth: viewPortWidth,
              isSorted: sortingOrder.column === "EngagementDeliveryLeads",
              isSortedDescending:
                sortingOrder.column === "EngagementDeliveryLeads"
                  ? sortingOrder.order === "desc"
                  : undefined,
              isResizable: true,
            },
            {
              key: (renderCountAddOn + 10).toString(),
              name: OFFERS_COLUMNS_TEXT.LAST_OFFER_PUBLISHED,
              fieldName: OFFERS_COLUMNS_FIELDS.LAST_OFFER_PUBLISHED,
              minWidth: viewPortWidth,
              maxWidth: viewPortWidth,
              isSorted: sortingOrder.column === "LastOfferPublishedOn",
              isSortedDescending:
                sortingOrder.column === "LastOfferPublishedOn"
                  ? sortingOrder.order === "desc"
                  : undefined,
              isResizable: true,
              onColumnClick: this._onColumnClick,
            },
            {
              key: (renderCountAddOn + 3).toString(),
              name: OFFERS_COLUMNS_TEXT.LAST_UPDATED_ON,
              fieldName: OFFERS_COLUMNS_FIELDS.LAST_UPDATED_ON,
              minWidth: viewPortWidth,
              maxWidth: viewPortWidth,
              isSorted: sortingOrder.column === "LastModifiedDate",
              isSortedDescending:
                sortingOrder.column === "LastModifiedDate"
                  ? sortingOrder.order === "desc"
                  : undefined,
              isResizable: true,
              onColumnClick: this._onColumnClick,
            },
            {
              key: (renderCountAddOn + 11).toString(),
              name: OFFERS_COLUMNS_TEXT.CREATED_BY,
              fieldName: OFFERS_COLUMNS_FIELDS.CREATED_BY,
              minWidth: viewPortWidth,
              maxWidth: 75,
              isSorted: sortingOrder.column === "CreatedBy",
              isSortedDescending:
                sortingOrder.column === "CreatedBy"
                  ? sortingOrder.order === "desc"
                  : undefined,
              isResizable: true,
              onColumnClick: this._onColumnClick,
            },
            {
              key: (renderCountAddOn + 12).toString(),
              name: OFFERS_COLUMNS_TEXT.CREATED_ON,
              fieldName: OFFERS_COLUMNS_FIELDS.CREATED_ON,
              minWidth: viewPortWidth,
              maxWidth: viewPortWidth,
              isSorted: sortingOrder.column === "CreatedDate",
              isSortedDescending:
                sortingOrder.column === "CreatedDate"
                  ? sortingOrder.order === "desc"
                  : undefined,
              isResizable: true,
              onColumnClick: this._onColumnClick,
            },
          ];
          break;
        case this.stateIds.INACTIVE:
          columnItems = [
            {
              key: (renderCountAddOn + 13).toString(),
              name: OFFERS_COLUMNS_TEXT.NAME,
              fieldName: OFFERS_COLUMNS_FIELDS.NAME,
              minWidth: viewPortWidth,
              maxWidth: viewPortWidth,
              isSorted: sortingOrder.column === "Role",
              isSortedDescending:
                sortingOrder.column === "Role"
                  ? sortingOrder.order === "desc"
                  : undefined,
              isResizable: true,
              onColumnClick: this._onColumnClick,
            },
            {
              key: (renderCountAddOn + 14).toString(),
              name: OFFERS_COLUMNS_TEXT.ACCESS_LEVELS,
              fieldName: OFFERS_COLUMNS_FIELDS.ACCESS_LEVELS,
              minWidth: viewPortWidth,
              maxWidth: viewPortWidth,
              isSorted: sortingOrder.column === "AccessLevel",
              isSortedDescending:
                sortingOrder.column === "AccessLevel"
                  ? sortingOrder.order === "desc"
                  : undefined,
              isResizable: true,
              onColumnClick: this._onColumnClick,
            },
            {
              key: (renderCountAddOn + 15).toString(),
              name: OFFERS_COLUMNS_TEXT.NO_OF_ENGAGEMENTS,
              fieldName: OFFERS_COLUMNS_FIELDS.NO_OF_ENGAGEMENTS,
              minWidth: viewPortWidth,
              maxWidth: viewPortWidth,
              isSorted: sortingOrder.column === "TotalEngagements",
              isSortedDescending:
                sortingOrder.column === "TotalEngagements"
                  ? sortingOrder.order === "desc"
                  : undefined,
              isResizable: true,
              onColumnClick: this._onColumnClick,
            },
            {
              key: (renderCountAddOn + 16).toString(),
              name: OFFERS_COLUMNS_TEXT.LEAD_USERS,
              fieldName: OFFERS_COLUMNS_FIELDS.LEAD_USERS,
              minWidth: viewPortWidth,
              maxWidth: viewPortWidth,
              isSorted: sortingOrder.column === "EngagementDeliveryLeads",
              isSortedDescending:
                sortingOrder.column === "EngagementDeliveryLeads"
                  ? sortingOrder.order === "desc"
                  : undefined,
              isResizable: true,
            },
            {
              key: (renderCountAddOn + 17).toString(),
              name: OFFERS_COLUMNS_TEXT.LAST_OFFER_PUBLISHED,
              fieldName: OFFERS_COLUMNS_FIELDS.LAST_OFFER_PUBLISHED,
              minWidth: viewPortWidth,
              maxWidth: viewPortWidth,
              isSorted: sortingOrder.column === "LastOfferPublishedOn",
              isSortedDescending:
                sortingOrder.column === "LastOfferPublishedOn"
                  ? sortingOrder.order === "desc"
                  : undefined,
              isResizable: true,
              onColumnClick: this._onColumnClick,
            },
            {
              key: (renderCountAddOn + 18).toString(),
              name: OFFERS_COLUMNS_TEXT.CREATED_BY,
              fieldName: OFFERS_COLUMNS_FIELDS.CREATED_BY,
              minWidth: viewPortWidth,
              maxWidth: viewPortWidth,
              isSorted: sortingOrder.column === "CreatedBy",
              isSortedDescending:
                sortingOrder.column === "CreatedBy"
                  ? sortingOrder.order === "desc"
                  : undefined,
              isResizable: true,
              onColumnClick: this._onColumnClick,
            },
            {
              key: (renderCountAddOn + 19).toString(),
              name: OFFERS_COLUMNS_TEXT.DEACTIVATED,
              fieldName: OFFERS_COLUMNS_FIELDS.DEACTIVATED,
              minWidth: viewPortWidth,
              maxWidth: viewPortWidth,
              isSorted: sortingOrder.column === "LastModifiedDate",
              isSortedDescending:
                sortingOrder.column === "LastModifiedDate"
                  ? sortingOrder.order === "desc"
                  : undefined,
              isResizable: true,
              onColumnClick: this._onColumnClick,
            },
          ];
          break;
      }
    }
    columnData = columnItems;
    renderCount = renderCount + 1;
    return columnData;
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    this.enableLoader();
    const _columns = this.state._columns;
    const items = this.state.items;
    const newColumns: IColumn[] = _columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol.key === currColumn.key) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.changeSortingOrder({
          column: newCol.fieldName
            ? newCol.fieldName
            : this.state.sortingOrder.column,
          order: currColumn.isSortedDescending ? "desc" : "asc",
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    this.setState({
      _columns: newColumns,
    });
  };

  sideNavCallback = (childData: string) => {
    activeSideNavs.offers = childData;
    this.setState({
      activeMenu: childData,
    });
  };

  sideMenuStatus = (childData: boolean) => {
    this.setState({
      sideMenuStatus: childData,
    });
  };

  createOffer = () => {

    sessionStorage.setItem("showCopilotWindow", String(false));
    sessionStorage.setItem("offerPanelOpen", String(true));
    window.dispatchEvent(new Event("storage"));
    this.props.history.push(
      `/${OFFERS_URL}?id=new${this.props.history["location"]["search"].replace(
        "?",
        "&"
      )}`
    );
    logEvent(ActionTrackingOffer, {
      AppName: AppConstants.ApplicationAlias,
      AppLayer: "UI",
      metricName: "CreateOffer",
      UserScenario: "User Clicks on Create offer Button",
      User: { Alias: this.props.user?.userName },
      applicationRoles: this.props.userContext.roles?.find(
        (role: any) => role.name
      ),
      entityOperation: "Create",
      entityName: "",
      metaData: {
        sourcePage: "Offers",
      },
      traceSeverity: "Low",
    });
  };

  enableLoader = (spinnerMessage: string = "Fetching Offers") => {
    this.props.toggleSpinner({
      spinner: true,
      spinnerMessage: spinnerMessage,
    });
  };

  disableLoader = () => {
    this.props.toggleSpinner({
      spinner: false,
      spinnerMessage: "",
    });
  };

  toggleHideDialog = () => {
    this.setState({
      hideDialog: true,
    });
  };

  changeSortingOrder = (payload: ISorting) => {
    this.setState({ sortingOrder: payload });
  };

  render() {
    return (
      <div className="offers-page">
     <Text variant="xLarge" block styles={{ root: { fontSize:"22px", margin: "10px" } }}>
  Offer Types
</Text>
        <div className="eg-search-view-add">
          <div className="eg-search-view">
            <div className="eg-search">
              <SearchBox
                placeholder="Search within offer types"
                value={this.state.searchTerm}
                id="search-box-board"
                onClear={(ev) => () => {}}
                autoComplete="off"
                onChange={(_, newValue) => {
                  this.setState({
                    searchTerm: newValue ? newValue : "",
                  });
                  clearTimeout(this.searchTimer);
                  this.searchTimer = setTimeout(() => {
                    searchCalled.offerSearchCalled = true;
                    this.setState({
                      actualSearchTerm: newValue ? newValue : "",
                    });
                  }, 900);
                  logEvent(ActionTrackingSearch, {
                    AppName: AppConstants.ApplicationAlias,
                    AppLayer: "UI",
                    metricName: "search",
                    UserScenario: "User performs search",
                    User: { Alias: this.props.user?.userName },
                    applicationRoles: this.props.userContext.roles?.find(
                      (role: any) => role.name
                    ),
                    entityOperation: "search",
                    entityName: "Offers",
                    metaData: {
                      searchString: newValue,
                      resultsCount: this.state.totalOffersCount,
                      selectedTab: this.state.activeMenuDisplaytext,
                      selectedView: "Offers",
                    },
                    traceSeverity: "Medium",
                  });
                }}
                onSearch={(newValue) => () => {}}
              />
            </div>
          </div>
          <div className="eg-add" style={{ paddingBottom: "5px" }}>
            <div className="listCount">
              <h4 style={{ marginTop: "1px" }} aria-hidden="true">
                Total Offers: {this.state.totalOffersCount}
              </h4>
              <h4
                style={{
                  position: "absolute",
                  width: "1px",
                  height: "1px",
                  margin: "-1px",
                  padding: "0",
                  overflow: "hidden",
                  clip: "rect(0,0,0,0)",
                  border: "0",
                }}
                role="alert"
                tabIndex={0}
              >
                {this.state.totalOffersCount} suggestion are available
              </h4>
            </div>
            {this.props.appScopes.Offers.Create &&
              this.props.userContext.roles?.find(
                (role: any) => role.name == AppRoles.SiteAdmin.roleName
              ) && (
                <TooltipHost
                  directionalHint={DirectionalHint.bottomCenter}
                  tooltipProps={{
                    onRenderContent: () => <div>Create Offer Type</div>
                  }}
                  calloutProps={ttcalloutProps}
                  closeDelay={200}
                  styles={ttstyles}
                >
                  <PrimaryButton
                    className="add-eg-btn"
                    onClick={this.createOffer}
                    text={`Create Offer Type`}
                  />
                </TooltipHost>
              )}
          </div>
        </div>
        <div className="board">
          <SideNav
            categoryData={this.state.categoryData}
            parentCallback={this.sideNavCallback}
            activeMenu={this.state.activeMenu}
            userContext={this.props.userContext}
          />
          <OffersList
            history={this.props.history}
            offersHeaderData={this.state._columns}
            offersData={this.state.items}
            activeMenuDisplaytext={this.state.activeMenuDisplaytext}
            fetching={!!this.props.offers.fetching}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: { ui: any; auth: any; api: any }) => ({
  user: state.auth.user,
  apiAccessToken: state.auth.apiAccessToken,
  coreApiAccessToken: state.auth.coreApiAccessToken,
  offers: state.api.offers,
  offersCategories: state.api.offersCategories,
  offerStates: state.api.metaData.data.OfferState,
  accessLevels: state.api.metaData.data.PodAuthorizationRole,
  pdfUrl: state.api.offersTermsAndCondition,
  getTCLinkState: state.api.offersTermsAndCondition,
  sendEmailToSelfState: state.api.sendEmailToSelf,
});

const mapDispatchToProps = (dispatch: any) => ({
  setOfferDetails: (payload: IOfferDetail) =>
    dispatch(setOfferDetails(payload)),
  getOffers: (payload: IGetOffersPayload) => dispatch(getOffers(payload)),
  getOffersCategories: (payload: IGetOffersCategoryPayload) =>
    dispatch(getOffersCategories(payload)),
  toggleSpinner: (payload: boolean) => dispatch(toggleSpinner(payload)),
  getOffersTermsAndConditionLink: (payload: string) =>
    dispatch(getOffersTermsAndConditionLink(payload)),
  sendEmailToSelf: (payload: string) => dispatch(sendEmailToSelf(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
