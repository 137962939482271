import { ISorting } from "./Generic";

export interface IEngagementDetail {
    show: boolean;
    new: boolean;
    details: IEngagementDetailData | null;
    isDraft: boolean;
}

export interface IEngagementDetailData {
    EngagementId: number,
    DeliveryLead: string,
    Status: number,
    SubStatusId: number,
    OfferCode: string,
    Customer: string,
    SubscriptionId: string,
    DeliveryPod: IDeliveryPod[],
    EngagementSubscriptions: IEngagementSubscriptions[],
    EngagementSecurityGroups: any | null,
    Description: string,
    SubmittedDateTime: string | null,
    GoLiveDateTime: string | null,
    LiveDateTime: string | null,
    TCSignedDate: string | null,
    OfferId: string,
    IsTest: boolean,
    ENG_ProgramName: string,
    ENG_ProgramId: number | null,
    Aliases: any | null,
    ENG_EntityTypeName: any | null,
    ENG_EntityType: number | string | null,
    ENG_EntityValue: string | null,
    ENG_Contract_Id: string,
    ENG_DeliveryOption_Id: number | null,
    IsPageComingFromHok: boolean,
    IsRoss: boolean,
    ENG_DeliveryOption_Other: string | null,
    ENG_MPC_Publisher_ID: string,
    OfferTemplateVersion? : string | null,
    IsUserInPod: boolean,
    ENG_OnboardingType_Id: number,
    ENG_Geography_Id: number,
    ENG_Region_Id: number | null,
    ARMTemplatePackageBase64String: string,
    ARMTemplatePackageName: string,
    Comment: string,
    IsSubscriptionRemoved: boolean,
    CreatedBy: string,
    LastModifiedBy: string,
    StartDate: Date,
    EndDate: Date,
    IsSupportOnly:boolean | null
}

interface IDeliveryPod {
    EngagementUserId: number,
    EngagementId: number,
    Alias: string,
    UserObjectId: string | null
}
export interface IEngagementSubscriptions {
    EngagementId: number | undefined,
    SubscriptionId: string
}

//Match enum from API
export const ENGAGEMENT_STATUS = {
    CREATED: 0,
    PUBLISHING: 1,
    FAILED: 2,
    GOING_LIVE: 3,
    LIVE: 4,
    CLOSED: 5,
    DRAFT: 6,
    DOWNLOADED_ARM: 7,
    MANUAL_INTERVENTION: 8,
    DOWNLOADED_TC:9,
    COMPLETED:10
};
export const ENGAGEMENT_SUBSTATUS = {
    TEST: 1,
    DUPLICATE: 2,
    OTHER: 3    
};
export const MAPPED_ENGAGEMENT_STATUS: {
    [key: string]: {
        IDENTIFIER: string,
        DISPLAY_NAME: string,
        ORIGINAL_STATUS : number[]
        ORIGINAL_STATUS_String : string[]
    }
} = {
    IN_DRAFT: {
        IDENTIFIER: "IN_DRAFT",
        DISPLAY_NAME: "In Draft",
        ORIGINAL_STATUS: [ENGAGEMENT_STATUS.DRAFT],
        ORIGINAL_STATUS_String: ["Draft"]
    },
    PROCESSING: {
        IDENTIFIER: "PROCESSING",
        DISPLAY_NAME: "Processing",
        ORIGINAL_STATUS: [ENGAGEMENT_STATUS.CREATED, ENGAGEMENT_STATUS.PUBLISHING, ENGAGEMENT_STATUS.GOING_LIVE,ENGAGEMENT_STATUS.DOWNLOADED_TC],
        ORIGINAL_STATUS_String:  ["Created", "Publishing", "GoingLive","Downloaded T&C"]
    },
    LIVE: {
        IDENTIFIER: "LIVE",
        DISPLAY_NAME: "Live",
        ORIGINAL_STATUS: [ENGAGEMENT_STATUS.LIVE, ENGAGEMENT_STATUS.DOWNLOADED_ARM],
        ORIGINAL_STATUS_String: ["Live", "Downloaded ARM"]
    },
    ERROR_FOUND: {
        IDENTIFIER: "ERROR_FOUND",
        DISPLAY_NAME: "Failed",
        ORIGINAL_STATUS: [ENGAGEMENT_STATUS.FAILED, ENGAGEMENT_STATUS.MANUAL_INTERVENTION],
        ORIGINAL_STATUS_String: ["Failed"]
    },
    CLOSED: {
        IDENTIFIER: "CLOSED",
        DISPLAY_NAME: "Closed",
        ORIGINAL_STATUS: [ENGAGEMENT_STATUS.CLOSED],
        ORIGINAL_STATUS_String: ["Closed"]
    },
    COMPLETED: {
        IDENTIFIER: "COMPLETED",
        DISPLAY_NAME: "Completed",
        ORIGINAL_STATUS: [ENGAGEMENT_STATUS.COMPLETED],
        ORIGINAL_STATUS_String: ["Completed"]
    },
    DOWNLOAD_ARM: {
        IDENTIFIER: "DOWNLOAD ARM",
        DISPLAY_NAME: "Download ARM",
        ORIGINAL_STATUS: [ENGAGEMENT_STATUS.DOWNLOADED_ARM],
        ORIGINAL_STATUS_String: ["Download ARM"]
    },
    DOWNLOAD_TC: {
        IDENTIFIER: "DOWNLOAD T&C",
        DISPLAY_NAME: "Download T&C",
        ORIGINAL_STATUS: [ENGAGEMENT_STATUS.DOWNLOADED_TC],
        ORIGINAL_STATUS_String: ["Download T&C"]
    }
}
export const MAPPED_ENGAGEMENT_SUBSTATUS: {
    [key: string]: {
        IDENTIFIER: string,
        DISPLAY_NAME: string,
        ORIGINAL_STATUS : number[]
        ORIGINAL_STATUS_String : string[]
    }
} = {
    DUPLICATE: {
        IDENTIFIER: "DUPLICATE",
        DISPLAY_NAME: "Duplicate",
        ORIGINAL_STATUS: [ENGAGEMENT_SUBSTATUS.DUPLICATE],
        ORIGINAL_STATUS_String:  ["Created", "Publishing", "GoingLive","Downloaded T&C"]
    },
    TEST: {
        IDENTIFIER: "TEST",
        DISPLAY_NAME: "Test",
        ORIGINAL_STATUS: [ENGAGEMENT_SUBSTATUS.TEST],
        ORIGINAL_STATUS_String: ["Test"]
    },   
    OTHER: {
        IDENTIFIER: "OTHER",
        DISPLAY_NAME: "Other",
        ORIGINAL_STATUS: [ENGAGEMENT_SUBSTATUS.OTHER],
        ORIGINAL_STATUS_String: ["Other"]
    }
}
export const ENGAGEMENT_VIEWS = {
    MY_ENGAGEMENTS: { key: "1", text: "My Engagements" },
    MY_TEAM_ENGAGEMENTS: { key: "2", text: "My Team Engagements" },
    ALL_ENGAGEMENTS: { key: "3", text: "All Engagements" }
}

export interface IGetEngagementPayload {
    searchTerm?: string;
    activeStatus?: string;
    selectedView?: string;
    sortingOrder?: ISorting;
    alias : string;
}

export interface IGetEngagementCategoryPayload {
    searchTerm?: string;
    selectedView?: string;
}