import { GET_METADATA, GET_OFFERS_DETAILS, GET_PARTICIPANTS_META } from "../../constants/actions/API/MetaData"

export const getMetaData = () => {
    return {
        type : GET_METADATA
    }
}

export const getParticipantsMetaData = () => {
    return {
        type : GET_PARTICIPANTS_META
    }
}

export const getOfferDetailsForDropDown = () => {
    return {
        type : GET_OFFERS_DETAILS
    }
}