import { Image, ImageFit } from "office-ui-fabric-react/lib/Image";
import React, { Component, CSSProperties } from "react";
import { IMainProps } from "../../constants/types/UI/Main";
import image from "../../assets/logo-cape-white.svg";
import { ReactComponent as RatingtIcon } from "../../assets/logo-rating.svg";
import "./Common.css";
import { Icon } from "@fluentui/react/lib/Icon";
import { FontIcon } from '@fluentui/react';
import {
  IPersonaSharedProps,
  Persona,
  PersonaPresence,
  PersonaSize,
} from "office-ui-fabric-react/lib/Persona";
import { HOME_URL } from "../../constants/routes";
import AppConstants, {
  AppRoles,
  ShowRating,
  entityQueryString,
} from "../../constants/AppConstants";
import { BannerMessage } from "./BannerMessage";
import {
  logEvent,
  SupportMenuItemSelected,
  NotificationClick,
  NotificationPreferences,
  ProfileSettingsClick,
} from "../../utils/insightsClient";
import NotificationBell from "./NotificationBell";
import {
  INotifications,
  ITeachingBubbleData,
} from "../../constants/types/UI/Notifications";
import {
  mergeStyleSets,
  Panel,
  PanelType,
  INavLinkGroup,
  Nav,
  INavStyles,
} from "@fluentui/react";
import Notification from "../notification/Notification";
import { parseQS } from "../../utils/helperFunctions";
import { ReactComponent as CopilotIcon } from "../copilot-v2/Asset/svgs/Copilot.svg";
import Copilot from "../copilot-v2/Copilot";
import { InsightsClient } from "../../utils/insightsClient";

interface IHeaderState {
  showNotifications: boolean;
  noScopesRoles: boolean;
  NotificationsData: INotifications[];
  bellNotifications: INotifications[];
  showProfileActions: boolean;
  showNotificationPreferences: boolean;
  showPanel: boolean;
  copilotPinnedState: boolean;
  splitMode: boolean;
  enableGridSplitView: boolean;
  isEngagementWindowOpen: boolean;
  teachingBubbleDetails: ITeachingBubbleData[];
}

interface IProps {
  handleUserRating: Function;
  showRating?: boolean;
}

interface IHeaderProps extends IMainProps, IProps {}

const notification_bell_id = "notification-bell";
const isIframe = window !== window.parent && !window.opener;

const currentUserName = sessionStorage.getItem("jwtUserAccountName");
const styles = mergeStyleSets({
  callout: {
    width: "450px",
    maxWidth: "90%",
    padding: "10px",
    position: "absolute",
    right: "200",
  },
});

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: "Notifications",
        url: "",
        expandAriaLabel: "",
        isExpanded: false,
        key: "key1",
        icon: "Ringer",
      },
    ],
  },
];

const navStyles: Partial<INavStyles> = {
  root: {
    width: "auto",
    height: "auto",
    boxSizing: "border-box",
  },
};

function getCopilotPinnedStateFromSessionStorage(): boolean {
  const item = sessionStorage.getItem("copilotPinnedState");
  if (item) {
    return JSON.parse(item);
  }

  sessionStorage.setItem("copilotPinnedState", String(true));
  return true;
}

function getShowCopilotWindowFromSessionStorage(): boolean {
  const item = sessionStorage.getItem("showCopilotWindow");

  if (item) {
    return JSON.parse(item);
  }

  sessionStorage.setItem("showCopilotWindow", String(false));
  return false;
}

class Header extends Component<IHeaderProps, IHeaderState> {
  myUpdateNotification: any;

  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      showNotifications: false,
      noScopesRoles: !!(
        this.props.userContext.data?.scopes.length == 0 &&
        this.props.userContext.data.roles.length == 0
      ),
      NotificationsData: [],
      bellNotifications: [],
      showProfileActions: false,
      showNotificationPreferences: false,
      showPanel: getShowCopilotWindowFromSessionStorage(),
      copilotPinnedState: getCopilotPinnedStateFromSessionStorage(),
      splitMode: false,
      enableGridSplitView: getCopilotPinnedStateFromSessionStorage(),
      isEngagementWindowOpen: false,
      teachingBubbleDetails: [],
    };
  }

  componentDidMount(): void {
    if (
      this.props.user &&
      this.props.apiAccessToken &&
      this.props.coreApiAccessToken
    ) {
      if (!isIframe) {
        this.triggerGetNotifications();
        this.myUpdateNotification = setInterval(
          () => this.triggerGetNotifications(),
          60000
        );
      }
    }
    window.addEventListener("storage", this.handleEngagementPanel, false);
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.handleEngagementPanel, false);
  }

  handleEngagementPanel = () => {
    this.setState({ isEngagementWindowOpen: this.getEngagementState() });
  };
  getEngagementState = () => {
    const isEngagementPanelOpen = JSON.parse(
      sessionStorage.getItem("engagementPanelOpen") || "false"
    );
    const isOfferPanelOpen = JSON.parse(
      sessionStorage.getItem("offerPanelOpen") || "false"
    );
    if (isEngagementPanelOpen === true || isOfferPanelOpen === true) {
      return true;
    } else {
      return false;
    }
    // return isEngagementPanelOpen;
  };

  triggerGetNotifications() {
    this.props.getNotifications();
  }
  componentDidUpdate(prevProps: IMainProps, prevState: IHeaderState) {
    if (prevProps.userContext !== this.props.userContext) {
      this.setState({
        noScopesRoles: !!(
          this.props.userContext.data?.scopes.length == 0 &&
          this.props.userContext.data.roles.length == 0
        ),
      });
    }
    if (
      prevProps.getNotificationsState.data !==
      this.props.getNotificationsState.data
    ) {
      this.setState(
        {
          NotificationsData: this.props.getNotificationsState.data,
        },
        () => this.getNotificationsData()
      );
    }
    if (
      prevProps.commonServicesToken.data != this.props.commonServicesToken.data
    ) {
    }
    if (prevState.copilotPinnedState !== this.state.copilotPinnedState) {
      this.handleStorageChange();
    }
  }

  handleStorageChange = () => {
    const isCopilotPinned = sessionStorage.getItem("copilotPinnedState");
    const isCopilotWindowOpen = sessionStorage.getItem("showCopilotWindow");

    if (isCopilotWindowOpen) {
      const parsedData = JSON.parse(isCopilotWindowOpen);
      this.setState({ showPanel: parsedData });
    }

    if (isCopilotPinned) {
      const parsedData = JSON.parse(isCopilotPinned);
      this.setState({ enableGridSplitView: parsedData });
      this.setState({ copilotPinnedState: parsedData });
      return;
    }
  };

  getNotifications = (notifications: any) => {
    this.setState({
      bellNotifications: notifications,
    });
  };
  getNotificationsData() {
    let sortedNotifications = this.state.NotificationsData.filter(
      (i) =>
        i.Data.PortalNotification.IsBanner === false &&
        i.Data.PortalNotification.IsCustom === false
    )
      .sort(
        (a, b) =>
          new Date(a.CreatedDate).getTime() - new Date(b.CreatedDate).getTime()
      )
      .reverse();
    const notifications: INotifications[] = [];
    sortedNotifications.forEach((i) => {
      let createdDate = new Date(i.CreatedDate);
      createdDate.setMinutes(
        createdDate.getMinutes() + i.Data.DelayStartInMinutes
      );
      notifications.push({
        Id: i.Id,
        Data: i.Data,
        Users: i.Users,
        CreatedDate: createdDate.toISOString(),
        LastModifiedDate: i.LastModifiedDate,
        IsSystemNotification: i.IsSystemNotification
      });
    });
    let currentNotifications = notifications.filter(
      (i) => new Date(i.CreatedDate) <= new Date()
    );
    this.setState({
      bellNotifications: currentNotifications,
    });
    this.getTeachingBubbleData();
  }
  getTeachingBubbleData = () => {
    let teachingBubbleData = this.state.NotificationsData.filter(
      (i) =>
        i.Data.PortalNotification.IsCustom === true &&
        i.Data.PortalNotification.Type === "TeachingBubble" &&
        i.Data.PortalNotification.Tag === "CopilotBubble"
    );
    const teachingBubbleDetails: ITeachingBubbleData[] = [];
    if (teachingBubbleData.length > 0) {
      let createdDate = new Date(teachingBubbleData[0].CreatedDate);
      createdDate.setMinutes(
        createdDate.getMinutes() +
          teachingBubbleData[0].Data.DelayStartInMinutes
      );
      teachingBubbleDetails.push({
        Id: teachingBubbleData[0].Id,
        Data: teachingBubbleData[0].Data,
        Users: teachingBubbleData[0].Users,
        CreatedDate: createdDate.toISOString(),
        LastModifiedDate: teachingBubbleData[0].LastModifiedDate,
      });
      this.setState({
        teachingBubbleDetails: teachingBubbleDetails,
      });
    }
  };
  clickAnchorHandler = (e: any, link: any, supportText: any) => {
    const parsedQS = parseQS(this.props.location.search);
    var engProgramName =
      parsedQS != null ? parsedQS[entityQueryString.ProgramName] : "";
    e.preventDefault();
    var a = document.createElement("a");
    document.body.appendChild(a);
    (a as HTMLElement).style.display = "none";
    a.href = link;
    a.setAttribute("target", "_blank");
    a.click();
    logEvent(SupportMenuItemSelected, {
      AppName: AppConstants.ApplicationAlias,
      AppLayer: "UI",
      MetricName: supportText,
      UserScenario: "User clicks on support icons",
      User: { Alias: this.props.userContext.data?.email },
      ApplicationRole: this.props.userContext.data?.roles,
      EntityOperation: "Click",
      EntityName: "",
      MetaData: { programName: engProgramName },
      traceSeverity: "Medium",
    });
    document.body.removeChild(a);
  };

  toggleNotifications = (show: boolean) => {
    const parsedQS = parseQS(this.props.location.search);
    var engProgramName =
      parsedQS != null ? parsedQS[entityQueryString.ProgramName] : "";
    this.setState({
      showNotifications: show,
    });
    logEvent(NotificationClick, {
      AppName: AppConstants.ApplicationAlias,
      AppLayer: "UI",
      metricName: "User Notification",
      UserScenario: "User Landed on CAPE Notification Window",
      User: { Alias: this.props.user?.userName },
      applicationRoles: this.props.userContext.data?.roles,
      entityOperation: "Close",
      entityName: "Notification",
      metaData: {
        notificationCount: this.state.bellNotifications.length,
        programName: engProgramName ? engProgramName : null,
      },
      traceSeverity: "Low",
    });
  };

  showPanel = (data: boolean) => {
    this.setState({
      showNotificationPreferences: data,
    });
  };

  logTelemetryData = (telemetryData: any) => {
    logEvent(NotificationPreferences, {
      AppName: AppConstants.ApplicationAlias,
      AppLayer: "UI",
      MetricName: "ActionTracking-NotificationPreference",
      UserScenario: "User updated Notification preferences",
      User: { Alias: this.props.userContext.data?.email },
      ApplicationRole: this.props.userContext.data?.roles,
      EntityOperation: "Update Prefernces click",
      EntityName: "",
      MetaData: telemetryData,
      traceSeverity: "Medium",
    });
  };

  copilotPinAction = () => {
    this.setState({ enableGridSplitView: true });
    this.setState({ copilotPinnedState: true });
    sessionStorage.setItem("copilotPinnedState", "true");
    window.dispatchEvent(new Event("storage"));
  };

  openPanel = () => {
    this.setState({ enableGridSplitView: false });
    this.setState({ copilotPinnedState: false });
    sessionStorage.setItem("copilotPinnedState", String(false));
    sessionStorage.setItem("showCopilotWindow", String(true));
    window.dispatchEvent(new Event("storage"));
  };

  copilotWindowOpenAction = () => {
    this.setState({ enableGridSplitView: true });
    this.setState({ copilotPinnedState: true });
    sessionStorage.setItem("copilotPinnedState", "true");
    // sessionStorage.setItem("engagementPanelOpen", String(true));
    // sessionStorage.setItem("offerPanelOpen", String(true));
    window.dispatchEvent(new Event("storage"));
  };

  render() {
    const { user } = this.props;
    const buttonId = "moreButton";
    const loginPersona: IPersonaSharedProps = {
      imageUrl: this.props.profileURL,
      imageAlt: user ? user.name : "",
    };
    return (
      <>
        <div
          className="header"
          style={
            this.state.enableGridSplitView ? pinnedHeaderStyle : headerStyle
          }
        >
          <Panel
            className="padTop50px"
            headerText="User settings"
            isOpen={this.state.showNotificationPreferences}
            onDismiss={() => {
              this.setState({
                showNotificationPreferences: false,
                showProfileActions: false,
              });
            }}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={"50%"}
          >
            <div className="settingsBody">
              <div style={{ flexGrow: "2", borderRight: "1px solid #eee" }}>
                <Nav
                  onLinkClick={() => {}}
                  selectedKey="key1"
                  ariaLabel="Nav basic example"
                  styles={navStyles}
                  groups={navLinkGroups}
                />
              </div>
              <div style={{ flexGrow: "10" }}>
                <Notification
                  tokenData={this.props.commonServicesToken?.data}
                  alias={this.props.user ? this.props.user.userName : ""}
                  subscriptionType={["csu_emailenabled"]}
                  showPanel={this.showPanel}
                  oDataFilter={`$filter=_csu_owningteam_value ne ${AppConstants.AdminNotificationTeam}`}
                  telemetryData={this.logTelemetryData}
                  commonServiceUrl={AppConstants.commonServiceUrl}
                />
              </div>
            </div>
          </Panel>
          <div
            className="title-header"
            style={mainTextStyle}
            onClick={() => {
              this.props.history.push(HOME_URL);
            }}
          >
            {!isIframe && (
              <a
                className="header-title-image"
                href="javascript:void(0)"
                aria-label="Cape Logo"
              >
                <Image
                  title="Cape Portal"
                  src={image}
                  imageFit={ImageFit.contain}
                  width={28}
                  height={28}
                  styles={{
                    root: { marginTop: 3, marginLeft: 10, marginRight: 10 },
                  }}
                  alt="Cape Logo"
                  role="img"
                  aria-hidden="true"
                />
              </a>
            )}
            {!isIframe && (
              <div className="main-title-text">
                <h1
                  style={{ fontSize: "20px", fontWeight: "normal" }}
                  aria-label="Customer Acceleration Portal for Engagements"
                >
                  {AppConstants.ApplicationName}
                </h1>
              </div>
            )}
            <br />
          </div>
          <NotificationBell
            userName={this.props.user?.userName}
            userRole={this.props.userContext.data?.roles}
            history={this.props.history}
            target={notification_bell_id}
            toggleCallout={this.toggleNotifications}
            showCallout={this.state.showNotifications}
            content={this.state.bellNotifications}
            getNotifications={this.getNotifications}
          />
          {this.props.bannerProps.toShow && (
            <BannerMessage
              setBanner={this.props.setBanner}
              bannerProps={this.props.bannerProps}
            />
          )}
          <div className="header-end" style={headerEndStyle}>
            {!isIframe && (
              <>
                <div
                  id={buttonId}
                  className="header-icons"
                  role="link"
                  title="Copilot"
                  // style={{ margin: "15px 11px", cursor: "pointer" }}
                  onClick={() => {
                    this.copilotWindowOpenAction();
                  }}
                >
                  <CopilotIcon />
                </div>
              </>
            )}

            {!isIframe && (
              <>
                <div
                  id="1-CAPE-Header-Rating"
                  className="header-icons"
                  role="button"
                  title="Rate"
                  // style={{ margin: "15px 11px", cursor: "pointer" }}
                  onClick={() => {
                    this.props.handleUserRating(true);
                  }}
                >
                  <RatingtIcon />
                </div>
              </>
            )}

            {!this.state.noScopesRoles && !isIframe && (
              <>
                <a
                  href={AppConstants.HelpLink}
                  id="1-CAPE-Header-Request-Help"
                  className="anchor-header-icons"
                  target="_blank"
                  role="button"
                  title="Request Help/Support"
                  // style={{ margin: "2px 11px" }}
                  onClick={(e) => {
                    this.clickAnchorHandler(
                      e,
                      AppConstants.HelpLink,
                      "RequestHelp"
                    );
                  }}
                >
                  <Icon
                    iconName="Unknown"
                    className="ms-Icon"
                    style={iconStyle}
                  />                </a>
              </>
            )}
            {!isIframe && (
              <>
                <a
                  href={AppConstants.FeedbackLink}
                  id="1-CAPE-Header-Feedback"
                  className="anchor-header-icons"
                  target="_blank"
                  role="link"
                  title="Feedback"
                  // style={{ margin: "2px 20px" }}
                  onClick={(e) => {
                    this.clickAnchorHandler(
                      e,
                      AppConstants.FeedbackLink,
                      "Feedback"
                    );
                  }}
                >
                  <Icon
                    iconName="Feedback"
                    className="ms-Icon"
                    style={iconStyle}
                  />
                </a>
                <a
                  href={AppConstants.RequestAccessLink}
                  id="1-CAPE-Header-Request Access"
                  className="anchor-header-icons"
                  target="_blank"
                  role="link"
                  title="Request Access"
                  // style={{ margin: "2px 20px" }}
                  onClick={(e) => {
                    this.clickAnchorHandler(
                      e,
                      AppConstants.RequestAccessLink,
                      "RequestAccess"
                    );
                  }}
                >
                  <Icon
                    iconName="PeopleAdd"
                    className="ms-Icon"
                    style={iconStyle}
                  />
                </a>
              </>
            )}
            {!this.state.noScopesRoles && !isIframe && (
              <a
                href="javascript:void(0)"
                title="Notifications"
                className={`anchor-header-icons ${this.state.showNotifications ? 'active' : ''}`}
              >
                <div className="headerNotification-bell-container">
                {this.state.bellNotifications &&
                    this.state.bellNotifications.length > 0 && (
                      <div className="headerNotification-dot">
                        <div
                          className={
                            this.state.bellNotifications.length > 99
                              ? "headerNotification-number"
                              : "headerNotification-numberlimit"
                          }
                        >
                          {this.state.bellNotifications.length > 99
                            ? "99+"
                            : this.state.bellNotifications.length}
                        </div>
                      </div>
                    )}
                     <Icon
                    id={notification_bell_id}
                    style={iconStyle}
                    iconName="Ringer"
                    className="ms-Icon"
                    onClick={
                      this.state.bellNotifications
                        ? () => {
                            this.toggleNotifications(
                              !this.state.showNotifications
                            );
                          }
                        : undefined
                    }
                    
                  />
                </div>
              </a>
            )}
            {!isIframe && (
              <span
                className="profileBody"
                onClick={() => {
                  this.setState({
                    showProfileActions: this.state.showProfileActions
                      ? false
                      : true,
                  });
                }}
              >
                <Persona
                  {...loginPersona}
                  size={PersonaSize.size32}
                  presence={PersonaPresence.none}
                  className="profileAlignment"
                />
              </span>
            )}
            {!isIframe &&
              this.props.userContext.data?.roles?.find(
                (role) => role.name == AppRoles.SiteAdmin.roleName
              ) && <span className="super">Admin</span>}

            <div
              className="profileDropdown"
              style={{
                display: this.state.showProfileActions ? "block" : "none",
              }}
            >
              <div
                className="profileActions"
                id="showPreferences"
                style={{ cursor: "context-menu" }}
              >
                <Icon
                  style={{ fontWeight: 400, padding: "5px" }}
                  iconName="contact"
                ></Icon>{" "}
                {user ? user.name.split(" ").slice(0, 2).join(" ") : ""}
              </div>
              {!this.state.noScopesRoles && (
                <div
                  className="profileActions"
                  id="showPreferences"
                  onClick={() => {
                    this.setState({
                      showNotificationPreferences: true,
                      showProfileActions: false,
                    });
                    logEvent(ProfileSettingsClick, {
                      AppName: AppConstants.ApplicationAlias,
                      AppLayer: "UI",
                      metricName: "Notification preferences",
                      UserScenario:
                        "User clicks on Notification Preferences settings",
                      User: { Alias: this.props.user?.userName },
                      applicationRoles: this.props.userContext.data?.roles,
                      entityOperation: "Click",
                      entityName: "Notification",
                      metaData: {},
                      traceSeverity: "Low",
                    });
                  }}
                >
                  <Icon
                    style={{ fontWeight: 400, padding: "5px" }}
                    iconName="settings"
                  ></Icon>{" "}
                  Settings
                </div>
              )}
            </div>
          </div>
        </div>

        <Copilot
          appInsightsEventNamePrefix="CAPE-Dashboard-CopilotChat-"
          appInsights={InsightsClient}
          appName="CAPE"
          currentUserName={this.props.user ? this.props.user.name : ""}
          currentUserId={this.props.user ? this.props.user.userName : ""}
          gptApiUrl={AppConstants.commonServiceUrl + "/api/v1/GPTTurnKey"}
          gptPlatformName="CAPE"
          gptAuthToken={this.props.apiAccessToken?.accessToken || ""}
          isOpen
          onFloatingIconClick={this.openPanel}
          disableFloatingIcon={this.state.splitMode}
          onDismiss={() => {
            sessionStorage.setItem("showCopilotWindow", String(false));
            sessionStorage.setItem("copilotPinnedState", String(false));
            this.setState({ showPanel: false });
            this.setState({ enableGridSplitView: false });
            this.setState({ copilotPinnedState: false });
            window.dispatchEvent(new Event("storage"));
          }}
          copilotPinAction={this.copilotPinAction}
          isPinned={this.state.copilotPinnedState}
          panelStyles={{
            main: {
              backgroundColor: "#F0F0F0",
              height: "100%",
              width: "26%",
              top: "100px",
              position: "absolute",
              borderRadius: "8px",
            },
          }}
          isEngagementWindowOpen={this.state.isEngagementWindowOpen}
          capeTeachingBubbleDetails={this.state.teachingBubbleDetails}
        />
      </>
    );
  }
}

export default Header;

function _onRenderGroupHeader(group: INavLinkGroup): JSX.Element {
  return <h3>{group.name}</h3>;
}

export const headerStyle: CSSProperties = {
  width: "100%",
  display: "flex",
  backgroundColor: "#0078d4",
  justifyContent: "space-between",
  color: "white",
  height: 50,
  flexWrap: "wrap",
};

export const pinnedHeaderStyle: CSSProperties = {
  width: "100%",
  display: "flex",
  backgroundColor: "#0078d4",
  justifyContent: "space-between",
  color: "white",
  height: 50,
  flexWrap: "wrap",
};

export const mainTextStyle: CSSProperties = {
  fontWeight: 400,
  fontSize: 22,
  background: "#0078d4",
  paddingRight: "0.7px",
  display: "flex",
  height: 50,
};

export const textStyle: CSSProperties = {
  lineHeight: "50px",
  fontSize: 14,
  margin: "0 32px 0 0px",
};

export const headerEndStyle: CSSProperties = {
  display: "flex",
  height: 50,
};

export const iconStyle: CSSProperties = {
  cursor: "pointer",
  color: "white",
  height: 50,
  lineHeight: "50px",
  fontSize: 18,
};
export const headerIconStyle: CSSProperties = {
  padding: "1.5px 8px",
  color: "white",
  width: 40,
  height: 50,
  lineHeight: "23px",
  fontSize: 18,
};
export const profilePicStyle: CSSProperties = {
  transform: "translate(-55%, 32%)",
  borderRadius: "50%",
  width: "32px",
  height: "32px",
};

function lazyWithRetry(
  arg0: () => Promise<typeof import("../copilot-v2/Copilot")>
) {
  throw new Error("Function not implemented.");
}
