import { CHANGE_MENU_BAR_SETTINGS } from '../../constants/actions/UI/navigation';
import {init_Menu} from '../../constants/types/UI/Menu';
import { IAction } from '../../constants/types/IAction';
import _ from 'lodash';


export const MenuBarReducers = (state = init_Menu, action: IAction) => {
    switch (action.type) {
        case CHANGE_MENU_BAR_SETTINGS:
            const cState = _.cloneDeep(init_Menu);
            cState[action.payload.itemName].isActive = action.payload.isActive
            return cState;
        default:
            return state;
    }
}

export default MenuBarReducers;