import { IAction } from '../../constants/types/IAction';
import {GET_CONTEXT,GET_COMMONSERVICESTOKEN} from '../../constants/actions/API/Context';


export function getContext(): IAction {
    return {
        type: GET_CONTEXT
    }
}

export function getCommonServicesToken(): IAction {
    return {
        type: GET_COMMONSERVICESTOKEN
    }
}
