import React, { useState, useEffect } from 'react';
import AppConstants from '../../constants/AppConstants';
import { Link } from '@fluentui/react';

const Footer: React.FunctionComponent<any> = (props: any) => {
const isIframe = window !== window.parent && !window.opener;

	return (
		<div className="footerPadding">
			<div className="footer">
				{!isIframe &&
				<div className="privacy-statement">
					<Link rel="noopener noreferrer" className='footerLinks' target="_blank" href={AppConstants.privacy} title="Privacy Statement">
						Privacy Statement
					</Link>
					<Link rel="noopener noreferrer" className='footerLinks' target="_blank" href={AppConstants.consumerhealthPrivacy} title="Consumer Health Privacy">
					    Consumer Health Privacy
					</Link>
					<Link rel="noopener noreferrer" className='footerLinks' target="_blank" href={AppConstants.termsOfUse} title="Terms of Use">
						Terms of Use
					</Link>
					<Link rel="noopener noreferrer" className='footerLinks' target="_blank" href={AppConstants.cookies} title="Cookies Statement">
						Cookies Statement
					</Link>
					<span className='copyrigt' title={AppConstants.Copyright}>
						{AppConstants.Copyright}
					</span>
				</div>
}
			</div>
		</div>

	);
}

export default Footer