import {
  FETCHING_OFFERS,
  FETCHING_OFFERS_FAILURE,
  FETCHING_OFFERS_SUCCESS,
  GET_OFFERS_CATEGORIES_FETCHING,
  GET_OFFERS_CATEGORIES_SUCCESS,
  GET_OFFERS_CATEGORIES_FAILURE,
} from "../../constants/types/API/Offers";
import {
  GET_OFFER_BY_ID_FAILURE,
  GET_OFFER_BY_ID_FETCHING,
  GET_OFFER_BY_ID_SUCCESS,
  GET_TERMS_AND_CONDITION,
  GET_TERMS_AND_CONDITION_FETCHING,
  GET_TERMS_AND_CONDITION_SUCCESS,
  SEND_EMAIL_TO_SELF_FAILURE,
  SEND_EMAIL_TO_SELF_FETCHING,
  SEND_EMAIL_TO_SELF_SUCCESS,
  UPDATE_OFFER,
} from "../../constants/actions/UI/Offers";

import { IAction } from "../../constants/types/IAction";
import { IDataResponse } from "../../constants/types/API/Apiresponse";
import { OFFER_UPSERT_FAILURE, OFFER_UPSERT_STARTING, OFFER_UPSERT_SUCCESS } from "../../constants/actions/API/OfferUpdate";
import { UPSERT_OFFER_FROM_DETAIL_FAILURE, UPSERT_OFFER_FROM_DETAIL_FETCHING, UPSERT_OFFER_FROM_DETAIL_SUCCESS } from "../../constants/actions/API/OffersUpsertFromDetail";

const initialState: IDataResponse = {
  data: null,
  fetching: false,
  error: null,
};

const OffersReducers = (state = initialState, action: IAction) => {
  switch (action.type) {
    case FETCHING_OFFERS:
      return { ...state, fetching: true, data: null, error: null };
    case FETCHING_OFFERS_SUCCESS:
      return {
        ...state,
        data: action.payload.response,
        fetching: false,
        error: null,
      };
    case FETCHING_OFFERS_FAILURE:
      return { ...state, error: action.payload, fetching: false, data: null };
    default:
      return state;
  }
};

export default OffersReducers;

export const OffersCategoryReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case GET_OFFERS_CATEGORIES_FETCHING:
      return { ...state, fetching: true, data: null, error: null };
    case GET_OFFERS_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: action.payload.response,
        fetching: false,
        error: null,
      };
    case GET_OFFERS_CATEGORIES_FAILURE:
      return { ...state, error: action.payload, fetching: false, data: null };
    default:
      return state;
  }
};

export const OffersDetailsAPIReducers = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case GET_OFFER_BY_ID_FETCHING:
      return { ...state, fetching: true, data: null, error: null };
    case GET_OFFER_BY_ID_SUCCESS:
      return {
        ...state,
        data: { id: action.payload.id, response: action.payload.response },
        fetching: false,
        error: null,
      };
    case GET_OFFER_BY_ID_FAILURE:
      return { ...state, error: action.payload, fetching: false, data: null };
    default:
      return state;
  }
};

export const UpsertOfferReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case OFFER_UPSERT_STARTING:
      return { ...state, fetching: true, data: null, error: null };
    case OFFER_UPSERT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        error: null,
      };
    case OFFER_UPSERT_FAILURE:
      return { ...state, error: action.payload, fetching: false, data: null };
    default:
      return state;
  }
};
export const UpsertOfferFromDetailReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case UPSERT_OFFER_FROM_DETAIL_FETCHING:
      return { ...state, fetching: true, data: null, error: null };
    case UPSERT_OFFER_FROM_DETAIL_SUCCESS:
      return { ...state, data: action.payload, fetching: false, error: null };
    case UPSERT_OFFER_FROM_DETAIL_FAILURE:
      return { ...state, error: action.payload, fetching: false, data: null }
    default:
      return state
  }

}

export const OffersTermsAndConditionReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case GET_TERMS_AND_CONDITION_FETCHING:
      return { ...state, fetching: true, data: null, error: null };
    case GET_TERMS_AND_CONDITION_SUCCESS:
      return {
        ...state,
        data: { id: action.payload.id, response: action.payload.response },
        fetching: false,
        error: null,
      };
    case GET_OFFERS_CATEGORIES_FAILURE:
      return { ...state, error: action.payload, fetching: false, data: null };
    default:
      return state;
  }
};

export const sendEmailToSelfReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case SEND_EMAIL_TO_SELF_FETCHING:
      return { ...state, fetching: true, data: null, error: null };
    case SEND_EMAIL_TO_SELF_SUCCESS:
      return {
        ...state,
        data: { id: action.payload.id, response: action.payload.response },
        fetching: false,
        error: null,
      };
    case SEND_EMAIL_TO_SELF_FAILURE:
      return { ...state, error: action.payload, fetching: false, data: null };
    default:
      return state;
  }
};
