import { ISorting } from "./Generic";

export const Partcipant_Roles = {
    SiteAdmin: "Site Admin",
    EngagementManager: "Engagement Manager",
    DeliveryTeamMember: "Delivery TeamMember",
}

export interface IGetParticipantPayload {
    searchTerm?: string;
    activeMenu?: string;
    sortingOrder?: ISorting;
}

export interface IParticipantsData {
    UserAlias: string;
    DisplayName: string;
    RoleName: string;
}
