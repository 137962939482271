// eslint-disable-next-line no-restricted-imports
import {
  LatencyCancel,
  LatencyLoader,
  LatencyWrapper,
  Suggestion,
} from "@fluentai/react-copilot";
import { Button, Link } from "@fluentui/react-components";
import { OutputCard } from "@fluentai/output-card";
import {
  ArrowClockwise16Filled,
  Star12Filled,
  Dismiss20Regular,
} from "@fluentui/react-icons";
import { Spinner, Stack } from "office-ui-fabric-react";
import React, { useEffect } from "react";
import Message from "./Message";
import { useMessageStyles } from "./Message.Styles";
import { getLatestAnnouncement } from "../../Services/gptApi";
import { mergeStyles } from "@fluentui/react";

type ThreadProps = {
  messages: any[];
  updateMessage: (messageId: string, message: any) => void;
  setTextFieldValue: (textFieldValue: string) => void;
  isSpinnerActive: boolean;
  suggestions: string[];
  showBanner: boolean;
  setBanner: Function;
  apiUrl: string;
  authToken: string;
};

const Thread = ({
  messages,
  updateMessage,
  setTextFieldValue,
  isSpinnerActive,
  suggestions,
  showBanner,
  setBanner,
  apiUrl,
  authToken,
}: ThreadProps) => {
  const styles = useMessageStyles();
  const [announcement, setAnnouncement] = React.useState<any>([]);

  const getRandomSuggestions = () => {
    const suggestionsCopy = [...suggestions];
    for (let i = suggestionsCopy.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [suggestionsCopy[i], suggestionsCopy[j]] = [
        suggestionsCopy[j],
        suggestionsCopy[i],
      ];
    }
    return suggestionsCopy?.splice(0, 3);
  };

  const [randomSuggestions, setRandomSuggestions] = React.useState<string[]>();

  useEffect(() => {
    fetchAndSetAnnouncements();
    setRandomSuggestions(getRandomSuggestions());
  }, [suggestions]);

  const shouldShowSuggestions = () => {
    
    if (messages.length === 0 || suggestions.length === 0) {
      return false;
    }
    const lastMessage = messages[messages.length - 1];
    if (lastMessage.from === "bot") {
      return true;
    }
    return false;
  };

  const handleBanner = () => {
    setBanner(!showBanner);
  };

  const fetchAndSetAnnouncements = async () => {
    const setAnnouncements = async () => {
      const announcementResponse = await getLatestAnnouncement(
        apiUrl,
        authToken
      );
      if (announcementResponse) {
        setAnnouncement(announcementResponse);
      }
    };
    setAnnouncements();
  };

  return (
    <div className={styles.threadRoot}>
      <Stack tokens={{ childrenGap: 8 }}>
        {showBanner && (
          <OutputCard className={styles.banner}>
            <div>
              <span className={styles.bannerHeader}>What's new </span>
              <Button
                aria-label="more"
                appearance="transparent"
                icon={<Star12Filled />}
              />
              {/* <span className={styles.bannerSpan}>Version 2.1</span> */}
              <Button
                aria-label="Close"
                appearance="transparent"
                title="Close"
                icon={<Dismiss20Regular />}
                className={styles.closeBanner}
                onClick={handleBanner}
              />
            </div>
            {announcement && announcement.length > 0 ? (
              <>
                <div>We've made some exciting updates</div>
                <ul className={styles.bannerList}>
                  {announcement.map((banner: any, key: any) => (
                    <li key={key}>
                      <div
                        style={{ textDecoration: "none", cursor: "pointer" }}
                        dangerouslySetInnerHTML={{ __html: banner.message }}
                      />
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <div>There are no new announcements at this time</div>
            )}
          </OutputCard>
        )}
        {messages.map((item: any, index) => {
          return (
            <div key={index}>
              <Message message={item} updateMessage={updateMessage} />
            </div>
          );
        })}
        {shouldShowSuggestions() &&
          randomSuggestions &&
          randomSuggestions.map((suggestion: string, index) => {
            return (
              <div className={styles.suggestionContainer} key={index}>
                <Suggestion
                  className={styles.suggestionItemContainer}
                  onClick={() => setTextFieldValue(suggestion)}
                  aria-activedescendant="suggestion-1"
                >
                  {suggestion}
                </Suggestion>
                {index === 2 && (
                  <div title="Refresh for new prompts">
                    <ArrowClockwise16Filled
                      className={styles.refreshButton}
                      onClick={() =>
                        setRandomSuggestions(getRandomSuggestions())
                      }
                    />
                  </div>
                )}
              </div>
            );
          })}
      </Stack>
      {isSpinnerActive && (
        <LatencyWrapper>
          <LatencyLoader
            header={"Looking for references..."}
            className={styles.copilotLatencyLoader}
            progress={{
              value: undefined,
              thickness: "large",
              color: "brand",
              shape: "rounded",
              props: { className: styles.copilotLatencyLoaderProgress },
            }}
            as="div"
          />
        </LatencyWrapper>
      )}
    </div>
  );
};

export default Thread;
