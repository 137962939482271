import React, { Component } from "react";
import { Icon, IStackStyles, Panel, PanelType } from "office-ui-fabric-react";
import {
  DefaultButton,
  FontIcon,
  PrimaryButton,
  Link,
  IComboBoxOption,
  MessageBarType,
  Toggle,
  DirectionalHint,
  IconButton,
} from "@fluentui/react";
import { connect } from "react-redux";
import "./OfferDetail.css";
import { Account } from "msal";
import {
  IOfferDetail,
  IOfferDetailData,
  OFFERS_STATE,
} from "../../constants/types/UI/Offers";
import CustomTextField from "../utils/CustomTextField/CustomTextField";
import AppConstants, {
  AppRoles,
  ShowHelpText,
} from "../../constants/AppConstants";
import {
  ActionTrackingOffer,
  DownloadOffersTCPDF,
  EmailOffersTCPDFToSelf,
  Feedback_Rating,
  logEvent,
  Navigation,
  OfferViewHelp,
} from "../../utils/insightsClient";
import {
  activeSideNavs,
  IAppScopes,
  IColumn,
  IField,
} from "../../constants/types/UI/Generic";
import { IMetaData } from "../../constants/types/API/MetaData";
import CustomComboBox from "../utils/CustomComboBox/CustomComboBox";
import OfferSummary from "../../assets/OfferSummary.png";
import OfferTitle from "../../assets/OfferTitle.png";
import CustomCheckBoxes from "../utils/CustomCheckBoxes/CustomCheckBoxes";
import { OFFERS_URL } from "../../constants/routes";
import _ from "lodash";
import { upsertOfferFromDetail } from "../../actions/API/OffersUpsertFromDetail";
import { IBannerMessagePayload } from "../../constants/types/UI/BannerMessage";
import { setBanner } from "../../actions/UI/BannerMessage";
import { IDataResponse } from "../../constants/types/API/Apiresponse";
import { getOfferDetailsForDropDown } from "../../actions/API/MetaData";
import { MPDialog } from "../utils/MPDialog";
import { ShowOfferImageDialog } from "../utils/ShowOfferImageDialog";
import {
  TooltipHost,
  ITooltipHostStyles,
  ITooltipProps,
} from "@fluentui/react/lib/Tooltip";
import { IRatingPayload } from "../../constants/types/UI/Rating";
import { showRatingInCenter } from "../../actions/UI/Rating";
import PersistentNotification from "../common/PersistentNotification";
import { INotifications } from "../../constants/types/UI/Notifications";
import {
  getOffersTermsAndConditionLink,
  sendEmailToSelf,
} from "../../actions/UI/Offers";

const ttstyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};
const ttcalloutProps = { gapSpace: 0 };

const savetooltipProps: ITooltipProps = {
  onRenderContent: () => (
    <div>
      Saves offer data, does not change the state of the offer but updates the
      fields of the item
    </div>
  ),
};
const drafttooltipProps: ITooltipProps = {
  onRenderContent: () => (
    <div>Creates a 'Draft' offer, and provides the offer an 'OfferCode'</div>
  ),
};
const createtooltipProps: ITooltipProps = {
  onRenderContent: () => (
    <div>
      Creates the offer, moves it to 'In Review' state and saves the user inputs
    </div>
  ),
};
const approvetooltipProps: ITooltipProps = {
  onRenderContent: () => (
    <div>
      Moves the offer from 'In Review' to 'Active' state and updates the data
    </div>
  ),
};
const finalSavetooltipProps: ITooltipProps = {
  onRenderContent: () => <div>Saves the offer and updates the field data</div>,
};

const stackStyles: Partial<IStackStyles> = { root: { padding: 10 } };

interface IOfferProps {
  offerDetail: IOfferDetail;
  metaData: { [key: string]: IMetaData };
  appScopes: IAppScopes;
  location: {
    pathname: string;
    search: string;
    hash: string;
  };
  initURLState: boolean;
  history: any;
  toggleSpinner: Function;
  user: Account;
  upsertOfferFromDetail: Function;
  setBanner: Function;
  upsertOfferFromDetailState: IDataResponse;
  getOfferDetailsForDropDown: Function;
  showRatingInCenter: Function;
  getNotifications: any;
  getOffersTermsAndConditionLink: Function;
  pdfUrl: any;
  getTCLinkState: IDataResponse;
  sendEmailToSelf: Function;
  sendEmailToSelfState: IDataResponse;
  userContext: any;
}

interface IOfferState {
  viewHelp: boolean;
  offeringSummary: IField<string>;
  offeringDetails: IField<string>;
  offerLegalTerms: IField<string>;
  team: IField<IComboBoxOption>;
  offeringTitle: IField<string>;
  dropDownsData: {
    team: IComboBoxOption[];
    accessLevels: string[];
  };
  allowFieldsEdit: boolean;
  accessLevel: IField<string[]>;
  isFormValid: boolean;
  offerStatus: IField<number>;
  offerStates: {
    active: any;
    inactive: any;
    inReview: any;
    draft: any;
  };
  duplicateRoleError: boolean;
  fullOfferNames: string[];
  hideOfferStateConfirmation: boolean;
  hideShowImageDialog: boolean;
  viewImageLink: any;
  offerCodeTogetLink: string;
  isSupportOnly: boolean;
  splitOfferWindow: boolean;
  isFieldModified:boolean;
}

export const offerStatus = {
  active: "active",
  inactive: "inactive",
  inReview: "in review",
  draft: "draft",
};

const getFullOfferName = (
  offers: IColumn[],
  accessLevels: IColumn[],
  teams: IColumn[]
): string[] => {
  const fullOfferNames: string[] = [];
  offers.forEach((offer) => {
    const newKey = `Microsoft - ${teams
      .find((team) => team.metaData.cdd_Id == offer.metaData.Team)
      ?.id?.toString()} - ${offer.metaData.OfferTitle} (${accessLevels
      .find((acsLvl) => acsLvl.metaData.cdd_Id == offer.metaData.AccessLevel)
      ?.id.toString()})`;
    fullOfferNames.push(newKey);
  });
  return fullOfferNames;
};

class OfferDetail extends Component<IOfferProps, IOfferState> {
  duplicateRoleTimeout: any;

  constructor(props: any) {
    super(props);
    this.state = {
      viewHelp: this.toShowViewHelp(),
      offeringSummary: { value: "", required: true },
      offeringDetails: { value: "", required: true },
      offerLegalTerms: { value: "", required: true },
      team: { required: true },
      offeringTitle: { value: "", required: true },
      dropDownsData: {
        team: buildDropDowns(this.props.metaData.Team.values ?? []),
        accessLevels:
          this.props.metaData.PodAuthorizationRole.values
            ?.filter(
              (i) =>
                i.metaData.cdd_Value !=
                AppConstants.ManagedServiceRegistrationDeleteRole
            )
            .map((item) => item.key.toString()) ?? [],
      },
      offerStatus: {
        value: this.props.offerDetail.details?.Status ?? undefined,
        required: true,
      },
      isFormValid: true,
      accessLevel: { value: [], required: true },
      allowFieldsEdit: this.props.offerDetail.new
        ? this.props.appScopes.Offers.Create
        : this.props.appScopes.Offers.Edit,
      offerStates: {
        active: this.props.metaData.OfferState.values?.find(
          (val) => val.id.toString().toLowerCase() == offerStatus.active
        )?.metaData.cdd_Id,
        inactive: this.props.metaData.OfferState.values?.find(
          (val) => val.id.toString().toLowerCase() == offerStatus.inactive
        )?.metaData.cdd_Id,
        inReview: this.props.metaData.OfferState.values?.find(
          (val) => val.id.toString().toLowerCase() == offerStatus.inReview
        )?.metaData.cdd_Id,
        draft: this.props.metaData.OfferState.values?.find(
          (val) => val.id.toString().toLowerCase() == offerStatus.draft
        )?.metaData.cdd_Id,
      },
      duplicateRoleError: false,
      fullOfferNames: getFullOfferName(
        this.props.metaData.Offers.values ?? [],
        this.props.metaData.PodAuthorizationRole.values ?? [],
        this.props.metaData.Team.values ?? []
      ),
      hideOfferStateConfirmation: true,
      hideShowImageDialog: true,
      viewImageLink: <div></div>,
      offerCodeTogetLink: "",
      isSupportOnly: this.props.offerDetail.new
        ? false
        : this.props.offerDetail.details?.IsSupportOnly != null
        ? this.props.offerDetail.details?.IsSupportOnly
        : false,
      splitOfferWindow: this.getOfferState(),
      isFieldModified:false
    };
  }

  componentDidMount() {
    this.updateFieldsForOffer();
    window.addEventListener("storage", this.handleOfferPanel, false);
  }

  componentWillUnmount() {
    this.setState({
      hideOfferStateConfirmation: true,
      hideShowImageDialog: true,
    });
    window.removeEventListener("storage", this.handleOfferPanel, false);
  }

  evaluateOriginalOffers = (apiOffer: Partial<IOfferDetailData>) => {
    var oldState = "";
    var newState = "";
    switch (apiOffer.Status) {
      case this.state.offerStates.draft:
        newState = OFFERS_STATE.DRAFT;
        break;
      case this.state.offerStates.active:
        newState = OFFERS_STATE.ACTIVE;
        break;
      case this.state.offerStates.inactive:
        newState = OFFERS_STATE.INACTIVE;
        break;
      case this.state.offerStates.inReview:
        newState = OFFERS_STATE.IN_REVIEW;
        break;
    }

    if (!this.props.offerDetail.details) {
      oldState = OFFERS_STATE.NEW;
    } else {
      switch (this.props.offerDetail.details.Status) {
        case this.state.offerStates.draft:
          oldState = OFFERS_STATE.DRAFT;
          break;
        case this.state.offerStates.active:
          oldState = OFFERS_STATE.ACTIVE;
          break;
        case this.state.offerStates.inactive:
          oldState = OFFERS_STATE.INACTIVE;
          break;
        case this.state.offerStates.inReview:
          oldState = OFFERS_STATE.IN_REVIEW;
          break;
      }
    }

    return [oldState, newState];
  };

  duplicateRoleFinder = () => {
    var duplicateFound = false;
    this.state.accessLevel.value?.forEach((acc) => {
      const currentOfferName =
        `Microsoft - ${this.state.team.value?.key} - ${this.state.offeringTitle.value} (${acc})`.toLowerCase();
      var foundDuplicate = this.state.fullOfferNames.find((offerName) => {
        return (
          offerName.toLowerCase() === currentOfferName &&
          currentOfferName !==
            `Microsoft - ${this.props.metaData.Team.values
              ?.find(
                (team) =>
                  team.metaData.cdd_Id == this.props.offerDetail.details?.Team
              )
              ?.id?.toString()} - ${
              this.props.offerDetail.details?.OfferTitle
            } (${this.props.metaData.PodAuthorizationRole.values
              ?.find(
                (acsLvl) =>
                  acsLvl.metaData.cdd_Id ==
                  this.props.offerDetail.details?.AccessLevel
              )
              ?.id.toString()})`.toLowerCase()
        );
      });
      if (foundDuplicate) {
        if (!duplicateFound) {
          duplicateFound = true;
        }
      }
    });
    this.setState({ duplicateRoleError: duplicateFound });
  };

  enableLoader = () => {
    this.props.toggleSpinner({
      spinner: true,
      spinnerMessage: "Sending Email...",
    });
  };

  disableLoader = () => {
    this.props.toggleSpinner({
      spinner: false,
      spinnerMessage: "",
    });
  };

  componentDidUpdate(prevProps: IOfferProps, prevState: IOfferState) {
    if (prevProps.sendEmailToSelfState !== this.props.sendEmailToSelfState) {
      if (
        this.props.sendEmailToSelfState.data &&
        !this.props.sendEmailToSelfState.fetching
      ) {
        this.disableLoader();
        this.buildAndShowBanner(
          "Offer Legal Terms sent to your email successfully.",
          MessageBarType.success,
          5000
        );
        logEvent(ActionTrackingOffer, {
          AppName: AppConstants.ApplicationAlias,
          AppLayer: "UI",
          MetricName: "Email Offer Legal terms and condition to Self",
          UserScenario: "User clicks Request Offer Legal Terms PDF button",
          User: { Alias: this.props.userContext.email },
          ApplicationRole: this.props.userContext.roles,
          EntityOperation: "Email",
          EntityName: "Offer",
          MetaData: {
            page: "Offer Detail",
            action: "Request Offer Legal Terms",
            OfferCode: this.props.offerDetail.details?.OfferCode,
            OfferTemplateVersion:
              this.props.offerDetail.details?.OfferTemplateVersion,
          },
          traceSeverity: "Medium",
        });
      } else if (
        this.props.sendEmailToSelfState.error &&
        !this.props.sendEmailToSelfState.fetching
      ) {
        this.buildAndShowBanner(
          "Failed to get offers data properly. Please reload!!",
          MessageBarType.severeWarning,
          5000
        );
        this.disableLoader();
      }
    }

    if (
      prevProps.upsertOfferFromDetailState !==
      this.props.upsertOfferFromDetailState
    ) {
      if (
        !this.props.upsertOfferFromDetailState.fetching &&
        !this.props.upsertOfferFromDetailState.error
      ) {
        const [oldState, newState] = this.evaluateOriginalOffers(
          this.props.upsertOfferFromDetailState.data.originalOffers[0]
        );
        if (oldState === newState) {
          this.buildAndShowBanner(
            `Updated offer 'Microsoft - ${this.props.upsertOfferFromDetailState.data.originalOffers[0].TeamName} - ${this.props.upsertOfferFromDetailState.data.originalOffers[0].OfferTitle}' successfully`,
            MessageBarType.success,
            7000
          );
        } else if (
          oldState == OFFERS_STATE.NEW &&
          newState == OFFERS_STATE.DRAFT
        ) {
          this.buildAndShowBanner(
            `Created draft offer 'Microsoft - ${this.props.upsertOfferFromDetailState.data.originalOffers[0].TeamName} - ${this.props.upsertOfferFromDetailState.data.originalOffers[0].OfferTitle}' successfully`,
            MessageBarType.success,
            7000
          );
        } else if (
          oldState == OFFERS_STATE.NEW &&
          newState == OFFERS_STATE.IN_REVIEW
        ) {
          this.buildAndShowBanner(
            `Created the offer 'Microsoft - ${this.props.upsertOfferFromDetailState.data.originalOffers[0].TeamName} - ${this.props.upsertOfferFromDetailState.data.originalOffers[0].OfferTitle}' successfully`,
            MessageBarType.success,
            7000
          );
          this.showUserRatingInCenter(true);
        } else if (
          oldState == OFFERS_STATE.DRAFT &&
          newState == OFFERS_STATE.IN_REVIEW
        ) {
          this.buildAndShowBanner(
            `Offer 'Microsoft - ${this.props.upsertOfferFromDetailState.data.originalOffers[0].TeamName} - ${this.props.upsertOfferFromDetailState.data.originalOffers[0].OfferTitle}' moved from 'Draft' to 'In Review' successfully`,
            MessageBarType.success,
            7000
          );
          this.showUserRatingInCenter(true);
        } else if (
          oldState == OFFERS_STATE.IN_REVIEW &&
          newState == OFFERS_STATE.ACTIVE
        ) {
          this.buildAndShowBanner(
            `Activated offer 'Microsoft - ${this.props.upsertOfferFromDetailState.data.originalOffers[0].TeamName} - ${this.props.upsertOfferFromDetailState.data.originalOffers[0].OfferTitle}' successfully`,
            MessageBarType.success,
            7000
          );
        } else if (
          oldState == OFFERS_STATE.ACTIVE &&
          newState == OFFERS_STATE.INACTIVE
        ) {
          this.buildAndShowBanner(
            `De-activated offer 'Microsoft - ${this.props.upsertOfferFromDetailState.data.originalOffers[0].TeamName} - ${this.props.upsertOfferFromDetailState.data.originalOffers[0].OfferTitle}' successfully`,
            MessageBarType.success,
            7000
          );
        } else if (
          oldState == OFFERS_STATE.INACTIVE &&
          newState == OFFERS_STATE.ACTIVE
        ) {
          this.buildAndShowBanner(
            `Re-activated offer 'Microsoft - ${this.props.upsertOfferFromDetailState.data.originalOffers[0].TeamName} - ${this.props.upsertOfferFromDetailState.data.originalOffers[0].OfferTitle}' successfully`,
            MessageBarType.success,
            7000
          );
        }
        activeSideNavs.offers = newState;
        this.closeOffer();
        this.manageSpinner(false, "");
        this.props.getOfferDetailsForDropDown();
      } else if (
        !this.props.upsertOfferFromDetailState.fetching &&
        this.props.upsertOfferFromDetailState.error
      ) {
        const [oldState, newState] = this.evaluateOriginalOffers(
          this.props.upsertOfferFromDetailState.error.originalOffers[0]
        );
        if (oldState == OFFERS_STATE.NEW) {
          this.buildAndShowBanner(
            `Failed to created new ${
              newState === OFFERS_STATE.DRAFT ? OFFERS_STATE.DRAFT : ""
            } offer. Please try again!!`,
            MessageBarType.error,
            7000
          );
        } else {
          this.buildAndShowBanner(
            `Failed to update offer - '${this.props.upsertOfferFromDetailState.error.originalOffers[0].OfferCode}'. Please try again!!`,
            MessageBarType.error,
            7000
          );
        }
        activeSideNavs.offers = newState;
        this.manageSpinner(false, "");
      }
    }
    if (
      (prevState.team !== this.state.team ||
        prevState.offeringTitle !== this.state.offeringTitle ||
        prevState.accessLevel !== this.state.accessLevel) &&
      this.state.team.value &&
      this.state.offeringTitle.value &&
      this.state.accessLevel.value?.length
    ) {
      clearTimeout(this.duplicateRoleTimeout);
      if (prevState.offeringTitle !== this.state.offeringTitle) {
        this.duplicateRoleTimeout = setTimeout(() => {
          this.duplicateRoleFinder();
        }, 600);
      } else {
        this.duplicateRoleFinder();
      }
    } else if (
      prevState.team !== this.state.team ||
      prevState.offeringTitle !== this.state.offeringTitle ||
      prevState.accessLevel !== this.state.accessLevel
    ) {
      clearTimeout(this.duplicateRoleTimeout);
      this.setState({ duplicateRoleError: false });
    }
  }

  handleOfferPanel = () => {
    this.setState({ splitOfferWindow: this.getOfferState() });
  };
  getOfferState = (): boolean => {
    let isOfferPanelOpen = sessionStorage.getItem("offerPanelOpen");
    let isCopilotOpen = sessionStorage.getItem("copilotPinnedState");
    if (isOfferPanelOpen && isCopilotOpen) {
      isOfferPanelOpen = JSON.parse(isOfferPanelOpen);
      isCopilotOpen = JSON.parse(isCopilotOpen);
      if (
        String(isOfferPanelOpen) === "true" &&
        String(isCopilotOpen) === "true"
      ) {
        return true;
      } else {
        return false;
      }
    }
    sessionStorage.setItem("offerPanelOpen", String(false));
    return false;
  };

  updateFieldsForOffer = () => {
    // -> Team & Offering title
    const teamTitle = this.props.metaData.Team.values?.find(
      (meta) => meta.metaData.cdd_Id === this.props.offerDetail.details?.Team
    );
    let status = false;
    this.setState({
      team: {
        ...this.state.team,
        value: teamTitle
          ? { key: teamTitle.key, text: teamTitle.key.toString() }
          : undefined,
        errorMessage: undefined,
      },
    });
    this.setState({
      offeringTitle: {
        ...this.state.offeringTitle,
        value: this.props.offerDetail.details?.OfferTitle ?? undefined,
        errorMessage: undefined,
      },
    });

    // -> Offering Summary & Offering Details && Offer details
    this.setState({
      offeringSummary: {
        ...this.state.offeringSummary,
        value: this.props.offerDetail.details?.OfferingSummary ?? undefined,
        errorMessage: undefined,
      },
      offeringDetails: {
        ...this.state.offeringDetails,
        value: this.props.offerDetail.details?.OfferingDetails ?? undefined,
        errorMessage: undefined,
      },
      offerLegalTerms: {
        ...this.state.offerLegalTerms,
        value: this.props.offerDetail.details?.OfferLegalTerms ?? undefined,
        errorMessage: undefined,
      },
     // isFieldModified:!status
    });

    // -> Access Levels

    const accessLevels: any = this.props.offerDetail.details?.AccessLevel
      ? this.props.metaData.PodAuthorizationRole.values?.find(
          (item) =>
            item.metaData.cdd_Id === this.props.offerDetail.details?.AccessLevel
        )
        ? [
            this.props.metaData.PodAuthorizationRole.values
              ?.find(
                (item) =>
                  item.metaData.cdd_Id ===
                  this.props.offerDetail.details?.AccessLevel
              )
              ?.id.toString(),
          ]
        : []
      : [];
    this.setState({
      accessLevel: {
        ...this.state.accessLevel,
        value: accessLevels,
        errorMessage: undefined,
      },
    });
  };

  toShowViewHelp = () => {
    const helpTextSS = window.sessionStorage.getItem(ShowHelpText);
    if (!!helpTextSS) {
      if (helpTextSS == "true") {
        return true;
      } else return false;
    }
    return true;
  };

  closeOffer = () => {
    sessionStorage.setItem("offerPanelOpen", String(false));
    window.dispatchEvent(new Event("storage"));
    if (this.props.initURLState) {
      this.props.history.push(OFFERS_URL);
    } else {
      this.props.history.goBack();
    }
    logEvent(ActionTrackingOffer, {
      AppName: "CAPE",
      AppLayer: "UI",
      MetricName: "Offer",
      UserScenario: "User cancels create Offer form",
      User: { Alias: this.props.userContext.email },
      ApplicationRole: this.props.userContext.roles,
      EntityOperation: "Cancel",
      EntityName: "Offer",
      traceSeverity: "Low",
    });
  };

  manageSpinner = (showSpinner: boolean, spinnerMessage: string) => {
    this.props.toggleSpinner({
      spinner: showSpinner,
      spinnerMessage: spinnerMessage,
    });
  };

  buildAndShowBanner(
    message: string,
    messageType: MessageBarType,
    timeOut: number
  ) {
    const bannerProps: IBannerMessagePayload = {
      toShow: true,
      message: message,
      messageType: messageType,
      styleClass: "",
      timeout: timeOut,
    };
    this.props.setBanner(bannerProps);
  }

  showUserRatingInCenter(showBannerInCenter: boolean) {
    const ratingStyleProps: IRatingPayload = {
      showInCenter: true,
    };
    logEvent(Feedback_Rating, {
      AppName: AppConstants.ApplicationAlias,
      AppLayer: "UI",
      MetricName: "CSATPopup",
      UserScenario: "CSAT component is visible",
      User: { Alias: this.props.userContext.email },
      ApplicationRole: this.props.userContext.roles,
      EntityOperation: "Click",
      EntityName: "Rating",
      MetaData: {
        page: "Offers",
        action: "Offer Creation",
      },
      traceSeverity: "Medium",
    });
    this.props.showRatingInCenter(ratingStyleProps);
  }

  validateSave = () => {
    var isFormValid = true;
    isFormValid =
      this.updateOrValidateTeam(this.state.team.value, true) && isFormValid;
    isFormValid =
      this.updateOrValidateOfferingTitle(
        this.state.offeringTitle.value,
        true
      ) && isFormValid;
    isFormValid =
      this.updateOrValidateOfferSummary(
        this.state.offeringSummary.value,
        true
      ) && isFormValid;
    isFormValid =
      this.updateOrValidateOfferDetails(
        this.state.offeringDetails.value,
        true
      ) && isFormValid;
    isFormValid =
      this.updateOrValidateAccessLevel(this.state.accessLevel.value, true) &&
      isFormValid;
    isFormValid =
      this.updateOrValidateOfferLegalTerms(
        this.state.offerLegalTerms.value,
        true
      ) && isFormValid;
    isFormValid = isFormValid && !this.state.duplicateRoleError;
    this.setState({ isFormValid: isFormValid });
    return isFormValid;
  };

  saveOffer = (isDraft: boolean = false, sameState: boolean = false) => {
    var offerObjs: Partial<IOfferDetailData>[] = [];
    var newDateString = new Date().toISOString();
    var templateOffer: Partial<IOfferDetailData> = {};
    if (this.props.offerDetail.new || isDraft) {
      templateOffer = {
        Team:
          this.props.metaData.Team.values?.find(
            (val) => val.key.toString() === this.state.team.value?.key
          )?.metaData.cdd_Id ?? undefined,
        OfferTitle: this.state.offeringTitle.value ?? null,
        OfferingSummary: this.state.offeringSummary.value ?? null,
        OfferingDetails: this.state.offeringDetails.value ?? null,
        OfferLegalTerms: this.state.offerLegalTerms.value ?? null,
        Role: `Microsoft - ${this.state.team.value?.key} - ${this.state.offeringTitle.value}`,
        IsActive: isDraft ? false : true,
        Status: isDraft
          ? this.state.offerStates.draft
          : this.state.offerStates.inReview,
        CreatedBy: this.props.user.userName.split("@")[0] ?? "",
        CreatedDate: newDateString,
        LastModifiedBy: this.props.user.userName.split("@")[0] ?? "",
        LastModifiedDate: newDateString,
        TeamName: this.state.team.value?.key.toString() ?? "",
        IsSupportOnly: this.state.isSupportOnly,
      };
    } else {
      templateOffer = {
        ...this.props.offerDetail.details,
        Team:
          this.props.metaData.Team.values?.find(
            (val) => val.key.toString() === this.state.team.value?.key
          )?.metaData.cdd_Id ?? undefined,
        OfferTitle: this.state.offeringTitle.value ?? null,
        OfferingSummary: this.state.offeringSummary.value ?? null,
        OfferingDetails: this.state.offeringDetails.value ?? null,
        OfferLegalTerms: this.state.offerLegalTerms.value ?? null,
        Role: `Microsoft - ${this.state.team.value?.key} - ${this.state.offeringTitle.value}`,
        IsActive:
          this.state.offerStatus.value === this.state.offerStates.inactive
            ? false
            : true,
        Status: sameState
          ? this.props.offerDetail.details?.Status
          : this.props.offerDetail.details?.Status ==
            this.state.offerStates.draft
          ? this.state.offerStates.inReview
          : this.props.offerDetail.details?.Status ==
            this.state.offerStates.inReview
          ? this.state.offerStates.active
          : this.state.offerStatus.value,
        LastModifiedBy: this.props.user.userName.split("@")[0] ?? "",
        LastModifiedDate: newDateString,
        TeamName: this.state.team.value?.key.toString() ?? "",
        IsSupportOnly: this.state.isSupportOnly,
      };
    }
    this.state.accessLevel.value?.forEach((acl) => {
      var localTemplateOffer = _.cloneDeep(templateOffer);
      localTemplateOffer.AccessLevel =
        this.props.metaData.PodAuthorizationRole.values?.find(
          (val) => val.key.toString() === acl
        )?.metaData.cdd_Id ?? undefined;
      localTemplateOffer.AccessLevelName = acl;
      offerObjs.push(localTemplateOffer);
    });
    this.manageSpinner(true, `Saving offer${offerObjs.length > 1 ? "s" : ""}`);
    this.props.upsertOfferFromDetail(offerObjs);
    logEvent(ActionTrackingOffer, {
      AppName: "CAPE",
      AppLayer: "UI",
      MetricName: "Offer",
      UserScenario:
        "User " + isDraft
          ? offerStatus.draft
          : offerStatus.inReview + "an Offer",
      User: { Alias: this.props.userContext.email },
      ApplicationRole: this.props.userContext.roles,
      EntityOperation: isDraft ? offerStatus.draft : offerStatus.inReview,
      EntityName: "Offer",
      Metadata: {
        IsDraft: isDraft ? true : false,
        Team:
          this.props.metaData.Team.values?.find(
            (val) => val.key.toString() === this.state.team.value?.key
          )?.metaData.cdd_Id ?? undefined,
        OfferTitle: this.state.offeringTitle.value ?? null,
        OfferingSummary: this.state.offeringSummary.value ?? null,
        OfferingDetails: this.state.offeringDetails.value ?? null,
        OfferLegalTerms: this.state.offerLegalTerms.value ?? null,
        Role: `Microsoft - ${this.state.team.value?.key} - ${this.state.offeringTitle.value}`,
        IsActive:
          this.state.offerStatus.value === this.state.offerStates.inactive
            ? false
            : true,
        Status: sameState
          ? this.props.offerDetail.details?.Status
          : this.props.offerDetail.details?.Status ==
            this.state.offerStates.draft
          ? this.state.offerStates.inReview
          : this.props.offerDetail.details?.Status ==
            this.state.offerStates.inReview
          ? this.state.offerStates.active
          : this.state.offerStatus.value,
        saved: true,
        OfferCode:
          this.props.offerDetail.details?.OfferCode == undefined
            ? 0
            : this.props.offerDetail.details?.OfferCode,
      },
      traceSeverity: "Important",
    });
  };

  confirmOfferStatusChange = () => {
    if (
      this.props.offerDetail.details?.Status !== this.state.offerStatus.value
    ) {
      this.setState({
        hideOfferStateConfirmation: false,
      });
    } else {
      this.saveOffer();
    }
  };

  getOffersTermsAndConditionLink = (offerCode?: string) => {
    if (offerCode) {
      this.setState(
        {
          offerCodeTogetLink: offerCode,
        },
        () => {
          this.props.getOffersTermsAndConditionLink(offerCode);
        }
      );
    }
  };

  sendEmailToSelf = (offerCode?: string) => {
    if (offerCode) {
      this.enableLoader();
      this.props.sendEmailToSelf(offerCode);
    }
  };

  onRenderFooterContent = () => {
    return (
      <div
        className="formFooter"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex" }}>
          {this.state.offerStates.active == this.state.offerStatus.value && (
            <Link
              href="javascript:void(0);"
              onClick={() =>
                this.sendEmailToSelf(this.props.offerDetail?.details?.OfferCode)
              }
              className="viewJsonLink"
            >
              Request Offer Legal Terms PDF
            </Link>
          )}
        </div>

        <div style={{ display: "flex" }}>
          {this.state.allowFieldsEdit && (
            <>
            
              {(this.props.offerDetail.details?.Status ==
                this.state.offerStates.draft ||
                this.props.offerDetail.details?.Status ==
                  this.state.offerStates.inReview) && (
                <div className="save-only-offer">
                  <TooltipHost
                    tooltipProps={savetooltipProps}
                    calloutProps={ttcalloutProps}
                    closeDelay={200}
                    styles={ttstyles}
                  >
                    <PrimaryButton
                      className="details-footer-buttons"
                      ariaLabel={
                        this.props.offerDetail.details?.Status ==
                        this.state.offerStates.draft
                          ? "Creates a 'Draft' offer, and provides the offer an 'OfferCode'"
                          : "Saves the offer and updates the field data"
                      }
                      disabled={
                        true
                      }
                     
                      text={
                        this.props.offerDetail.details?.Status ==
                        this.state.offerStates.draft
                          ? "Save as Draft"
                          : "Save"
                      }
                      onClick={(e) => {
                        if (this.validateSave()) {
                          this.saveOffer(false, true);
                        } else {
                          this.buildAndShowBanner(
                            "Some validation errors were found. Please check them!",
                            MessageBarType.error,
                            6000
                          );
                        }
                      }}
                    />
                  </TooltipHost>
                </div>
              )}
              <TooltipHost
                tooltipProps={
                  this.props.offerDetail.new ||
                  this.props.offerDetail.details?.Status ===
                    this.state.offerStates.draft
                    ? createtooltipProps
                    : this.props.offerDetail.details?.Status ==
                      this.state.offerStates.inReview
                    ? approvetooltipProps
                    : finalSavetooltipProps
                }
                calloutProps={ttcalloutProps}
                closeDelay={200}
                styles={ttstyles}
              >
                <PrimaryButton
                  className="details-footer-buttons"
                  styles={stackStyles}
                  disabled={
                    (this.props.userContext.roles?.find(
                      (role: any) =>
                        role.name == AppRoles.SiteAdmin.roleName
                    )) && (this.state.isFieldModified)
                      ? false
                      : true
                  }
                  text={
                    this.props.offerDetail.new ||
                    this.props.offerDetail.details?.Status ===
                      this.state.offerStates.draft
                      ? "Create"
                      : this.props.offerDetail.details?.Status ==
                        this.state.offerStates.inReview
                      ? "Approve"
                      : "Save"
                  }
                  onClick={(e) => {
                    if (this.validateSave()) {
                      if (
                        [
                          this.state.offerStates.active,
                          this.state.offerStates.inactive,
                        ].indexOf(this.props.offerDetail.details?.Status) > -1
                      ) {
                        this.confirmOfferStatusChange();
                      } else {
                        this.saveOffer();
                      }
                    } else {
                      this.buildAndShowBanner(
                        "Some validation errors were found. Please check them!",
                        MessageBarType.error,
                        6000
                      );
                    }
                  }}
                  ariaLabel="Creates the offer, moves it to 'In Review' state
                        and saves the user inputs"
                />
              </TooltipHost>
              {this.props.offerDetail.new && (
                <div className="save-as-draft-offer">
                  <TooltipHost
                    tooltipProps={drafttooltipProps}
                    calloutProps={ttcalloutProps}
                    closeDelay={200}
                    styles={ttstyles}
                  >
                    <DefaultButton
                      text="Save as Draft"
                      className="details-footer-buttons"
                     
                      ariaLabel="Creates a 'Draft' offer, and provides the offer an 'OfferCode'"
                      onClick={(e) => {
                        if (this.validateSave()) {
                          this.saveOffer(true);
                        } else {
                          this.buildAndShowBanner(
                            "Some validation errors were found. Please check them!",
                            MessageBarType.error,
                            6000
                          );
                        }
                      }}
                    />
                  </TooltipHost>
                </div>
              )}
            </>
          )}
          <DefaultButton
            className="details-footer-buttons"
            styles={stackStyles}
            text="Cancel"
            onClick={() => this.closeOffer()}
          />
        </div>
      </div>
    );
  };

  toggleViewHelp = () => {
    const scrollableDiv = document.querySelector(
      ".offer-detail-panel .ms-Panel-scrollableContent"
    );
    if (!this.state.viewHelp) {
      scrollableDiv?.classList.add("hideXOverflow");
      setTimeout(() => {
        scrollableDiv?.classList.remove("hideXOverflow");
      }, 900);
      window.sessionStorage.setItem(ShowHelpText, "true");
      logEvent(Navigation, {
        AppName: "CAPE",
        AppLayer: "UI",
        MetricName: "Navigation",
        UserScenario: "User navigation",
        User: { Alias: this.props.userContext.email },
        ApplicationRole: this.props.userContext.roles,
        EntityOperation: "Click",
        EntityName: "Button",
        Viewhelp: true,
        MetaData: {
          item: "Offer Help",
        },
        traceSeverity: "Low",
      });
    } else {
      window.sessionStorage.setItem(ShowHelpText, "false");
      logEvent(Navigation, {
        AppName: "CAPE",
        AppLayer: "UI",
        MetricName: "Navigation",
        UserScenario: "User navigation",
        User: { Alias: this.props.userContext.email },
        ApplicationRole: this.props.userContext.roles,
        EntityOperation: "Click",
        EntityName: "Button",
        Hidehelp: true,
        MetaData: {
          item: "Offer Help",
        },
        traceSeverity: "Low",
      });
    }
    this.setState({ viewHelp: !this.state.viewHelp });
  };

  onRenderHeader = () => (
    <div className="panelHeader">
      <div className="divWrapper">
        <h2 className="leftHeader" role="heading" aria-level={2}>
          {this.props.offerDetail.new ? (
            "New Offer"
          ) : (
            <span>{`Microsoft - ${this.props.metaData.Team.values
              ?.find(
                (team) =>
                  team.metaData.cdd_Id == this.props.offerDetail.details?.Team
              )
              ?.id?.toString()} - ${
              this.props.offerDetail.details?.OfferTitle
            } (${
              this.props.metaData.PodAuthorizationRole.values?.find(
                (access) =>
                  this.props.offerDetail.details?.AccessLevel ===
                  access.metaData.cdd_Id
              )?.id
            })`}</span>
          )}
        </h2>
        <div className="rightHeader">
          <div>
            <FontIcon
              iconName="Cancel"
              title="Close"
              className="header-right-icons clickable"
              onClick={() => this.closeOffer()}
            />
          </div>
        </div>
      </div>
    </div>
  );

  renderHelpContent = () => {
    return (
      <div className="help-content">
         <IconButton
    iconProps={{ iconName: 'Cancel' }}
    title="Close"
    ariaLabel="Close"
    onClick={() => this.setState({ viewHelp: false })}
    style={{ position: 'absolute', top: 0, right: 0 }}
  />
        <div className="help-header">
          Step-by-Step Instructions for filling the form:
        </div>
        <div className="help-body">
          <div className="help-sub-body">
            <div className="help-sub-header">Team</div>
            <div>
              <ul>
                <li>
                  Describes the team which will deliver the Offering and obtain
                  access to the customer subscription.
                </li>
                <li>
                  Example:{" "}
                  <span className="help-example">"GBB / CSU / EAE" - </span>
                  <Link href="" underline>
                    <div className="help-img-container">
                      <div className="help-img-div">
                        <FontIcon
                          iconName="Photo2"
                          title="Image"
                          className="clickable help-image"
                        />
                      </div>
                      <div className="help-img-text">
                        <a
                          href="javascript:void(0)"
                          aria-label="Click here to view image"
                          onClick={(e) => {
                            this.clickAnchorHandler(e, OfferTitle);
                          }}
                        >
                          Show Image
                        </a>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">Offering Title</div>
            <div>
              <ul>
                <li>
                  Shown to the customer in the Azure Portal to help them
                  differentiate between Offers.
                </li>
                <li>
                  Will be displayed as "Microsoft - [Team Abbreviation] -
                  [Offering Title]".
                </li>
                <li>(Max length 82 characters.)</li>
                <li>
                  Example:{" "}
                  <span className="help-example">
                    "Early Access Innovation Program" -{" "}
                  </span>
                  <Link href="" underline>
                    <div className="help-img-container">
                      <div className="help-img-div">
                        <FontIcon
                          iconName="Photo2"
                          title="Image"
                          className="clickable help-image"
                        />
                      </div>
                      <div className="help-img-text">
                        <Link
                          href="javascript:void(0)"
                          aria-label="Click here to view image"
                          onClick={(e) => {
                            this.clickAnchorHandler(e, OfferTitle);
                          }}
                        >
                          Show Image
                        </Link>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">Marketplace Preview</div>
            <div>
              <ul>
                <li>
                  Shown in the 'Offer Card' which the customer will select in
                  the Azure Portal.
                </li>
                <li>(Max length 100 characters.)</li>
                <li>
                  Example:{" "}
                  <span className="help-example">
                    "Microsoft - EAE - Early Access Innovation Program" -{" "}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">Offering Summary</div>
            <div>
              <ul>
                <li>Succinct description of the offering.</li>
                <li>
                  Shown in the 'Offer Card' which the customer will select in
                  the Azure Portal.
                </li>
                <li>(Max length 100 characters.)</li>
                <li>
                  Example:{" "}
                  <span className="help-example">
                    "Microsoft Early Access Innovation Program (EAiP) 1–3-week
                    guided innovation service" -
                  </span>
                  <Link href="" underline>
                    <div className="help-img-container">
                      <div className="help-img-div">
                        <FontIcon
                          iconName="Photo2"
                          title="Image"
                          className="clickable help-image"
                        />
                      </div>
                      <div className="help-img-text">
                        <Link
                          href="javascript:void(0)"
                          aria-label="Click here to view image"
                          onClick={(e) => {
                            this.clickAnchorHandler(e, OfferSummary);
                          }}
                        >
                          Show Image
                        </Link>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">Offering Details</div>
            <div>
              <ul>
                <li>
                  Longer summary which is shown to users who are viewing offer
                  details.
                </li>
                <li>(Max length 3000 characters.)</li>
                <li>
                  Example:{" "}
                  <span className="help-example">
                    "Through 1-3 week innovation service Microsoft's Early
                    Access Engineering team will guide you through a proven
                    innovation process to help you identify and frame an
                    innovation opportunity within your organization and build a
                    Minimum Viable Product (MVP)"
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">Support Only Offer</div>
            <div>
              <ul>
                <li>
                  This offer can be used when field members have existing
                  contract with customer.
                </li>
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">Access Levels</div>
            <div>
              <ul>
                <li>
                  Level of access required for the engagement. If both are
                  selected, two separate Offers will be created. See
                  <Link
                    target="_blank"
                    rel="noopener"
                    href={AppConstants.AzureRbacUrl}
                  >
                    {" "}
                    Azure Roles{" "}
                  </Link>
                  for details about these access levels
                </li>
                <li>
                  Example:{" "}
                  <span className="help-example">
                    "Reader or Contributor or Reader & Contributor"
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="help-sub-body">
            <div className="help-sub-header">Offer Legal Terms</div>
            <div>
              <ul>
                <li>
                  Please work with your CELA representative to confirm that your
                  program is approved for CAPE.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /**
   * For updating or validating 'Offer Summary'
   */
  updateOrValidateOfferSummary = (
    cont?: string,
    validateForm: boolean = false
  ) => {
    const isInvalid = !!cont ? (!cont?.trimStart() ? true : false) : true;
    let status = false;
    this.setState({
      offeringSummary: {
        ...this.state.offeringSummary,
        value: cont ?? "",
        errorMessage: isInvalid ? "'Offering Summary' is required" : undefined,
      },
      //isFieldModified:!status
    });
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };

  /**
   * For updating or validating 'Offer Details'
   */
  updateOrValidateOfferDetails = (
    cont?: string,
    validateForm: boolean = false
  ) => {
    const isInvalid = !!cont ? (!cont?.trimStart() ? true : false) : true;
    let status = false;
    this.setState({
      offeringDetails: {
        ...this.state.offeringDetails,
        value: cont ?? "",
        errorMessage: isInvalid ? "'Offering Details' are required" : undefined,
      },
     // isFieldModified:!status
    });
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };

  /**
   * For updating or validating 'Offer Legal Terms'
   */
  updateOrValidateOfferLegalTerms = (
    cont?: string,
    validateForm: boolean = false
  ) => {
    const isInvalid = !!cont ? (!cont?.trimStart() ? true : false) : true;
    let status = false;
    this.setState({
      offerLegalTerms: {
        ...this.state.offerLegalTerms,
        value: cont ?? "",
        errorMessage: isInvalid
          ? "'Offer Legal Terms' are required"
          : undefined,
      },
     // isFieldModified:!status
    });
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };

  /**
   * For updating or validating 'Team'
   */
  updateOrValidateTeam = (
    option?: IComboBoxOption,
    validateForm: boolean = false
  ) => {
    const isInvalid = !option || option.key == "Select an Option";
    let status = false;
    this.setState({
      team: {
        ...this.state.team,
        value: option,
        errorMessage: isInvalid ? "'Team' is required" : undefined,
      },
     // isFieldModified:!status
    });
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };

  clickAnchorHandler = (e: any, link: any) => {
    this.setState({
      viewImageLink: (
        <img
          src={link}
          style={{ width: "100%" }}
          alt="Describes the team which will deliver the Offering and obtain access to the customer subscription"
        />
      ),
      hideShowImageDialog: false,
    });
  };

  /**
   * For updating or validating 'Offering Title'
   */
  updateOrValidateOfferingTitle = (
    cont?: string,
    validateForm: boolean = false
  ) => {
    const isInvalid = !!cont ? (!cont?.trimStart() ? true : false) : true;
    this.setState({
      offeringTitle: {
        ...this.state.offeringTitle,
        value: cont ?? "",
        errorMessage: isInvalid ? "'Offering Title' is required" : undefined,
      },
      //isFieldModified:cont != this.state.offeringTitle ? true : false
    });
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };

  /**
   * For updating or validating 'Access Level'
   */
  updateOrValidateAccessLevel = (
    cont?: string[],
    validateForm: boolean = false
  ) => {
    const isInvalid = !cont || cont?.length == 0;
    let status = false;
    this.setState({
      accessLevel: {
        ...this.state.accessLevel,
        value: cont ?? [],
        errorMessage: isInvalid ? "'Access Levels' are required" : undefined,
      },
     // isFieldModified:!status
    });
    if (isInvalid && validateForm) {
      return false;
    }
    return true;
  };

  render() {
    return (
      <Panel
        type={
          this.state.splitOfferWindow ? PanelType.customNear : PanelType.custom
        }
        className="padTop50px offer-detail-panel"
        onRenderFooterContent={this.onRenderFooterContent}
        onRenderNavigationContent={this.onRenderHeader}
        customWidth={this.state.splitOfferWindow ? "73.5%" : "100%"}
        hasCloseButton={false}
        isOpen={this.props.offerDetail.show}
        onDismiss={() => this.closeOffer()}
        closeButtonAriaLabel="Close"
        isBlocking={false}
        onOuterClick={() => {}}
        aria-label="New Offer"
      >
        {/* <div>
              <PersistentNotification getNotifications={this.props.getNotifications} />
            </div> */}
       
        <div className="panel-content" role="main" aria-label="Offer Details">
          {!this.state.hideOfferStateConfirmation && (
            <MPDialog
              functionality={this.saveOffer}
              toHide={this.state.hideOfferStateConfirmation}
              toHideDialog={(toHide: boolean) => {
                this.setState({ hideOfferStateConfirmation: toHide });
              }}
              title={`Confirm`}
              subText={`Are you sure you want to change the offer state from 
                        ${
                          this.props.offerDetail.details?.Status ==
                          this.state.offerStates.active
                            ? "'Active' to 'Inactive' ?"
                            : "'Inactive' to 'Active' ?"
                        }`}
            />
          )}
          {!this.state.hideShowImageDialog && (
            <ShowOfferImageDialog
              toHide={this.state.hideShowImageDialog}
              toHideDialog={(toHide: boolean) => {
                this.setState({ hideShowImageDialog: toHide });
              }}
              title=""
              subText={this.state.viewImageLink}
            />
          )}
          <div className={`offer-form-content ${this.state.viewHelp}`}
        style={this.state.splitOfferWindow ? { minWidth: "50vw" } : {}}
          >
            <div className="offer-form-main-content">
            {this.state.allowFieldsEdit && (
          <div
          style={{ position: 'relative' }}
            onClick={this.toggleViewHelp}
          >
           
             
              <Icon iconName="Help" title="Help"  style={{ position: 'absolute', top: "-5px", right: "-5px", cursor: 'pointer' }}></Icon>
             
           
          </div>
        )}
              <div style={{paddingTop:"10px" }}>
                {[
                  this.state.offerStates.active,
                  this.state.offerStates.inactive,
                ].indexOf(this.props.offerDetail.details?.Status) > -1 && (
                  <div
                    className="offer-content-sub"
                    style={{ display: "flow-root" }}
                  >
                    <div className="activeOffer-toggle-div">
                      <label className="ms-Label activeOffer-label">
                        Active
                      </label>
                      <Toggle
                        checked={
                          this.state.offerStatus.value ==
                          this.state.offerStates.active
                        }
                        onText="Yes"
                        offText="No"
                        className="activeOffer-toggle"
                        onChange={(ev: any, checked?: boolean) => {
                          this.setState({
                            offerStatus: {
                              ...this.state.offerStatus,
                              value: !!checked
                                ? this.state.offerStates.active
                                : this.state.offerStates.inactive,
                            },
                            //isFieldModified:!checked
                          });
                        }}
                        disabled={
                          !this.props.userContext.roles?.find(
                            (role: any) =>
                              role.name == AppRoles.SiteAdmin.roleName
                          )
                        }
                      />
                    </div>
                  </div>
                )}
                <div className="offer-content-sub">
                  <div className="offer-clubbed-title">
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        marginTop: "4px",
                        marginRight: "5px",
                      }}
                    >
                      <label>Microsoft</label>
                    </div>
                    <div className="offer-detail-dash">-</div>
                    <div className="od-team">
                      <CustomComboBox
                        placeholder="Select an Option"
                        label="Team"
                        options={this.state.dropDownsData.team}
                        required={this.state.team.required}
                        errorMessage={this.state.team.errorMessage}
                        selectedKey={this.state.team.value?.key}
                        updateCDD={this.updateOrValidateTeam}
                        isFormValid={this.state.isFormValid}
                        disabled={
                          !this.props.userContext.roles?.find(
                            (role: any) =>
                              role.name == AppRoles.SiteAdmin.roleName
                          )
                        }
                      />
                    </div>
                    <div className="offer-detail-dash">-</div>
                    <div className="od-offer-title">
                      <CustomTextField
                        label="Offering Title"
                        placeholder="Offering title"
                        rows={4}
                        letterLimit={82}
                        required={this.state.offeringTitle.required}
                        value={this.state.offeringTitle.value}
                        errorMessage={this.state.offeringTitle.errorMessage}
                        onUpdateCTF={this.updateOrValidateOfferingTitle}
                        isCopyAllowed={true}
                        multiline={false}
                        isFormValid={this.state.isFormValid}
                        disabled={
                          !this.props.userContext.roles?.find(
                            (role: any) =>
                              role.name == AppRoles.SiteAdmin.roleName
                          )
                        }
                      />
                    </div>
                  </div>
                  {this.state.offeringTitle.value &&
                    this.state.team.value &&
                    (this.state.accessLevel.value?.length ?? 0) > 0 &&
                    this.state.duplicateRoleError && (
                      <div className="common-field-error">{`'Microsoft - {Team} - {Offering Title} ({Access Level})' should be unique`}</div>
                    )}
                </div>
                <div className="offer-content-sub">
                  <CustomTextField
                    label="Marketplace Preview"
                    rows={4}
                    value={`Microsoft - ${
                      this.state.team?.value?.text ?? ""
                    } - ${this.state.offeringTitle.value ?? ""}`}
                    onUpdateCTF={() => {}}
                    isCopyAllowed={true}
                    letterLimit={100}
                    multiline={false}
                    disabled={true}
                  />
                </div>
                <div className="offer-content-sub">
                  <CustomTextField
                    label="Offering Summary"
                    rows={4}
                    placeholder="Enter offering summary here"
                    letterLimit={100}
                    required={this.state.offeringSummary.required}
                    value={this.state.offeringSummary.value}
                    errorMessage={this.state.offeringSummary.errorMessage}
                    onUpdateCTF={this.updateOrValidateOfferSummary}
                    multiline={true}
                    isCopyAllowed={true}
                    isFormValid={this.state.isFormValid}
                    disabled={
                      !this.props.userContext.roles?.find(
                        (role: any) => role.name == AppRoles.SiteAdmin.roleName
                      )
                    }
                  />
                </div>
                <div className="offer-content-sub">
                  <CustomTextField
                    label="Offering Details"
                    rows={6}
                    placeholder="Enter offering details here"
                    letterLimit={3000}
                    required={this.state.offeringDetails.required}
                    value={this.state.offeringDetails.value}
                    errorMessage={this.state.offeringDetails.errorMessage}
                    onUpdateCTF={this.updateOrValidateOfferDetails}
                    isCopyAllowed={true}
                    multiline={true}
                    isFormValid={this.state.isFormValid}
                    disabled={
                      !this.props.userContext.roles?.find(
                        (role: any) => role.name == AppRoles.SiteAdmin.roleName
                      )
                    }
                  />
                </div>
                <div className="offer-content-sub">
                  <div className="support-only-offer">
                    <label className="ms-Label" style={{ fontWeight: "500" }}>
                      Support only Offer type
                    </label>
                    <Toggle
                      ariaLabel="Customer has existing Contract"
                      checked={this.state.isSupportOnly}
                      onText="Yes"
                      offText="No"
                      style={{ fontWeight: 100 }}
                      onChange={(ev: any, checked?: boolean) => {
                        this.setState({ isSupportOnly: !!checked });
                      }}
                      disabled={
                        !this.props.userContext.roles?.find(
                          (role: any) =>
                            role.name == AppRoles.SiteAdmin.roleName
                        )
                      }
                    />
                  </div>
                </div>
                <div className="offer-content-sub">
                  <CustomCheckBoxes
                    label="Access Levels"
                    items={this.state.dropDownsData.accessLevels}
                    groupName={"access_Levels"}
                    selectedCheckBoxes={this.state.accessLevel.value}
                    required={this.state.accessLevel.required}
                    errorMessage={this.state.accessLevel.errorMessage}
                    updateSelectedRB={this.updateOrValidateAccessLevel}
                    disabled={
                      (!this.state.allowFieldsEdit ||
                        !this.props.offerDetail.new) &&
                      !this.props.userContext.roles?.find(
                        (role: any) => role.name == AppRoles.SiteAdmin.roleName
                      )
                    }
                  />
                </div>
                <div className="offer-content-sub">
                  <CustomTextField
                    label="Offer Legal Terms"
                    rows={6}
                    placeholder="Enter offering legal terms here"
                    required={this.state.offerLegalTerms.required}
                    value={this.state.offerLegalTerms.value}
                    errorMessage={this.state.offerLegalTerms.errorMessage}
                    onUpdateCTF={this.updateOrValidateOfferLegalTerms}
                    multiline={true}
                    isCopyAllowed={this.props.userContext.roles?.find(
                      (role: any) => role.name == AppRoles.SiteAdmin.roleName
                    )}
                    isFormValid={this.state.isFormValid}
                    disabled={
                      !this.props.userContext.roles?.find(
                        (role: any) => role.name == AppRoles.SiteAdmin.roleName
                      )
                    }
                    sanitizeText={true}
                  />
                </div>
                <div className="offer-content-sub">
                  <CustomTextField
                    label="Marketplace Legal terms preview"
                    rows={2}
                    readOnly={true}
                    value={this.state.offerLegalTerms.value}
                    onUpdateCTF={() => {}}
                    multiline={true}
                    disabled={true}
                    isCopyAllowed={this.props.userContext.roles?.find(
                      (role: any) => role.name == AppRoles.SiteAdmin.roleName
                    )}
                    donotShowLetters={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`view-help-container ${this.state.viewHelp}`}>
            {this.renderHelpContent()}
          </div>
        </div>
      </Panel>
    );
  }
}

const buildDropDowns = (metaData: IColumn[]): IComboBoxOption[] => {
  const dropDownValues: IComboBoxOption[] = [
    {
      key: "Select an Option",
      text: "Select an Option",
      styles: { optionText: { fontStyle: "italic", color: "#424040" } },
      disabled: true,
    },
  ];
  metaData.forEach((column) => {
    dropDownValues.push({ key: column.key, text: column.key.toString() });
  });
  return dropDownValues;
};

const mapStateToProps = (state: { ui: any; auth: any; api: any }) => ({
  offerDetail: state.ui.offerDetail,
  user: state.auth.user,
  upsertOfferFromDetailState: state.api.upsertOfferFromDetail,
  pdfUrl: state.api.offersTermsAndCondition,
  getTCLinkState: state.api.offersTermsAndCondition,
  sendEmailToSelfState: state.api.sendEmailToSelf,
});

const mapDispatchToProps = (dispatch: any) => ({
  setBanner: (payload: IBannerMessagePayload) => dispatch(setBanner(payload)),
  upsertOfferFromDetail: (payload: any) =>
    dispatch(upsertOfferFromDetail(payload)),
  getOfferDetailsForDropDown: () => dispatch(getOfferDetailsForDropDown()),
  showRatingInCenter: (payload: IRatingPayload) =>
    dispatch(showRatingInCenter(payload)),
  getOffersTermsAndConditionLink: (payload: string) =>
    dispatch(getOffersTermsAndConditionLink(payload)),
  sendEmailToSelf: (payload: string) => dispatch(sendEmailToSelf(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetail);
