import React from "react";
import styles from "../notification/styles.module.css";
import {
  Icon,
  PrimaryButton,
  Spinner,
  Toggle,
  Callout,
  Text,
  mergeStyleSets,
  DelayedRender,
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { name } from "msal/lib-commonjs/packageMetadata";
import { axisRight } from "d3";
import { DirectionalHint } from "office-ui-fabric-react";

interface Props {
  tokenData: string;
  alias: string;
  subscriptionType: any;
  showPanel: Function;
  oDataFilter: string;
  telemetryData: any;
  commonServiceUrl: string;
}

interface State {
  notificationData: any;
  subscription: any;
  subscriptionToSave: any;
  checkboxType: string;
  spinnerStatus: boolean;
  enableAll: boolean;
  enableGroupItems: any;
  groupId: any;
  enableAllStarts: boolean;
}

class Notification extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      notificationData: [],
      subscription: [],
      subscriptionToSave: [],
      checkboxType: "",
      spinnerStatus: true,
      enableAll: false,
      groupId: 0,
      enableGroupItems: [
        {
          groupId: 0,
          itemId: 0,
          isChecked: false,
        },
      ],
      enableAllStarts: false,
    };
  }

  componentDidMount(): void {
    this.getSubscription();
    this.getNotificationData();
  }
  setChecked = () => {
    let notificationIds = this.state.notificationData.map(
      (i: any) => i.notificationRules
    );
    let checkedItems: any = [];
    notificationIds.forEach((i: any) => {
      i.map((j: any) => {
        let isCheckedItems = {
          groupId: j._csu_notificationrulegroup_value,
          notificationRuleId: j.csu_notificationruleid,
          isChecked:
            this.state.subscription.filter(
              (elem: any) =>
                elem._csu_notificationrule_value === j.csu_notificationruleid
            ).length > 0
              ? this.state.subscription
                  .filter(
                    (elem: any) =>
                      elem._csu_notificationrule_value ===
                      j.csu_notificationruleid
                  )
                  .map((k: any) => k.csu_emailenabled)[0]
              : false,
        };
        checkedItems.push(isCheckedItems);
      });
    });
    this.setState({
      enableGroupItems: checkedItems,
    });
  };
  getSubscription = () => {
    fetch(
      this.props.commonServiceUrl +
        "api/v1/Subscription?$filter=csu_email eq " +
        `'` +
        this.props.alias +
        `'`,
      {
        method: "GET",
        headers: {
          Authorization: this.props.tokenData,
          "Content-type": "application/json;",
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        this.setState(
          {
            subscription: json,
          },
          () => {
            let subsData: {
              csu_email: string;
              csu_emailenabled: boolean;
              csu_treamsenabled: boolean;
              csu_smsenabled: boolean;
              _csu_notificationrule_value: string;
            }[] = [];
            this.state.subscription.map((element: any) => {
              subsData.push({
                csu_email: this.props.alias,
                csu_emailenabled: element.csu_emailenabled,
                csu_treamsenabled: element.csu_teamsenabled,
                csu_smsenabled: element.csu_smsenabled,
                _csu_notificationrule_value:
                  element._csu_notificationrule_value,
              });
            });
            this.setState({
              subscriptionToSave: subsData,
            });
          }
        );
      })
      .catch((err) => console.log(err));
  };

  getNotificationData = () => {
    fetch(
      this.props.commonServiceUrl +
        "api/v1/NotificationRuleGroup?" +
        this.props.oDataFilter,
      {
        method: "GET",
        headers: {
          Authorization: this.props.tokenData,
          "Content-type": "application/json;",
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        let data = json.filter((elem: any) => elem.status == "Active");
        this.setState(
          {
            notificationData: data,
          },
          () => {
            this.setState(
              {
                spinnerStatus: false,
              },
              () => this.setChecked()
            );
          }
        );
      })
      .catch((err) => console.log(err));
  };

  saveNotificationSubscription = () => {
    fetch(this.props.commonServiceUrl + "api/v1/Subscription/batch", {
      method: "POST",
      headers: {
        Authorization: this.props.tokenData,
        "Content-type": "application/json;",
      },
      body: JSON.stringify(this.state.subscriptionToSave),
    })
      .then((response) => response.json())
      .then((json) => {
        this.props.showPanel(false);
        this.props.telemetryData(json);
      })
      .catch((err) => console.log(err));
  };

  onEnableDisableAllChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    val?: any
  ) => {
    let upSubs: any = [];
    let ids = this.state.subscriptionToSave.map(
      (i: any) => i._csu_notificationrule_value
    );
    ids.forEach((i: any) => {
      let data = {
        csu_email: this.props.alias,
        csu_emailenabled: val,
        csu_teamsenabled: val,
        csu_smsenabled: false,
        _csu_notificationrule_value: i,
      };
      upSubs.push(data);
    });

    this.setState({
      enableAll: this.state.enableAll == true ? false : true,
      enableGroupItems: this.state.enableAll == true ? false : true,
      enableAllStarts: true,
      subscriptionToSave: upSubs,
    });
  };

  onEnableDisableAllGroupItems = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    val?: any,
    notificationRuleGroupId?: string,
    checkboxType?: string
  ) => {
    let upSubs: any = [];
    let notificationIds = this.state.notificationData
      .filter(
        (i: any) => i.csu_notificationrulegroupid === notificationRuleGroupId
      )
      .map((i: any) => i.notificationRules);

    notificationIds[0].forEach((i: any) => {
      let data = {
        csu_email: this.props.alias,
        csu_emailenabled: val,
        csu_teamsenabled: val,
        csu_smsenabled: false,
        _csu_notificationrule_value: i.csu_notificationruleid,
      };
      upSubs.push(data);
    });
    this.setState({
      subscriptionToSave: upSubs,
    });
    let ids = this.state.notificationData.map((i: any) => i.notificationRules);
    let checkedItems: any = [];
    ids.forEach((i: any) => {
      i.map((j: any) => {
        let isCheckedItems = {
          groupId: j._csu_notificationrulegroup_value,
          notificationRuleId: j.csu_notificationruleid,
          isChecked:
            j._csu_notificationrulegroup_value === notificationRuleGroupId
              ? val
              : this.state.enableGroupItems
                  .filter(
                    (a: any) =>
                      a.notificationRuleId === j.csu_notificationruleid
                  )
                  .map((checked: any) => checked.isChecked)[0],
        };
        checkedItems.push(isCheckedItems);
      });
    });
    this.setState({
      enableGroupItems: checkedItems,
    });
  };

  onCheckboxChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean,
    notificationRuleId?: string,
    checkboxType?: string
  ) => {
    ev?.preventDefault();
    let toggleValue: any = [];
    // check if user is subscribed for any rule
    if (this.state.subscriptionToSave.length > 0) {
      // filter if any data exist for notification rule id
      let existingData = this.state.subscriptionToSave?.filter(
        (elem: any) => elem._csu_notificationrule_value == notificationRuleId
      );
      if (existingData.length > 0) {
        //update the flag for specific property
        let updatedData = [
          {
            csu_email: this.props.alias,
            csu_emailenabled:
              checkboxType == "csu_emailenabled"
                ? isChecked
                : existingData[0].csu_emailenabled,
            csu_teamsenabled:
              checkboxType == "csu_teamsenabled"
                ? isChecked
                : existingData[0].csu_teamsenabled,
            csu_smsenabled:
              checkboxType == "csu_smsenabled"
                ? isChecked
                : existingData[0].csu_smsenabled,
            _csu_notificationrule_value:
              existingData[0]._csu_notificationrule_value,
          },
        ];

        this.setState({
          subscriptionToSave: updatedData,
          enableAllStarts: true,
        });
      } else {
        // create the object to add the notification subscription
        let newSubs = {
          csu_email: this.props.alias,
          csu_emailenabled:
            checkboxType == "csu_emailenabled" ? isChecked : false,
          csu_teamsenabled:
            checkboxType == "csu_teamsenabled" ? isChecked : false,
          csu_smsenabled: checkboxType == "csu_smsenabled" ? isChecked : false,
          _csu_notificationrule_value: notificationRuleId,
        };

        let existingData = this.state.subscriptionToSave;
        existingData.push(newSubs);
        let toggle = {
          itemId: notificationRuleId,
          isChecked: isChecked,
        };
        toggleValue.push(toggle);
        this.setState({
          enableGroupItems: toggleValue,
          subscriptionToSave: existingData,
        });
      }
      let notificationIds = this.state.notificationData.map(
        (i: any) => i.notificationRules
      );
      let checkedItems: any = [];
      notificationIds.forEach((i: any) => {
        i.map((j: any) => {
          let isCheckedItems = {
            groupId: j._csu_notificationrulegroup_value,
            notificationRuleId: j.csu_notificationruleid,
            isChecked:
              j.csu_notificationruleid === notificationRuleId
                ? isChecked
                : this.state.enableGroupItems
                    .filter(
                      (a: any) =>
                        a.notificationRuleId === j.csu_notificationruleid
                    )
                    .map((checked: any) => checked.isChecked)[0],
          };
          checkedItems.push(isCheckedItems);
        });
      });
      this.setState({
        enableGroupItems: checkedItems,
      });
    }
  };

  notificationGroupCallout = (description: string) => () => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
      useBoolean(false);
    const buttonId = useId("callout-button");
    const labelId = useId("callout-label");
    const descriptionId = useId("callout-description");
    const styles = mergeStyleSets({
      callout: {
        width: 320,
        maxWidth: "90%",
        padding: "20px 24px",
      },
      title: {
        marginBottom: 12,
      },
    });
    return (
      <>
        <Icon
          iconName="Info"
          onMouseEnter={toggleIsCalloutVisible}
          onMouseOut={toggleIsCalloutVisible}
          id={buttonId}
          style={{ cursor: "pointer", color: "#90968d" }}
        />
        {isCalloutVisible && (
          <Callout
            className={styles.callout}
            directionalHint={DirectionalHint.rightTopEdge}
            ariaLabelledBy={labelId}
            ariaDescribedBy={descriptionId}
            gapSpace={0}
            target={`#${buttonId}`}
            onDismiss={toggleIsCalloutVisible}
            setInitialFocus
          >
            <Text variant="small">{description}</Text>
          </Callout>
        )}
      </>
    );
  };

  getNotiItems = () => {
    return (
      <div className={styles.notiItems}>
        <div className={styles.headingText}></div>
        {this.state.notificationData.map((noti: any, ind: number) => {
          const NotificationGroupCallout = this.notificationGroupCallout(
            noti.csu_description
          );
          let isGroupChecked = this.state.enableGroupItems
            .filter((j: any) => j.groupId === noti.csu_notificationrulegroupid)
            .map((i: any) => i.isChecked);
          let enableAll = isGroupChecked.includes(false) ? false : true;

          return (
            <div style={{ paddingBottom: "30px" }} key={ind}>
              <div style={{ color: "blue", display: "flex" }}>
                <span style={{ flex: 3 }}>
                  {" "}
                  {noti.csu_name} <NotificationGroupCallout />
                </span>

                {this.props.subscriptionType.includes("csu_emailenabled") &&
                  enableAll && (
                    <Toggle
                      onText="On"
                      offText="Off"
                      checked={enableAll}
                      onChange={(event: any, val: any) => {
                        this.onEnableDisableAllGroupItems(
                          event,
                          val,
                          noti.csu_notificationrulegroupid,
                          "csu_emailenabled"
                        );
                      }}
                    />
                  )}
                {!enableAll && (
                  <Toggle
                    onText="On"
                    offText="Off"
                    checked={enableAll}
                    onChange={(event: any, val: any) => {
                      this.onEnableDisableAllGroupItems(
                        event,
                        val,
                        noti.csu_notificationrulegroupid,
                        "csu_emailenabled"
                      );
                    }}
                  />
                )}
              </div>
              <div style={{ color: "black" }}>
                {noti.notificationRules.map((i: any, ind: number) => (
                  <div
                    style={{ display: "flex" }}
                    key={i.csu_notificationruleid}
                  >
                    <span style={{ flex: 3 }}> {i.csu_description}</span>
                    {this.state.enableGroupItems
                      .filter(
                        (j: any) =>
                          j.notificationRuleId === i.csu_notificationruleid
                      )
                      .map((checked: any) => checked.isChecked)[0] && (
                      <Toggle
                        onText="On"
                        offText="Off"
                        checked={
                          !this.state.enableGroupItems
                            .filter(
                              (j: any) => j.itemId === i.csu_notificationruleid
                            )
                            .map((checked: any) => checked.isChecked)[0]
                        }
                        onChange={(event: any, val: any) => {
                          this.onCheckboxChange(
                            event,
                            val,
                            i.csu_notificationruleid,
                            "csu_emailenabled"
                          );
                        }}
                      />
                    )}
                    {!this.state.enableGroupItems
                      .filter(
                        (j: any) =>
                          j.notificationRuleId === i.csu_notificationruleid
                      )
                      .map((checked: any) => checked.isChecked)[0] && (
                      <Toggle
                        onText="On"
                        offText="Off"
                        checked={
                          this.state.enableGroupItems
                            .filter(
                              (j: any) => j.itemId === i.csu_notificationruleid
                            )
                            .map((checked: any) => checked.isChecked)[0]
                        }
                        onChange={(event: any, val: any) => {
                          this.onCheckboxChange(
                            event,
                            val,
                            i.csu_notificationruleid,
                            "csu_emailenabled"
                          );
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  render() {
    if (this.state.spinnerStatus) {
      return (
        <div>
          <Spinner label="Please wait..." />
        </div>
      );
    } else {
      return (
        <div className={styles.notificationCompBody}>
          <div className={styles.headerComp}>
            <div className={styles.headingText}>
              Set your preferences for email notifications
            </div>
            {this.props.subscriptionType.includes("csu_emailenabled") && (
              <div className={styles.checkBoxBody}>
                <Icon iconName="Mail" />
                {/* <Toggle
                            onText="On" offText="Off"
                            checked={this.state.enableAll}
                            onChange={(event: any, val: any) => {
                                this.onEnableDisableAllChange(event, val)
                            }}
                            className={styles.enableAll}
                        /> */}
              </div>
            )}
            {this.props.subscriptionType.includes("csu_teamsenabled") && (
              <div className={styles.checkBoxBody}>
                <Icon iconName="TeamsLogo16" />
              </div>
            )}
            {this.props.subscriptionType.includes("csu_smsenabled") && (
              <div className={styles.checkBoxBody}>
                <Icon iconName="Message" />
              </div>
            )}
          </div>
          {this.getNotiItems()}
          <PrimaryButton
            text="Update Preferences"
            style={{ bottom: "15px", position: "absolute", right: "15px" }}
            onClick={() => {
              this.saveNotificationSubscription();
            }}
          ></PrimaryButton>
        </div>
      );
    }
  }
}

export default Notification;
