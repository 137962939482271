export interface IRating {
    ratingValue: number,
    ratingType: "CSAT 1-5" | "CSAT 1-10";
    version: number;
    comments: string;
    createdBy: string;
    createdDate: string;
}

export const SAVING_RATING : string = "SAVING_RATING";
export const SAVING_RATING_SUCCESS : string = "SAVING_RATING_SUCCESS";
export const SAVING_RATING_FAILURE : string = "SAVING_RATING_FAILURE";
export const rating_V1 = 1;
export const CSAT_1to5 = "CSAT 1-5";
export const CSAT_1to10 = "CSAT 1-10";


