import { IAction } from "../../constants/types/IAction";
import { SHOW_RATING_IN_CENTER } from "../../constants/actions/UI/Rating";
import { IRatingPayload } from "../../constants/types/UI/Rating";
import _ from "lodash";

export const initialState: IRatingPayload = {
  showInCenter: false,
};

export const RatingStyleReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SHOW_RATING_IN_CENTER:
      return _.cloneDeep(action.payload);
    default:
      return state;
  }
};
