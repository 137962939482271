import { TOGGLE_SPINNER } from '../../constants/actions/UI/globalSpinner';
import { IAction } from '../../constants/types/IAction';

export const initialState= {
    spinner : true,
    spinnerMessage : "We are getting Cape Portal ready for you..."
}

export const SpinnerReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case TOGGLE_SPINNER:
            return action.payload    
        default:
            return state;
    }
}