import React, { Component } from "react";
import { MPMessageBar } from "../utils/MPMessageBar";
import AppConstants, { AppRoles } from "../../constants/AppConstants";
import {
  IBannerNotifications,
  INotifications,
} from "../../constants/types/UI/Notifications";
import { IDataResponse } from "../../constants/types/API/Apiresponse";
import { IconButton, MessageBarType } from "office-ui-fabric-react";
import { logEvent, NotificationClick } from "../../utils/insightsClient";
import { IUserContext } from "../../constants/types/UI/Generic";

interface IPersistentNotificationProps {
  getNotifications: IDataResponse;
  userContext: IDataResponse<IUserContext>;
}

interface IPersistentNotificationState {
  toShow: boolean;
  NotificationsData: INotifications[];
  bannerNotifications: INotifications[];
  showBannerNotifications: IBannerNotifications[];
}

class PersistentNotification extends Component<
  IPersistentNotificationProps,
  IPersistentNotificationState
> {
  constructor(props: IPersistentNotificationProps) {
    super(props);
    this.state = {
      toShow: true,
      NotificationsData: [],
      bannerNotifications: [],
      showBannerNotifications: [],
    };
  }

  componentDidMount() {
    // if (!!(AppConstants.PersistentNotification?.timeOut)) {
    //     setTimeout(() => {
    //         this.setState({
    //             toShow: false
    //         })
    //     }, AppConstants.PersistentNotification.timeOut)
    // }

    this.setState(
      {
        NotificationsData: this.props.getNotifications?.data || [],
      },
      () => this.getNotificationsData()
    );
  }
  
  componentDidUpdate(prevProps:any, prevState:any) {
    if (prevState.NotificationsData !== this.state.NotificationsData) {
      this.getNotificationsData();
    }
  }
  getbanner() {
    const bannerNotifications: IBannerNotifications[] = [];
    this.state.bannerNotifications.length > 0 && this.state.bannerNotifications?.forEach((i) => {
      bannerNotifications.push({
        Id: i.Id,
        Show: true,
        EndDateTime: i.Data.EndDateTime,
        PortalNotification: {
          BadgeType: i.Data.PortalNotification.BadgeType,
          IsBanner: i.Data.PortalNotification.IsBanner,
          Content: i.Data.PortalNotification.Content,
        },
      });
    });
      this.setState({
        showBannerNotifications: bannerNotifications,
      
      });
  }
  getNotificationsData() {
    if(this.state.NotificationsData && this.state.NotificationsData.length > 0)
      {
    let bannerNotifications = this.state.NotificationsData.filter(
      (i) => i.Data.PortalNotification.IsBanner === true
    );
    this.setState(
      {
        bannerNotifications: bannerNotifications,
      },
      () => this.getbanner()
    );
  }
  }
  dismissNotification(id: string | undefined) {
    let filteredNotification = this.state.showBannerNotifications.map((i) => {
      if (i.Id === id) {
        return { ...i, Show: false };
      }
      return i;
    });
    this.setState({ showBannerNotifications: filteredNotification });
    logEvent(NotificationClick, {
      AppName: AppConstants.ApplicationAlias,
      AppLayer: "UI",
      metricName: "Portal Notification",
      UserScenario: "User acting on CAPE Banner Notifications",
      User: { Alias: this.props.userContext.data?.email },
      applicationRoles: this.props.userContext.data?.roles,
      entityOperation: "Close",
      entityName: "Notification",
      metaData: {
        notificationId: id,
      },
      traceSeverity: "Low",
    });
  }
  render() {
    const { showBannerNotifications,toShow } = this.state;
    if (!toShow) {
      return <div>Loading...</div>;
    }
    return (
      <>
        <div className="persistent-banner-container">
          { (this.props.userContext.data?.roles.length === 0 ||
            this.props.userContext.data?.scopes.length === 0) && 
            <MPMessageBar messageType={MessageBarType.warning}>To use the CAPE portal, complete the compliance training, then use the 'Request Access' button below </MPMessageBar> }      
          {showBannerNotifications.length > 0 &&
            showBannerNotifications.map((notification) => {
              return (
                <>
                  {(notification.EndDateTime === null ||
                    notification.EndDateTime >= new Date().toISOString()) && (
                    <div key={notification.Id}>
                      {notification.Show && (
                        <MPMessageBar
                          className={
                            notification.PortalNotification.BadgeType ===
                            "Info"
                              ? "persistentBanner-message-style"
                              : "banner-message-style"
                          }
                          isMultiLine={false}
                          onDismiss={() => {
                            this.dismissNotification(notification.Id);
                          }}
                          messageType={
                            notification.PortalNotification.BadgeType ===
                            "Info"
                              ? MessageBarType.info
                              : notification.PortalNotification.BadgeType ===
                                "Warning"
                              ? MessageBarType.warning
                              : notification.PortalNotification.BadgeType ===
                                "Critical"
                              ? MessageBarType.error
                              : MessageBarType.severeWarning
                          }
                        >
                          <div key={notification.Id}>
                            <div
                              className="banner-message-style"
                              dangerouslySetInnerHTML={{
                                __html: notification.PortalNotification.Content,
                              }}
                            />
                          </div>
                        </MPMessageBar>
                      )}
                    </div>
                  )}
                </>
              );
            })}
        </div>
        {/* {this.state.toShow && <MPMessageBar
                    className={'banner-message-style'}
                    isMultiLine={true}
                    onDismiss={() => { this.setState({ toShow: false }) }}
                    messageType={AppConstants.PersistentNotification.messageType}
                >
                    <div>
                        <div className="banner-message-style" dangerouslySetInnerHTML={{ __html: "NOTE: CAPE has a dependency on Lighthouse which has a limitation on data-plane engagement access in customer subscriptions. Read more about this limitation" + `<a target="_blank" href="https://docs.microsoft.com/en-us/azure/lighthouse/concepts/cross-tenant-management-experience#current-limitations">` + "here" + "</a>" }} />
                    </div>

                </MPMessageBar>} */}
      </>
    );
  }
}

export default PersistentNotification;
