import {
  SIGN_IN, SIGN_OUT, GET_USER_PHOTO, GET_API_ACCESS_TOKEN,
  REAQUIRE_API_ACCESS_TOKEN, GET_POWER_BI_ACCESS_TOKEN,
  GET_CORE_API_ACCESS_TOKEN,
  GET_COMMON_SERVICE_TOKEN
} from '../constants/actions/Auth/auth';
import { IAction } from '../constants/types/IAction';

export function signIn(): IAction {
  return {
    type: SIGN_IN
  }
}

export function getUserPhoto(payload: any): IAction {
  return {
    type: GET_USER_PHOTO,
    payload: payload
  }
}

export function getApiAccessToken(): IAction {
  return {
    type: GET_API_ACCESS_TOKEN
  }
}

export function getPowerBIApiAccessToken(): IAction {
  return {
    type: GET_POWER_BI_ACCESS_TOKEN
  }
}

export function getCoreApiAccessToken(): IAction {
  return {
    type: GET_CORE_API_ACCESS_TOKEN
  }
}

export function getCommonServiceToken(): IAction {
  return {
    type: GET_COMMON_SERVICE_TOKEN
  }
}

export function reAquireAccessToken(): IAction {
  return {
    type: REAQUIRE_API_ACCESS_TOKEN
  }
}

export const signOut = () => (
  {
    type: SIGN_OUT
  }
);