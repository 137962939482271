import React, { Component } from "react";
import "./LandingComponent.css";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { connect } from "react-redux";
import { ENGAGEMENTS_URL } from "../../constants/routes";
import { IAppScopes, IUserContext } from "../../constants/types/UI/Generic";
import AppConstants, {
  AppRoles,
  entityQueryString,
} from "../../constants/AppConstants";
import {
  ActionTrackingEngagement,
  logEvent,
  Navigation,
  ProgramReferal,
} from "../../utils/insightsClient";
import {
  DirectionalHint,
  ITooltipHostStyles,
  TooltipHost,
} from "@fluentui/react";
import { Account } from "msal";
import { parseQS } from "../../utils/helperFunctions";
import { IDataResponse } from "../../constants/types/API/Apiresponse";
import { DefaultButton } from "office-ui-fabric-react";

interface ILandingCompHolderProps {
  history: any;
  location: any;
  appScopes: IAppScopes;
  newUser: boolean;
  user: Account;
  userContext: IDataResponse<IUserContext>;
}

const tabs = {
  PRODUCT_OVERVIEW: "Product Overview",
  GETTING_STARTED: "Getting Stated",
  COMPLIANCE_TRAINING: "Compliance Training",
  CUSTOMER_GUIDE: "Customer Guild",
  USER_GUIDE: "User Guide",
  FAQ: "Faq",
  RELEASE_NOTES: "Release Notes",
};

interface ILandingCompHolderState {
  source: string;
  activeTab: string;
  copilotPinnedState: boolean;
}

const ttstyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};
const ttcalloutProps = { gapSpace: 0 };

// class PureIframe extends React.PureComponent<ILandingCompHolderState> {

//     // constructor() {
//     //     // super(props);
//     //     this.state = {
//     //        source:"div onClick"
//     //     }
//     // }
//     // // state = {
//     // //     source:"https://microsoft.sharepoint.com/teams/CS_CAPE/SitePages/newhome.aspx"
//     // //  }

//     render() {
//         return (
//             <iframe className="cape-sp" src={this.state.source}  />
//         );
//     }
// }

function getCopilotPinnedStateFromSessionStorage(): boolean {
  const item = sessionStorage.getItem("copilotPinnedState");
  if (item) {
    return JSON.parse(item);
  }

  sessionStorage.setItem("copilotPinnedState", String(true));
  return true;
}

class LandingComponentHolder extends Component<
  ILandingCompHolderProps,
  ILandingCompHolderState
> {
  constructor(props: ILandingCompHolderProps) {
    super(props);
    this.state = {
      source:
        "https://microsoft.sharepoint.com/teams/CS_CAPE/SitePages/NewHome.aspx#automated-publication-of-private-offers-for-resource-delegation.",
      activeTab: tabs.PRODUCT_OVERVIEW,
      copilotPinnedState: getCopilotPinnedStateFromSessionStorage(),
    };

    if (this.props.location.search) {
      const parsedQS = parseQS(this.props.location.search);
      var engProgramName =
        parsedQS != null ? parsedQS[entityQueryString.ProgramName] : "";
      if (engProgramName) {
        logEvent(ProgramReferal, {
          AppName: AppConstants.ApplicationAlias,
          AppLayer: "UI",
          metricName: "Program Referal",
          UserScenario: "User Landing on CAPE portal from a referal site",
          User: { Alias: this.props.userContext.data?.email },
          applicationRoles: this.props.userContext.data?.roles,
          entityOperation: "Click",
          entityName: "Program",
          metaData: {
            landingPage: "Home",
            queryString: parsedQS,
            programName: engProgramName,
          },
          traceSeverity: "Important",
        });
      }
    }
  }

  componentDidMount() {
    window.addEventListener("storage", this.handleStorageChange, false);
  }

  createEngagement = () => {
    sessionStorage.setItem("showCopilotWindow", String(false));
    sessionStorage.setItem("engagementPanelOpen", String(true));
    window.dispatchEvent(new Event("storage"));
    const parsedQS = parseQS(this.props.location.search);
    var engProgramName =
      parsedQS != null ? parsedQS[entityQueryString.ProgramName] : "";
    logEvent(ActionTrackingEngagement, {
      AppName: AppConstants.ApplicationAlias,
      AppLayer: "UI",
      metricName: "CreateEngagement",
      UserScenario: "User Clicks on Create Engagement Button",
      User: { Alias: this.props.userContext.data?.email },
      applicationRoles: this.props.userContext.data?.roles,
      entityOperation: "Create",
      entityName: "",
      metaData: {
        sourcePage: "Home",
        programName: engProgramName ? engProgramName : null,
      },
      traceSeverity: "Low",
    });
    this.props.history.push(
      `/${ENGAGEMENTS_URL}?id=new${this.props.history["location"][
        "search"
      ].replace("?", "&")}`
    );
  };

  renderInnerHtml = (htmlString: string) => {
    const container = <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
    return container;
  };

  setSource = (newlink: string) => {
    this.setState({
      source: newlink,
    });
  };

  setActiveTab = (tab: string) => {
    this.setState({
      activeTab: tab,
    });
  };

  clickAnchorHandler = (e: any, link: any) => {
    const parsedQS = parseQS(this.props.location.search);
    var engProgramName =
      parsedQS != null ? parsedQS[entityQueryString.ProgramName] : "";
    e.preventDefault();
    var a = document.createElement("a");
    document.body.appendChild(a);
    (a as HTMLElement).style.display = "none";
    a.href = link;
    a.setAttribute("target", "_blank");
    a.click();
    logEvent(Navigation, {
      AppName: AppConstants.ApplicationAlias,
      AppLayer: "UI",
      MetricName: "Navigation",
      UserScenario: "User navigation",
      User: { Alias: this.props.userContext.data?.email },
      ApplicationRoles: this.props.userContext.data?.roles,
      EntityOperation: "Click",
      EntityName: "Button",
      MetaData: {
        item: "Contact Us",
        programName: engProgramName ? engProgramName : null,
      },
      traceSeverity: "Low",
    });
    document.body.removeChild(a);
  };

  handleStorageChange = () => {
    const isCopilotPinned = sessionStorage.getItem("copilotPinnedState");
    if (isCopilotPinned) {
      const parsedData = JSON.parse(isCopilotPinned);
      this.setState({ copilotPinnedState: parsedData });
      return;
    }
  };

  render() {
    const parsedQS = parseQS(this.props.location.search);
    var engProgramName =
      parsedQS != null ? parsedQS[entityQueryString.ProgramName] : "";
    return (
      <div className="landingcomponent-holder">
        <div className="landing-page-header">
          <h1 className="primary-name" aria-label="Cape">
            CAPE
          </h1>
          <div className="secondary-name">
            <div className="secondary-name-sub">
              Customer Acceleration Portal for Engagements
            </div>
          </div>
          <div className="lp-header-buttons">
            {this.props.appScopes.Engagements.Create && (
              <div className="lp-header-enagagement">
                <TooltipHost
                  directionalHint={DirectionalHint.bottomCenter}
                  tooltipProps={{
                    onRenderContent: () => (
                      <div>Create a new engagement in CAPE</div>
                    ),
                  }}
                  calloutProps={ttcalloutProps}
                  closeDelay={200}
                  styles={ttstyles}
                >
                  <PrimaryButton
                    text="Create Engagement"
                    onClick={this.createEngagement}
                    // styles={{
                    //   label: {
                    //     fontSize: "14px",
                    //     fontWeight: 600,
                    //     letterSpacing: "0.7px",
                    //     padding: "7px 20px",
                    //     color: "black",
                    //   },
                    //   root: {
                    //     backgroundColor: "#8ac402",
                    //     border: "1px solid #8ac402",
                    //   },
                    // }}
                  />
                </TooltipHost>
              </div>
            )}
            {this.props.newUser && (
              <div className="lp-header-enagagement">
                <TooltipHost
                  directionalHint={DirectionalHint.bottomCenter}
                  tooltipProps={{
                    onRenderContent: () => <div>Request Access</div>,
                  }}
                  calloutProps={ttcalloutProps}
                  closeDelay={200}
                  styles={ttstyles}
                >
                  <PrimaryButton
                    text="Request Access"
                    onClick={(e) => {
                      this.clickAnchorHandler(
                        e,
                        AppConstants.RequestAccessLink
                      );
                    }}
                    // styles={{
                    //   label: {
                    //     fontSize: "14px",
                    //     fontWeight: 600,
                    //     letterSpacing: "0.7px",
                    //     padding: "7px 20px",
                    //     color: "black",
                    //   },
                    //   root: {
                    //     backgroundColor: "#8ac402",
                    //     border: "1px solid #8ac402",
                    //   },
                    // }}
                  />
                </TooltipHost>
              </div>
            )}

            <div className="lp-header-contactus">
              <TooltipHost
                directionalHint={DirectionalHint.bottomCenter}
                tooltipProps={{
                  onRenderContent: () => (
                    <div>Contact the CAPE Team via Email</div>
                  ),
                }}
                calloutProps={ttcalloutProps}
                closeDelay={200}
                styles={ttstyles}
              >
                <DefaultButton
                  text="Contact Us"
                  // styles={{
                  //   label: {
                  //     fontSize: "14px",
                  //     fontWeight: 600,
                  //     letterSpacing: "0.7px",
                  //     padding: "7px 35px",
                  //     // color: "white",
                  //   },
                  //   root: {
                  //     // backgroundColor: "#1a1b20",
                  //     border: "1px solid white",
                  //   },
                  // }}
                  onClick={(e) => {
                    this.clickAnchorHandler(e, AppConstants.ContactLink);
                  }}
                />
              </TooltipHost>
            </div>
          </div>
          <div
            className={
              this.state.copilotPinnedState === true
                ? "lp-pinned-navigator"
                : "lp-navigator"
            }
          >
            <div style={{ position:'relative',left:"10px"}}>
            <TooltipHost
              directionalHint={DirectionalHint.bottomCenter}
              tooltipProps={{
                onRenderContent: () => <div>Product Overview</div>,
              }}
              calloutProps={ttcalloutProps}
              closeDelay={200}
              styles={ttstyles}
            >
              <div
                className={
                  this.state.activeTab === tabs.PRODUCT_OVERVIEW
                    ? "navigator-items active-nav-item"
                    : "navigator-items"
                }
                style={{ left:"10vw" }}
              >
                <a
                  href="javascript:void(0)"
                  title="Product Overview"
                  id="1-CAPE-Home-SharepointMenu-Product-Overview"
                  onClick={() => {
                    this.setActiveTab(tabs.PRODUCT_OVERVIEW);
                    this.setSource(
                      "https://microsoft.sharepoint.com/teams/CS_CAPE/SitePages/NewHome.aspx#what-is-cape"
                    );
                    logEvent(Navigation, {
                      AppName: "CAPE",
                      AppLayer: "UI",
                      MetricName: "SharePointTabNavigation",
                      UserScenario: "User navigation",
                      User: { Alias: this.props.userContext.data?.email },
                      ApplicationRoles: this.props.userContext.data?.roles,
                      EntityOperation: "Click",
                      EntityName: "Product Overview",
                      MetaData: {
                        programName: engProgramName ? engProgramName : null,
                      },
                      traceSeverity: "Low",
                    });
                  }}
                >
                  Product Overview
                </a>
              </div>
            </TooltipHost>
            <TooltipHost
              directionalHint={DirectionalHint.bottomCenter}
              tooltipProps={{
                onRenderContent: () => <div>Getting Started</div>,
              }}
              calloutProps={ttcalloutProps}
              closeDelay={200}
              styles={ttstyles}
            >
              <div
                className={
                  this.state.activeTab === tabs.GETTING_STARTED
                    ? "navigator-items active-nav-item"
                    : "navigator-items"
                }
              >
                <a
                  href="javascript:void(0)"
                  title="Getting Started"
                  id="2-CAPE-Home-SharepointMenu-Getting-Started"
                  onClick={() => {
                    this.setActiveTab(tabs.GETTING_STARTED);
                    this.setSource(
                      "https://microsoft.sharepoint.com/teams/CS_CAPE/SitePages/NewHome.aspx#getting-started"
                    );
                    logEvent(Navigation, {
                      AppName: "CAPE",
                      AppLayer: "UI",
                      MetricName: "SharePointTabNavigation",
                      UserScenario: "User navigation",
                      User: { Alias: this.props.userContext.data?.email },
                      ApplicationRoles: this.props.userContext.data?.roles,
                      EntityOperation: "Click",
                      EntityName: "Getting Started",
                      MetaData: {
                        programName: engProgramName ? engProgramName : null,
                      },
                      traceSeverity: "Low",
                    });
                  }}
                >
                  Getting Started
                </a>
              </div>
            </TooltipHost>
            <TooltipHost
              directionalHint={DirectionalHint.bottomCenter}
              tooltipProps={{
                onRenderContent: () => <div>Compliance Training</div>,
              }}
              calloutProps={ttcalloutProps}
              closeDelay={200}
              styles={ttstyles}
            >
              <div
                className={
                  this.state.activeTab === tabs.COMPLIANCE_TRAINING
                    ? "navigator-items active-nav-item"
                    : "navigator-items"
                }
              >
                <a
                  href="javascript:void(0)"
                  title="Compliance Training"
                  id="3-CAPE-Home-SharepointMenu-Compliance-Training"
                  onClick={() => {
                    this.setActiveTab(tabs.COMPLIANCE_TRAINING);
                    this.setSource(
                      "https://microsoft.sharepoint.com/teams/CS_CAPE/SitePages/NewHome.aspx#requirements-for-microsoft-employee-participation"
                    );
                    logEvent(Navigation, {
                      AppName: "CAPE",
                      AppLayer: "UI",
                      MetricName: "SharePointTabNavigation",
                      UserScenario: "User navigation",
                      User: { Alias: this.props.userContext.data?.email },
                      ApplicationRoles: this.props.userContext.data?.roles,
                      EntityOperation: "Click",
                      EntityName: "Compliance Training",
                      MetaData: {
                        programName: engProgramName ? engProgramName : null,
                      },
                      traceSeverity: "Low",
                    });
                  }}
                >
                  Compliance Training
                </a>
              </div>
            </TooltipHost>
            <TooltipHost
              directionalHint={DirectionalHint.bottomCenter}
              tooltipProps={{
                onRenderContent: () => <div>User Guide</div>,
              }}
              calloutProps={ttcalloutProps}
              closeDelay={200}
              styles={ttstyles}
            >
              <div
                className={
                  this.state.activeTab === tabs.USER_GUIDE
                    ? "navigator-items active-nav-item"
                    : "navigator-items"
                }
              >
                <a
                  href="javascript:void(0)"
                  title="User Guide"
                  id="4-CAPE-Home-SharepointMenu-User-Guide"
                  onClick={() => {
                    this.setActiveTab(tabs.USER_GUIDE);
                    this.setSource(
                      "https://microsoft.sharepoint.com/teams/CS_CAPE/SitePages/NewHome.aspx#cape-user-guide"
                    );
                    logEvent(Navigation, {
                      AppName: "CAPE",
                      AppLayer: "UI",
                      MetricName: "SharePointTabNavigation",
                      UserScenario: "User navigation on CAPE Home page",
                      User: { Alias: this.props.userContext.data?.email },
                      ApplicationRoles: this.props.userContext.data?.roles,
                      EntityOperation: "Click",
                      EntityName: "User Guide",
                      MetaData: {
                        programName: engProgramName ? engProgramName : null,
                      },
                      traceSeverity: "Low",
                    });
                  }}
                >
                  User Guide
                </a>
              </div>
            </TooltipHost>
            <TooltipHost
              directionalHint={DirectionalHint.bottomCenter}
              tooltipProps={{
                onRenderContent: () => <div>Customer Guide</div>,
              }}
              calloutProps={ttcalloutProps}
              closeDelay={200}
              styles={ttstyles}
            >
              <div
                className={
                  this.state.activeTab === tabs.CUSTOMER_GUIDE
                    ? "navigator-items active-nav-item"
                    : "navigator-items"
                }
              >
                <a
                  href="javascript:void(0)"
                  title="Customer Guide"
                  id="5-CAPE-Home-SharepointMenu-Customer-Guide"
                  onClick={() => {
                    this.setActiveTab(tabs.CUSTOMER_GUIDE);
                    this.setSource(
                      "https://microsoft.sharepoint.com/teams/CS_CAPE/SitePages/NewHome.aspx#customer-guide"
                    );
                    logEvent(Navigation, {
                      AppName: "CAPE",
                      AppLayer: "UI",
                      MetricName: "SharePointTabNavigation",
                      UserScenario: "User navigation",
                      User: { Alias: this.props.userContext.data?.email },
                      ApplicationRoles: this.props.userContext.data?.roles,
                      EntityOperation: "Click",
                      EntityName: "Customer Guide",
                      MetaData: {
                        programName: engProgramName ? engProgramName : null,
                      },
                      traceSeverity: "Low",
                    });
                  }}
                >
                  Customer Guide
                </a>
              </div>
            </TooltipHost>
            <TooltipHost
              directionalHint={DirectionalHint.bottomCenter}
              tooltipProps={{
                onRenderContent: () => <div>FAQs</div>,
              }}
              calloutProps={ttcalloutProps}
              closeDelay={200}
              styles={ttstyles}
            >
              <div
                className={
                  this.state.activeTab === tabs.FAQ
                    ? "navigator-items active-nav-item"
                    : "navigator-items"
                }
              >
                <a
                  href="javascript:void(0)"
                  title="Frequently Asked Questions"
                  id="6-CAPE-Home-SharepointMenu-FAQs"
                  onClick={() => {
                    this.setActiveTab(tabs.FAQ);
                    this.setSource(
                      "https://microsoft.sharepoint.com/teams/CS_CAPE/SitePages/NewHome.aspx#cape-faq"
                    );
                    logEvent(Navigation, {
                      AppName: "CAPE",
                      AppLayer: "UI",
                      MetricName: "SharePointTabNavigation",
                      UserScenario: "User navigation",
                      User: { Alias: this.props.userContext.data?.email },
                      ApplicationRoles: this.props.userContext.data?.roles,
                      EntityOperation: "Click",
                      EntityName: "FAQs",
                      MetaData: {
                        programName: engProgramName ? engProgramName : null,
                      },
                      traceSeverity: "Low",
                    });
                  }}
                >
                  FAQs
                </a>
              </div>
            </TooltipHost>
            <TooltipHost
              directionalHint={DirectionalHint.bottomCenter}
              tooltipProps={{
                onRenderContent: () => <div>Release Notes</div>,
              }}
              calloutProps={ttcalloutProps}
              closeDelay={200}
              styles={ttstyles}
            >
              <div
                className={
                  this.state.activeTab === tabs.RELEASE_NOTES
                    ? "navigator-items active-nav-item"
                    : "navigator-items"
                }
              >
                <a
                  href="javascript:void(0)"
                  title="Release Notes"
                  id="7-CAPE-Home-SharepointMenu-Release-Notes"
                  onClick={() => {
                    this.setActiveTab(tabs.RELEASE_NOTES);
                    this.setSource(
                      "https://microsoft.sharepoint.com/teams/CS_CAPE/SitePages/NewHome.aspx#release-notes"
                    );
                    logEvent(Navigation, {
                      AppName: "CAPE",
                      AppLayer: "UI",
                      MetricName: "SharePointTabNavigation",
                      UserScenario: "User navigation",
                      User: { Alias: this.props.userContext.data?.email },
                      ApplicationRoles: this.props.userContext.data?.roles,
                      EntityOperation: "Click",
                      EntityName: "Release Notes",
                      MetaData: {
                        programName: engProgramName ? engProgramName : null,
                      },
                      traceSeverity: "Low",
                    });
                  }}
                >
                  Release Notes
                </a>
              </div>
            </TooltipHost>
          </div>
       
          </div>
        </div>

        <div className="cape-sp-holder">
          <iframe
            className={
              this.state.copilotPinnedState === true
                ? "cape-sp-pinned"
                : "cape-sp"
            }
            aria-label="Getting Started"
            src={this.state.source}
          ></iframe>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: { ui: any; auth: any }) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingComponentHolder);
