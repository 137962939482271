import {
  DefaultButton,
  NeutralColors,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import {
  DialogContent,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import React from "react";

interface FeedbackCalloutProps {
  calloutTarget: string;
  isActive: boolean;
  label: string;
  onFeedbackDismiss: Function;
  onFeedbackSend: Function;
  textFieldInputLimit: number;
  textFieldValue: string;
  textFieldPlaceholder?: string;
  renderAdditionalInputs?: Function | null;
  textFieldTitle: boolean;
  message: any;
  updateMessage: (messageId: string, message: any) => any;
  disabled?: boolean;
}

const useFeedbackCalloutStyles = makeStyles({
  stackContainer: {
    backgroundColor: NeutralColors.white,
    marginTop: "16px",
    outlineStyle: "none",
  },
  calloutContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    outlineStyle: "none",
    marginBottom: "16px",
  },
  text: {
    lineHeight: "16px",
    color: NeutralColors.gray160,
  },
  title: {
    fontWeight: "400",
    fontSize: "14px",
    color: NeutralColors.black,
    marginBottom: "14px",
  },
  margin16: {
    marginBottom: "16px",
  },
  detailBox: {
    ...shorthands.borderRadius("8px"),
    marginBottom: "16px",
  },
  defaultButton: {
    ...shorthands.borderRadius("8px"),
  },
});

const FeedbackCallout = ({
  label,
  onFeedbackDismiss,
  onFeedbackSend,
  textFieldInputLimit,
  textFieldValue,
  textFieldPlaceholder,
  renderAdditionalInputs,
  textFieldTitle,
  message,
  updateMessage,
  disabled,
}: FeedbackCalloutProps) => {
  const [textFieldErrorMessage, setTextFieldErrorMessage] =
    React.useState<string>("");

  const styles = useFeedbackCalloutStyles();

  const getErrorMessage = (value: string): string => {
    return value.length <= textFieldInputLimit
      ? ""
      : `Character limit of ${textFieldInputLimit} reached. Current number of characters: ${value.length}.`;
  };

  const renderCalloutDescription = () => {
    return (
      <Text variant="small" className={styles.text}>
        Your feedback on the Copilot experience will help us improve the quality
        of the answers Copilot provides.
      </Text>
    );
  };

  const renderLabel = () => {
    return (
      <Text variant="small" className={styles.title}>
        {textFieldTitle ? "What was the issue with you with the response?" : ""}
      </Text>
    );
  };

  const handleTextFieldChange = (newValue: string) => {
    updateMessage(message.id, { additionalUserFeedback: newValue });
    setTextFieldErrorMessage(getErrorMessage(newValue || ""));
  };

  return (
    <DialogContent className={styles.calloutContainer}>
      <Text variant="mediumPlus" className={styles.margin16}>
        {label}
      </Text>
      {renderAdditionalInputs && renderAdditionalInputs()}
      {renderLabel()}
      <TextField
        errorMessage={textFieldErrorMessage}
        rows={5}
        placeholder={textFieldPlaceholder}
        multiline
        onChange={(event, newValue) => {
          handleTextFieldChange(newValue || "");
        }}
        className={styles.detailBox}
        resizable={false}
        value={textFieldValue}
      />
      {renderCalloutDescription()}
      <Stack
        className={styles.stackContainer}
        horizontal
        tokens={{ childrenGap: 16 }}
        horizontalAlign="end"
      >
        <DefaultButton
          className={styles.defaultButton}
          disabled={disabled}
          onClick={() => onFeedbackSend()}
          primary
          text="Send"
        />
        <DefaultButton
          className={styles.defaultButton}
          onClick={() => onFeedbackDismiss()}
          text="Not now"
        />
      </Stack>
    </DialogContent>
  );
};

export default FeedbackCallout;
