import React from 'react'
import "./EngagementsList.css";
import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Selection
} from "@fluentui/react/lib/DetailsList";
import { ENGAGEMENTS_URL } from '../../constants/routes';
import { IEngagementDetailData } from '../../constants/types/UI/Engagements';
import { FontIcon } from '@fluentui/react';
import { ReactComponent as NoItemsFoundIcon } from '../../assets/icons/noitemsfound.svg';

interface IEngagementListState {
}

export interface IEngagementList {
  EngagementId: number;
  Customer_Onboarding: string;
  Description: string;
  OfferTitle: string;
  OfferCode: string;
  SubmittedDateTime: string;
  LiveDateTime: string;
  DeliveryLead: string;
}

interface IEngagementListProps {
  _columns: IColumn[];
  items: any;
  history: any;
  searchTerm: any;
  activeStatusData: {
    displayText: string,
    count: number
  }
  fetching : boolean;
}

export class EngagementsList extends React.Component<
  IEngagementListProps,
  IEngagementListState
> {
  private rowSelector: Selection;
  constructor(props: IEngagementListProps) {
    super(props);

    this.rowSelector = new Selection({
      onSelectionChanged: this.onActionGridRowSelect,
    });

    this.removeAllGridSelection = this.removeAllGridSelection.bind(this);
  }

  private onActionGridRowSelect = () => {
    let selectedRows: any = this.rowSelector.getSelection();
    if (selectedRows && selectedRows.length > 0) {
      let row: IEngagementDetailData = selectedRows[0];
      this.props.history.push(`${ENGAGEMENTS_URL}?id=${row.EngagementId}`);
    }
  };

  intervalId: any;

  addScrollListener = () => {
    const element = document.querySelector(".engagements-detail-list .ms-DetailsList-contentWrapper");
    if (element) {
      element.addEventListener("scroll", (event: any) => {
        this.runOnScroll(event);
      });
      clearInterval(this.intervalId);
    }
  }

  runOnScroll = (event: any) => {
    const headerElement = document.querySelector(".engagements-detail-list .ms-DetailsList-headerWrapper");
    if (headerElement) {
      headerElement.scrollLeft = event.target.scrollLeft;
    }
  }

  removeAllGridSelection = () => {
    this.rowSelector.setAllSelected(false);
  }

  componentDidMount() {
    this.intervalId = setInterval(this.addScrollListener, 2000);
    document.addEventListener('triggerEngagementUpdate',this.removeAllGridSelection,true);
  }

  componentWillUnmount(){
    document.removeEventListener('triggerEngagementUpdate',this.removeAllGridSelection,true);
  }

  componentDidUpdate(prevProps: IEngagementListProps) {
    this.intervalId = setInterval(this.addScrollListener, 2000);
  }

  render() {  
    if (this.props.items.length > 0) {
      return (
        <div className="ListContainer engagements-detail-list" role="alert" aria-labelledby="Engagement List">
          <h4 className="engagementCategoryTitle">
            {this.props.activeStatusData.displayText
              ? this.props.activeStatusData.displayText
              : ""}
          </h4>
          { this.props.searchTerm == '' ? <h4 style={{position:'absolute', width:'1px', height: '1px', margin: '-1px', padding: '0', overflow: 'hidden', clip: 'rect(0,0,0,0)', border: '0'}} role="alert" tabIndex={0}>Table updated table with {this.props.items.length} rows and 8 columns</h4>
          : <h4 style={{position:'absolute', width:'1px', height: '1px', margin: '-1px', padding: '0', overflow: 'hidden', clip: 'rect(0,0,0,0)', border: '0'}} role="alert" tabIndex={0}>{this.props.items.length} suggestions are available</h4>}
           {/* <div role="log" aria-label='Engagement list'> */}
          <DetailsList
              className="actionDetailsGrid"
              items={this.props.items}
              columns={this.props._columns}
              setKey="set"
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              selection={this.rowSelector}
              isHeaderVisible={true}
              compact={false}
              // layoutMode={DetailsListLayoutMode.fixedColumns}
              constrainMode={ConstrainMode.unconstrained}
              checkboxVisibility={CheckboxVisibility.hidden}
              ariaLabel={this.props.activeStatusData.displayText + " table"}

            />
        </div>
      // </div>
      );
    } else {
      return (
        <div className="ListContainer">
          <h4 className="engagementCategoryTitle">
            {this.props.activeStatusData.displayText
              ? this.props.activeStatusData.displayText
              : ""}
          </h4>
          <div className="noRecord">
            {!this.props.fetching && <div className="no-item-container">
              <div><NoItemsFoundIcon ></NoItemsFoundIcon> </div>
              <div className="no-item-text" role="alert">No Engagements found !</div>
            </div>}
          </div>
        </div>
      );
    }
  }
}

export default EngagementsList
