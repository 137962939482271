import React, { useEffect, useState } from "react";
import styles from "./copilot.module.css";
import { Image, IImageProps } from "@fluentui/react/lib/Image";
import {
  Pin20Regular,
  Pin20Filled,
  Megaphone20Regular,
  Dismiss20Regular,
  QuestionCircle16Regular,
} from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
// import { UATConstants } from "src/Common/Models/UATConstants";
import CopilotIcon from "./Asset/Copilot.png";
import {
  DirectionalHint,
  IContextualMenuProps,
  IIconProps,
  IconButton,
  Stack,
  Text,
} from "@fluentui/react";

interface ITeachingBubbleProps {
  isTeachingBubbleVisible: boolean;
  setIsTeachingBubbleVisible: (isTeachingBubbleVisible: boolean) => void;
  onTeachingBubbleDismiss: () => void;
}

type HeaderProps = {
  appInsights: any;
  copilotContext: any;
  isOpen: boolean;
  refreshChat: Function;
  handleBanner: Function;
  teachingBubbleProps?: ITeachingBubbleProps | undefined;
  onDismiss: () => void;
  onDismissPinnedMode: () => void;
  handleCloseWindow: () => void;
  handleFloatingIcon: Function;
  copilotPinAction: Function;
  showBanner: boolean;
  setShowPinned: Function;
  showPinned: boolean;
  showBannerNotification: boolean;
  isEngagementWindowOpen: boolean;
};

const RenderDefaultHeader = ({
  appInsights,
  copilotContext,
  isOpen,
  refreshChat,
  handleBanner,
  teachingBubbleProps,
  onDismiss,
  onDismissPinnedMode,
  handleCloseWindow,
  copilotPinAction,
  showBanner,
  setShowPinned,
  showPinned,
  showBannerNotification,
  isEngagementWindowOpen,
}: HeaderProps) => {
  const imageProps: Partial<IImageProps> = {
    src: CopilotIcon,
  };
  const moreIcon: IIconProps = {
    iconName: "More",
    styles: { root: { color: "black" } },
  };

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: "Help",
        text: "Help",
        iconProps: { iconName: "Question" },
        onRenderIcon: () => <QuestionCircle16Regular />,
        onClick: () => {
          window.open(
            "https://microsoft.sharepoint.com/sites/AICopilotCSAE",
            "_blank"
          );

          appInsights.trackEvent({ name :`${copilotContext.appInsightsEventNamePrefix}HelpClick`}
          ,
          {
            UserName: copilotContext.currentUserId,
          });
        },
      },
    ],
    directionalHint: DirectionalHint.bottomRightEdge,
    directionalHintFixed: true,
  };
  return (
    <div className={styles.copilotPanelHeader}>
      <span>
        <Image
          {...imageProps}
          alt="Example with no image fit value and only width is specified."
          width={24}
        />
      </span>
      <Text variant="xLarge" style={{ fontWeight: 600, paddingLeft: "10px" }}>
        Copilot
      </Text>
      <span className={styles.super}>Beta</span>

      {isOpen && (
        <Stack
          horizontal
          tokens={{ childrenGap: 8 }}
          style={{ display: "flex", marginLeft: "auto" }}
        >
          <IconButton
            className={styles.headerButton}
            menuProps={menuProps}
            iconProps={moreIcon}
            title="More"
            ariaLabel="More"
            onRenderMenuIcon={() => {
              return null;
            }}
            //If there is a teaching bubble, then we want to close it when the contextual menu is activated
            {...(teachingBubbleProps !== undefined && {
              onMenuClick: () =>
                teachingBubbleProps.setIsTeachingBubbleVisible(false),
            })}
          />
          <Button
            icon={showPinned ? <Pin20Filled /> : <Pin20Regular />}
            className={styles.headerButton}
            disabled={isEngagementWindowOpen}
            title={showPinned ? "Pinned" : "Unpinned"}
            aria-labelledby="Pinned"
            onClick={() => {
              appInsights.trackEvent(
                 {name:`${copilotContext.appInsightsEventNamePrefix}${
                  showPinned ? "Unpinned" : "Pinned"
                }`},
                {
                  
                  UserName: copilotContext.currentUserName,
                  UserEmail: copilotContext.currentUserId                
                }
              );
              
              if (!showPinned) {
                copilotPinAction();
                setShowPinned(!showPinned);
                return;
              }
              onDismissPinnedMode();
              setShowPinned(!showPinned);
            }}
          />
          <Button
            icon={<Megaphone20Regular />}
            className={styles.headerButton}
            title="Announcements"
            aria-labelledby="Announcements"
            onClick={() => handleBanner(!showBanner)}
          />
          <Button
            aria-label="close"
            icon={<Dismiss20Regular />}
            className={styles.headerButton}
            onClick={handleCloseWindow}
            title="Close"
          />
        </Stack>
      )}
    </div>
  );
};

export default RenderDefaultHeader;
