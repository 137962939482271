import { ENGAGEMENT_STATUS, ENGAGEMENT_VIEWS, MAPPED_ENGAGEMENT_STATUS } from "./Engagements"
import { OFFERS_STATE } from "./Offers"

export interface IPeople {
    name: string;
    alias: string;
    imageUrl?: string;
}

export interface IField<T> {
    value?: T;
    errorMessage?: string;
    required?: boolean;
}
export interface IDate<T> {
    value?: T;
    errorMessage?: string;
    required?: boolean;
}
export interface IColumn {
    id: string | number;
    key: string | number;
    metaData: any;
}

export interface ISorting {
    column: string;
    order: "asc" | "desc";
}

export interface IScope {
    Show: boolean;
    Create: boolean;
    Edit: boolean;
    Delete: boolean;
}

export interface IAppScopes {
    Engagements: IScope;
    Offers: IScope;
    Reports: IScope;
    Participants: IScope;
}

export var AppScopes: IAppScopes = {
    Engagements: {
        Show: false,
        Create: false,
        Edit: false,
        Delete: false
    },
    Offers: {
        Show: false,
        Create: false,
        Edit: false,
        Delete: false
    },
    Reports: {
        Show: false,
        Create: false,
        Edit: false,
        Delete: false
    },
    Participants: {
        Show: false,
        Create: false,
        Edit: false,
        Delete: false
    }
}

export interface IUserContext {
    displayName: string,
    email: string,
    givenName: string,
    lastName: string,
    roles: {name : string}[],
    scopes: string[],
    showCSATModule: boolean,
    flights: string[],
    restrictLiveEngagementLimit:number,
    enableCreateEngagementRestriction:boolean,
    commonservicesAuthToken:string

}


export const activeSideNavs = {
    engagements : MAPPED_ENGAGEMENT_STATUS.LIVE.IDENTIFIER,
    offers : OFFERS_STATE.ACTIVE
}

export const activeEngagementView = {
    view : ENGAGEMENT_VIEWS.MY_ENGAGEMENTS.key
}

export const searchCalled = {
    engagementSearchCalled : false,
    offerSearchCalled : false,
    participantSearchCalled : false
}