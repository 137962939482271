import React from "react";
import { Broom24Regular } from "@fluentui/react-icons";
import { Divider, Button, Textarea, makeStyles } from "@fluentui/react-components";
import { chatFooterStyles } from "./ChatFooter.Styles";
import { CAPECopilotConstants } from "../../Constants";
import { IIconProps, IconButton, TooltipHost } from "@fluentui/react";

type ChatFooterProps = {
  disabled: boolean;
  textFieldValue: string;
  textFieldMaxInputLimit?: number;
  textFieldMinInputLimit?: number;
  isPinned?: boolean;
  setTextFieldValue: (textFieldValue: string) => void;
  setMessages: Function;
  sendMessage: (messageText: string) => void;
  clearMessage: Function;
  refreshChat: Function;
};
const textAreaStyles = makeStyles({
  base: {
    display: "flex",
    alignItems: 'center',
    '& > :nth-child(1)': {
      flex: '1',
    },
  },
});

const Footer = ({
  disabled,
  textFieldValue,
  textFieldMaxInputLimit = 1500,
  textFieldMinInputLimit = 10,
  sendMessage,
  setTextFieldValue,
  isPinned,
  refreshChat,
}: ChatFooterProps) => {
  const [textFieldValueLength, setTextFieldValueLength] =
    React.useState<number>(0);
  const [textFieldErrorMessage, setTextFieldErrorMessage] =
    React.useState<string>("");
  const [disabledButton, setDisabledButton] = React.useState<boolean>(false);

  React.useEffect(() => {
    let isDisabled =
      textFieldValue.length === 0 ||
      disabled ||
      !(
        textFieldValue.length >= textFieldMinInputLimit &&
        textFieldValue.length <= textFieldMaxInputLimit
      );
    setDisabledButton(isDisabled);
    setTextFieldValueLength(textFieldValue.length);
  }, [textFieldValue]);

  const handleTextFieldKeyDown = (event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (textFieldValue.length < textFieldMinInputLimit) {
        setTextFieldErrorMessage(
          `Character minimum of ${textFieldMinInputLimit} not reached. Current number of characters: ${textFieldValue.length}.`
        );
      }
    }
  };

  const onSendButtonClicked = () => {
    sendMessage(textFieldValue.trim());
    setTextFieldValue("");
    setTextFieldValueLength(0);
  };

  const onEnterClicked = (e: any) => {
    if (e === 'Enter') {
      onSendButtonClicked()
    }
  };

  const handleOnChange = (event: any) => {
    const value = event.target.value;
    setTextFieldValueLength(value ? value.length : 0);
    setTextFieldErrorMessage(getOnChangeErrorMessage(value || ""));
    setTextFieldValue(value || "");
  };

  const handleClearCoversation = () => {
    refreshChat();
  };

  const getOnChangeErrorMessage = (value: string): string => {
    if (
      textFieldErrorMessage !== "" &&
      value.length < textFieldMinInputLimit &&
      value.length !== 0
    ) {
      return `Character minimum of ${textFieldMinInputLimit} not reached. Current number of characters: ${value.length}.`;
    }
    if (value.length > textFieldMaxInputLimit) {
      return `Character limit of ${textFieldMaxInputLimit} reached. Current number of characters: ${value.length}.`;
    }
    return "";
  };

  const handleOnBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (
      textFieldValue.length < textFieldMinInputLimit &&
      textFieldValue.length !== 0
    ) {
      setTextFieldErrorMessage(
        `Character minimum of ${textFieldMinInputLimit} not reached. Current number of characters: ${textFieldValue.length}.`
      );
    }
  };

  const sendIcon: IIconProps = {
    iconName: "Send",
    style: {
      fontSize: 22,
      color: "black",
    },
  };

  const broomIcon: IIconProps = {
    iconName: "Broom",
    style: {
      fontSize: 22,
      color: "black",
    },
  };
  const styles = textAreaStyles();

  return (
    <div style={chatFooterStyles.ChatRoot} className="no-cursor">
       <div className={styles.base}>
        <Textarea
          maxLength={2000}
          value={textFieldValue}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onKeyDown={handleTextFieldKeyDown}
          aria-label='Copilot Chat'
          placeholder='Ask me questions about Actions on UA Tracker'
          onKeyDownCapture={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
          onKeyUp={(e) => { onEnterClicked(e.key) }}
          onSubmit={onSendButtonClicked}
        />
        <TooltipHost content={'Clear Coversation'}>
          <IconButton
            iconProps={broomIcon}
            ariaLabel='Clear Coversation'
            onClick={handleClearCoversation}
          />
        </TooltipHost>
        <TooltipHost content={'Send'}>
          <IconButton
            iconProps={sendIcon}
            ariaLabel='Send Icon'
            onClick={onSendButtonClicked}
            disabled={disabledButton}
          />
        </TooltipHost>
      </div>
      <div style={chatFooterStyles.aiDisclaimer}>
        {CAPECopilotConstants.disclaimerLine1}
        <br />
        {CAPECopilotConstants.disclaimerLine2}
      </div>
    </div>
  );
};

export default Footer;
