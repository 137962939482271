
import { IAction } from '../../constants/types/IAction';

const INIT_URL_STATE_CHANGE = "INIT_URL_STATE_CHANGE";

export const initialState = true;

export const changeInitUrlState = (state : boolean) => {
    return {
        type : INIT_URL_STATE_CHANGE,
        payload : state
    }
}

export const InitUrlStateReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case INIT_URL_STATE_CHANGE:
            return action.payload    
        default:
            return state;
    }
}