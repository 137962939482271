import { IAction } from '../../constants/types/IAction';
import {SET_BANNER} from '../../constants/actions/UI/BannerMessage';
import {IBannerMessagePayload} from '../../constants/types/UI/BannerMessage';


export function setBanner(payload : IBannerMessagePayload): IAction {
    return {
        type: SET_BANNER,
        payload: payload
    }
}
