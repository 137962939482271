import { IAction } from '../../constants/types/IAction';
import { GET_PARTICIPANTS} from '../../constants/actions/UI/Participants';
import { IGetParticipantPayload } from '../../constants/types/UI/Participants';



export function getParticipants(payload : IGetParticipantPayload): IAction {
  return {
    type: GET_PARTICIPANTS,
    payload
  };
}



