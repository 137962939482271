import { NeutralColors, SharedColors } from "../../Constants";

export const chatFooterStyles = {
  ChatRoot: {
    margin: 8,
  },
  footerText: {
    marginTop: 8,
  },
  iconButton: {
    borderRadius: "50%",
  },
  iconButtonPrimary: {
    root: {
      color: NeutralColors.white,
      backgroundColor: SharedColors.cyanBlue10,
      borderRadius: "50%",
      marginRight: 8,
    },
    rootHovered: {
      color: NeutralColors.white,
      backgroundColor: SharedColors.cyanBlue15,
    },
    rootPressed: {
      color: NeutralColors.white,
      borderRadius: "50%",
      backgroundColor: SharedColors.cyanBlue17,
    },
  },
  textField: {
    maxHeight: "25vh",
    overflow: "auto",
  },
  textFieldContainer: {
    backgroundColor: NeutralColors.white,
    borderRadius: "10px",
    overflow: "hidden",
    padding: "10px"
  },
  textAreaGrid: {
    flex: 1,
    padding: 10,
    color: SharedColors.darkSilver,
  },
  aiDisclaimer: {
    color: "rgba(112, 112, 112, 1)",
    fontSize: "10px",
    fontWeight: 400,
    fontFamily: "Segoe UI",
    lineHeight: "14px",
    marginTop: 8,
  },
};
