import { TOGGLE_NAV_BAR } from '../../constants/actions/UI/navigation';
import { IAction } from '../../constants/types/IAction';

export const initialState: boolean = true;

export const NavBarReducers = (state = initialState, action: IAction) => {
    switch (action.type) {
        case TOGGLE_NAV_BAR:
            if (action.payload !== state) return action.payload;
            return !state;
        default:
            return state;
    }
}

export default NavBarReducers;