import React from 'react'
import "./ParticipantsList.css";
import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import { IParticipantsData } from '../../constants/types/UI/Participants';
import { FontIcon } from '@fluentui/react';

interface IParticipantsListState {
}

interface IParticipantsListProps {
  participantsData: IParticipantsData[];
  participantsHeaderData: IColumn[];
  activeMenu: string;
  history: any;
  activeStatusData: {
    displayText: string,
    count: number
  }
  fetching : boolean;
}

export class ParticipantsList extends React.Component<IParticipantsListProps, IParticipantsListState> {

  intervalId: any;

  constructor(props: IParticipantsListProps) {
    super(props);
  }

  addScrollListener = () => {
    const element = document.querySelector(".participants-detail-list .ms-DetailsList-contentWrapper");
    if (element) {
      element.addEventListener("scroll", (event: any) => {
        this.runOnScroll(event);
      });
      clearInterval(this.intervalId);
    }
  }

  runOnScroll = (event: any) => {
    const headerElement = document.querySelector(".participants-detail-list .ms-DetailsList-headerWrapper");
    if (headerElement) {
      headerElement.scrollLeft = event.target.scrollLeft;
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.addScrollListener, 2000);
  }

  componentDidUpdate(prevProps: IParticipantsListProps) {
    this.intervalId = setInterval(this.addScrollListener, 2000);
  }


  render() {
    if (this.props.participantsData.length > 0) {
      return (
        <div className="ListContainer participants-detail-list" role="main" aria-label="Participants list">
          <h4 className="participantsTitle">{this.props.activeMenu}</h4>
          <div role="log" aria-labelledby='Participants list' aria-describedby='Participants list' >
            <DetailsList
              className="actionDetailsGrid"
              items={this.props.participantsData}
              columns={this.props.participantsHeaderData}
              selectionMode={SelectionMode.none}
              setKey="set"
              layoutMode={DetailsListLayoutMode.fixedColumns}
              isHeaderVisible={true}
              compact={false}
              constrainMode={ConstrainMode.unconstrained}
              checkboxVisibility={CheckboxVisibility.hidden}
              ariaLabel={this.props.activeMenu + " table"}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="ListContainer">
          <h4 className="participantsTitle">{this.props.activeMenu}</h4>
          <div className="noRecord">
            {!this.props.fetching && <div className="no-item-container">
              <div><FontIcon className="no-items-icon" iconName="SearchAndApps" /></div>
              <div className="no-item-text" role="alert">No Participants to display here !</div>
            </div>}
          </div>
        </div>
      );
    }
  }
}

export default ParticipantsList
