import { CREATE_OFFER_IN_PC, ENGAGEMENT_UPSERT } from "../../constants/actions/API/EngagementUpdate";
import {IEngagementDetailData} from "../../constants/types/UI/Engagements";

export const upsertEngagement = (payload : Partial<IEngagementDetailData>) => {
    return {
        type : ENGAGEMENT_UPSERT,
        payload : payload
    }
}

export const createOfferInPC = (payload : Partial<IEngagementDetailData>) => {
    return {
        type : CREATE_OFFER_IN_PC,
        payload : payload
    }
}