import React from 'react';
import unauthorizedIcon from "../../../assets/unauthorized.png";
import { HOME_URL } from '../../../constants/routes';
import "./Unauthorized.css";

interface IUnauthorizedProps {
  history: any;
}

export class Unauthorized extends React.Component<IUnauthorizedProps, {}> {
  navigate = () => {
    this.props.history.push(`/${HOME_URL}`);
  };

  render() {
    return (
      <div className="unauthorized-div">
        <div>
          <img
            className="unauthroized-img"
            alt="unauthorized"
            src={unauthorizedIcon}
          />
          <h1>You are not authorized to this content/action</h1>
          <h4 style={{cursor: "pointer"}} onClick={this.navigate}>Go to Homepage</h4>
        </div>
      </div>
    );
  }
}

export default Unauthorized;
