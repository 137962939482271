import React, { createContext } from "react";
import {
  Dropdown,
  IDropdownOption,
  SearchBox,
  PrimaryButton,
  IIconProps,
  MessageBarType,
  DirectionalHint,
  ITooltipHostStyles,
  TooltipHost,
} from "@fluentui/react";
import Board from "../board/Board";
import "./Engagements.css";
import { connect } from "react-redux";
import {
  IEngagementDetail,
  MAPPED_ENGAGEMENT_STATUS,
  ENGAGEMENT_VIEWS,
} from "../../constants/types/UI/Engagements";
import { ENGAGEMENTS_URL } from "../../constants/routes";
import { Account, AuthResponse } from "msal";
import {
  activeEngagementView,
  activeSideNavs,
  IAppScopes,
  IPeople,
  ISorting,
  searchCalled,
} from "../../constants/types/UI/Generic";
import { IBannerMessagePayload } from "../../constants/types/UI/BannerMessage";
import { setBanner } from "../../actions/UI/BannerMessage";
import AppConstants, {
  AppRoles,
  entityQueryString,
} from "../../constants/AppConstants";
import _ from "lodash";
import {
  ActionTrackingEngagement,
  ActionTrackingSearch,
  logEvent,
  Navigation,
} from "../../utils/insightsClient";
import { parseQS } from "../../utils/helperFunctions";
import { Text } from '@fluentui/react';

interface IEngagementsPageProps {
  history: any;
  graphTokenObj: AuthResponse | null;
  setBanner: Function;
  buildAndShowBanner: (
    message: string,
    messageType: MessageBarType,
    timeOut: number
  ) => void;
  appScopes: IAppScopes;
  roles: { name: string }[] | undefined;
  user: Account;
}

interface IEngagementsPageState {
  selectedView: string;
  searchTerm: string;
  activeStatus: string;
  sortingOrder: ISorting;
  actualSearchTerm: string;
  views: IDropdownOption<any>[];
  copilotPinnedState: boolean;
}

const ttstyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};
const ttcalloutProps = { gapSpace: 0 };

class Engagements extends React.Component<
  IEngagementsPageProps,
  IEngagementsPageState
> {
  searchTimer: any;
  constructor(props: IEngagementsPageProps) {
    super(props);
    this.state = {
      selectedView: _.cloneDeep(activeEngagementView).view,
      searchTerm: "",
      actualSearchTerm: "",
      sortingOrder: { column: "SubmittedDateTime", order: "desc" },
      activeStatus: activeSideNavs.engagements,
      views: this.buildViews(),
      copilotPinnedState: this.getPinnedState(),
    };
  }

  buildViews = () => {
    var viewdropDownOptions: IDropdownOption<any>[] = [
      {
        key: ENGAGEMENT_VIEWS.MY_ENGAGEMENTS.key,
        text: ENGAGEMENT_VIEWS.MY_ENGAGEMENTS.text,
      },
      {
        key: ENGAGEMENT_VIEWS.MY_TEAM_ENGAGEMENTS.key,
        text: ENGAGEMENT_VIEWS.MY_TEAM_ENGAGEMENTS.text,
      },
    ];
    if (
      this.props.roles?.find(
        (role) => role.name === AppRoles.SiteAdmin.roleName
      )
    ) {
      viewdropDownOptions.push({
        key: ENGAGEMENT_VIEWS.ALL_ENGAGEMENTS.key,
        text: ENGAGEMENT_VIEWS.ALL_ENGAGEMENTS.text,
      });
    }

    return viewdropDownOptions;
  };

  componentDidMount() {
    window.addEventListener("storage", this.handlePinnedState, false);
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.handlePinnedState, false);
  }

  handlePinnedState = () => {
    this.setState({ copilotPinnedState: this.getPinnedState() });
  };
  getPinnedState = (): boolean => {
    const isPinned = sessionStorage.getItem("copilotPinnedState");
    if (isPinned) {
      return JSON.parse(isPinned);
    }
    return false;
  };

  createEngagement = () => {
    sessionStorage.setItem("showCopilotWindow", String(false));
    sessionStorage.setItem("engagementPanelOpen", String(true));
    window.dispatchEvent(new Event("storage"));
    const parsedQS = parseQS(this.props.history["location"]["search"]);
    var engProgramName =
      parsedQS != null ? parsedQS[entityQueryString.ProgramName] : "";
    logEvent(ActionTrackingEngagement, {
      AppName: AppConstants.ApplicationAlias,
      AppLayer: "UI",
      metricName: "CreateEngagement",
      UserScenario: "User Clicks on Create Engagement Button",
      User: { Alias: this.props.user.userName },
      applicationRoles: this.props.roles?.find((role) => role.name),
      entityOperation: "Create",
      entityName: "",
      metaData: {
        sourcePage: "Engagements",
        programName: engProgramName ? engProgramName : null,
      },
      traceSeverity: "Low",
    });
    this.props.history.push(
      `/${ENGAGEMENTS_URL}?id=new${this.props.history["location"][
        "search"
      ].replace("?", "&")}`
    );
  };

  changeActiveStatus = (payload: string) => {
    this.setState({ activeStatus: payload });
  };

  changeSortingOrder = (payload: ISorting) => {
    this.setState({ sortingOrder: payload });
  };

  render() {
    return (
      <div className="engagements-page">
      <Text variant="xLarge" block styles={{ root: { fontSize:"22px", margin: "10px" } }}>
        Engagements
      </Text>
        <div className="eg-search-view-people">
        
          <div className="eg-search-view">
            <div className="eg-view">
              <div style={{ paddingRight: "10px", fontWeight: 400 }}>View:</div>
              <div style={{ height: "32px" }}>
                <Dropdown
                  aria-label="select a view"
                  placeholder="Select a view"
                  options={this.state.views}
                  selectedKey={this.state.selectedView}
                  styles={{
                    root: { display: "inline-block", width: "150px" },
                  }}
                  onChange={(
                    event: React.FormEvent<HTMLDivElement>,
                    option?: IDropdownOption<any> | undefined
                  ) => {
                    if (!!option) {
                      activeEngagementView.view = option.key.toString();
                      this.setState({
                        selectedView: option.key.toString(),
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="eg-search">
              <SearchBox
                placeholder="Search by Keyword"
                value={this.state.searchTerm}
                id="search-box-board"
                autoComplete="off"
                onChange={(_, newValue) => {
                  this.setState({
                    searchTerm: newValue ? newValue : "",
                  });
                  clearTimeout(this.searchTimer);
                  this.searchTimer = setTimeout(() => {
                    searchCalled.engagementSearchCalled = true;
                    this.setState({
                      actualSearchTerm: newValue ? newValue : "",
                    });
                  }, 900);
                  logEvent(ActionTrackingSearch, {
                    AppName: AppConstants.ApplicationAlias,
                    AppLayer: "UI",
                    metricName: "search",
                    UserScenario: "User performs search",
                    User: { Alias: this.props.user.userName },
                    applicationRoles: this.props.roles?.find(
                      (role) => role.name
                    ),
                    entityOperation: "search",
                    entityName: "Engagements",
                    metaData: {
                      searchString: newValue,
                      resultsCount: "",
                      selectedTab: this.state.activeStatus,
                      selectedView: this.state.selectedView,
                    },
                    traceSeverity: "Medium",
                  });
                }}
              />
            </div>

            {/* <div style="display: flex; align-items: center;"><div role="search" class="ms-SearchBox search-grid-ViewHolder root-80"><div class="ms-SearchBox-iconContainer iconContainer-81" aria-hidden="true"><i data-icon-name="Search" aria-hidden="true" class="ms-SearchBox-icon icon-86"></i></div><input id="search-box" autocomplete="off" class="ms-SearchBox-field field-84" placeholder="Search by keyword" role="searchbox" value=""></div><div class="search-button-cont " style="border-top: 0.5px solid black; border-right: 0.5px solid black; border-bottom: 0.5px solid black; border-top-right-radius: 2px; border-bottom-right-radius: 2px;"><abbr title="Search"><i data-icon-name="Search" aria-hidden="true" class="search-button root-87"></i></abbr></div></div> */}
          </div>
          <div className="eg-add">
            {this.props.appScopes.Engagements.Create && (
              <TooltipHost
                directionalHint={DirectionalHint.bottomCenter}
                tooltipProps={{
                  onRenderContent: () => (
                    <div>Create a new engagement in CAPE</div>
                  ),
                }}
                calloutProps={ttcalloutProps}
                closeDelay={200}
                styles={ttstyles}
              >
                <PrimaryButton
                  className="add-eg-btn"
                  onClick={this.createEngagement}
                  text={`Create Engagement`}
                />
              </TooltipHost>
            )}
          </div>
        </div>
        <Board
          dndHandler={(a: string, b: string) => {}}
          searchTerm={this.state.actualSearchTerm}
          activeStatus={this.state.activeStatus}
          changeActiveStatus={this.changeActiveStatus}
          history={this.props.history}
          selectedView={this.state.selectedView}
          sortingOrder={this.state.sortingOrder}
          changeSortingOrder={this.changeSortingOrder}
          buildAndShowBanner={this.props.buildAndShowBanner}
          roles={this.props.roles}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: { ui: any; auth: any }) => ({
  graphTokenObj: state.auth.accessToken,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  setBanner: (payload: IBannerMessagePayload) => dispatch(setBanner(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Engagements);
